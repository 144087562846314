import { fetchShiftPositions } from 'src/apps/company-frontend/services/job/api';
import { ShiftPosition, ShiftPositions } from 'src/types/job.detail.type';
import { convertToTimezone } from 'src/utils/DateUtils';
import { IWorkerData } from 'src/types/worker.type';
import { ShiftStatus } from 'src/types/jobs.type';
import QueryKeys from 'src/constants/queryKeys';
import { EventInput } from '@fullcalendar/core';
import _, { groupBy, sortBy } from 'lodash';
import { isAfter, isToday } from 'date-fns';
import { AxiosError } from 'axios';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { initialApplicantFilters, useJobContext } from './jobContext';
import ApplicantFilter from '../types/applicant-filter.type';

interface IJobShiftContext {
  error?: AxiosError<unknown, any> | null;
  events: Array<EventInput>;
  shifts?: ShiftPosition[];
  isLoading?: boolean;
  isRefetching: boolean;
  statusFilter: string[];
  workersFilter: IWorkerData[];
  filteredShifts: ShiftPosition[];
  selectedShifts: ShiftPosition[];
  selectedWorker: IWorkerData | undefined;
  applicantFilters: ApplicantFilter;
  workersFilterList: IWorkerData[];
  setEvents: (events: EventInput[]) => void;
  setStatusFilter: (status: string[]) => void;
  setWorkersFilter: (workers: IWorkerData[]) => void;
  setFilteredShifts: (filteredShifts: ShiftPosition[]) => void;
  setSelectedShifts: (selectedShifts: ShiftPosition[]) => void;
  setSelectedWorker: (worker: IWorkerData | undefined) => void;
  setApplicantFilters: (filter: ApplicantFilter) => void;
  setWorkersFilterList: React.Dispatch<React.SetStateAction<IWorkerData[]>>;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<ShiftPositions, AxiosError<unknown, any>>>;
}

const JobShiftContext: IJobShiftContext | any = createContext({});

export function JobShiftProvider(props: any) {
  const { job } = useJobContext();
  const [activeJobId, setActiveJobId] = useState<number>(0);
  const [shifts, setShifts] = useState<ShiftPosition[] | undefined>();
  const [filteredShifts, setFilteredShifts] = useState<ShiftPosition[]>([]);
  const [selectedShifts, setSelectedShifts] = useState<ShiftPosition[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [workersFilter, setWorkersFilter] = useState<IWorkerData[]>([]);
  const [workersFilterList, setWorkersFilterList] = useState<IWorkerData[]>([]);
  const [events, setEvents] = useState<Array<EventInput>>([]);

  const [selectedWorker, setSelectedWorker] = useState<IWorkerData | undefined>(
    undefined
  );
  const setJobParamId = useCallback((rawParamId: string) => {
    const sanitizedId = rawParamId ? rawParamId.split('--').pop() : '';
    const parsedId = sanitizedId ? parseInt(sanitizedId, 10) : 0;
    setActiveJobId(parsedId);
  }, []);
  const [applicantFilters, setApplicantFilters] = useState<ApplicantFilter>(
    initialApplicantFilters
  );

  const { isLoading, error, data, refetch, isRefetching } = useQuery<
    ShiftPositions,
    AxiosError
  >({
    queryKey: [QueryKeys.COMPANY_JOB_SHIFTS],
    queryFn: async () => await fetchShiftPositions(job!.id),
    enabled: job !== undefined && job.id > 0,
  });

  useEffect(() => {
    if (job?.id) {
      refetch();
    }
  }, [job?.id, refetch]);

  useEffect(() => {
    if (data?.shifts) {
      setShifts(data.shifts);
      setFilteredShifts(data.shifts);
      const workers = data.shifts
        .filter((shift) => shift.jobAssignment?.worker !== undefined)
        .map((shift) => {
          return shift.jobAssignment!.worker;
        });
      setWorkersFilterList(_.uniqBy(workers, 'id'));
    }
  }, [data]);

  const groupShiftsByMultipleFields = (
    shiftList: ShiftPosition[],
    fields: string[]
  ) => {
    return groupBy(shiftList, (item) =>
      fields.map((field) => item[field as keyof ShiftPosition]).join('-')
    );
  };

  useEffect(() => {
    const setCalendarEvents = () => {
      if (job?.address) {
        const groupByDate = groupShiftsByMultipleFields(
          sortBy(filteredShifts, (shift) => shift.start),
          ['start', 'end']
        );
        const filterByDate: { date: string; data: ShiftPosition[] }[] = [];

        Object.entries(groupByDate).forEach(([key, value]) => {
          filterByDate.push({ date: key, data: value });
        });

        const eventList =
          filterByDate.map((shift) => {
            const isPast = isAfter(new Date(), new Date(shift.data[0].start));
            const classNames = [];
            let backgroundColor = '--light-blue';
            if (isPast) {
              classNames.push('past-events');
            }
            const containsAssignment =
              shift.data.filter(
                (shiftData) => shiftData.jobAssignment !== undefined
              ).length > 0;
            if (containsAssignment) {
              backgroundColor = '--light-gray';
            }

            return {
              id: shift.data[0].id.toString(),
              title: shift.data[0].title.name,
              start: convertToTimezone(
                shift.data[0].start,
                job.address.timezoneId
              ),
              end: convertToTimezone(shift.data[0].end, job.address.timezoneId),
              allDay: false,
              backgroundColor,
              textColor: '',
              classNames,
              isPast,
              isToday: isToday(new Date(shift.data[0].start)),
            };
          }) ?? [];
        setEvents(eventList);
      }
    };
    setCalendarEvents();
  }, [filteredShifts, job]);

  useEffect(() => {
    const refreshFilteredShifts = () => {
      if (shifts) {
        if (
          statusFilter.length === 0 &&
          workersFilter.length === 0 &&
          !selectedWorker
        ) {
          setFilteredShifts(shifts);
          return;
        }
        let filteredData: ShiftPosition[] = shifts;
        if (statusFilter.length > 0) {
          filteredData = filteredData.filter((d) =>
            statusFilter.includes(d.shiftStatus)
          );
        }
        if (workersFilter.length > 0) {
          setWorkersFilterList((prev) =>
            prev.map((workerData) => ({
              ...workerData,
              isSelected: !!workersFilter.find(
                (worker) => worker.id === workerData.id
              ),
            }))
          );
          filteredData = filteredData.filter(
            (shift) =>
              shift.jobAssignment &&
              workersFilter.find(
                (worker) => worker.id === shift.jobAssignment?.worker.id
              )
          );
        }
        if (
          selectedWorker &&
          statusFilter.some((status) => status !== ShiftStatus.OPEN)
        ) {
          filteredData = filteredData.filter(
            (shift) =>
              shift.jobAssignment &&
              shift.jobAssignment.worker.id === selectedWorker.id
          );
        }
        setFilteredShifts(filteredData);
      }
    };
    refreshFilteredShifts();
  }, [selectedWorker, shifts, statusFilter, workersFilter]);

  const contextValue: IJobShiftContext = useMemo(() => {
    return {
      error,
      shifts,
      events,
      isLoading,
      activeJobId,
      isRefetching,
      statusFilter,
      workersFilter,
      selectedWorker,
      selectedShifts,
      filteredShifts,
      applicantFilters,
      workersFilterList,
      refetch,
      setEvents,
      setJobParamId,
      setActiveJobId,
      setStatusFilter,
      setWorkersFilter,
      setSelectedShifts,
      setFilteredShifts,
      setSelectedWorker,
      setWorkersFilterList,
      setApplicantFilters,
    };
  }, [
    error,
    shifts,
    events,
    isLoading,
    activeJobId,
    isRefetching,
    statusFilter,
    workersFilter,
    selectedWorker,
    selectedShifts,
    filteredShifts,
    applicantFilters,
    workersFilterList,
    refetch,
    setEvents,
    setJobParamId,
    setActiveJobId,
    setStatusFilter,
    setWorkersFilter,
    setSelectedShifts,
    setFilteredShifts,
    setSelectedWorker,
    setWorkersFilterList,
    setApplicantFilters,
  ]);
  const { children } = props;
  return (
    <JobShiftContext.Provider value={contextValue}>
      {children}
    </JobShiftContext.Provider>
  );
}

export function useJobShiftContext() {
  const context: IJobShiftContext = useContext(JobShiftContext);
  if (context === undefined) {
    throw new Error(
      'useJobContext must be used within a JobProvider. Wrap a parent component in <JobProvider> to fix this error.'
    );
  }
  return context;
}
