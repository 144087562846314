import { privateApi } from 'services/api';
import { CertificateSchemaType } from 'src/types/certificates.type';
import { Skill } from 'src/types/worker.type';
import {
  EducationSchemaType,
  EventExperienceSchemaType,
  IWorkerJobData,
  NonEventExperienceSchemaType,
  WorkerProfileSchemaType,
} from 'src/types/workerProfile.type';

export const WORKER_URL = 'worker';
const WORKER_PROFILE_URL = 'worker/profile';

export const getWorker = async () => {
  try {
    const result = await privateApi.get<IWorkerJobData>(`${WORKER_URL}/me`);
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

/**
 * WORKER PROFILE RELATED QUERIES
 */
export const getWorkerOptions = async () => {
  try {
    const result = await privateApi.get('search/options/worker');
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const addSkillsToWorker = async (skillsToAdd: Skill[]) => {
  try {
    const result = await privateApi.post(
      `${WORKER_PROFILE_URL}/skills`,
      skillsToAdd
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const deleteSkillFromWorker = async (skillId: number) => {
  try {
    const result = await privateApi.delete(
      `${WORKER_PROFILE_URL}/skills/${skillId}`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const addEducationToWorker = async (
  educationToAdd: EducationSchemaType
) => {
  try {
    const result = await privateApi.post(
      `${WORKER_PROFILE_URL}/education`,
      educationToAdd
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const deleteEducationFromWorker = async (educationId: number) => {
  try {
    const result = await privateApi.delete(
      `${WORKER_PROFILE_URL}/education/${educationId}`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const addEventExperience = async (
  eventExp: EventExperienceSchemaType
) => {
  try {
    const result = await privateApi.post(
      `${WORKER_PROFILE_URL}/experience/event`,
      eventExp
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const deleteEventExpFromWorker = async (eventExpId: number) => {
  try {
    const result = await privateApi.delete(
      `${WORKER_PROFILE_URL}/experience/event/${eventExpId}`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const addNonEeventExperience = async (
  nonEventExp: NonEventExperienceSchemaType
) => {
  try {
    const result = await privateApi.post(
      `${WORKER_PROFILE_URL}/experience/non-event`,
      nonEventExp
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const deleteNonEventExpFromWorker = async (eventExpId: number) => {
  try {
    const result = await privateApi.delete(
      `${WORKER_PROFILE_URL}/experience/non-event/${eventExpId}`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const updateWorkerProfile = async (
  workerProfile: WorkerProfileSchemaType
) => {
  return privateApi.post(
    `${WORKER_PROFILE_URL}/basic?expandFullWorkerProfile=true`,
    workerProfile
  );
};

export const updateWorkerCustomUrl = async (newCustomUrl: string) => {
  return privateApi.put(
    `${WORKER_URL}/set-profile-url?workerProfileCustomUrl=${newCustomUrl}`
  );
};

export const saveAdditionalPicture = async (
  path: string,
  name: string,
  contentType: string
) => {
  try {
    const result = await privateApi.post(`${WORKER_PROFILE_URL}/pictures`, [
      {
        path,
        name,
        contentType,
      },
    ]);
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const deleteAdditionalPicture = async (pictureId: number) => {
  try {
    const result = await privateApi.delete(
      `${WORKER_PROFILE_URL}/pictures/${pictureId}`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getAvailableCertificates = async () => {
  try {
    const result = await privateApi.get(`${WORKER_PROFILE_URL}/certificates`);
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const addWorkerCertificate = async (
  certificate: CertificateSchemaType
) => {
  try {
    const result = await privateApi.post(
      `${WORKER_PROFILE_URL}/certificates`,
      certificate,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const deleteWorkerCertificate = async (certificateId: number) => {
  try {
    const result = await privateApi.delete(
      `${WORKER_PROFILE_URL}/certificates/${certificateId}`
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
