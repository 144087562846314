import { Alert, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { calculateRemainingTime } from 'src/utils/DateUtils';
import { NOT_ACCEPTABLE } from 'src/constants/httpStatus';
import useAlert from 'src/hooks/useAlert';
import { AxiosError } from 'axios';
import SurveyHeaderInfo from './SurveyHeaderInfo';
import SurveyList from './SurveyList';
import './styles.scss';
import { getSurveyLink } from '../../services/survey/api';
import { lastClickTime } from './constants';
import { useSurveyContext } from '../../state/surveyContext';
import DisabledLayer from './DisabledLayer';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

function Survey() {
  const [surveyAttemptLimit, setSurveyAttemptLimit] = useState(false);
  const [isDisabledLocationLayerVisible, setIsDisabledLocationLayerVisible] =
    useState(false);
  const [
    isDisabledLimitReachedLayerVisible,
    setIsDisabledLimitReachedLayerVisible,
  ] = useState(false);
  const {
    startSurveyDisabled,
    setStartSurveyDisabled,
    error,
    errorProfile,
    profileData,
    isLoadingProfile,
  } = useSurveyContext();
  const { showWarningAlert, alertMessage } = useAlert();

  useEffect(() => {
    // Check if the error indicates a 406 status and show the layer
    if (profileData?.totalSurveysReached) {
      setIsDisabledLimitReachedLayerVisible(true);
    } else {
      setIsDisabledLimitReachedLayerVisible(false);
    }
    if (errorProfile?.response?.status === NOT_ACCEPTABLE) {
      setIsDisabledLocationLayerVisible(true);
    } else {
      setIsDisabledLocationLayerVisible(false);
    }
    setSurveyAttemptLimit(
      !!profileData?.dailyAttemptsReached ||
        !!profileData?.hourlyAttemptsReached
    );
  }, [errorProfile, profileData]);

  const fetchSurveyLink = async () => {
    try {
      setStartSurveyDisabled(true);
      localStorage.setItem('lastSurveyStartButtonClick', Date.now().toString());
      const result = await getSurveyLink();
      if (result?.link) {
        window.open(result.link, '_blank', 'noopener,noreferrer');
      }
      // re-enable the button after 15 seconds
      setTimeout(() => setStartSurveyDisabled(false), 15000);
    } catch (err) {
      let errorMessage;
      if (err instanceof AxiosError) {
        if (
          err.response &&
          err.response.data &&
          'message' in err.response.data
        ) {
          errorMessage = err.response.data.message;
        } else {
          errorMessage = 'Unknown error';
        }
      } else if (err instanceof Error) {
        errorMessage = err.message;
      } else {
        errorMessage = 'Unknown error';
      }
      console.error('Error getting link:', errorMessage);
      showWarningAlert(errorMessage);
    }
  };

  useEffect(() => {
    if (lastClickTime) {
      const timeSinceLastClick = Date.now() - parseInt(lastClickTime, 10);
      if (timeSinceLastClick < 15000) {
        setStartSurveyDisabled(true);
        if (!errorProfile) {
          // Set a timeout to re-enable the button after the remaining time
          setTimeout(
            () => setStartSurveyDisabled(false),
            15000 - timeSinceLastClick
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToHomePage = () => {
    window.location.href = SERVER_URL;
  };

  return (
    <>
      {isDisabledLocationLayerVisible && (
        <DisabledLayer
          title="Currently not available in your area!"
          body="Surveys will be available for our Canadian members starting in early 2025."
          buttonText="Go to Dashboard"
          onButtonClick={goToHomePage}
        />
      )}
      {isDisabledLimitReachedLayerVisible && (
        <DisabledLayer
          title="Daily survey limit reached!"
          body="You have reached the daily survey limit. Please try again tomorrow."
          buttonText="Go to Dashboard"
          onButtonClick={goToHomePage}
        />
      )}
      <SurveyHeaderInfo
        surveyAttemptLimit={surveyAttemptLimit}
        fetchSurveyLink={fetchSurveyLink}
      />
      <h3 className="ms-4 title-section">Available Survey Opportunities</h3>
      <Button
        className="cint-button-link ms-4"
        onClick={fetchSurveyLink}
        disabled={isLoadingProfile || surveyAttemptLimit || startSurveyDisabled}
      >
        Go to Surveys!
      </Button>
      {profileData?.dailyAttemptsReached && (
        <p className="ms-4 mt-2 text-danger">
          Please try again later, you can only complete 50 surveys in a rolling
          24 hour period. Please retry in{' '}
          {calculateRemainingTime(profileData.lastSurveyTimeDay)}.
        </p>
      )}
      {!profileData?.dailyAttemptsReached &&
        profileData?.hourlyAttemptsReached && (
          <p className="ms-4 mt-2 text-danger">
            Please try again later, you can only complete 15 surveys in a
            rolling 60 minute period. Retry in{' '}
            {calculateRemainingTime(profileData.lastSurveyTimeHour)}.
          </p>
        )}
      <p className="ms-4 mt-2 title-section__description">
        Take time to truthfully answer questions. Since each survey looks for a
        certain persona, not every attempt will be successful, keep
        trying!&nbsp;
        <i>
          Sometimes companies who initiate the survey can disqualify your survey
          due to various reasons based on responses.
        </i>
      </p>
      {error && (
        <p className="ms-4 mt-2 text-danger">
          Survey service is not available. Please try again later.
        </p>
      )}
      <SurveyList />
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed alert-shift-create"
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default Survey;
