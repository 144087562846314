import { isEqual } from 'date-fns';
import { ShiftPosition } from 'src/types/job.detail.type';
import { ShiftStatus } from 'src/types/jobs.type';
import { IWorkerData } from 'src/types/worker.type';
import { containsDuplicates } from 'src/utils/CollectionUtils';

const getShiftPositionTimeString = (shift: ShiftPosition): string => {
  return shift.start + shift.end;
};
/**
 * THIS IS USED IN JOB WORKER MODAL FOR OPEN SHIFTS TO AVOID SHOWING DUPLICATED SHIFTS
 * SINCE THE WORKER CAN ONLY ACCEPT ONE
 * @param shifts
 * @returns shifts
 */
export const removeDuplicatedShifts = (shifts: ShiftPosition[]) => {
  if (!shifts) {
    return [];
  }
  return shifts.reduce((acc: ShiftPosition[], curr) => {
    const isPresent = acc.some(
      (shift) =>
        isEqual(new Date(shift.start), new Date(curr.start)) &&
        isEqual(new Date(shift.end), new Date(curr.end))
    );
    if (!isPresent) {
      acc.push(curr);
    }
    return acc;
  }, []);
};

/**
 * This function checks if worker has already assigned a shift with same start and end dates (validate using shifts array)
 * @param worker
 * @param shift
 * @param shifts
 * @returns boolean
 */
export const hasSameShiftAssigned = (
  worker: IWorkerData,
  shift: ShiftPosition,
  shifts: ShiftPosition[]
) => {
  return shifts.some(
    (shiftItem) =>
      shiftItem.jobAssignment?.worker.id === worker.id &&
      shiftItem.start === shift.start &&
      shiftItem.end === shift.end
  );
};

/**
 * THIS THREE FUNCTIONS BELOW ARE NOT USED YET BUT WILL BE USEFUL
 * TO FILTER SHIFTS BY STATUS
 */
export const getOpenShifts = (shifts: ShiftPosition[]) => {
  const openShifts = shifts.filter((shift) => {
    return shift.shiftStatus === ShiftStatus.OPEN.toLowerCase();
  });
  return openShifts;
};

export const getPendingShifts = (shifts: ShiftPosition[]) => {
  const pendingShifts = shifts.filter((shift) => {
    return shift.shiftStatus === ShiftStatus.PENDING.toLowerCase();
  });
  return pendingShifts;
};

export const getConfirmedShifts = (shifts: ShiftPosition[]) => {
  const confirmedShifts = shifts.filter((shift) => {
    return shift.shiftStatus === ShiftStatus.CONFIRMED.toLowerCase();
  });
  return confirmedShifts;
};

/*
  Check if at least one shift can be offered to a worker
 */
export const canShiftsBeOfferedToAWorker = (
  workerId: number,
  shifts: ShiftPosition[],
  selectedShifts: ShiftPosition[]
) => {
  const isAnyShiftOfferable = selectedShifts.some(
    (shift) => shift.jobAssignment === undefined
  );
  if (!isAnyShiftOfferable) {
    return false;
  }
  const workerAssociatedShifts = shifts.filter(
    (shift) => shift.jobAssignment?.worker.id === workerId
  );
  const workerAssociatedShiftsTimeString = workerAssociatedShifts.map((shift) =>
    getShiftPositionTimeString(shift)
  );
  // find eligible shifts for worker
  // 1. no assigned shifts
  // 2. shift transformed to unique date time
  const eligibleShiftsToOffer = selectedShifts
    .filter((shift) => shift.jobAssignment === undefined)
    .map((shift) => getShiftPositionTimeString(shift));
  return !(
    eligibleShiftsToOffer.length === 0 ||
    containsDuplicates(eligibleShiftsToOffer, workerAssociatedShiftsTimeString)
  );
};
