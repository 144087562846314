import DATE_FORMATS from 'src/constants/dateFormat';
import IJobDetailData from 'src/types/job.detail.type';
import { formatLocalDate } from './DateUtils';

const getJobFormattedLocalStartDate = (
  job: IJobDetailData,
  format: string = DATE_FORMATS.SHORT_DATE_FORMAT
): string => {
  try {
    return formatLocalDate(job.jobStart, format, job.timezone);
  } catch (formatError) {
    console.error('Cannot parse job start date', formatError);
    return 'cannot parse';
  }
};

// Does not handle ongoing job logic here. Do that in the caller of this function
const getJobFormattedLocalEndDate = (
  job: IJobDetailData,
  format: string = DATE_FORMATS.SHORT_DATE_FORMAT
): string => {
  if (!job?.jobEnd) {
    return 'n/a';
  }
  try {
    return formatLocalDate(job.jobEnd, format, job.timezone);
  } catch (formatError) {
    console.error('Cannot parse job end date', formatError);
    return 'cannot parse';
  }
};

export { getJobFormattedLocalStartDate, getJobFormattedLocalEndDate };
