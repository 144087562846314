import { getFilteredData } from 'src/services/activity-tracker/activityTrackerService';
import { useActivityContext } from 'src/apps/company-frontend/state/activityContext';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { BsChevronRight } from '@react-icons/all-files/bs/BsChevronRight';
import { useJobAssignmentStatus } from 'src/hooks/useJobAssignmentStatus';
import { getFormattedShiftTimes } from 'src/services/shift/shiftService';
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown';
import { FcCheckmark } from '@react-icons/all-files/fc/FcCheckmark';
import { ActivityDetail } from 'src/types/daily-activity.type';
import { JobAssignment } from 'src/types/job-assignment.type';
import { BiEdit } from '@react-icons/all-files/bi/BiEdit';
import { ActivityTrackerView } from 'types/jobs.type';
import DATE_FORMATS from 'src/constants/dateFormat';
import { useEffect, useId, useState } from 'react';
import { UTC_TIMEZONE } from 'src/utils/DateUtils';
import { TbMessageCircle2 } from 'react-icons/tb';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { format } from 'date-fns';
import ActivityTrackerTableHeader from './ActivityTrackerTableHeader';
import ShiftReviewModal from './ShiftReviewModal/ShiftReviewModal';
import FeedbackModal from './FeedbackModal';
import './styles.scss';

interface Props {
  viewType: string;
  groupedBy: string;
  activities: ActivityDetail[];
  filters: string[];
  openChatAction?: () => void;
  approveTimeSheet: (activityDetail: ActivityDetail) => void;
  openWorkerSideModal: (jobAssignment: JobAssignment) => void;
  handleConfirmMarkPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
  handleConfirmMarkUnPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
}

function ActivityTrackerMobile({
  handleConfirmMarkPaid,
  handleConfirmMarkUnPaid,
  openWorkerSideModal,
  approveTimeSheet,
  openChatAction,
  viewType,
  groupedBy,
  activities,
  filters,
}: Props) {
  const { showReviewShiftModal, setShowReviewShiftModal } =
    useActivityContext();

  const [selectedActivity, setSelectedActivity] = useState<ActivityDetail>();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const handleCloseFeedbackModal = () => setShowFeedbackModal(false);
  const handleOpenFeedbackModal = () => setShowFeedbackModal(true);
  const jobAssignmentStatus = useJobAssignmentStatus();
  const { openChat } = useChatContext();
  const { job } = useJobContext();
  const { isShiftPayable, getCheckInValue, getCheckOutValue, upcoming } =
    useActivityContext();
  const [filteredActivities, setFilteredActivities] = useState(
    activities || []
  );

  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [expandState, setExpandState] = useState<Record<number, boolean>>({});

  const handleOpenReviewShiftModal = (activity: ActivityDetail) => {
    setSelectedActivity(activity);
    setShowReviewShiftModal(true);
  };

  const handleCloseReviewShiftModal = () => {
    setShowReviewShiftModal(false);
    setSelectedActivity(undefined);
  };

  const TABLE_HEADER_MOBILE = ['WORKER', 'STATUS'];
  if (viewType === ActivityTrackerView.WORKER) {
    TABLE_HEADER_MOBILE.shift();
    TABLE_HEADER_MOBILE.unshift('DATE');
  }
  const handleExpandRow = (shiftId: number) => {
    const isRowExpanded = expandedRows.includes(shiftId);
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter((id) => id !== shiftId)
      : expandedRows.concat(shiftId);

    const obj: { [key: number]: boolean } = {};
    if (isRowExpanded) {
      obj[shiftId] = false;
    } else {
      obj[shiftId] = true;
    }
    setExpandState(obj);
    setExpandedRows(newExpandedRows);
  };

  const divKey = useId();
  const tableKey = useId();
  const activityKey = useId();

  useEffect(() => {
    setFilteredActivities(
      getFilteredData(
        activities,
        filters,
        upcoming,
        job?.timezone ?? UTC_TIMEZONE
      )
    );
    if (selectedActivity) {
      setSelectedActivity(
        activities.find(
          (x) => x.shiftPosition.id === selectedActivity.shiftPosition.id
        )
      );
    }
  }, [activities, filters, job?.timezone, selectedActivity, upcoming]);

  const handleOpenChat = (activityDetail: ActivityDetail) => {
    const { isHiredOrApplicant } = jobAssignmentStatus(
      activityDetail.shiftPosition.jobAssignment?.status
    );
    if (activityDetail.shiftPosition.jobAssignment) {
      openChat(
        activityDetail.shiftPosition.jobAssignment.worker,
        activityDetail.shiftPosition.jobAssignment.id,
        isHiredOrApplicant()
      );
      if (openChatAction) {
        openChatAction();
      }
    }
  };

  const approveTimeSheetAction = async (activityDetail: ActivityDetail) => {
    approveTimeSheet(activityDetail);
    handleCloseReviewShiftModal();
  };

  return (
    <div key={divKey}>
      {showReviewShiftModal && selectedActivity && (
        <ShiftReviewModal
          handleOpenFeedbackModal={handleOpenFeedbackModal}
          activityDetail={selectedActivity}
          approveTimeSheet={approveTimeSheetAction}
          handleClose={handleCloseReviewShiftModal}
        />
      )}
      <FeedbackModal
        activity={activities![0]}
        show={showFeedbackModal}
        handleCloseFeedbackModal={handleCloseFeedbackModal}
      />
      <ActivityTrackerTableHeader
        filteredActivities={filteredActivities}
        groupedBy={groupedBy}
        handleConfirmMarkPaid={handleConfirmMarkPaid}
        viewType={viewType}
        openWorkerSideModal={openWorkerSideModal}
      />
      <hr className="table-horizontal-line" />
      <Table responsive borderless>
        <thead className="table-header fw-bold">
          <tr>
            {TABLE_HEADER_MOBILE.map((header, index) => (
              <th
                className={`${index > 0 ? '' : 'text-start'}`}
                key={`${tableKey}-${header}`}
              >
                {header}
              </th>
            ))}
            <th aria-label="edit" />
          </tr>
        </thead>
        <tbody className="table-body">
          {filteredActivities.map((activity) => (
            <>
              <tr
                key={`${activityKey}-${activity.shiftPosition.id}`}
                onClick={() => handleExpandRow(activity.shiftPosition.id)}
                className="cursor-point"
              >
                <td className="text-start">
                  {viewType === ActivityTrackerView.DATE
                    ? activity.shiftPosition.jobAssignment?.worker.fullName
                    : format(
                        new Date(activity.shiftPosition.date),
                        DATE_FORMATS.DATE_FORMAT_SHORT
                      )}
                </td>
                <td
                  className={`fw-bold status_colors status_colors__${activity.shiftPosition.shiftStatus
                    .replace(' ', '_')
                    .toLowerCase()}`}
                >
                  {activity.shiftPosition.shiftStatus}
                </td>
                <td className="text-end">
                  {expandState[activity.shiftPosition.id] ? (
                    <BsChevronDown color="var(--black)" />
                  ) : (
                    <BsChevronRight color="var(--black)" />
                  )}
                </td>
              </tr>
              {expandedRows.includes(activity.shiftPosition.id) ? (
                <>
                  <tr>
                    <td colSpan={3}>
                      <hr className="table-horizontal-line" />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">
                      <Button
                        variant="var(--light)"
                        onClick={() => handleOpenChat(activity)}
                      >
                        <TbMessageCircle2
                          className="mb-1"
                          color="var(--black)"
                          size={18}
                        />
                        <span className="ps-1">OPEN CHAT</span>
                      </Button>
                    </td>
                    <td className="text-end">
                      <Button
                        variant="var(--light)"
                        onClick={() => handleOpenReviewShiftModal(activity)}
                      >
                        <BiEdit
                          color="var(--black)"
                          className="mb-1"
                          size={20}
                          style={{ cursor: 'pointer' }}
                        />
                        <span className="ps-1">EDIT SHIFT</span>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <hr className="table-horizontal-line" />
                    </td>
                  </tr>
                  <tr className="w-100">
                    <td className="text-start">SCHEDULED SHIFT TIME</td>
                    <td className="text-start">
                      {getFormattedShiftTimes(
                        activity.shiftPosition,
                        job?.timezone
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">CHECK IN</td>
                    <td className="text-start">
                      {getCheckInValue(activity, handleOpenReviewShiftModal)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">CHECK OUT</td>
                    <td
                      className={`${
                        activity.dailyActivity?.checkInTime
                          ? ''
                          : 'fst-italic table-data__checkout'
                      } text-start`}
                    >
                      {getCheckOutValue(activity, handleOpenReviewShiftModal)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">SUBMITTED TIME</td>
                    <td>
                      {activity.timeSheet?.localizedStart &&
                        activity.timeSheet?.localizedEnd && (
                          <div>
                            {`${format(
                              new Date(activity.timeSheet.localizedStart),
                              DATE_FORMATS.TIME_FORMAT
                            )} - ${format(
                              new Date(activity.timeSheet.localizedEnd),
                              DATE_FORMATS.TIME_FORMAT
                            )}`}
                          </div>
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">TOTAL HOURS</td>
                    <td className="text-start">
                      {activity.timeSheet?.hoursWorked || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">WORK PAY</td>
                    <td className="text-start">
                      {activity.timeSheet?.workerEarnedMoney && (
                        <div>
                          {'$ '}
                          {Number(
                            activity.timeSheet?.workerEarnedMoney
                          ).toFixed(2)}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">SHIFT PAID?</td>
                    <td className="text-start">
                      {activity.shiftPosition.isPaid ? (
                        <FcCheckmark
                          onClick={() => {
                            handleConfirmMarkUnPaid(
                              groupedBy,
                              activity.shiftPosition.id,
                              filteredActivities,
                              true
                            );
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <Button
                          size="sm"
                          variant="light"
                          style={{ fontSize: '14px', padding: '0px 5px' }}
                          disabled={!isShiftPayable(activity)}
                          onClick={() =>
                            handleConfirmMarkPaid(
                              groupedBy,
                              activity.shiftPosition.id,
                              filteredActivities
                            )
                          }
                        >
                          MARK PAID
                        </Button>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <hr className="table-horizontal-line" />
                    </td>
                  </tr>
                </>
              ) : null}
            </>
          ))}
        </tbody>
      </Table>
      <hr className="table-horizontal-line" />
    </div>
  );
}

export default ActivityTrackerMobile;
