import { Row } from 'react-bootstrap';
import RiseInStatus from './RiseInStatus';
import SuccessReview from './SuccessReview';
import { INonThJobAssignmentData } from '../../types/non-th-job-assignment.type';

interface Props {
  stepTitle?: string;
  isActive?: boolean;
  jobAssignment: INonThJobAssignmentData | undefined;
  isFinished: boolean;
  setFinished: (value: boolean) => void;
  startedJobAssignmentParam: boolean;
}

function ReviewLastStep({
  stepTitle,
  jobAssignment,
  isActive,
  isFinished,
  setFinished,
  startedJobAssignmentParam,
}: Props) {
  const finishReview = () => setFinished(true);

  return (
    <Row key={stepTitle} className={`mb-5 ${isActive ? 'd-block' : 'd-none'}`}>
      {isFinished ? (
        <SuccessReview startedJobAssignmentParam={startedJobAssignmentParam} />
      ) : (
        <RiseInStatus
          jobAssignment={jobAssignment}
          finishReview={finishReview}
        />
      )}
    </Row>
  );
}

export default ReviewLastStep;
