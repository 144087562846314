import { Button, Stack } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@tanstack/react-query';
import { HiArrowLeft } from '@react-icons/all-files/hi/HiArrowLeft';
import { IoMdOpen } from '@react-icons/all-files/io/IoMdOpen';
import { AxiosError } from 'axios';
import { IWorkerJobData } from 'types/workerProfile.type';
import * as workerApi from 'company/services/worker/api';
import { WorkerProfile } from 'company/components';
import { SideEndModal, ThLoading } from 'components/elements';
import QueryKeys from 'constants/queryKeys';
import { DirectMessageChat } from 'src/components/elements/DirectMessage';
import { useDirectMessageContext } from '../../state/directMessageContext';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

interface JobWorkerSideModalParams {
  handleCloseWorkerProfile: () => void;
  workerId: number;
}

export default function ComHubWorkerSideModal({
  workerId,
  handleCloseWorkerProfile,
}: JobWorkerSideModalParams) {
  const queryKey = `${QueryKeys.WORKER_PROFILE}_${workerId}`;

  const { data: workerProfileData, isLoading } = useQuery<
    IWorkerJobData,
    AxiosError,
    IWorkerJobData
  >({
    queryKey: [queryKey],
    queryFn: () => workerApi.fetchWorkerJobInfo(workerId, undefined, true),
  });

  const { worker, clearDirectMessageData } = useDirectMessageContext();

  const renderWorkerProfileModal = (
    <SideEndModal
      show={!worker}
      onHide={handleCloseWorkerProfile}
      width={isMobile ? '100%' : '85%'}
    >
      <div>
        <Stack direction="horizontal" gap={3}>
          <div style={{ cursor: 'pointer' }} onClick={handleCloseWorkerProfile}>
            <HiArrowLeft size={20} />
            &nbsp; <b>Back</b>
          </div>
          <div className="ms-auto">
            <Button
              variant="link"
              onClick={() =>
                window.open(
                  `${SERVER_URL}/w/${workerProfileData?.worker.customUrl}`,
                  '_blank'
                )
              }
              style={{
                textDecoration: 'none',
                color: 'unset',
                fontSize: '16px',
              }}
            >
              <IoMdOpen size={20} />
              {!isMobile && <b>&nbsp;Open profile in new window</b>}
            </Button>
          </div>
        </Stack>
        <hr />
        {isLoading && <ThLoading />}
        {workerProfileData && (
          <WorkerProfile
            isLoadingWorker={false}
            workerProfileData={workerProfileData}
          />
        )}
      </div>
    </SideEndModal>
  );

  const renderDirectMessageModal = worker && (
    <SideEndModal
      show
      onHide={clearDirectMessageData}
      width={isMobile ? '90%' : '50%'}
    >
      <DirectMessageChat worker={worker} />
    </SideEndModal>
  );

  return (
    <>
      {renderWorkerProfileModal}
      {renderDirectMessageModal}
    </>
  );
}
