import { useJobWorkerShiftContext } from 'src/apps/company-frontend/state/jobWorkerShiftContext';
import RefreshButton from 'src/apps/company-frontend/components/RefreshButton/RefreshButton';
import { ConfirmationCancelShiftsMessage } from 'src/apps/company-frontend/components';
import { useJobShiftContext } from 'src/apps/company-frontend/state/jobShiftContext';
import { JobWorkerSideModal } from 'src/apps/company-frontend/components/JobWorker';
import { ShiftTables } from 'src/apps/company-frontend/components/ShiftTables';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import { ThFilterWorker } from 'src/components/elements/ThFilterWorker';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { ModalConfirm, ThError, ThLoading } from 'components/elements';
import * as jobApi from 'src/apps/company-frontend/services/job/api';
import { removeDuplicatedShifts } from 'src/utils/ShiftsUtils';
import ThFilterTab from 'src/components/elements/ThFilterTab';
import IModalConfirmModel from 'src/types/confirm-model.type';
import { JobAssignment } from 'src/types/job-assignment.type';
import TooltipOverlay from 'src/components/elements/Tooltip';
import { useErrorBoundary } from 'react-error-boundary';
import { isDateAfterOrNow } from 'src/utils/DateUtils';
import { IWorkerData } from 'src/types/worker.type';
import Select, { MultiValue } from 'react-select';
import { ShiftStatus } from 'types/jobs.type';
import { useEffect, useState } from 'react';
import useAlert from 'src/hooks/useAlert';
import {
  initialApplicantFilters,
  useJobContext,
} from 'src/apps/company-frontend/state/jobContext';
import {
  getSelectedShiftIdsToCancelOrDelete,
  areAllFilteredShiftsSelected,
  canCancelSelectedShifts,
  canDeleteSelectedShifts,
  totalCancelableShifts,
  totalDeletableShifts,
  CANCEL_SHIFT,
  CREATE_SHIFT,
  OFFER_SHIFT,
} from 'src/services/shift/shiftService';
import {
  ToggleButtonGroup,
  OverlayTrigger,
  ToggleButton,
  Container,
  Tooltip,
  Button,
  Alert,
  Stack,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { countBy } from 'lodash';
import { CreateShiftMobileModal, CreateShiftModal } from './CreateShift';
import { OfferShiftMobileModal, OfferShiftModal } from './OfferShift';
import { OfferShiftsButtonWeb } from './OfferShiftButton';
import { DeleteShiftsButton } from './DeleteShiftButton';
import { CalendarView } from './CalendarView';
import './styles.css';

const VIEWS = { list: 'LIST VIEW', calendar: 'CALENDAR VIEW' };
enum ViewsTypes {
  LIST = 'list',
  CALENDAR = 'calendar',
}

const shiftStatusOptions = [
  { value: ShiftStatus.OPEN, label: 'Open' },
  { value: ShiftStatus.PENDING, label: 'Pending' },
  { value: ShiftStatus.CONFIRMED, label: 'Confirmed' },
];

export default function Scheduling() {
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [radioValue, setRadioValue] = useState(ViewsTypes.LIST);
  const [showModal, setShowModal] = useState(false);
  const [showWorkerProfile, setShowWorkerProfile] = useState(false);
  const [clickedJobAssignment, setClickedJobAssignment] = useState<
    JobAssignment | undefined
  >();
  const handleClose = () => {
    setShowModal(false);
  };
  const { job, jobTimezone, fetchApplicantCounters } = useJobContext();
  const handleShow = () => setShowModal(true);
  const [showOfferShiftModal, setShowOfferShiftModal] = useState(false);
  const [showCreateShiftModal, setShowCreateShiftModal] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalConfirmModel, setModalConfirmModel] =
    useState<IModalConfirmModel>();

  const {
    error,
    shifts,
    isLoading,
    statusFilter,
    isRefetching,
    selectedShifts,
    filteredShifts,
    workersFilterList,
    refetch,
    setStatusFilter,
    setWorkersFilter,
    setSelectedShifts,
    setSelectedWorker,
    setApplicantFilters,
  } = useJobShiftContext();

  const { setIsJobWorkerSideModalOpen } = useJobWorkerShiftContext();
  const { chatPerson } = useChatContext();

  const openOfferShiftModal = () => setShowOfferShiftModal(true);
  const closeOfferShiftModal = () => setShowOfferShiftModal(false);
  const openCreateShiftModal = () => setShowCreateShiftModal(true);
  const closeCreateShiftModal = () => setShowCreateShiftModal(false);

  const openWorkerSideModal = (jobAssignment: JobAssignment) => {
    setClickedJobAssignment(jobAssignment);
    setShowWorkerProfile(true);
    refetch();
    setSelectedShifts([]);
    setIsJobWorkerSideModalOpen(true);
  };

  const handleCloseWorkerProfile = () => {
    setShowWorkerProfile(false);
    setIsJobWorkerSideModalOpen(false);
    setClickedJobAssignment(undefined);
    setStatusFilter([]);
    setSelectedWorker(undefined);
  };

  const handleChangeFilterWorker = (workers: MultiValue<IWorkerData>) => {
    setWorkersFilter(Array.from(workers));
    setSelectedShifts([]);
  };

  const selectAllAction = (isAllSelected: boolean) => {
    if (isAllSelected) {
      setSelectedShifts([]);
    } else {
      setSelectedShifts(filteredShifts);
    }
    setSelectedWorker(undefined);
  };

  useEffect(() => {
    setIsLoadingAction(isRefetching);
  }, [isRefetching]);

  if (!job) {
    return <div />;
  }

  const cancelSelectedShifts = async () => {
    if (selectedShifts) {
      try {
        setIsLoadingAction(true);
        const shiftIds = getSelectedShiftIdsToCancelOrDelete(
          selectedShifts,
          true
        );
        if (shiftIds?.length > 0) {
          const result = await jobApi.cancelJobShifts(job.id, shiftIds);

          if (result.status === 200) {
            const messageSuccess = result?.data.message;
            await refetch();
            showSuccessAlert(messageSuccess);
          } else {
            showWarningAlert(result.data.message);
          }
        }
      } catch (err) {
        console.log(`Occurred an error trying to cancel shift`, err);
      } finally {
        setShowConfirmModal(false);
        setSelectedShifts([]);
        setIsLoadingAction(false);
        fetchApplicantCounters();
      }
    }
  };

  const deleteSelectedShifts = async () => {
    if (selectedShifts) {
      try {
        setIsLoadingAction(true);
        const shiftIds = getSelectedShiftIdsToCancelOrDelete(
          selectedShifts,
          false
        );

        let messageSuccess = '';
        if (shiftIds?.length > 0) {
          const result = await jobApi.deleteJobShifts(job.id, shiftIds);
          if (result?.success) {
            messageSuccess = result.message;
            await refetch();
            showSuccessAlert(messageSuccess);
          }
        }
      } catch (err) {
        console.log(`Occurred an error trying to cancel shift`, err);
      } finally {
        setSelectedShifts([]);
        setShowConfirmModal(false);
        setIsLoadingAction(false);
        fetchApplicantCounters();
      }
    }
  };

  const getModalConfirmData = (isCancelAction: boolean) => {
    setModalConfirmModel({
      message: (
        <ConfirmationCancelShiftsMessage isCancelAction={isCancelAction} />
      ),
      titleMessage: (
        <div>
          <span>Are you sure?</span>
        </div>
      ),
      confirmFunction: isCancelAction
        ? cancelSelectedShifts
        : deleteSelectedShifts,
      show: showConfirmModal,
      styleClass: 'modal-confirm-style',
    });
  };

  const openConfirmModal = async (isCancelAction: boolean) => {
    getModalConfirmData(isCancelAction);
    setShowConfirmModal(true);
  };

  const shiftCreatedCloseModal = async (message?: string) => {
    showSuccessAlert(
      message ?? `Job shift${isMobile ? 's' : ''} successfully created`
    );
    closeCreateShiftModal();
  };

  const shiftOfferedCloseModal = async (
    isSuccess: boolean,
    message: string
  ) => {
    if (isSuccess) {
      showSuccessAlert(message);
      refetch();
    } else {
      showWarningAlert(message);
    }
    setApplicantFilters({
      ...initialApplicantFilters,
    });
    setSelectedShifts([]);
    closeOfferShiftModal();
  };

  const { open, pending, confirmed } = countBy(shifts, (d) => d.shiftStatus);

  const handleFilterSelection = async (status: ShiftStatus) => {
    if (statusFilter.includes(status as string)) {
      setStatusFilter(statusFilter.filter((x) => x !== (status as string)));
    } else {
      setStatusFilter([...statusFilter, status as string]);
    }
    setSelectedShifts([]);
  };

  const handleFilterSelectionMobile = (
    selectedOptions: MultiValue<{
      value: string;
      label: string;
    }>
  ) => {
    setStatusFilter(selectedOptions.map((opt) => opt.value));
    setSelectedShifts([]);
  };

  const renderToggle = () => (
    <ToggleButtonGroup
      type="radio"
      name="options"
      className="view-selector"
      defaultValue={ViewsTypes.LIST}
      onChange={setRadioValue}
    >
      <ToggleButton id="tbg-radio-1" value={ViewsTypes.LIST} className="w-50">
        {VIEWS.list}
      </ToggleButton>
      <ToggleButton
        id="tbg-radio-2"
        value={ViewsTypes.CALENDAR}
        className="w-50"
      >
        {VIEWS.calendar}
      </ToggleButton>
    </ToggleButtonGroup>
  );

  if (isLoading || isLoadingAction) return <ThLoading />;

  if (error) {
    showBoundary(error); // pass the error as string to the error boundary
    if (error.response?.status) {
      return <>No data found</>;
    }
    return <ThError message={error.message} />;
  }

  const getTotalShifts = (shiftStatus: ShiftStatus) => {
    switch (shiftStatus) {
      case ShiftStatus.OPEN:
        return open;
      case ShiftStatus.PENDING:
        return pending;
      default:
        return confirmed;
    }
  };

  const cancelShiftsButton = () => {
    if (canCancelSelectedShifts(selectedShifts)) {
      return (
        <Button
          variant={`${isMobile ? 'primary' : 'light'} fw-bold`}
          onClick={() => openConfirmModal(true)}
        >
          {CANCEL_SHIFT}
          {totalCancelableShifts(selectedShifts) > 1 ? 'S' : ''} (
          {totalCancelableShifts(selectedShifts)})
        </Button>
      );
    }
    return <div />;
  };

  const cancelShiftsButtonWithTooltip = () => {
    if (canCancelSelectedShifts(selectedShifts)) {
      return (
        <TooltipOverlay
          text="Move to open the selected shifts which are assigned to workers"
          position="bottom"
        >
          {cancelShiftsButton()}
        </TooltipOverlay>
      );
    }
    return <div />;
  };

  const deleteShiftButton = () => {
    if (canDeleteSelectedShifts(selectedShifts)) {
      return (
        <DeleteShiftsButton
          totalOpenSelectedShifts={totalDeletableShifts(selectedShifts)}
          deleteShiftAction={() => openConfirmModal(false)}
        />
      );
    }
    return null;
  };

  const hasSomeOpenSelectedShifts = () => {
    return (
      selectedShifts &&
      selectedShifts.length > 0 &&
      selectedShifts.some(
        (x) => x.shiftStatus.toLowerCase() === ShiftStatus.OPEN.toLowerCase()
      )
    );
  };

  const getMobileActionButtons = () => {
    if (hasSomeOpenSelectedShifts()) {
      return (
        <Stack direction="horizontal" gap={2}>
          {cancelShiftsButtonWithTooltip()}
          {deleteShiftButton()}
          <Button variant="primary fw-bold" onClick={openOfferShiftModal}>
            {OFFER_SHIFT}
            {selectedShifts?.length > 1 ? 'S' : ''} (
            {selectedShifts?.length || 0})
          </Button>
        </Stack>
      );
    }

    return (
      <Row>
        <Stack direction="horizontal" gap={2}>
          {cancelShiftsButton()}
          <Button variant="primary fw-bold" onClick={openCreateShiftModal}>
            {CREATE_SHIFT}
          </Button>
        </Stack>
      </Row>
    );
  };

  const renderShiftView = () => {
    return radioValue === ViewsTypes.LIST ? (
      <ShiftTables openWorkerSideModal={openWorkerSideModal} />
    ) : (
      <CalendarView />
    );
  };

  const getSelectAllFormCheck = (isAllSelected: boolean) => {
    return (
      <Form.Check
        type="checkbox"
        id="selectAll"
        className="py-2 me-3"
        label={isAllSelected ? 'Deselect All' : 'Select All'}
        checked={isAllSelected}
        onChange={() => selectAllAction(isAllSelected)}
      />
    );
  };

  const showSelectionButtons = () => {
    const isAllSelected = areAllFilteredShiftsSelected(
      selectedShifts,
      filteredShifts
    );
    return (
      <>
        <BrowserView>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="m-1">
                {isAllSelected
                  ? 'Clear selection'
                  : 'Select all filtered shifts'}
              </Tooltip>
            }
          >
            {getSelectAllFormCheck(isAllSelected)}
          </OverlayTrigger>
        </BrowserView>
        <MobileView>
          <Row>
            <Col xs={6} md={4}>
              {getSelectAllFormCheck(isAllSelected)}
            </Col>
            <Col xs={6} className="justify-content-end">
              <RefreshButton
                isSmall
                className="mt-1"
                refreshFunction={refetch}
              />
            </Col>
          </Row>
        </MobileView>
      </>
    );
  };

  return (
    <>
      <MobileView>
        {showCreateShiftModal && (
          <CreateShiftMobileModal
            job={job}
            handleClose={closeCreateShiftModal}
            shiftCreatedCloseModal={shiftCreatedCloseModal}
          />
        )}
        {showOfferShiftModal && (
          <OfferShiftMobileModal
            handleClose={closeOfferShiftModal}
            shiftOfferedCloseModal={shiftOfferedCloseModal}
          />
        )}
        <Stack gap={3} className="mb-5 mobile-view">
          {renderToggle()}
          <ThFilterWorker
            filterWorkers={workersFilterList}
            handleChangeFilterWorker={handleChangeFilterWorker}
          />
          <span className="text-uppercase fw-bold">Filter by status</span>
          <Select
            isMulti
            options={shiftStatusOptions}
            placeholder="Select filters"
            isClearable={false}
            isSearchable={false}
            onChange={handleFilterSelectionMobile}
            styles={{
              dropdownIndicator: (provided) => ({
                ...provided,
                color: 'var(--black)',
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
              }),
              control: (provided) => ({
                ...provided,
                background: 'var(--light)',
              }),
            }}
          />
          {filteredShifts && filteredShifts.length > 0
            ? showSelectionButtons()
            : null}
          {renderShiftView()}
        </Stack>
        <div className="mobile-cta-btn">{getMobileActionButtons()}</div>
      </MobileView>
      <BrowserView>
        {showModal && (
          <CreateShiftModal
            job={job}
            handleClose={handleClose}
            shiftCreatedCloseModal={shiftCreatedCloseModal}
          />
        )}
        {showOfferShiftModal && (
          <OfferShiftModal
            handleClose={closeOfferShiftModal}
            shiftOfferedCloseModal={shiftOfferedCloseModal}
          />
        )}
        <Container fluid>
          <Stack direction="horizontal" gap={2}>
            {renderToggle()}
            <ThFilterWorker
              filterWorkers={workersFilterList}
              handleChangeFilterWorker={handleChangeFilterWorker}
            />
            <span className="text-uppercase fw-bold">Filter by status</span>
            <Stack direction="horizontal" gap={2}>
              {shiftStatusOptions.map((tab) => {
                return (
                  <ThFilterTab
                    key={tab.value}
                    name={`${
                      tab.label.toLowerCase() === 'open'
                        ? `${tab.label} shifts`
                        : tab.label
                    }`}
                    total={getTotalShifts(tab.value)}
                    selected={statusFilter.includes(tab.value.toLowerCase())}
                    onClick={() => handleFilterSelection(tab.value)}
                  />
                );
              })}
            </Stack>
            <Stack direction="horizontal" gap={2} className="ms-auto">
              <RefreshButton isSmall refreshFunction={refetch} />
            </Stack>
            <Stack direction="horizontal" gap={2} className="ms-auto">
              <Button
                variant="primary fw-bold"
                onClick={handleShow}
                className="ms-auto"
              >
                {CREATE_SHIFT}
              </Button>
            </Stack>
          </Stack>
          <Row className="ms-0 mt-3">
            <Stack direction="horizontal" gap={3} className="ms-0">
              {filteredShifts && filteredShifts.length > 0
                ? showSelectionButtons()
                : null}
              {cancelShiftsButtonWithTooltip()}
              {deleteShiftButton()}
              <OfferShiftsButtonWeb
                openOfferShiftModal={openOfferShiftModal}
                totalSelectedOfferableShifts={removeDuplicatedShifts(
                  selectedShifts
                ).reduce(
                  (total, shiftItem) =>
                    shiftItem.shiftStatus === ShiftStatus.OPEN &&
                    shiftItem.jobAssignment === undefined &&
                    isDateAfterOrNow(shiftItem.start, jobTimezone)
                      ? total + 1
                      : total,
                  0
                )}
              />
            </Stack>
          </Row>
          <div className="mt-3">{renderShiftView()}</div>
        </Container>
      </BrowserView>
      {showConfirmModal && modalConfirmModel && (
        <ModalConfirm
          modalConfirmModel={modalConfirmModel}
          setShowModal={setShowConfirmModal}
        />
      )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
      {showWorkerProfile && clickedJobAssignment && chatPerson === null && (
        <JobWorkerSideModal
          handleCloseWorkerProfile={handleCloseWorkerProfile}
          worker={clickedJobAssignment.worker}
          jobAssignment={clickedJobAssignment}
          isJobRelated
        />
      )}
    </>
  );
}
