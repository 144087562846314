import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { isMobile } from 'react-device-detect';
import { format } from 'date-fns';
import {
  submitTimeSheetHours,
  updateSubmittedTimeSheet,
} from 'company/services/activityTracker/api';
import { useActivityContext } from 'company/state/activityContext';
import { useJobContext } from 'company/state/jobContext';
import { ActivityDetail } from 'types/daily-activity.type';
import { AddHours } from 'types/time-sheet.type';
import { ThLoading } from 'components/elements';
import DATE_FORMATS from 'constants/dateFormat';
import { convertToTimezone } from 'utils/DateUtils';
import '../ShiftReviewModal/customUpdateSubmittedTimes.css';
import '../styles.scss';

interface MemberAddHoursModalProps {
  setShowSubmitHoursModal: (show: boolean) => void;
  handleSubmitHoursSuccess: (message: string) => void;
  activityDetail: ActivityDetail;
}

export function MemberAddHoursModal({
  handleSubmitHoursSuccess,
  setShowSubmitHoursModal,
  activityDetail,
}: MemberAddHoursModalProps) {
  const { setIsLoadingAction, isLoadingAction, refetch } = useActivityContext();
  const [startTime, setStartTime] = useState<Date | undefined>();
  const [endTime, setEndTime] = useState<Date | undefined>();
  const [minutesOfBreak, setMinutesOfBreak] = useState(0);
  const { job } = useJobContext();

  useEffect(() => {
    if (job?.address.timezoneId && activityDetail.shiftPosition) {
      setStartTime(
        convertToTimezone(
          activityDetail.timeSheet?.start ?? activityDetail.shiftPosition.start,
          job.address.timezoneId
        )
      );
      setEndTime(
        convertToTimezone(
          activityDetail.timeSheet?.end ?? activityDetail.shiftPosition.end,
          job.address.timezoneId
        )
      );
      if (activityDetail.timeSheet) {
        setMinutesOfBreak(activityDetail.timeSheet.minutesOfBreak);
      }
    }
  }, [activityDetail, job?.address.timezoneId]);

  useEffect(() => {
    if (startTime && endTime) {
      if (endTime.getTime() <= startTime.getTime()) {
        // Shift starts in a day and ends on the next day
        const newDate = new Date(endTime);
        newDate.setDate(newDate.getDate() + 1);
        setEndTime(newDate);
      }
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
      if (endTime.getTime() - startTime.getTime() > twentyFourHoursInMs) {
        // Reduce a day from the end date because the diff between start and end dates is bigger than 24 hours
        const newDate = new Date(endTime);
        newDate.setDate(newDate.getDate() - 1);
        setEndTime(newDate);
      }
    }
  }, [endTime, startTime]);

  const canSubmitHours = useMemo(() => {
    if (startTime && endTime) {
      const totalWorkedMinutes =
        Math.abs(endTime.getTime() - startTime.getTime()) / (1000 * 60);
      return (
        activityDetail.shiftPosition.jobAssignment &&
        startTime < endTime &&
        minutesOfBreak >= 0 &&
        totalWorkedMinutes > minutesOfBreak
      );
    }
    return false;
  }, [
    activityDetail.shiftPosition.jobAssignment,
    endTime,
    startTime,
    minutesOfBreak,
  ]);

  if (!job) {
    return null;
  }

  const submitHours = async () => {
    setIsLoadingAction(true);
    try {
      if (canSubmitHours) {
        let result;
        const submitHoursObj: AddHours = {
          startTime: format(startTime!, DATE_FORMATS.TIME_FORMAT),
          endTime: format(endTime!, DATE_FORMATS.TIME_FORMAT),
          minutesOfBreak,
        };
        if (activityDetail.timeSheet) {
          result = await updateSubmittedTimeSheet(
            activityDetail.shiftPosition.jobId,
            activityDetail.timeSheet.id,
            submitHoursObj
          );
        } else {
          result = await submitTimeSheetHours(
            activityDetail.shiftPosition.jobAssignment!.id,
            activityDetail.shiftPosition.id,
            submitHoursObj
          );
        }

        if (result.success) {
          handleSubmitHoursSuccess(result.message);
          await refetch();
        }
      }
    } catch (error) {
      console.error('An error occurred while submitting hours:', error);
    } finally {
      setIsLoadingAction(false);
    }
  };

  if (isLoadingAction) {
    return <ThLoading />;
  }

  return (
    <Modal
      show
      size="lg"
      centered
      onHide={() => setShowSubmitHoursModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
          Submit hours for the worker
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <Row className="my-3">
          <h5>You&apos;re submitting hours to the worker on this shift</h5>
        </Row>
        <Row className={`${isMobile ? 'mx-3 my-3' : ''}`}>
          <Col md={4} xs={12}>
            <Form.Label className="d-flex">Start time</Form.Label>
            <DatePicker
              className="form-control d-flex text-center"
              selected={startTime}
              onChange={(date) => {
                if (date) {
                  setStartTime(date || undefined);
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="hh:mm aa"
            />
          </Col>
          <Col md={4} xs={12}>
            <Form.Label className="d-flex">End time</Form.Label>
            <DatePicker
              selected={endTime}
              className="form-control d-flex text-center"
              onChange={(date) => setEndTime(date || undefined)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="hh:mm aa"
            />
          </Col>
          <Col md={4} xs={12}>
            <Form.Label className="d-flex">Break minutes</Form.Label>
            <Form.Control
              type="number"
              min={0}
              step={5}
              className="text-center"
              value={minutesOfBreak}
              onChange={(e) => {
                const newValue = parseInt(e.target.value, 10);
                setMinutesOfBreak(newValue || 0);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-top-0 justify-content-between">
        <span>
          <Button
            size={isMobile ? 'sm' : undefined}
            variant="outline-danger fw-bold border-0"
            className="text-start"
            onClick={() => setShowSubmitHoursModal(false)}
          >
            CANCEL
          </Button>
        </span>
        <span>
          <Button
            size={isMobile ? 'sm' : undefined}
            variant="primary"
            className="ms-2"
            disabled={!canSubmitHours}
            onClick={submitHours}
          >
            Submit
          </Button>
        </span>
      </Modal.Footer>
    </Modal>
  );
}
