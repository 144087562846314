import * as jobAssignmentApi from 'src/apps/company-frontend/services/jobAssignment/api';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import * as jobApi from 'src/apps/company-frontend/services/job/api';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { JobHiringType } from 'src/constants/jobHiringType';
import { JobAssignment } from 'types/job-assignment.type';
import { IJobTitle, ShiftStatus } from 'types/jobs.type';
import CurrencyInput from 'react-currency-input-field';
import { PayRatePeriod } from 'types/payRatePeriod';
import { NullableString } from 'types/common.type';
import { IWorkerData } from 'types/worker.type';
import { ThLoading } from 'components/elements';
import { useJobShiftContext } from '../state/jobShiftContext';
import { ShiftTables } from './ShiftTables';
import './style.css';

interface Params {
  jobAssignment?: JobAssignment | null;
  worker: IWorkerData;
  handleSuccess: (message?: string) => void;
  handleFailure: (message?: string) => void;
  handleClose: () => void;
  hiringType: JobHiringType | null;
}

function HireModal({
  jobAssignment,
  worker,
  handleSuccess,
  handleFailure,
  handleClose,
  hiringType,
}: Params): ReactElement {
  const { setSelectedShifts, selectedShifts, shifts } = useJobShiftContext();
  const { job, setNewHired, setNewOffered } = useJobContext();

  const [isLoading, setIsLoading] = useState(false);
  const [jobTitleList, setJobTitleList] = useState<IJobTitle[]>([]);
  const [jobTitle, setJobTitle] = useState<IJobTitle | undefined>(job?.title);
  const [payRate, setPayRate] = useState<number | undefined>(job?.payRate);
  const [payRatePeriod, setPayRatePeriod] = useState<PayRatePeriod | undefined>(
    job?.payRatePeriod
  );
  const [jobTitleDescription, setJobTitleDescription] =
    useState<NullableString>(null);
  const [note, setNote] = useState<NullableString>(null);

  const jobTitleDescriptionRef = useRef(null);

  const onSelectJobPosition = (jobTitleId: number) => {
    const jobTitleSelected = jobTitleList.find((i) => i.id === jobTitleId);
    setJobTitle(jobTitleSelected);
  };

  const hasOpenShifts = useMemo(() => {
    return shifts?.some((shift) => shift.shiftStatus === ShiftStatus.OPEN);
  }, [shifts]);

  useEffect(() => {
    const getJobList = async () => {
      const data = await jobApi.fetchJobPositionList();
      setJobTitleList(data.jobTitles);
      const selectedTitle = data.jobTitles.find(
        (i) =>
          i.name === jobAssignment?.jobTitle?.name || i.name === job?.position
      );
      if (selectedTitle) {
        setJobTitle(selectedTitle);
      }
    };
    getJobList();
  }, [job?.position, jobAssignment]);

  const offerJob = async (
    jobPositionTitleId: number,
    jobPositionDescription: NullableString,
    comments: NullableString
  ) => {
    if (job) {
      setIsLoading(true);
      const shiftIds = selectedShifts?.map((shift) => shift.id) ?? [];
      const result = await jobAssignmentApi.offerJob(
        jobPositionTitleId,
        jobPositionDescription,
        job.id,
        worker.id,
        comments,
        payRate,
        payRatePeriod,
        shiftIds
      );
      setIsLoading(false);
      setSelectedShifts([]);
      if (result && result.success) {
        setNewOffered(true);
        handleSuccess(result.message);
      } else {
        handleFailure('Offer failed to send');
      }
    }
  };

  const hireWorker = async (
    jobPositionTitleId: number,
    jobPositionDescription: NullableString,
    comments: NullableString
  ) => {
    if (jobAssignment) {
      setIsLoading(true);
      const result = await jobAssignmentApi.hireWorker(
        jobPositionTitleId,
        jobPositionDescription,
        comments,
        jobAssignment,
        payRate,
        payRatePeriod
      );
      setIsLoading(false);
      if (result && result.success) {
        setNewHired(true);
        handleSuccess(result.message);
      } else {
        handleFailure(
          'An error has occurred while was trying to hire the worker'
        );
      }
    }
  };

  const handleClick = () => {
    const id = jobTitle?.id;
    if (id) {
      return hiringType === JobHiringType.HIRE
        ? hireWorker(
            id,
            jobTitleDescriptionRef?.current ? jobTitleDescription : null,
            note
          )
        : offerJob(
            id,
            jobTitleDescriptionRef?.current ? jobTitleDescription : null,
            note
          );
    }
    return () => {};
  };

  const handleOnValueChange: any = (value: number): void => {
    setPayRate(value);
  };

  return (
    <Modal show onHide={handleClose} size="lg" id="hire-modal">
      {isLoading && <ThLoading />}
      <Modal.Header closeButton>
        <Modal.Title>
          {`${
            hiringType === JobHiringType.HIRE
              ? JobHiringType.HIRE
              : 'Send Offer to '
          } ${worker.firstName} ${worker.lastName}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form>
            <Form.Group className="mb-3" controlId="jobPosition">
              <Form.Label>
                <b>Job Position</b>
              </Form.Label>
              <Form.Select
                aria-label="Job Position"
                value={jobTitle?.id}
                onChange={(e) => onSelectJobPosition(Number(e.target.value))}
              >
                <option key={0} value="jobPosition.id">
                  Select a position
                </option>
                {jobTitleList.map((jobPosition) => (
                  <option key={jobPosition.id} value={jobPosition.id}>
                    {jobPosition.name}
                  </option>
                ))}
              </Form.Select>
              {jobTitle?.name === 'Other' && (
                <Form.Control
                  aria-label="Job Position Description"
                  defaultValue=""
                  type="text"
                  placeholder="Describe position"
                  onChange={(e) => setJobTitleDescription(e.target.value)}
                  className="mt-3"
                  disabled={jobTitle?.name !== 'Other'}
                  ref={jobTitleDescriptionRef}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="payRate">
              <Form.Label>
                <b>Pay Rate</b>
              </Form.Label>
              <Row>
                <Col>
                  <Form.Select
                    aria-label="Pay Rate Period"
                    defaultValue={payRatePeriod}
                    onChange={(e) =>
                      setPayRatePeriod(
                        PayRatePeriod[
                          e.target.value as keyof typeof PayRatePeriod
                        ]
                      )
                    }
                  >
                    <option value={PayRatePeriod.HOUR}>Per hour</option>
                    <option value={PayRatePeriod.DAY}>Per day</option>
                  </Form.Select>
                </Col>
                <Col>
                  <CurrencyInput
                    allowNegativeValue={false}
                    decimalScale={2}
                    id="payRate"
                    name="payRate"
                    className="form-control"
                    value={payRate}
                    onValueChange={handleOnValueChange}
                    placeholder="Pay Rate"
                    prefix="$ "
                    step={1}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="note">
              <Form.Label>
                <b>Note (optional)</b>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Row>
        {hiringType === JobHiringType.OFFER && hasOpenShifts && (
          <>
            <Row className="justify-content-center fw-bold mt-3">
              Would you like to include shifts on this offer? You&apos;re able
              to pick them below
            </Row>
            <Row>
              <ShiftTables showResumed showOnlyOpenShifts />
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          <b>CANCEL</b>
        </Button>
        <Button variant="primary" disabled={!jobTitle} onClick={handleClick}>
          <b>{hiringType}</b>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HireModal;
