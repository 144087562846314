import { GrClose } from '@react-icons/all-files/gr/GrClose';
import { Badge } from 'react-bootstrap';
import IFilterData from 'src/apps/company-frontend/types/talent-filterdata.type';

interface Params {
  handleClose: () => void;
  advancedFilters: IFilterData | undefined;
  setAdvancedFilters: (filterParams: IFilterData) => void;
}

const keyMappings: Record<string, string> = {
  countries: 'Countries',
  travelNationally: 'Travel nationally',
  travelLonger: 'Travel for long periods',
  gender: 'Gender',
  filterWorkers: 'Favorite/Blocked',
  workerStatus: 'Worker Status',
  skills: 'Skills',
  eventTypes: 'Event types',
  traits: 'Traits',
  badges: 'Badges',
  profileStatus: 'Profile Status',
};

export default function AdvancedFiltersBadges({
  handleClose,
  advancedFilters,
  setAdvancedFilters,
}: Params): JSX.Element {
  const handleBadgeClean = (key: keyof IFilterData) => {
    if (advancedFilters) {
      handleClose();
      const newData: IFilterData = { ...advancedFilters };
      delete newData[key];
      setAdvancedFilters(newData);
    }
  };

  const handleClearAll = () => {
    handleClose();
    setAdvancedFilters({} as IFilterData);
  };

  const renderBadges = () => {
    if (!advancedFilters) {
      return null;
    }
    const badges: JSX.Element[] = [];

    Object.entries(advancedFilters).forEach(([key, value]) => {
      let badgeText = keyMappings[key];
      if (Array.isArray(value)) {
        badgeText += ` (${value.length})`;
      }

      if (value != null && value !== false) {
        badges.push(
          <Badge
            key={`advanced-badge-${key}-${value}`}
            className="me-1"
            pill
            bg="third"
            text="dark"
          >
            {badgeText}
            <span onClick={() => handleBadgeClean(key as keyof IFilterData)}>
              <GrClose />
            </span>
          </Badge>
        );
      }
    });

    if (badges.length > 0) {
      badges.push(
        <span
          key="advanced-badge-clear"
          onClick={handleClearAll}
          className="ms-3 cursor-point"
        >
          <b>CLEAR FILTERS</b>
        </span>
      );
    }

    return badges;
  };

  return <div className="mt-2">{renderBadges()}</div>;
}
