import { useRef, useState } from 'react';
import { InputGroup, Stack } from 'react-bootstrap';
import Select, { GroupBase, MultiValue, SelectInstance } from 'react-select';
import { isMobile } from 'react-device-detect';
import { BiSearch } from 'react-icons/bi';
import { IWorkerData } from 'src/types/worker.type';
import ThFilterWorkerOption from './ThFilterWorkerOption';

export interface Props {
  filterWorkers: IWorkerData[];
  handleChangeFilterWorker: (val: MultiValue<IWorkerData>) => void;
}
export default function ThFilterWorker({
  filterWorkers,
  handleChangeFilterWorker,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef =
    useRef<SelectInstance<IWorkerData, true, GroupBase<IWorkerData>>>(null);

  const onClear = () => {
    if (selectRef.current) {
      setTimeout(() => selectRef.current!.blur(), 1);
    }
  };

  return (
    <InputGroup className={`${isMobile ? 'w-100' : 'w-auto'}`}>
      <Stack direction="horizontal" className={`${isMobile ? 'w-100' : ''}`}>
        <InputGroup.Text className="h-100 search-input">
          <BiSearch size={22} />
        </InputGroup.Text>
        <Select
          ref={selectRef}
          isMulti
          menuIsOpen={isOpen}
          name="filterWorkers"
          placeholder="Search by worker"
          options={filterWorkers}
          isClearable
          isSearchable
          openMenuOnClick
          backspaceRemovesValue={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          className={`search-input-group ${isMobile ? 'w-100' : ''}`}
          getOptionLabel={(e) => e?.fullName}
          getOptionValue={(e) => e?.id.toString()}
          components={{ Option: ThFilterWorkerOption }}
          onChange={(val, { action }) => {
            if (action === 'clear') {
              setIsOpen(false);
              onClear();
            }
            handleChangeFilterWorker(val);
          }}
          onFocus={() => {
            setIsOpen(true);
          }}
          onBlur={() => {
            setIsOpen(false);
          }}
          styles={{
            clearIndicator: (styles) => ({
              ...styles,
              padding: '0px',
            }),
            multiValue: (styles) => ({
              ...styles,
              backgroundColor: 'var(--secondary)',
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              backgroundColor: 'var(--secondary)',
              fontWeight: 'bold',
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              backgroundColor: 'var(--secondary)',
              color: 'black',
              ':hover': {
                backgroundColor: 'var(--yellow)',
              },
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              color: 'var(--black)',
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: 'none',
            }),
            control: (styles) => ({
              ...styles,
              background: 'var(--light)',
              boxShadow: 'none',
              border: 'none',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }),
            menu: (styles) => ({
              ...styles,
              left: '-45px',
              width: isMobile ? 'calc(100% + 45px)' : '400px',
            }),
          }}
        />
      </Stack>
    </InputGroup>
  );
}
