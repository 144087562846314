import Button from 'react-bootstrap/Button';
import { isMobile } from 'react-device-detect';
import { ActivityDetail } from 'src/types/daily-activity.type';
import '../styles.scss';

interface ApproveButtonProps {
  approveTimeSheet: (activityDetail: ActivityDetail) => void;
  activityDetail: ActivityDetail;
}

export function ApproveButton({
  activityDetail,
  approveTimeSheet,
}: ApproveButtonProps) {
  return (
    <Button
      size={isMobile ? 'sm' : undefined}
      variant="primary"
      className="ms-2"
      form="offer-form"
      type="submit"
      disabled={
        !activityDetail.timeSheet || activityDetail.timeSheet?.isApproved
      }
      onClick={() => {
        approveTimeSheet(activityDetail);
      }}
    >
      APPROVE
    </Button>
  );
}
