import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import {
  Dashboard,
  JobList,
  JobOverview,
  WorkerProfilePage,
} from 'src/apps/company-frontend/pages';
import { JobProvider } from 'src/apps/company-frontend/state/jobContext';
import { useEffect } from 'react';
import { TEN_MINUTES } from 'src/constants/various';
import { ThLoading } from 'src/components/elements';
import { isDev, isProduction } from 'src/utils/Environment';
import { UpdateWorkerProvider } from 'src/apps/company-frontend/state/useUpdateWorker/useUpdateWorker';
import GeneralSearchTalent from 'src/apps/company-frontend/components/GeneralSearchTalent/GeneralSearchTalent';
import LeaveSingleReview from 'company/pages/LeaveReview/LeaveSingleReview';
import { googleManager } from 'src/utils/GoogleTagManager';
import NotFound from 'src/components/elements/NotFound';
import { LoginRedirect } from 'components/elements';
import { Header } from '../components/layout';
import { useCompanyContext } from '../state/companyContext';
import CommunicationHub from '../pages/CommunicationHub/CommunicationHub';
import PayrollServices from '../pages/JobOverview/PayrollServices/PayrollServices';
import { JobShiftProvider } from '../state/jobShiftContext';
import { ActivityProvider } from '../state/activityContext';
import { JobWorkerShiftProvider } from '../state/jobWorkerShiftContext';
import { JobAssignmentProvider } from '../state/jobAssignmentContext';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

export default function CompanyRoutes() {
  const isInIframe = window.self !== window.top;
  const [searchParams] = useSearchParams();
  const noHeader = searchParams.get('no-header') === 'true';
  const location = useLocation();

  const { fetchCompany, fetchNotifications, isLoading } = useCompanyContext();

  useEffect(() => {
    async function fetchCompanyData() {
      await fetchCompany();
    }

    fetchCompanyData();
    fetchNotifications();
    const notificationsIntervalId = setInterval(
      fetchNotifications,
      TEN_MINUTES
    );

    return () => {
      clearInterval(notificationsIntervalId);
    };
  }, [fetchCompany, fetchNotifications]);

  useEffect(() => {
    if (isProduction() && location?.pathname !== undefined) {
      googleManager.page({
        url: location.pathname,
      });
    }
  }, [location.pathname]);

  if (isLoading) {
    return <ThLoading />;
  }

  return (
    <>
      {!(isInIframe || noHeader) && <Header />}
      <Routes>
        {isDev() && <Route path={`${BASE_PATH}`} element={<Dashboard />} />}
        {isDev() && <Route path={`${BASE_PATH}jobs`} element={<JobList />} />}
        <Route path={`${BASE_PATH}login`} element={<LoginRedirect />} />
        <Route
          path={`${BASE_PATH}searchWorkers`}
          element={
            <UpdateWorkerProvider>
              <JobAssignmentProvider>
                <GeneralSearchTalent />
              </JobAssignmentProvider>
            </UpdateWorkerProvider>
          }
        />
        <Route
          path={`${BASE_PATH}communication-hub/:activeTab?`}
          element={
            <JobProvider>
              <JobShiftProvider>
                <UpdateWorkerProvider>
                  <JobAssignmentProvider>
                    <CommunicationHub />
                  </JobAssignmentProvider>
                </UpdateWorkerProvider>
              </JobShiftProvider>
            </JobProvider>
          }
        />
        <Route
          path={`${BASE_PATH}payroll-services`}
          element={<PayrollServices isActive />}
        />
        <Route
          path={`${BASE_PATH}jobs/:id`}
          element={
            <JobProvider>
              <JobShiftProvider>
                <JobWorkerShiftProvider>
                  <ActivityProvider>
                    <UpdateWorkerProvider>
                      <JobAssignmentProvider>
                        <JobOverview />
                      </JobAssignmentProvider>
                    </UpdateWorkerProvider>
                  </ActivityProvider>
                </JobWorkerShiftProvider>
              </JobShiftProvider>
            </JobProvider>
          }
        />

        <Route
          path={`${BASE_PATH}workers/:id`}
          element={
            <UpdateWorkerProvider>
              <WorkerProfilePage />
            </UpdateWorkerProvider>
          }
        />

        <Route
          path={`${BASE_PATH}leaveReview/:jobAssignmentId?`}
          element={<LeaveSingleReview isModal={isInIframe} />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
