import { Navigate } from 'react-router-dom';
import CompanyRoutes from 'src/apps/company-frontend/routes/CompanyRoutes';
import { ChatProvider } from 'src/apps/company-frontend/state/chatContext';
import { CompanyProvider } from 'src/apps/company-frontend/state/companyContext';
import { WorkerProvider } from 'src/apps/worker-frontend/state/workerContext';
import WorkerRoutes from 'src/apps/worker-frontend/routes/WorkerRoutes';
import { ThLoading } from 'src/components/elements';
import { UserRole } from 'src/constants/userRoles';
import { useUserContext } from 'src/state/userContext';
import AuthFailedModal from 'src/components/elements/AuthFailedModal';
import { DirectMessageProvider } from 'src/apps/company-frontend/state/directMessageContext';
import { WorkerProfileProvider } from 'src/apps/worker-frontend/state/workerProfileContext';

export default function AppRoutes() {
  const { hasRole, isLoading } = useUserContext();

  const getRoutes = () => {
    if (hasRole(UserRole.ROLE_COMPANY)) {
      return (
        <CompanyProvider>
          <ChatProvider>
            <DirectMessageProvider>
              <CompanyRoutes />
            </DirectMessageProvider>
          </ChatProvider>
        </CompanyProvider>
      );
    }
    if (hasRole(UserRole.ROLE_USER)) {
      return (
        <WorkerProfileProvider>
          <WorkerProvider>
            <WorkerRoutes />
          </WorkerProvider>
        </WorkerProfileProvider>
      );
    }

    return <Navigate to="/login" />;
  };

  if (isLoading) {
    return <ThLoading />;
  }

  return (
    <>
      <AuthFailedModal />
      {getRoutes()}
    </>
  );
}
