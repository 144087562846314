import { Offcanvas } from 'react-bootstrap';
import { ModalProps } from 'react-bootstrap/Modal';
import './style.css';

interface Props extends ModalProps {
  width?: string;
}

export default function SideEndModal(props: Props) {
  const { children, width = '85%' } = props;
  return (
    <Offcanvas {...props} placement="end" style={{ width }}>
      <Offcanvas.Body>{children}</Offcanvas.Body>
    </Offcanvas>
  );
}
