import { FiEdit } from '@react-icons/all-files/fi/FiEdit';
import { FiTrash2 } from '@react-icons/all-files/fi/FiTrash2';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { Panel, ThError, ThLoading } from 'components/elements';
import QueryKeys from 'src/constants/queryKeys';
import * as api from 'src/apps/company-frontend/services/worker/api';
import DATE_FORMATS from 'src/constants/dateFormat';
import IWorkerNotesData, {
  IWorkerNoteData,
} from 'src/apps/company-frontend/types/workerNotes.type';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import IWorkerNoteCreation from 'src/apps/company-frontend/types/workerNoteCreation.type';
import { IWorkerData } from 'types/worker.type';
import { useUserContext } from 'src/state/userContext';

interface Params {
  handleNotesCount: (value: number) => void;
  worker: IWorkerData;
  isActive: boolean;
}

export default function WorkerNotes({
  handleNotesCount,
  worker,
  isActive,
}: Params) {
  const { id: userId } = useUserContext();
  const { register, reset, setValue, handleSubmit } =
    useForm<IWorkerNoteCreation>();

  const [noteEditing, setNoteEditing] = useState<IWorkerNoteData | undefined>();

  const { isLoading, error, data, refetch } = useQuery<
    IWorkerNotesData,
    AxiosError
  >({
    queryKey: [`${QueryKeys.WORKER_NOTES}_${worker.id}`],
    queryFn: () => api.fetchNotes(worker.id, 0, 100),
    enabled: isActive,
  });

  useEffect(() => {
    if (data && data.results) {
      handleNotesCount(data.results.length);
    }
  }, [data, handleNotesCount]);

  if (isLoading) return <ThLoading />;

  if (error) {
    if (error.response?.status) {
      return <ThError message="No data found" />;
    }
    return <ThError message={error.message} />;
  }

  const getStatus = (note: IWorkerNoteData) => {
    if (note.dateDeleted) {
      return 'Removed';
    }
    if (note.lastUpdated) {
      return 'Edited';
    }
    return '';
  };

  const onSubmit = async (dataRequest: IWorkerNoteCreation) => {
    if (noteEditing) {
      await api.updateNote(worker.id, noteEditing.id, dataRequest);
    } else {
      await api.createNote(worker.id, dataRequest);
    }

    setNoteEditing(undefined);
    refetch();
    reset();
  };

  const onDelete = async (workerNoteId: number) => {
    await api.deleteNote(worker.id, workerNoteId);
    refetch();
  };

  function onEdit(note: IWorkerNoteData): void {
    setValue('description', note.description);
    setNoteEditing(note);
  }

  const handleCancelEditing = () => {
    setNoteEditing(undefined);
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-4">
          <p>
            <b>Notes</b>
          </p>
          <p>
            These notes can only be seen by internal company members, at your
            company. The worker, or other companies, cannot see them. Still,
            please be professional.
          </p>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows={3}
              style={noteEditing && { border: '2px solid var(--yellow)' }}
              {...register('description', { required: true })}
            />
          </Form.Group>
        </Row>
        <Stack direction="horizontal" gap={3}>
          <div className="ms-auto">
            {noteEditing && (
              <Button
                variant="light"
                className="fw-bolder me-2"
                type="button"
                onClick={handleCancelEditing}
              >
                CANCEL EDIT
              </Button>
            )}
            <Button variant="primary" className="fw-bolder" type="submit">
              {noteEditing ? `EDIT NOTE` : 'POST NOTE'}
            </Button>
          </div>
        </Stack>
      </form>
      <hr />
      <Row>
        {data?.results.map((note) => (
          <Col md={6} sm={12} key={note.id} className="mt-3">
            <Panel style={{ minHeight: 150 }}>
              <div className="d-flex justify-content-between">
                <p>
                  <b>
                    {`${note.createdBy.firstName} ${note.createdBy.lastName}`}
                  </b>
                </p>
                <p className="fw-light">{getStatus(note)}</p>
                <p>
                  <b>
                    {format(
                      new Date(note?.dateCreated),
                      DATE_FORMATS.DATE_FORMAT
                    )}
                  </b>
                </p>
              </div>
              {!note.dateDeleted && (
                <>
                  <p>{note.description}</p>
                  <Stack direction="horizontal" gap={3}>
                    <div className="ms-auto">
                      <Stack direction="horizontal" gap={4}>
                        {userId === note.createdBy.id && (
                          <div
                            className="d-none d-sm-block cursor-point"
                            onClick={() => onEdit(note)}
                          >
                            <Stack direction="horizontal" gap={1}>
                              <FiEdit />
                              <b>EDIT NOTE</b>
                            </Stack>
                          </div>
                        )}
                        <div
                          className="d-none d-sm-block cursor-point"
                          onClick={() => onDelete(note.id)}
                        >
                          <Stack direction="horizontal" gap={1}>
                            <FiTrash2 />
                            <b>DELETE NOTE</b>
                          </Stack>
                        </div>
                      </Stack>
                    </div>
                  </Stack>
                </>
              )}
            </Panel>
          </Col>
        ))}
      </Row>
    </>
  );
}
