import { Pagination } from 'src/types/pageable.type';

export default interface IJobsData {
  pagination: Pagination;
  results: IJobTitle[];
}

export interface IJobOptionsData {
  jobTitles: IJobTitle[];
  companyTypes: ICompanyType[];
}

export interface IJobTitle {
  id: number;
  name: string;
}

export interface ICompanyType {
  id: number;
  name: string;
}

export enum ShiftStatus {
  OPEN = 'open',
  CONFIRMED = 'confirmed',
  PENDING = 'pending',
}

export enum ActivityTrackerView {
  DATE = 'date',
  WORKER = 'worker',
}

export enum ActivityFilters {
  UPCOMING = 'upcoming',
  ONBOARDING = 'onboarding',
  MARK_PAID = 'mark_paid',
  TO_APPROVE = 'to_approve',
  NO_CHECK_IN_OUT = 'no_check_in_out',
  NO_SUBMITTED_TIME = 'no_submitted_time',
}

export interface IActivityData {
  id: number;
  worker: string;
  status: string;
  onboarding: string;
  scheduledTime: string;
  checkIn: string;
  checkOut: string;
  submittedTime: string;
  totalHours: number;
  workPay: string;
  expensePay: string;
  totalPay: string;
  shiftPaid: boolean;
  date: string;
}
