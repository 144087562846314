import { Badge, Card, Col, Row, Stack } from 'react-bootstrap';
import { Panel, StarRating, ThWorkerBadge } from 'components/elements';
import { IoLogoFacebook } from '@react-icons/all-files/io/IoLogoFacebook';
import { IoLogoInstagram } from '@react-icons/all-files/io/IoLogoInstagram';
import { IoLogoTwitter } from '@react-icons/all-files/io/IoLogoTwitter';
import { IoLogoLinkedin } from '@react-icons/all-files/io/IoLogoLinkedin';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import { GrLocation } from '@react-icons/all-files/gr/GrLocation';
import {
  EventType,
  IWorkerJobData,
  PersonalityTrait,
} from 'types/workerProfile.type';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { isEmpty } from 'lodash';
import DATE_FORMATS from 'src/constants/dateFormat';
import THCarousel from 'components/elements/THCarousel';
import { useEffect, useRef, useState } from 'react';
import { ITag } from 'types/tag.type';
import ShowImage from 'components/elements/ShowImage';
import YoutubeEmbed from 'components/elements/YoutubeEmbed';
import { formatOpenDateRange } from 'src/utils/DateUtils';
import useAvatarImageFallback from 'src/hooks/useAvatarImageFallback';
import WorkerStats from 'components/elements/WorkerProfile/WorkerStats/WorkerStats';
import WorkerCertificates from './WorkerCertificates';
import EndorsementList from './EndorsementList';

const TOP_25_LOCAL_MARKET = 25;

interface Params {
  workerProfile: IWorkerJobData;
  distance?: number;
  tags?: ITag[];
}

export default function WorkerDetails({
  workerProfile,
  distance,
  tags,
}: Params) {
  const {
    worker,
    workerEndorsements,
    promoWorkExperiences,
    educations,
    nonPromoWorkExperiences,
  } = workerProfile;

  const [mostEvents, setMostEvents] = useState<EventType | null>(null);
  const [traitsMost, setTraitsMost] = useState<PersonalityTrait | null>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  useAvatarImageFallback(avatarRef);

  const calcMostEventsAndTraits = () => {
    if (workerProfile.workerEndorsements) {
      if (workerProfile.workerEndorsements.eventTypes) {
        workerProfile.workerEndorsements.eventTypes.forEach((eventType) => {
          if (
            mostEvents === null ||
            eventType.endorsers.length > mostEvents.endorsers.length
          ) {
            setMostEvents(eventType);
          }
        });
      }
      if (workerProfile.workerEndorsements.personalityTraits) {
        workerProfile.workerEndorsements.personalityTraits.forEach(
          (personalityTrait) => {
            if (
              traitsMost === null ||
              personalityTrait.endorsers.length > traitsMost.endorsers.length
            ) {
              setTraitsMost(personalityTrait);
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    calcMostEventsAndTraits();
  });

  if (!worker) {
    return <div />;
  }

  const buildMediaItems = () => {
    const items: any[] = [];
    if (worker.presentationVideoURL) {
      items.push(
        <div key={0} style={{ width: 325 }}>
          <YoutubeEmbed
            url={worker.presentationVideoURL}
            width={310}
            height={155}
          />
        </div>
      );
    }
    if (worker.additionalPictures) {
      worker.additionalPictures.pictures.map((picture) => {
        items.push(
          <Col key={picture.id} style={{ width: 170 }}>
            <ShowImage
              src={picture.url}
              style={{
                width: 155,
                height: 155,
                objectFit: 'cover',
                backgroundPosition: '50% 50%',
                borderRadius: 4,
              }}
            />
          </Col>
        );
        return picture;
      });
    }
    return items;
  };

  const shouldRenderLocalMarketRank = () => {
    return (
      worker?.localMarketPosition !== undefined &&
      worker?.localMarketPosition > 0 &&
      worker?.localMarketPosition <= TOP_25_LOCAL_MARKET
    );
  };

  return (
    <Row>
      <Col md={3} xs={12} className="mt-4">
        <Card className="panel p-0 square-container" border="light">
          <div className="square-content">
            <Card.Img
              variant="top"
              src={worker.profileImageUrl}
              ref={avatarRef}
            />
          </div>
          <ThWorkerBadge
            addClass="badge-position cursor-default"
            worker={workerProfile.worker}
            badgeText="IN-NETWORK"
          />

          <Card.Body>
            <Col xs={12} md={12} lg={12} className="mt-1">
              <Stack className="justify-content-start" gap={1}>
                <Row>
                  <Col className="float-left" xs={12}>
                    <b className="ms-auto fs-5">
                      {worker.firstName} {worker.lastName}
                    </b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <small>
                      <b className="ms-auto">{worker.yearsInIndustry} years</b>{' '}
                      in the industry
                    </small>
                  </Col>
                </Row>
                <Row>
                  {distance && distance >= 0 && (
                    <Col>
                      <GrLocation className="location-icon" />{' '}
                      <small>{distance?.toFixed(2)} miles</small>
                    </Col>
                  )}

                  <Col>
                    <StarRating value={worker.rating} color="yellow" />
                  </Col>
                </Row>
                <Row md="auto">
                  {tags?.map((tag: any) => (
                    <Col className="p-1" key={tag.label}>
                      <Badge className={tag.bgText} bg={tag.bg}>
                        {tag.label}
                      </Badge>
                    </Col>
                  ))}
                </Row>
                {shouldRenderLocalMarketRank() && (
                  <>
                    <b className="fs-5">Rating</b>
                    <div>
                      #{worker?.localMarketPosition} Local Market Leaders
                    </div>
                  </>
                )}
                <b className="fs-5">Social Media</b>
                <Stack direction="horizontal" gap={3}>
                  {worker.facebookUser && (
                    <IoLogoFacebook
                      key={1}
                      className="pe-auto cursor-point"
                      size={24}
                      onClick={() =>
                        window.open(
                          `https://facebook.com/${worker.facebookUser}`,
                          '_blank',
                          'noreferrer'
                        )
                      }
                    />
                  )}
                  {worker.instagramUser && (
                    <IoLogoInstagram
                      key={2}
                      className="pe-auto cursor-point"
                      size={24}
                      onClick={() =>
                        window.open(
                          `https://instagram.com/${worker.instagramUser}`,
                          '_blank',
                          'noreferrer'
                        )
                      }
                    />
                  )}
                  {worker.twitterUser && (
                    <IoLogoTwitter
                      key={3}
                      className="pe-auto cursor-point"
                      size={24}
                      onClick={() =>
                        window.open(
                          `https://twitter.com/${worker.twitterUser}`,
                          '_blank',
                          'noreferrer'
                        )
                      }
                    />
                  )}
                  {worker.linkedinUser && (
                    <IoLogoLinkedin
                      key={4}
                      className="pe-auto cursor-point"
                      size={24}
                      onClick={() =>
                        window.open(
                          `https://linkedin.com/in/${worker.linkedinUser}`,
                          '_blank',
                          'noreferrer'
                        )
                      }
                    />
                  )}
                </Stack>
                <b>Travel Preferences</b>
                <div>
                  Travel nationally?&nbsp;
                  {worker.willingToTravel ? (
                    <AiFillCheckCircle color="green" />
                  ) : (
                    <AiFillCloseCircle color="red" />
                  )}
                </div>
                <div>
                  Travel for longer periods?&nbsp;
                  {worker.travelLonger ? (
                    <>
                      <AiFillCheckCircle color="green" />
                      <br />
                      {worker.travelLonger.label}
                    </>
                  ) : (
                    <AiFillCloseCircle color="red" />
                  )}
                </div>
              </Stack>
            </Col>
          </Card.Body>
        </Card>
      </Col>
      <Col md={9} xs={12} className="mt-4">
        <Row>
          <Col md={12} className="mb-3">
            <WorkerStats
              confirmedJobsWorked={worker.stats?.confirmedJobsWorked ?? 0}
              confirmedJobsWorkedUniqueCompany={
                worker.stats?.confirmedJobUniqueCompanies ?? 0
              }
              notConfirmedJobsWorked={worker.stats?.nonConfirmedJobsWorked ?? 0}
              notConfirmedJobsWorkedUniqueCompany={
                worker.stats?.nonConfirmedJobUniqueCompanies ?? 0
              }
            />
          </Col>
          <Col md={12} className="mt-3">
            <Panel>
              <p>
                <b>Skills</b>
              </p>
              {workerProfile.worker.skills.length > 0 ? (
                <Row md="auto">
                  {workerProfile.worker.skills &&
                    workerProfile.worker.skills.map((skill) => (
                      <Col key={skill.id} className="p-1">
                        <Badge className="text-dark" bg="light">
                          {skill.name}
                        </Badge>
                      </Col>
                    ))}
                </Row>
              ) : (
                <span className="text-gray fst-italic">
                  Worker has not added any skills.
                </span>
              )}
            </Panel>
          </Col>
          <Col md={12} className="mt-3">
            <Panel>
              <p>
                <b>Badges</b>
              </p>
              {workerProfile.certificates.certificates.length > 0 ? (
                <Row>
                  {workerProfile.certificates && (
                    <WorkerCertificates
                      certificateList={workerProfile.certificates}
                    />
                  )}
                </Row>
              ) : (
                <span className="text-gray fst-italic">
                  Worker does not have certificates
                </span>
              )}
            </Panel>
          </Col>

          <Col md={12} className="mt-3">
            <Panel>
              <p>
                <b>Endorsements</b>
              </p>
              {isEmpty(workerEndorsements) ||
              workerEndorsements?.totalEndorsements === 0 ? (
                <span className="text-gray fst-italic">
                  Worker does not have any Endorsement information yet.
                </span>
              ) : (
                <>
                  <Row md="auto">
                    <Col md={4}>
                      Total Endorsements{' '}
                      <b>&nbsp;{workerEndorsements.totalEndorsements}</b>
                    </Col>
                    <Col md={4}>
                      Total as Manager{' '}
                      <b>&nbsp;{workerEndorsements.totalAsManager}</b>
                    </Col>
                    <Col md={4}>
                      Total from Companies{' '}
                      <b>&nbsp;{workerEndorsements.totalFromCompany}</b>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={4} xs={12}>
                      <b>How many events have you worked with them?</b>
                    </Col>
                    <Col md={8} xs={12}>
                      <Row>
                        {workerEndorsements.eventsNumbers?.map(
                          (eventNumber) => (
                            <Col
                              key={eventNumber.label}
                              md={3}
                              className="text-center"
                            >
                              <Stack className="align-items-center">
                                <b>{eventNumber.label}</b>
                                <div style={{ width: 40, height: 40 }}>
                                  <CircularProgressbar
                                    value={33}
                                    text={`${33}%`}
                                    styles={buildStyles({
                                      textSize: '26px',
                                      pathColor: `green`,
                                      textColor: '#000',
                                    })}
                                  />
                                </div>
                              </Stack>
                            </Col>
                          )
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={12}>
                      <b>Types of events worked?</b>
                    </Col>
                    {mostEvents && (
                      <Col xs={12}>
                        <Badge
                          className="text-dark mt-2 badge"
                          bg="light-success"
                        >
                          {worker.firstName} has worked at {mostEvents.title}{' '}
                          events the most.
                        </Badge>
                      </Col>
                    )}
                    {workerEndorsements?.eventTypes && (
                      <EndorsementList
                        endorsements={workerEndorsements.eventTypes}
                      />
                    )}
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={12}>
                      <b>Traits that best describe worker?</b>
                    </Col>
                    <Col xs={12}>
                      <Badge
                        className="text-dark mt-2 badge"
                        bg="light-success"
                      >
                        Most of people that have worked with {worker.firstName}{' '}
                        would describe them as {traitsMost?.title}.
                      </Badge>
                    </Col>
                    {workerEndorsements?.personalityTraits && (
                      <EndorsementList
                        endorsements={workerEndorsements.personalityTraits}
                      />
                    )}
                  </Row>
                </>
              )}
            </Panel>
          </Col>
        </Row>
      </Col>
      <Col md={12} xs={12} className="mt-3">
        <Panel>
          <p>
            <b>Media</b>
          </p>
          {worker.additionalPictures &&
          worker.additionalPictures.pictures.length > 0 ? (
            <Row>
              <THCarousel items={buildMediaItems()} />
            </Row>
          ) : (
            <span className="text-gray fst-italic">
              No media has been added.
            </span>
          )}
        </Panel>
      </Col>

      <Col md={12} xs={12} className="mt-3">
        <Panel>
          <p>
            <b>Promotional Experience</b>
          </p>
          {promoWorkExperiences.values.length > 0 ? (
            promoWorkExperiences.values.map((promoWork, index) => (
              <span key={promoWork.id}>
                <Stack direction="horizontal" gap={3}>
                  <div>
                    {promoWork.hiringCompany}
                    <p>
                      {promoWork.position} for {promoWork.brandRepresented}
                    </p>
                    <p>{promoWork.duties}</p>
                  </div>
                  <div className="ms-auto work-date">
                    <b>
                      {formatOpenDateRange(
                        promoWork.startDate,
                        null,
                        DATE_FORMATS.DATE_FORMAT,
                        true
                      )}
                    </b>
                  </div>
                </Stack>
                {index !== promoWorkExperiences.values.length - 1 && <hr />}
              </span>
            ))
          ) : (
            <span className="text-gray fst-italic">
              Worker has not added promotional job experience.
            </span>
          )}
        </Panel>
      </Col>
      <Col md={6} xs={12} className="mt-3">
        <Panel>
          <p>
            <b>Education</b>
          </p>
          {educations.values.length > 0 ? (
            educations.values.map((education, index) => (
              <span key={education.id}>
                <Stack direction="horizontal" gap={3}>
                  <div>
                    {education.university}
                    <br />
                    {education.degree}
                  </div>
                  <div className="ms-auto work-date">
                    <b>
                      {formatOpenDateRange(
                        education.degreeDate,
                        null,
                        DATE_FORMATS.DATE_FORMAT,
                        true
                      )}
                    </b>
                  </div>
                </Stack>
                {index !== educations.values.length - 1 && <hr />}
              </span>
            ))
          ) : (
            <span className="text-gray fst-italic">
              Worker has not added any education information.
            </span>
          )}
        </Panel>
      </Col>
      <Col md={6} xs={12} className="mt-3">
        <Panel>
          <p>
            <b>Work History</b>
          </p>
          {nonPromoWorkExperiences.values.length > 0 ? (
            nonPromoWorkExperiences.values.map((nonPromo, index) => (
              <span key={nonPromo.id}>
                <Stack direction="horizontal" gap={3}>
                  <div>
                    {nonPromo.position} on {nonPromo.hiringCompany}
                    <br />
                    {nonPromo.duties}
                  </div>
                  <div className="ms-auto work-date">
                    <b>
                      {formatOpenDateRange(
                        nonPromo.startDate,
                        nonPromo?.endDate,
                        DATE_FORMATS.DATE_FORMAT
                      )}
                    </b>
                  </div>
                </Stack>
                {index !== nonPromoWorkExperiences.values.length - 1 && <hr />}
              </span>
            ))
          ) : (
            <span className="text-gray fst-italic">
              Worker has not added non-promotional job experience.
            </span>
          )}
        </Panel>
      </Col>
    </Row>
  );
}
