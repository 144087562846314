import { ReactElement } from 'react';
import { TbPointFilled } from 'react-icons/tb';
import { Col, Row, Stack } from 'react-bootstrap';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import './style.css';
import { toNatural } from 'src/utils/StringUtils';

export default function JobQuestions(): ReactElement {
  const { job } = useJobContext();

  if (!job) {
    return <div />;
  }

  const getJobQuestions = () => {
    if (job.questions) {
      return (
        <>
          <Row className="mt-3 mb-4">
            <p className="job-description-text m-0">
              <b>Application Questions:</b>
            </p>
          </Row>
          {job.questions.map((question) => {
            return (
              <Row key={question.id} className="mt-2">
                <Col sm={9} className="d-flex">
                  <TbPointFilled className="me-1 question-dot-icon" />
                  <p className="job-description-text">{question.text}</p>
                </Col>
                <Col sm={3}>
                  <p className="job-description-text ms-3">
                    <b>Type: </b>
                    {toNatural(question.type)}
                  </p>
                </Col>
                {question.choices && (
                  <p className="job-description-text ms-4 mb-0">Options:</p>
                )}
                {question.choices &&
                  question.choices.map((choice) => {
                    return (
                      <Stack key={choice.choice} direction="horizontal" gap={1}>
                        <TbPointFilled className="me-1 ms-5" />
                        <p className="m-0 job-description-text justify-content-center">
                          {choice.choice}
                        </p>
                      </Stack>
                    );
                  })}
                {question.choices && <div className="pb-3" />}
              </Row>
            );
          })}
        </>
      );
    }
    return <div />;
  };

  return <>{getJobQuestions()}</>;
}
