import {
  ComHubDismissTypeEnum,
  Review,
} from 'src/apps/company-frontend/types/comm-hub.type';

export const dismissItems = async (
  items: Review[],
  associatedType: ComHubDismissTypeEnum,
  mutateFunction: (itemsToDismiss: {
    items: Array<{
      associatedType: ComHubDismissTypeEnum;
      associatedIds: number[];
    }>;
  }) => Promise<void>
) => {
  const itemsToDismiss = {
    items: [
      {
        associatedType,
        associatedIds: items.map((item) => item.userState.associatedId),
      },
    ],
  };

  try {
    await mutateFunction(itemsToDismiss);
  } catch (error) {
    console.error('Error dismissing review:', error);
  }
};
