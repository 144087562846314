import { getZoomLevel, haversineDistance } from 'src/utils/GoogleMapsUtils';
import { isMobile } from 'react-device-detect';
import { useMemo } from 'react';
import GoogleMapComponent, { Marker } from '../GoogleMapComponent';
import '../styles.scss';
import './styles.css';

interface CheckInOutLocationProps {
  latitude: number;
  longitude: number;
  markers: Marker[];
}

export default function CheckInOutLocation({
  latitude,
  longitude,
  markers,
}: CheckInOutLocationProps) {
  const zoomLevel = useMemo(() => {
    if (markers.length === 2) {
      // calculate distance between event and worker markers
      return haversineDistance(
        latitude,
        longitude,
        markers[1].lat,
        markers[1].lng
      );
    }
    // default distance whenever there is no worker marker
    return 10;
  }, [latitude, longitude, markers]);

  return (
    <GoogleMapComponent
      lat={latitude}
      lng={longitude}
      markers={markers}
      widthSize="100%"
      heightSize={isMobile ? '110px' : '150px'}
      initialZoom={getZoomLevel(zoomLevel)}
    />
  );
}
