import {
  ComHubEndorsementCase,
  GivenReview,
  GivenReviewTypeEnum,
  UserState,
  WorkInfo,
} from 'company/types/comm-hub.type';
import DATE_FORMATS from 'src/constants/dateFormat';
import { formatWorkDateRange } from 'src/utils/DateUtils';
import { WorkerRating } from 'types/rating.type';

export class GivenReviewModel implements GivenReview {
  jobAssignmentId: number;

  endorsementCaseId: number;

  eventName: string;

  workerId: number;

  jobId: number;

  type: GivenReviewTypeEnum;

  workerRating: WorkerRating | null;

  endorsementCase?: ComHubEndorsementCase;

  workInfo: WorkInfo;

  userState: UserState;

  constructor(givenReview: GivenReview) {
    this.jobAssignmentId = givenReview.jobAssignmentId;
    this.endorsementCaseId = givenReview.endorsementCaseId;
    this.eventName = givenReview.eventName;
    this.workerId = givenReview.workerId;
    this.jobId = givenReview.jobId;
    this.type = givenReview.type;
    this.workerRating = givenReview.workerRating;
    this.endorsementCase = givenReview.endorsementCase;
    this.workInfo = givenReview.workInfo;
    this.userState = givenReview.userState;
  }

  getUniqueKey(): number {
    return this.jobAssignmentId || this.endorsementCaseId;
  }

  /**
   * Gets the performance rating regardless of review type
   */
  getPerformance(): number | undefined {
    if (this.type === GivenReviewTypeEnum.RATING) {
      return this.workerRating?.performance;
    }
    return this.endorsementCase?.performance;
  }

  /**
   * Gets the author name regardless of review type
   */
  getAuthorName(): string {
    if (this.type === GivenReviewTypeEnum.RATING) {
      return this.workerRating?.reviewer.name || 'Unknown';
    }
    return this.endorsementCase?.endorser.fullName || 'Unknown';
  }

  /**
   * Gets the worker name regardless of review type
   */
  getWorkerName(): string {
    if (this.type === GivenReviewTypeEnum.RATING) {
      return this.workInfo?.workerName || 'Unknown';
    }
    return this.endorsementCase?.endorsedWorker?.fullName || 'Unknown';
  }

  /**
   * Gets the date created regardless of review type
   */
  getDateCreated(): string {
    if (this.type === GivenReviewTypeEnum.RATING) {
      return this.workerRating?.dateCreated || '';
    }
    return this.endorsementCase?.dateCreated || '';
  }

  getWorkedDateRange(): string {
    if (this.type === GivenReviewTypeEnum.ENDORSEMENT_CASE) {
      return 'N/A';
    }

    if (!this.workInfo?.start || !this.workInfo?.end) {
      return '-';
    }

    const startDate = new Date(this.workInfo.start);
    const endDate = new Date(this.workInfo.end);

    return formatWorkDateRange(startDate, endDate, DATE_FORMATS.DATE_FORMAT);
  }
}
