import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useRef, useState } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { updateWorkerProfile } from 'src/apps/worker-frontend/services/worker/api';
import { useWorkerProfileContext } from 'src/apps/worker-frontend/state/workerProfileContext';
import { ThLoading } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import { Country } from 'src/types/address.type';
import {
  workerProfileInfoValidationSchema,
  WorkerProfileSchemaType,
} from 'src/types/workerProfile.type';
import DocViewer from 'src/utils/DocViewer';
import { convertFileToBase64 } from 'src/utils/FileUtils';

function Resume() {
  const formRef = useRef<HTMLFormElement>(null);
  const { workerProfile, setWorkerProfile } = useWorkerProfileContext();
  const { showErrorAlert, showSuccessAlert, alertMessage } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { isLoading: isLoadingForm },
    setValue,
    reset,
  } = useForm<WorkerProfileSchemaType>({
    resolver: zodResolver(workerProfileInfoValidationSchema),
  });

  const defaultValues = workerProfile && {
    id: workerProfile.id, // use the worker id as hidden input for the form
    firstName: workerProfile.firstName,
    lastName: workerProfile.lastName,
    address: {
      line1: workerProfile.address?.line1,
      city: workerProfile.address?.city,
      postalCode: workerProfile.address?.postalCode,
      state: workerProfile.address?.state.unnabreviated,
      country: (workerProfile.address?.country as Country)?.unnabreviated,
      googleAddress: workerProfile.address.googleAddress,
    },
    cellPhone: workerProfile.cellPhone,
    yearsInIndustry: workerProfile.yearsInIndustry,
    monthsInIndustry: workerProfile.monthsInIndustry,
    birthday: workerProfile.birthday
      ? new Date(workerProfile.birthday)
      : undefined,
    gender: workerProfile.gender,
    heightFeet: workerProfile.heightFeet,
    heightInches: workerProfile.heightInches,
    facebookUser: workerProfile.facebookUser,
    instagramUser: workerProfile.instagramUser,
    twitterUser: workerProfile.twitterUser,
    linkedinUser: workerProfile.linkedinUser,
    presentationVideoURL: workerProfile.presentationVideoURL,
    willingToTravel: workerProfile.willingToTravel,
    travelPeriod: workerProfile.travelPeriod?.value,
  };

  const handleResumeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pdfFile = event.currentTarget.files![0];
    reset(defaultValues);
    try {
      const pdfBase64 = await convertFileToBase64(pdfFile);
      setValue('resume.contentType', pdfFile.type);
      setValue('resume.content', pdfBase64 as string);
      formRef.current!.requestSubmit();
    } catch {
      showErrorAlert('An error ocurred processing the resume.');
    }
  };

  const onSubmit: SubmitHandler<WorkerProfileSchemaType> = useCallback(
    (data: WorkerProfileSchemaType) => {
      setIsLoading(true);
      updateWorkerProfile(data)
        .then((response) => {
          setWorkerProfile(response.data.worker);
          showSuccessAlert('Resume updated successfully!');
        })
        .catch((error) => {
          showErrorAlert(
            error.response?.data?.message ||
              'An error occurred submitting the form.'
          );
        })
        .finally(() => {
          formRef.current?.reset();
          setIsLoading(false);
        });
    },
    [setWorkerProfile, showErrorAlert, showSuccessAlert]
  );

  return (
    <Container className="mt-2">
      {(isLoading || isLoadingForm) && <ThLoading />}
      <Row className="justify-content-md-center text-center">
        <b>
          {!workerProfile?.resumeUrl
            ? 'Recruiters look for profiles with resumes, add yours now!'
            : 'Is your resume outdated? Upload a new one!'}
        </b>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          ref={formRef}
          className="mb-4"
        >
          <Row>
            <Col xs={12} md={6} className="m-auto mt-4">
              <Form.Control
                type="file"
                accept="application/pdf"
                onChange={handleResumeChange}
              />
            </Col>
          </Row>
        </Form>
        {workerProfile?.resumeUrl && (
          <a
            href={workerProfile.resumeUrl}
            className="mb-5"
            target="_blank"
            rel="noreferrer"
            style={{ width: 'auto' }}
          >
            Download your resume file
          </a>
        )}
        <DocViewer
          data-testid="pdf-viewer"
          file={workerProfile?.resumeUrl}
          useGooglePreviewerForPdf
          style={{ width: '100%', height: '100vh' }}
        />
      </Row>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Container>
  );
}

export default Resume;
