import { useSearchParams } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';
import { GoBell } from 'react-icons/go';
import ThDateRangePicker from 'components/elements/Datepicker';
import ThSearchInput from 'components/elements/ThSearchInput/ThSearchInput';
import { ComHubQueryParamsEnum } from 'company/types/comm-hub.type';
import { useState } from 'react';

interface FilterBarProps {
  onSearch: (searchObject: {
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }) => void;
  onClear: () => void;
  onShowOnlyNew?: () => void;
  showOnlyNew?: boolean;
}

function FilterBar({
  onSearch,
  onClear,
  onShowOnlyNew,
  showOnlyNew,
}: FilterBarProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(ComHubQueryParamsEnum.SEARCH_TEXT) || ''
  );
  const [pickedDateRange, setPickedDateRange] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: searchParams.get(ComHubQueryParamsEnum.DATE_FROM)
      ? new Date(searchParams.get(ComHubQueryParamsEnum.DATE_FROM)!)
      : undefined,
    endDate: searchParams.get(ComHubQueryParamsEnum.DATE_TO)
      ? new Date(searchParams.get(ComHubQueryParamsEnum.DATE_TO)!)
      : undefined,
  });

  const handleShowOnlyNew = () => {
    if (searchParams.get(ComHubQueryParamsEnum.NEW)) {
      searchParams.delete(ComHubQueryParamsEnum.NEW);
    } else {
      searchParams.set(ComHubQueryParamsEnum.NEW, 'true');
    }
    setSearchParams(searchParams);
    if (onShowOnlyNew) {
      onShowOnlyNew();
    }
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchValue(inputValue);
  };

  const handleDateRangeChange = (
    dateRange: Array<{ startDate: Date; endDate: Date }>
  ) => {
    const { startDate, endDate } = dateRange[0];

    setPickedDateRange({
      startDate,
      endDate,
    });
  };

  const clearFilters = () => {
    setSearchValue('');
    setPickedDateRange({ startDate: undefined, endDate: undefined });
    onClear();
  };

  const handleSearch = () => {
    onSearch({
      searchText: searchValue,
      dateRange: {
        startDate: pickedDateRange.startDate,
        endDate: pickedDateRange.endDate,
      },
    });
  };

  return (
    <Stack direction="horizontal" className="mb-3">
      <div style={{ maxWidth: '500px', width: '100%' }}>
        <ThSearchInput
          value={searchValue}
          name="received-reviews"
          placeholder="Search by event, name or position"
          onChange={handleSearchOnChange}
        />
      </div>
      <div className="date-range-picker">
        Date Range
        <ThDateRangePicker
          placeholder="Select Date Range"
          start={pickedDateRange.startDate}
          end={pickedDateRange.endDate}
          setStart={() => undefined}
          setEnd={() => undefined}
          onChange={handleDateRangeChange}
        />
      </div>
      <Button style={{ marginLeft: '20px' }} onClick={handleSearch}>
        SEARCH
      </Button>
      <Button
        variant="light"
        style={{ marginLeft: '20px' }}
        onClick={clearFilters}
      >
        Clear
      </Button>
      {onShowOnlyNew && (
        <Button
          variant="light"
          className="ms-auto fw-bold"
          onClick={handleShowOnlyNew}
        >
          <GoBell className="me-2 bell-icon" />
          {showOnlyNew ? 'See all' : 'See new'}
        </Button>
      )}
    </Stack>
  );
}

export default FilterBar;
