import { Form, Button, Card, Row, Col, Stack } from 'react-bootstrap';
import { AiOutlineSend } from 'react-icons/ai';
import { isMobile } from 'react-device-detect';
import MessageRequest, { MessageObject } from 'src/types/message-request.type';
import { useForm } from 'react-hook-form';
import * as api from 'src/apps/company-frontend/services/directMessage/api';
import { IWorkerData } from 'src/types/worker.type';
import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useLayoutEffect,
} from 'react';
import { useDirectMessageContext } from 'src/apps/company-frontend/state/directMessageContext';

interface Params {
  worker: IWorkerData;
  setMessageRecentlySent: (value: MessageObject) => void;
}
export default function DirectMessageChatFooter({
  worker,
  setMessageRecentlySent,
}: Params) {
  const { register, handleSubmit, reset, watch } = useForm<MessageRequest>();
  const { chatId, setChatId } = useDirectMessageContext();
  const [disableSendMessageButton, setDisableSendMessageButton] =
    useState(false);

  const { onChange, ref, ...rest } = register('message');

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useImperativeHandle(ref, () => textAreaRef.current);

  const adjustHeight = () => {
    textAreaRef.current!.style.height = 'inherit';
    textAreaRef.current!.style.height = `${
      textAreaRef.current!.scrollHeight
    }px`;
  };

  useLayoutEffect(adjustHeight, []);

  const handleKeyDown = (e: any) => {
    onChange(e);
    adjustHeight();
  };

  const messageWatcher = watch(['message']);

  useEffect(() => {
    const disableButton = !messageWatcher[0];
    setDisableSendMessageButton(disableButton);
  }, [messageWatcher]);

  const onSubmit = async (dataRequest: MessageRequest) => {
    if (!dataRequest.message) return;
    setDisableSendMessageButton(true);
    const temporaryId = Math.random();
    try {
      setMessageRecentlySent({
        message: dataRequest.message,
        sent: true,
        id: temporaryId,
      });
      const resultMessageSent = await api.sendWorkerDirectMessage(
        worker.id,
        dataRequest
      );
      if (resultMessageSent?.success) {
        if (!chatId) {
          const result = await api.fetchWorkerDirectMessages(worker.id);
          setChatId(result.messages?.[0]?.chatId ?? null);
        }
      }
      if (!resultMessageSent?.success) {
        setMessageRecentlySent({
          message: dataRequest.message || '',
          sent: false,
          id: temporaryId,
        });
      }
      reset();
      setDisableSendMessageButton(false);
      adjustHeight();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Row>
          <Col xs={isMobile ? 8 : 10}>
            <Form.Control
              style={{
                maxHeight: '70svh',
                resize: 'none',
                height: 'auto',
                backgroundColor: 'white',
              }}
              as="textarea"
              ref={textAreaRef}
              onChange={handleKeyDown}
              {...rest}
              rows={2}
            />
          </Col>
          <Col xs={isMobile ? 4 : 2} className="float-right">
            <Stack
              direction="horizontal"
              className="justify-content-end align-items-center h-100"
              gap={3}
            >
              <Button
                disabled={disableSendMessageButton}
                type="submit"
                variant="light"
                className="me-3"
              >
                <AiOutlineSend size={20} />
              </Button>
            </Stack>
          </Col>
        </Row>
      </Card>
    </form>
  );
}
