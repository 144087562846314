import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import {
  CapabilityType,
  CompanyCapability,
} from 'src/apps/company-frontend/types/company-capability.data';
import ICompanyMemberIndicators from 'src/types/company-member-indicators.type';
import getCompany from '../services/company/api';
import { fetchCompanyMemberNotifications } from '../services/notifications/api';

interface CompanyContextData {
  company: ICompanyContext | undefined;
  capabilities: CompanyCapability[];
}

interface ICompanyContext {
  isLoading: boolean;
  id: number;
  name: string | null;
  premium: boolean;
  profileURLName: string;
  profileImageUrl: string;
  rating: number;
  reviewsCount: number;
  capabilities: CompanyCapability[];
  indicators: ICompanyMemberIndicators | undefined;
  fetchCompany: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<any, Error>>; // fix type
  fetchNotifications: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<ICompanyMemberIndicators, Error>>; // fix type
  hasCapability(capabilityType: CapabilityType): boolean;
}

// define context
const CompanyContext: ICompanyContext | any = React.createContext({});

// define provider
export function CompanyProvider(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId]: any = useState(null);
  const [name, setName]: any = useState(null);
  const [profileImageUrl, setProfileImageUrl]: any = useState('');
  const [premium, setPremium] = useState(false);
  const [profileURLName, setProfileURLName] = useState('');
  const [rating, setRating] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [capabilities, setCapabilities] = useState<CompanyCapability[]>([]);
  const [indicators, setIndicators] = useState<ICompanyMemberIndicators>();

  function updateState(data: CompanyContextData) {
    if (data?.company) {
      setId(data.company.id);
      setName(data.company.name);
      setPremium(data.company.premium);
      setProfileURLName(data.company.profileURLName);
      setProfileImageUrl(data.company.profileImageUrl);
      setRating(data.company.rating);
      setReviewsCount(data.company.reviewsCount);
      setCapabilities(data.capabilities ?? []);
    }
  }

  const { data: notificationData, refetch: fetchNotifications } = useQuery({
    queryKey: ['companyNotifications'],
    queryFn: fetchCompanyMemberNotifications,
  });

  useEffect(() => {
    setIndicators(notificationData);
  }, [notificationData]);

  const { data: companyData, refetch: fetchCompany } = useQuery({
    queryKey: ['company'],
    queryFn: getCompany,
  });

  useEffect(() => {
    updateState(companyData);
    setIsLoading(false);
  }, [companyData]);

  const contextValue: ICompanyContext = useMemo(() => {
    const hasCapability = (capabilityType: CapabilityType): boolean => {
      return (
        capabilities &&
        capabilities.find(
          (capability) => capability.capabilityType === capabilityType
        ) !== undefined
      );
    };

    return {
      isLoading,
      id,
      name,
      profileImageUrl,
      premium,
      profileURLName,
      rating,
      reviewsCount,
      indicators,
      capabilities,
      fetchCompany,
      fetchNotifications,
      hasCapability,
    };
  }, [
    isLoading,
    id,
    name,
    profileImageUrl,
    premium,
    profileURLName,
    rating,
    reviewsCount,
    indicators,
    capabilities,
    fetchCompany,
    fetchNotifications,
  ]);
  const { children } = props;
  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
}

export function useCompanyContext() {
  const context: ICompanyContext = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error(
      'useCompanyContext must be used within a CompanyProvider. Wrap a parent component in <CompanyProvider> to fix this error.'
    );
  }
  return context;
}
