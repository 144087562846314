import { Badge } from 'react-bootstrap';
import { IWorkerData } from 'src/types/worker.type';
import './style.css';

interface Params {
  worker: IWorkerData;
  showSmall?: boolean;
  addClass?: string;
  badgeText: string;
}

export default function ThWorkerBadge({
  worker,
  showSmall,
  addClass,
  badgeText,
}: Params) {
  const containsInNetworkBadge = (): boolean => {
    return !!worker?.features?.find((f) => f.feature === 'IN_NETWORK_BADGE');
  };

  const classNames = (): string => {
    let classesToAdd = 'mx-1 text-dark';
    if (addClass) {
      classesToAdd = `${classesToAdd} ${addClass}`;
    }
    return classesToAdd;
  };

  if (containsInNetworkBadge()) {
    return showSmall ? (
      <small>
        <Badge className={classNames()} bg="primary">
          {badgeText}
        </Badge>
      </small>
    ) : (
      <Badge className={classNames()} bg="primary">
        {badgeText}
      </Badge>
    );
  }

  return <div />;
}
