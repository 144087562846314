import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as workerApi from 'src/apps/company-frontend/services/worker/api';
import { IWorkerJobData } from 'src/types/workerProfile.type';
import QueryKeys from 'src/constants/queryKeys';
import { AxiosError } from 'axios';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import { useJobContext } from './jobContext';

interface IJobAssignmentContext {
  queryKey: string;
  workerId: number | undefined;
  workerRatable: boolean;
  isLoadingWorkerProfileData: boolean;
  workerJobData: IWorkerJobData | undefined;
  setWorkerId: (worker: number | undefined) => void;
  setWorkerRatable: (workerRatable: boolean) => void;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IWorkerJobData, AxiosError<unknown, any>>>;
}

const JobAssignmentContext: IJobAssignmentContext | any = createContext({});

interface ProviderProps {
  children: React.ReactNode;
}

export function JobAssignmentProvider({ children }: ProviderProps) {
  const [workerId, setWorkerId] = useState<number | undefined>();
  const [isLoadingWorkerProfileData, setIsLoadingWorkerProfileData] =
    useState(false);
  const [workerRatable, setWorkerRatable] = useState(true);

  const { job } = useJobContext();

  const queryKey = useMemo(() => {
    return `${QueryKeys.WORKER_PROFILE}_${workerId}_${job?.id ?? '0'}`;
  }, [workerId, job?.id]);

  const {
    data: workerJobData,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery<IWorkerJobData, AxiosError, IWorkerJobData>({
    queryKey: [queryKey],
    queryFn: () => workerApi.fetchWorkerJobInfo(workerId, job?.id, true),
    enabled: workerId !== undefined,
  });

  useEffect(() => {
    setIsLoadingWorkerProfileData(isLoading || isRefetching);
  }, [isLoading, isRefetching]);

  const contextValue: IJobAssignmentContext = useMemo(() => {
    return {
      queryKey,
      workerId,
      workerJobData,
      workerRatable,
      isLoadingWorkerProfileData,
      refetch,
      setWorkerId,
      setWorkerRatable,
    };
  }, [
    queryKey,
    workerId,
    workerJobData,
    workerRatable,
    isLoadingWorkerProfileData,
    refetch,
    setWorkerId,
    setWorkerRatable,
  ]);
  return (
    <JobAssignmentContext.Provider value={contextValue}>
      {children}
    </JobAssignmentContext.Provider>
  );
}

export function useJobAssignmentContext() {
  const context: IJobAssignmentContext = useContext(JobAssignmentContext);
  if (context === undefined) {
    throw new Error(
      'useJobContext must be used within a JobProvider. Wrap a parent component in <JobProvider> to fix this error.'
    );
  }
  return context;
}
