import { Badge, Image, Stack } from 'react-bootstrap';
import { format } from 'date-fns';
import { Endorsement } from 'company/types/comm-hub.type';
import StarRatingButton from 'components/elements/StarRatingButton/StarRatingButton';
import DATE_FORMATS from 'constants/dateFormat';

import './styles.css';

interface GivenEndorsementProps {
  endorsement: Endorsement;
}

function GivenEndorsement({ endorsement }: GivenEndorsementProps) {
  return endorsement ? (
    <Stack className="given-endorsement" gap={3}>
      <Stack direction="horizontal" className="justify-content-between">
        <span className="endorsement-date">
          {format(new Date(endorsement.dateCreated), DATE_FORMATS.DATE_FORMAT)}
        </span>
        <b>Endorsed</b>
      </Stack>

      <div className="endorsement-worker-data">
        <span className="endorsement-label">Worker</span>
        <Stack
          direction="horizontal"
          className="text-capitalize align-items-center"
        >
          <Image
            className="rounded-circle me-1"
            src={endorsement.endorsedWorker.profileImageUrl}
            width={30}
            height={30}
            alt={endorsement.endorsedWorker.fullName}
            style={{
              objectFit: 'cover',
            }}
          />
          {endorsement.endorsedWorker.fullName}
        </Stack>
        <span className="endorsement-label">Events worked</span>
        <span>{endorsement.sharedEventsNumber}</span>
      </div>

      <hr />

      <Stack>
        <span className="endorsement-label">
          Does their picture fairly portray them?
        </span>
        <span className="text-capitalize">
          {endorsement.photoIsRepresentative ? 'yes' : 'no'}.
        </span>
      </Stack>
      <Stack>
        <span className="endorsement-label">Did they manage you?</span>
        <span className="text-capitalize">
          {endorsement.isEndorsedManager ? 'yes' : 'no'}.
        </span>
      </Stack>
      <Stack>
        <span className="endorsement-label">
          What type of events did you work with them?
        </span>
        <Stack direction="horizontal" gap={2} className="flex-wrap">
          {endorsement.sharedEventsType.map((event) => (
            <Badge
              className="text-dark p-2 text-uppercase"
              bg="light"
              key={event.id}
            >
              {event.name}
            </Badge>
          ))}
        </Stack>
      </Stack>
      <Stack>
        <span className="endorsement-label">
          Traits that describe them best.
        </span>
        <Stack direction="horizontal" gap={2} className="flex-wrap">
          {endorsement.endorsedTraits.map((trait) => (
            <Badge
              className="text-dark p-2 text-uppercase"
              bg="light"
              key={trait.id}
            >
              {trait.name}
            </Badge>
          ))}
        </Stack>
      </Stack>
      <Stack>
        <span className="endorsement-label">
          Brands that you’ve worked on/for with this person.
        </span>
        <span>{endorsement.brandWorked.name}</span>
      </Stack>
      {endorsement.reviewDescription && (
        <Stack>
          <span className="endorsement-label">Review:</span>
          <p className="mb-0">{endorsement.reviewDescription}</p>
        </Stack>
      )}
      {endorsement.performance && (
        <div data-testid="rating-stars">
          <StarRatingButton
            value={endorsement.performance}
            allowChange={false}
            hideLabels
            size={35}
            gap={3}
          />
        </div>
      )}
    </Stack>
  ) : null;
}

export default GivenEndorsement;
