import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import IJobDetailData, { ShiftPosition } from 'src/types/job.detail.type';
import * as jobApi from 'src/apps/company-frontend/services/job/api';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ReactElement, useEffect, useId, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { zodResolver } from '@hookform/resolvers/zod';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from '@tanstack/react-query';
import { IJobTitle } from 'src/types/jobs.type';
import QueryKeys from 'src/constants/queryKeys';
import clone from 'src/assets/clone-shift.svg';
import useAlert from 'src/hooks/useAlert';
import DatePicker from 'react-datepicker';
import {
  getValidationSchema,
  shiftDefaultValues,
} from 'src/services/shift/shiftService';
import {
  Alert,
  Button,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
} from 'react-bootstrap';
import {
  createDateTimeFromDifferentDates,
  isSameDateOrAfter,
} from 'src/utils/DateUtils';
import { z } from 'zod';
import './style.css';

const TABLE_HEADER_WEB = [
  'Date',
  'Position',
  'Wage',
  'Hourly/Daily',
  'Openings',
  'Backups',
  'Start time',
  'End time',
  'Paid break',
];

export interface CreateShiftTableParams {
  job: IJobDetailData;
  shift?: ShiftPosition;
  onSubmit: (data: any) => void;
}

export default function CreateShiftTable({
  job,
  shift,
  onSubmit,
}: CreateShiftTableParams) {
  const [checkedIds, setCheckedIds] = useState(new Set<number>());
  const [jobTitles, setJobTitles] = useState<IJobTitle[]>([]);
  const { showWarningAlert, alertMessage } = useAlert();
  const [errorList, setErrorList] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useForm<ValidationShiftSchema>({
    resolver: zodResolver(getValidationSchema(job?.timezone)),
    defaultValues: shiftDefaultValues(job, shift),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shifts',
  });

  const { showErrorAlert } = useAlert();

  useEffect(() => {
    const errorFields: string[] = [
      'root',
      'date',
      'position',
      'payRate',
      'payRatePeriod',
      'openPositions',
      'openBackups',
      'startTime',
      'endTime',
      'breakPaid',
    ];
    const newErrorList: string[] = [];
    const addErrorMessage = (line: number, message: string | undefined) => {
      const isSingleLine = errors?.shifts?.length === 1;
      if (message) {
        newErrorList.push(
          !shift && !isSingleLine ? `Line ${line}: ${message}` : message
        );
      }
    };

    if (errors?.shifts && Array.isArray(errors.shifts)) {
      errors.shifts.forEach((item, index) => {
        if (item) {
          errorFields.forEach((field) => {
            addErrorMessage(index + 1, (item as any)[field]?.message);
          });
        }
      });
      setErrorList(newErrorList);
    }
  }, [errors, shift]);

  useEffect(() => {
    if (errorList.length > 0) {
      showWarningAlert(errorList.join('\n'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorList]);

  const queryKey = `${QueryKeys.JOB_TITLES}`;
  const { data: jobTitleList } = useQuery({
    queryKey: [queryKey],
    queryFn: () => jobApi.fetchJobPositionList(),
  });

  useEffect(() => {
    if (jobTitleList) {
      setJobTitles(jobTitleList.jobTitles);
    }
  }, [jobTitleList]);

  const tableKey = useId();

  const validationSchema = getValidationSchema(job.timezone);
  type ValidationShiftSchema = z.infer<typeof validationSchema>;

  const handleSelecting = (id: number) => {
    if (setCheckedIds) {
      const newCheckedIds = new Set(checkedIds);
      if (!newCheckedIds.has(id) && newCheckedIds.size === 1) {
        showErrorAlert('Only one row can be selected');
      }
      if (newCheckedIds.has(id) || newCheckedIds.size === 1) {
        newCheckedIds.delete(id);
      } else {
        newCheckedIds.add(id);
      }
      setCheckedIds(newCheckedIds);
    }
  };

  const adjustDateTimes = (date: Date, index: number) => {
    getValues().shifts[index].date = date;
    getValues().shifts[index].date.setHours(0);
    getValues().shifts[index].date.setMinutes(0);
    getValues().shifts[index].date.setSeconds(0);
    if (getValues().shifts[index].startTime) {
      getValues().shifts[index].startTime.setDate(date.getDate());
      getValues().shifts[index].startTime.setMonth(date.getMonth());
      getValues().shifts[index].startTime.setFullYear(date.getFullYear());
    }
    if (getValues().shifts[index].endTime) {
      getValues().shifts[index].endTime.setDate(date.getDate());
      getValues().shifts[index].endTime.setMonth(date.getMonth());
      getValues().shifts[index].endTime.setFullYear(date.getFullYear());
    }
  };

  const cloneShifts = async () => {
    const { date } = getValues().shifts.reduce((a, b) =>
      a.date > b.date ? a : b
    );
    const selectedId = checkedIds.values().next().value;
    if (selectedId !== undefined) {
      const startTime = getValues(`shifts.${selectedId}.startTime`);
      const endTime = getValues(`shifts.${selectedId}.endTime`);

      const newDateString = date.toDateString();
      const newDate = new Date(newDateString);
      newDate.setDate(date.getDate() + 1);

      const newStartTime = createDateTimeFromDifferentDates(newDate, startTime);

      const newEndTime = createDateTimeFromDifferentDates(newDate, endTime);

      append({
        ...getValues(`shifts.${selectedId}`),
        date: newDate,
        startTime: newStartTime,
        endTime: newEndTime,
      });
      adjustDateTimes(newDate, getValues().shifts.length - 1);
    }
  };

  const showSelectShift = (index: number): ReactElement => {
    if (shift) {
      return <td />;
    }
    if (checkedIds) {
      return (
        <td>
          <Form.Check
            type="checkbox"
            id="checkbox-1"
            checked={checkedIds.has(index)}
            onChange={() => handleSelecting(index)}
          />
        </td>
      );
    }
    return <div />;
  };

  const mountTableHeader = () => {
    const fieldsToExclude = ['Openings', 'Backups'];
    const headerFields = !shift
      ? TABLE_HEADER_WEB
      : TABLE_HEADER_WEB.filter((x) => !fieldsToExclude.includes(x));
    return headerFields.map((header) => (
      <th className="text-start" key={`${tableKey}-${header}`}>
        {header}
      </th>
    ));
  };

  const isNextDay = (index: number) => {
    return watch(`shifts.${index}.isNextDay`);
  };

  return (
    <>
      <Form id="shift-form" onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Table id="table-data" responsive borderless>
          <thead className="table-header fw-bold">
            <tr>
              <th aria-label="checkbox" />
              {mountTableHeader()}
              <th aria-label="delete" />
            </tr>
          </thead>
          <tbody>
            {fields.map((f, index) => {
              return (
                <tr key={f.id}>
                  {showSelectShift(index)}
                  <td width={!shift ? '10%' : '15%'}>
                    <Controller
                      control={control}
                      name={`shifts.${index}.date`}
                      render={({ field }) => (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            errors?.shifts &&
                            errors.shifts[index]?.date?.message ? (
                              <Tooltip>
                                {errors.shifts[index]?.date?.message}
                              </Tooltip>
                            ) : (
                              <div />
                            )
                          }
                        >
                          <Form.Group>
                            <DatePicker
                              id={`date-${index}`}
                              className={`form-control ${
                                errors?.shifts &&
                                errors.shifts[index]?.date?.message
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              wrapperClassName="date-picker"
                              selected={field.value as unknown as Date}
                              placeholderText="MM/DD/YYYY"
                              formatWeekDay={(name) => name.substring(0, 1)}
                              onChange={(date) => {
                                field.onChange(date as Date);
                                if (date) {
                                  adjustDateTimes(date, index);
                                }
                              }}
                              calendarStartDay={1}
                            />
                          </Form.Group>
                        </OverlayTrigger>
                      )}
                    />
                  </td>
                  <td width={!shift ? '16%' : '25%'}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        errors?.shifts &&
                        errors.shifts[index]?.position?.message ? (
                          <Tooltip>
                            {errors.shifts[index]?.position?.message}
                          </Tooltip>
                        ) : (
                          <div />
                        )
                      }
                    >
                      <Form.Select
                        isInvalid={
                          errors?.shifts &&
                          !!errors.shifts[index]?.position?.message
                        }
                        {...register(`shifts.${index}.position`, {
                          valueAsNumber: true,
                        })}
                        className={`form-control ${
                          errors?.shifts &&
                          errors.shifts[index]?.position?.message
                            ? 'is-invalid'
                            : ''
                        }`}
                      >
                        <option value={0}>Please select</option>
                        {jobTitles &&
                          jobTitles.map((item) => {
                            return (
                              <option
                                key={item.id}
                                value={item.id}
                                selected={f.position === item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        errors?.shifts &&
                        errors.shifts[index]?.payRate?.message ? (
                          <Tooltip>
                            {errors.shifts[index]?.payRate?.message}
                          </Tooltip>
                        ) : (
                          <div />
                        )
                      }
                    >
                      <Form.Group>
                        <CurrencyInput
                          {...register(`shifts.${index}.payRate`)}
                          name={`shifts.${index}.payRate`}
                          allowNegativeValue={false}
                          defaultValue={Number(
                            f.payRate.substring(2) ?? shift?.wage?.payRate
                          )}
                          className={`form-control ${
                            errors?.shifts &&
                            errors.shifts[index]?.payRate?.message
                              ? 'is-invalid'
                              : ''
                          }`}
                          prefix="$ "
                          step={5}
                          min={1}
                        />
                      </Form.Group>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        errors?.shifts &&
                        errors.shifts[index]?.payRatePeriod?.message ? (
                          <Tooltip>
                            {errors.shifts[index]?.payRatePeriod?.message}
                          </Tooltip>
                        ) : (
                          <div />
                        )
                      }
                    >
                      <Form.Select
                        isInvalid={
                          errors?.shifts &&
                          !!errors.shifts[index]?.payRatePeriod?.message
                        }
                        {...register(`shifts.${index}.payRatePeriod`)}
                      >
                        <option>Select</option>
                        <option value="hourly">Hourly</option>
                        <option value="daily">Daily</option>
                      </Form.Select>
                    </OverlayTrigger>
                  </td>
                  {!shift && (
                    <>
                      <td width="8%">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            errors?.shifts &&
                            errors.shifts[index]?.openPositions?.message ? (
                              <Tooltip>
                                {errors.shifts[index]?.openPositions?.message}
                              </Tooltip>
                            ) : (
                              <div />
                            )
                          }
                        >
                          <Form.Control
                            type="number"
                            min={0}
                            isInvalid={
                              errors?.shifts &&
                              !!errors.shifts[index]?.openPositions?.message
                            }
                            {...register(`shifts.${index}.openPositions`, {
                              valueAsNumber: true,
                            })}
                          />
                        </OverlayTrigger>
                      </td>
                      <td width="8%">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            errors?.shifts &&
                            errors.shifts[index]?.openBackups?.message ? (
                              <Tooltip>
                                {errors.shifts[index]?.openBackups?.message}
                              </Tooltip>
                            ) : (
                              <div />
                            )
                          }
                        >
                          <Form.Control
                            type="number"
                            min={0}
                            max={5}
                            isInvalid={
                              errors?.shifts &&
                              !!errors.shifts[index]?.openBackups?.message
                            }
                            {...register(`shifts.${index}.openBackups`, {
                              valueAsNumber: true,
                            })}
                          />
                        </OverlayTrigger>
                      </td>
                    </>
                  )}
                  <td>
                    <Controller
                      control={control}
                      name={`shifts.${index}.startTime`}
                      render={({ field }) => (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            errors?.shifts &&
                            errors.shifts[index]?.startTime?.message ? (
                              <Tooltip>
                                {errors.shifts[index]?.startTime?.message}
                              </Tooltip>
                            ) : (
                              <div />
                            )
                          }
                        >
                          <Form.Group>
                            <DatePicker
                              className={`form-control ${
                                errors?.shifts &&
                                errors.shifts[index]?.startTime?.message
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              selected={field.value as unknown as Date}
                              placeholderText="HH:MM AA"
                              onChange={(date: Date) => {
                                const newStartTime =
                                  createDateTimeFromDifferentDates(
                                    getValues().shifts[index].date,
                                    date
                                  );
                                field.onChange(newStartTime);
                                setValue(
                                  `shifts.${index}.startTime`,
                                  newStartTime
                                );
                                setValue(
                                  `shifts.${index}.isNextDay`,
                                  isSameDateOrAfter(
                                    getValues().shifts[index].startTime,
                                    getValues().shifts[index].endTime
                                  )
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                            />
                          </Form.Group>
                        </OverlayTrigger>
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name={`shifts.${index}.endTime`}
                      render={({ field }) => (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            errors?.shifts &&
                            errors.shifts[index]?.endTime?.message ? (
                              <Tooltip>
                                {errors.shifts[index]?.endTime?.message}
                              </Tooltip>
                            ) : (
                              <div />
                            )
                          }
                        >
                          <Form.Group>
                            <DatePicker
                              className={`form-control ${
                                errors?.shifts &&
                                errors.shifts[index]?.endTime?.message
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              selected={field.value as unknown as Date}
                              placeholderText="HH:MM AA"
                              onChange={(date: Date) => {
                                const newEndTime =
                                  createDateTimeFromDifferentDates(
                                    getValues().shifts[index].date,
                                    date
                                  );
                                field.onChange(newEndTime);
                                setValue(`shifts.${index}.endTime`, newEndTime);
                                setValue(
                                  `shifts.${index}.isNextDay`,
                                  isSameDateOrAfter(
                                    getValues().shifts[index].startTime,
                                    getValues().shifts[index].endTime
                                  )
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                            />
                          </Form.Group>
                        </OverlayTrigger>
                      )}
                    />
                    {isNextDay(index) && (
                      <p className="next-day-class m-0 fw-bolder justify-content-end">
                        NEXT DAY
                      </p>
                    )}
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        errors?.shifts &&
                        errors.shifts[index]?.breakPaid?.message ? (
                          <Tooltip>
                            {errors.shifts[index]?.breakPaid?.message}
                          </Tooltip>
                        ) : (
                          <div />
                        )
                      }
                    >
                      <Form.Select
                        {...register(`shifts.${index}.breakPaid`)}
                        isInvalid={
                          errors?.shifts &&
                          !!errors.shifts[index]?.breakPaid?.message
                        }
                      >
                        <option>Select</option>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </Form.Select>
                    </OverlayTrigger>
                  </td>
                  {!shift && (
                    <td>
                      <RiDeleteBin6Line
                        className="mt-2"
                        color="var(--red)"
                        cursor={fields.length > 1 ? 'pointer' : 'not-allowed'}
                        onClick={() => fields.length > 1 && remove(index)}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Form>
      {!shift && (
        <Button
          variant="white"
          form="shift-form"
          disabled={!checkedIds.size}
          onClick={() => cloneShifts()}
        >
          <img
            src={clone}
            alt="clone shift"
            className="ps-3 me-2 mb-1"
            style={{
              opacity: !checkedIds.size ? '0.5' : '1',
            }}
          />
          CLONE SHIFT
        </Button>
      )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed alert-shift-create"
      >
        <Alert.Heading>
          {alertMessage.message.split('\n').map((msg) => (
            <div key={msg}>- {msg}</div>
          ))}
        </Alert.Heading>
      </Alert>
    </>
  );
}
