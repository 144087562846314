import { useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Endorser } from 'types/workerProfile.type';

interface Params {
  endorsers: Endorser[];
}

const displayed = 6;

export default function EndorsementImageList({ endorsers }: Params) {
  const [showMore, setShowMore] = useState(displayed);

  return (
    <div>
      {endorsers &&
        endorsers.slice(0, showMore).map((endorser) => (
          <Image
            key={endorser.endorserId}
            className="avatar rounded-circle me-1"
            src={endorser.icon}
            style={{
              width: 24,
              height: 24,
              objectFit: 'cover',
            }}
          />
        ))}
      {showMore === displayed && endorsers.length > displayed && (
        <Button
          onClick={() => setShowMore(endorsers.length)}
          variant="light"
          className="p-0 rounded-circle"
          style={{ width: 26, height: 26, fontSize: 10 }}
        >
          + {endorsers.length - displayed}
        </Button>
      )}
    </div>
  );
}
