import { privateApi } from 'src/services/api';
import IResultResponse from 'src/types/resultResponse.type';

export const MAIN_URL = 'error';

export const sendErrorReport = async (note: string, error: string) => {
  const result = await privateApi.post<IResultResponse>(
    `${MAIN_URL}/send-crash-report`,
    {
      note,
      error,
      errorId: Math.random().toString(36).substring(3, 9).toUpperCase(),
    }
  );
  return result.data;
};
