export const toNatural = (enumParam: any): string => {
  if (!enumParam) return '';
  const words = enumParam.split('_').map((word: any) => word.toLowerCase());
  return words
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeValue = (value: string) => {
  if (value) {
    if (value.length > 1) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
    return value.toUpperCase();
  }
  return value;
};
