import { useState } from 'react';
import { Badge, Button, Col, Collapse, Dropdown, Row } from 'react-bootstrap';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';
import { BsThreeDots } from '@react-icons/all-files/bs/BsThreeDots';
import './styles.css';
import { FiCheckCircle } from '@react-icons/all-files/fi/FiCheckCircle';
import { FiEye } from '@react-icons/all-files/fi/FiEye';
import { HiOutlineShare } from '@react-icons/all-files/hi/HiOutlineShare';
import { AiOutlineFileAdd } from '@react-icons/all-files/ai/AiOutlineFileAdd';
import { format } from 'date-fns';
import DATE_FORMATS from 'src/constants/dateFormat';
import { Link } from 'react-router-dom';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

interface Params {
  jobList: any[];
  type: string;
  handleOpenCompleteJobModal: (() => void) | undefined;
}

export default function JobTableMobile({
  jobList,
  type,
  handleOpenCompleteJobModal,
}: Params) {
  const [openItem, setOpenItem] = useState(0);

  function toggleItem(item: number) {
    setOpenItem(item);
  }

  return (
    <>
      {jobList.map((job) => (
        <div key={job.id} className="panel mt-3">
          <Row>
            <Col xs={5}>
              <Link
                style={{ textDecoration: 'none', color: 'unset' }}
                to={`${BASE_PATH}jobs/${job?.id}`}
              >
                <b>EVENT NAME</b>
                <p>
                  {job.eventName}
                  {!job.datePublished && (
                    <Badge className="ms-3" bg="secondary" text="dark">
                      Draft
                    </Badge>
                  )}
                </p>
              </Link>
            </Col>
            <Col xs={5}>
              <b>POSITION</b>
              <p>{job.position}</p>
            </Col>
            <Col xs={2} className=" d-flex flex-column justify-content-between">
              <Button variant="btn-link">
                <FiChevronDown
                  size={20}
                  onClick={() => toggleItem(job.id === openItem ? 0 : job.id)}
                />
              </Button>

              <Dropdown style={{ content: 'none' }}>
                <Dropdown.Toggle variant="">
                  <BsThreeDots size={20} />
                </Dropdown.Toggle>
                {type === 'COMPLETED' ? (
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => alert('a')}>
                      <AiOutlineFileAdd size={24} />
                      &nbsp;
                      <b>ADD</b>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => alert('a')}>
                      <HiOutlineShare size={20} />
                      &nbsp;
                      <b>SHARE JOB</b>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => alert('b')}>
                      <FiEye size={20} />
                      &nbsp;
                      <b>HIDE JOB</b>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleOpenCompleteJobModal}>
                      <FiCheckCircle size={20} />
                      &nbsp;
                      <b>COMPLETE JOB</b>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </Col>
          </Row>
          <Collapse in={job.id === openItem}>
            <div>
              <hr className="mt-0" />
              <Row>
                <Col xs={6}>
                  <b>LOCATION</b>
                  <p>
                    {job.nationalJob
                      ? 'National Job'
                      : job?.address?.googleAddress}
                  </p>
                </Col>
                {type === 'COMPLETED' ? (
                  <Col xs={6}>
                    <b>DATE COMPLETED</b>
                    {/* TODO This is another field */}
                    <p>
                      {format(new Date(job?.start), DATE_FORMATS.DATE_FORMAT)}
                    </p>
                  </Col>
                ) : (
                  <Col xs={6}>
                    <b>DATES</b>
                    <p>
                      <strong>Start</strong>{' '}
                      <span>
                        {/* TODO: use local job date time */}
                        {format(
                          new Date(job?.start),
                          DATE_FORMATS.DATE_FORMAT_SHORT
                        )}
                      </span>
                      <br />
                      <strong>End</strong>{' '}
                      <span>
                        {format(
                          new Date(job?.end),
                          DATE_FORMATS.DATE_FORMAT_SHORT
                        )}
                      </span>
                    </p>
                  </Col>
                )}
              </Row>
              <hr className="mt-0" />
              <Row>
                {/* TODO map this mocked fields */}
                <Col xs={6}>
                  <b>INTERNAL</b>
                  <p>3 (Mocked)</p>
                </Col>
                <Col xs={6}>
                  <b>PENDING</b>
                  <p>3 (Mocked)</p>
                </Col>
              </Row>
              <hr className="mt-0" />
              <Row>
                <Col xs={6}>
                  <b>FILLED POSITIONS</b>
                  <p>{job.totalPositions}</p>
                </Col>
                <Col xs={6}>
                  <b>HIDDEN</b>
                  <p>3 (Mocked)</p>
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      ))}
    </>
  );
}
