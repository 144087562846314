import {
  createContext,
  Reducer,
  ReducerState,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { UpdateWorkerActions } from 'src/apps/company-frontend/state/useUpdateWorker/actions';
import * as workerApi from 'src/apps/company-frontend/services/worker/api';
import { InfiniteData, QueryClient } from '@tanstack/react-query';
import reducer, {
  updateWorkerInitialState,
  UpdateWorkerModel,
} from './reducers';
import ITalentsData from '../../types/talents.type';

interface FetchUpdatedWorkerProps {
  data: InfiniteData<ITalentsData, unknown> | undefined;
  queryClient: QueryClient;
  queryKey: string;
}
interface IUpdateWorkerContext {
  updateWorker: (payload: UpdateWorkerModel) => Promise<void>;
  manuallyUpdateWorker: (payload: UpdateWorkerModel) => Promise<void>;
  updatedWorker: UpdateWorkerModel;
  fetchUpdatedWorker: (payload: FetchUpdatedWorkerProps) => Promise<void>;
}

export const UpdateWorkerContext: IUpdateWorkerContext | any = createContext(
  updateWorkerInitialState
);

type UpdateWorkerModelState = UpdateWorkerModel & Reducer<any, any>;

function useUpdateWorkerReducer() {
  const [updatedWorker, dispatch] = useReducer(
    reducer,
    updateWorkerInitialState as ReducerState<UpdateWorkerModelState>
  );

  const updateWorker = async (payload: UpdateWorkerModel) => {
    dispatch({ type: UpdateWorkerActions.UPDATING });
    try {
      const workerProfile = await workerApi.fetchWorkerJobInfo(
        payload.workerId,
        payload.jobId
      );
      const value = { ...payload, workerProfile };
      dispatch({ type: UpdateWorkerActions.UPDATE, value });
      dispatch({ type: UpdateWorkerActions.UPDATED });
    } catch (err) {
      dispatch({ type: UpdateWorkerActions.UPDATE_FAILED });
    }
  };

  const manuallyUpdateWorker = async (payload: UpdateWorkerModel) => {
    dispatch({ type: UpdateWorkerActions.UPDATING });
    dispatch({ type: UpdateWorkerActions.UPDATE, value: payload });
    dispatch({ type: UpdateWorkerActions.UPDATED });
  };

  return {
    updateWorker,
    updatedWorker,
    manuallyUpdateWorker,
  };
}

export function UpdateWorkerProvider({ children }: any) {
  const { updateWorker, updatedWorker, manuallyUpdateWorker } =
    useUpdateWorkerReducer();

  // TODO: change to better named function, maybe refreshUpdatedWorker
  const fetchUpdatedWorker = useCallback(
    async (payload: FetchUpdatedWorkerProps) => {
      const { data, queryClient, queryKey } = payload;
      if (data?.pages && updatedWorker?.workerProfile) {
        const updatedPages = data.pages.map((page) => {
          if (!page.results) {
            return {};
          }
          const updatedResults = page.results.map((result) => {
            const updatedResult = { ...result };
            if (
              updatedWorker.workerProfile &&
              result.worker.id === updatedWorker?.workerId
            ) {
              updatedResult.worker = updatedWorker.workerProfile.worker;
              updatedResult.jobAssignment =
                updatedWorker.workerProfile.jobAssignment!;
              updatedResult.jobPlacement =
                updatedWorker.workerProfile.jobPlacement!;
            }
            return updatedResult;
          });
          return { ...page, results: updatedResults };
        });
        queryClient.setQueryData([queryKey], { ...data, pages: updatedPages });
      }
    },
    [updatedWorker?.workerId, updatedWorker.workerProfile]
  );

  const contextValue: IUpdateWorkerContext = useMemo(() => {
    return {
      updateWorker,
      updatedWorker,
      fetchUpdatedWorker,
      manuallyUpdateWorker,
    };
  }, [updateWorker, updatedWorker, fetchUpdatedWorker, manuallyUpdateWorker]);

  return (
    <UpdateWorkerContext.Provider value={contextValue}>
      {children}
    </UpdateWorkerContext.Provider>
  );
}

export default function useUpdateWorker() {
  const context: IUpdateWorkerContext = useContext(UpdateWorkerContext);
  if (context === undefined) {
    throw new Error(
      'useUpdateWorkerReducer must be used within a UpdateWorkerProvider. Wrap a parent component in <UpdateWorkerProvider> to fix this error.'
    );
  }
  return context;
}
