import { isMobile } from 'react-device-detect';
import { Body } from 'src/apps/company-frontend/components/layout';
import { FiAlertTriangle } from '@react-icons/all-files/fi/FiAlertTriangle';
import { useState } from 'react';
import JobTableMobile from './JobTableMobile/JobTableMobile';
import JobTableWeb from './JobTableWeb/JobTableWeb';
import CompleteJobModal from './CompleteJobModal';

interface Params {
  jobList: any[];
  type: any;
  showGrouped: boolean;
}

export default function JobTable({ jobList, type, showGrouped }: Params) {
  const [openCompleteJobModal, setOpenCompleteJobModal] = useState(false);

  const groupedJobList = [
    'New applicants',
    'Missing check in/out',
    'Missing onboarding',
    'Open shifts',
    'Missing payments',
  ];

  const renderList = (group?: any) => (
    <Body key={group}>
      {group && (
        <>
          <FiAlertTriangle size={24} color="red" />
          <b className="ms-3 fs-5">{group}</b>
          <hr />
        </>
      )}
      {isMobile ? (
        <JobTableMobile
          jobList={jobList}
          type={type}
          handleOpenCompleteJobModal={() => setOpenCompleteJobModal(true)}
        />
      ) : (
        <JobTableWeb jobList={jobList} type={type} showGrouped={showGrouped} />
      )}
      <CompleteJobModal
        show={openCompleteJobModal}
        handleClose={() => setOpenCompleteJobModal(false)}
      />
    </Body>
  );

  if (!showGrouped) {
    return renderList();
  }

  return (
    <>
      {groupedJobList.map((group) => {
        return renderList(group);
      })}
    </>
  );
}
