import { useJobWorkerShiftContext } from 'src/apps/company-frontend/state/jobWorkerShiftContext';
import RefreshButton from 'src/apps/company-frontend/components/RefreshButton/RefreshButton';
import * as activityTrackerApi from 'src/apps/company-frontend/services/activityTracker/api';
import { useActivityContext } from 'src/apps/company-frontend/state/activityContext';
import { JobWorkerSideModal } from 'src/apps/company-frontend/components/JobWorker';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import { ActivityTrackerView, ActivityFilters } from 'src/types/jobs.type';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Alert, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import * as jobApi from 'src/apps/company-frontend/services/job/api';
import { useJobShiftContext } from 'company/state/jobShiftContext';
import ThFilterBadge from 'src/components/elements/ThFilterBadge';
import React, { useCallback, useEffect, useState } from 'react';
import { ModalConfirm, ThError } from 'src/components/elements';
import { ActivityDetail } from 'src/types/daily-activity.type';
import { JobAssignment } from 'src/types/job-assignment.type';
import IModalConfirmModel from 'src/types/confirm-model.type';
import {
  activityFilterOptions,
  getFilteredData,
  getTotalActivities,
} from 'src/services/activity-tracker/activityTrackerService';
import ThLoading from 'src/components/elements/ThLoading';
import { useErrorBoundary } from 'react-error-boundary';
import DATE_FORMATS from 'src/constants/dateFormat';
import { UTC_TIMEZONE } from 'src/utils/DateUtils';
import Select, { MultiValue } from 'react-select';
import useAlert from 'src/hooks/useAlert';
import { format } from 'date-fns';
import './styles.scss';
import ActivityTrackerMobile from './ActivityTrackerMobile';
import ActivityTrackerDesktop from './ActivityTrackerDesktop';

interface Params {
  setActivitiesCount: (value: number) => void;
}

function ActivityTracker({ setActivitiesCount }: Params) {
  const { showBoundary } = useErrorBoundary();
  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();
  const [showWorkerProfile, setShowWorkerProfile] = useState(false);
  const { refetch: refetchShifts } = useJobShiftContext();
  const [hasFilteredActivities, setHasFilteredActivities] = useState(false);
  const [clickedJobAssignment, setClickedJobAssignment] = useState<
    JobAssignment | undefined
  >();
  const { chatPerson } = useChatContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalConfirmModel, setModalConfirmModel] =
    useState<IModalConfirmModel>();

  const { job } = useJobContext();
  const {
    error,
    viewType,
    upcoming,
    isLoading,
    activities,
    isRefetching,
    isLoadingAction,
    activitiesFilter,
    groupedByDateActivities,
    groupedByWorkerActivities,
    refetch,
    setUpcoming,
    setViewType,
    isShiftPayable,
    isShiftUnPayable,
    setIsLoadingAction,
    setActivitiesFilter,
  } = useActivityContext();
  const { setStatusFilter, setSelectedWorker, setSelectedShifts } =
    useJobShiftContext();

  const { setIsJobWorkerSideModalOpen } = useJobWorkerShiftContext();

  const handleActivitiesCount = useCallback(async () => {
    if (activities) {
      setActivitiesCount(activities?.length ?? 0);
    }
  }, [activities, setActivitiesCount]);

  useEffect(() => {
    handleActivitiesCount();
  }, [activities, handleActivitiesCount]);

  useEffect(() => {
    if (setIsLoadingAction) {
      setIsLoadingAction(isRefetching);
    }
  }, [isRefetching, setIsLoadingAction]);

  useEffect(() => {
    if (activities) {
      setHasFilteredActivities(
        getFilteredData(
          activities,
          activitiesFilter,
          upcoming,
          job?.timezone ?? UTC_TIMEZONE
        )?.length > 0
      );
    }
  }, [activities, activitiesFilter, job?.timezone, upcoming]);

  const handleDateButton = () => setViewType(ActivityTrackerView.DATE);
  const handleWorkerButton = () => setViewType(ActivityTrackerView.WORKER);
  const toggleUpcomingShifts = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpcoming(e.target.checked);
    if (e.target.checked) {
      setActivitiesFilter([...activitiesFilter, ActivityFilters.UPCOMING]);
    } else {
      setActivitiesFilter(
        activitiesFilter.filter((x) => x !== ActivityFilters.UPCOMING)
      );
    }
  };

  const activityKey = (value: string) => {
    return value as keyof typeof ActivityFilters;
  };

  const handleFilterSelection = async (filter: ActivityFilters) => {
    if (activitiesFilter.includes(filter as string)) {
      setActivitiesFilter(
        activitiesFilter.filter((x) => x !== (filter as string))
      );
      if (filter === ActivityFilters.UPCOMING) {
        setUpcoming(false);
      }
    } else {
      setActivitiesFilter([...activitiesFilter, filter as string]);
      if (filter === ActivityFilters.UPCOMING) {
        setUpcoming(true);
      }
    }
  };

  const openWorkerSideModal = (jobAssignment: JobAssignment) => {
    setClickedJobAssignment(jobAssignment);
    setShowWorkerProfile(true);
    refetchShifts();
    setSelectedShifts([]);
    setIsJobWorkerSideModalOpen(true);
  };

  const handleCloseWorkerProfile = () => {
    setShowWorkerProfile(false);
    setIsJobWorkerSideModalOpen(false);
    setClickedJobAssignment(undefined);
    setStatusFilter([]);
    setSelectedWorker(undefined);
  };

  const handleFilterSelectionMobile = (
    selectedOptions: MultiValue<{
      value: string;
      label: string;
    }>
  ) => {
    setActivitiesFilter(selectedOptions.map((opt) => activityKey(opt.value)));
  };

  if (error) {
    showBoundary(error); // pass the error as string to the error boundary
    if (error.response?.status) {
      return <>No data found</>;
    }
    return <ThError message={error.message} />;
  }

  const markAsPaid = async (activityList: ActivityDetail[]) => {
    try {
      setIsLoadingAction(true);
      let successMessage = '';
      const shiftIds = activityList.map((x) => x.shiftPosition.id);
      const result = await jobApi.markShiftsPaid(
        activityList[0].shiftPosition.jobId,
        shiftIds
      );
      successMessage = result.message;
      if (result.success) {
        showSuccessAlert(successMessage);
        refetch();
      }
    } catch (err) {
      console.log(`Occurred an error trying to cancel shift`, err);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const markAsUnpaid = async (activityList: ActivityDetail[]) => {
    try {
      setIsLoadingAction(true);
      let successMessage = '';
      const shiftIds = activityList.map((shift) => shift.shiftPosition.id);
      const result = await jobApi.markShiftsUnPaid(
        activityList[0].shiftPosition.jobId,
        shiftIds
      );
      successMessage = result.message;
      if (result.success) {
        showSuccessAlert(successMessage);
        refetch();
      }
    } catch (err) {
      console.log(`Occurred an error trying to cancel shift`, err);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const approveTimeSheet = async (activityDetail: ActivityDetail) => {
    setIsLoadingAction(true);
    try {
      if (activityDetail.timeSheet) {
        const result = await activityTrackerApi.approveTimeSheet(
          activityDetail.shiftPosition.jobId,
          activityDetail.timeSheet.id
        );
        if (result.success) {
          showSuccessAlert(result.message);
          await refetch();
        } else {
          showWarningAlert(result.errorMessage);
        }
      }
    } catch (err) {
      console.log(`Occurred an error trying to cancel shift`, err);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleMarkPaid = (id: number, listActivities: ActivityDetail[]) => {
    const activity = listActivities.find((a) => a.shiftPosition.id === id);
    if (activity && isShiftPayable(activity)) {
      markAsPaid(activity ? [activity] : []);
    }
  };

  const handleMarkAllPaid = (listActivities: ActivityDetail[]) => {
    markAsPaid(listActivities.filter((activity) => isShiftPayable(activity)));
  };

  const handleMarkUnPaid = (id: number, listActivities: ActivityDetail[]) => {
    const activity = listActivities.find((a) => a.shiftPosition.id === id);
    if (activity && isShiftUnPayable(activity)) {
      markAsUnpaid(activity ? [activity] : []);
    }
  };

  const handleMarkAllUnPaid = (listActivities: ActivityDetail[]) => {
    markAsUnpaid(
      listActivities.filter((activity) => isShiftUnPayable(activity))
    );
  };

  const getMarkAsPaidConfirmationMessage = (
    all: boolean,
    filteredActivities: ActivityDetail[]
  ) => {
    if (all) {
      return (
        <p>
          This action will mark <b>ALL</b> shifts available to be paid, that are
          related to{' '}
          {viewType === ActivityTrackerView.WORKER
            ? `the worker ${filteredActivities[0].shiftPosition.jobAssignment?.worker.fullName}`
            : `the date ${format(
                new Date(filteredActivities[0].shiftPosition.date),
                DATE_FORMATS.DATE_FORMAT_SHORT
              )},`}{' '}
          as paid
        </p>
      );
    }
    return (
      <p>
        This action will mark <b>this shift as paid</b>
      </p>
    );
  };

  const getModalConfirmData = (
    groupedById: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all: boolean = false
  ) => {
    const unpaidActivities = all
      ? filteredActivities.filter((x) => !x.shiftPosition.isPaid)
      : filteredActivities.find((a) => a.shiftPosition.id === id);
    console.log('unpaidActivities', unpaidActivities);
    if (unpaidActivities) {
      setModalConfirmModel({
        message: (
          <div className="text-center">
            {getMarkAsPaidConfirmationMessage(all, filteredActivities)}
          </div>
        ),
        titleMessage: (
          <div>
            <span>Are you sure?</span>
          </div>
        ),
        confirmFunction: all
          ? () => handleMarkAllPaid(filteredActivities)
          : () => handleMarkPaid(id, filteredActivities),
        show: showConfirmModal,
        styleClass: 'modal-confirm-style',
      });
    }
  };

  const getModalConfirmDataUnpaid = (
    groupedById: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all: boolean = false
  ) => {
    const paidActivities = all
      ? filteredActivities.filter((x) => x.shiftPosition.isPaid)
      : filteredActivities.find((a) => a.shiftPosition.id === id);
    console.log('paidActivities', paidActivities);
    if (paidActivities) {
      setModalConfirmModel({
        message: (
          <div className="text-center">
            <p>
              This action will mark <b>this shift as unpaid</b>
            </p>
          </div>
        ),
        titleMessage: (
          <div>
            <span>Are you sure?</span>
          </div>
        ),
        confirmFunction: all
          ? () => handleMarkAllUnPaid(filteredActivities)
          : () => handleMarkUnPaid(id, filteredActivities),
        show: showConfirmModal,
        styleClass: 'modal-confirm-style',
      });
    }
  };

  const handleConfirmMarkPaid = (
    groupedById: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all: boolean = false
  ) => {
    if (filteredActivities.length === 0 && !all) {
      showWarningAlert('There is no shifts to mark as paid');
      return;
    }
    setShowConfirmModal(true);
    getModalConfirmData(groupedById, id, filteredActivities, all);
  };

  const handleConfirmMarkUnPaid = (
    groupedById: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all: boolean = false
  ) => {
    if (filteredActivities.length === 0 && !all) {
      showWarningAlert('There is no shifts to mark as unpaid');
      return;
    }
    setShowConfirmModal(true);
    getModalConfirmDataUnpaid(groupedById, id, filteredActivities, all);
  };

  const showNoActivitiesMessage = (message?: string) => {
    const messageToShow =
      message ||
      'Create a schedule, and once a worker accepts a shift, they’ll appear here for you to easily track their day of shifts!';
    return (
      <div className="mt-5 d-flex justify-content-center">
        <p>{messageToShow}</p>
      </div>
    );
  };

  const showNoFilteredActivitiesMessage = () => {
    return showNoActivitiesMessage(
      'It’s all clear, reset your quick filters to see everything'
    );
  };

  const getGroupedActivities = () => {
    return viewType === ActivityTrackerView.DATE
      ? groupedByDateActivities
      : groupedByWorkerActivities;
  };

  const showActivitiesList = () => {
    if (activities?.length === 0) {
      return showNoActivitiesMessage();
    }
    const groupedActivities = getGroupedActivities();
    if (!hasFilteredActivities) {
      return showNoFilteredActivitiesMessage();
    }
    return groupedActivities.map(({ groupedBy, groupedByActivities }) => (
      <div key={groupedBy}>
        <MobileView>
          <ActivityTrackerMobile
            key={groupedBy}
            groupedBy={groupedBy}
            activities={groupedByActivities}
            viewType={viewType}
            filters={activitiesFilter}
            approveTimeSheet={approveTimeSheet}
            handleConfirmMarkPaid={handleConfirmMarkPaid}
            handleConfirmMarkUnPaid={handleConfirmMarkUnPaid}
            openWorkerSideModal={openWorkerSideModal}
          />
        </MobileView>
        <BrowserView>
          <ActivityTrackerDesktop
            key={groupedBy}
            groupedBy={groupedBy}
            activities={groupedByActivities}
            viewType={viewType}
            filters={activitiesFilter}
            handleConfirmMarkPaid={handleConfirmMarkPaid}
            handleConfirmMarkUnPaid={handleConfirmMarkUnPaid}
            approveTimeSheet={approveTimeSheet}
            openWorkerSideModal={openWorkerSideModal}
          />
        </BrowserView>
      </div>
    ));
  };

  return (
    <>
      {isLoading || (isLoadingAction && <ThLoading />)}
      <Row className="justify-content-start align-items-center gx-0">
        <Col xs={12} md={2} className="m-0 p-0">
          <Button
            variant={
              viewType === ActivityTrackerView.DATE ? 'primary' : 'outline-dark'
            }
            className="w-50"
            onClick={handleDateButton}
          >
            DATE
          </Button>
          <Button
            variant={
              viewType === ActivityTrackerView.WORKER
                ? 'primary'
                : 'outline-dark'
            }
            className="w-50"
            onClick={handleWorkerButton}
          >
            WORKER
          </Button>
        </Col>
        <Col
          xs={10}
          md={2}
          className={`d-flex ${isMobile && 'justify-content-between fw-bold'}`}
        >
          <Form.Label className={`fw-bold ${isMobile ? 'pb-2 pt-2' : 'ms-3'}`}>
            SHOW UPCOMING SHIFTS
          </Form.Label>
          <Form.Check
            className={`ps-1 ${isMobile && 'pt-2'}`}
            type="switch"
            id="upcoming-shifts-switch"
            checked={upcoming}
            onChange={toggleUpcomingShifts}
            aria-label="Show Upcoming Shifts"
          />
        </Col>
        <Col
          xs={2}
          md={1}
          className="d-flex justify-content-center align-items-center"
        >
          <RefreshButton
            isSmall
            refreshFunction={refetch}
            className={`${isMobile ? 'mt-1' : ''}`}
          />
        </Col>
        <Col xs={12} md={7}>
          <MobileView>
            <Form.Label className="fw-bold mb-0">QUICK FILTERS</Form.Label>
            <Select
              isMulti
              options={activityFilterOptions}
              placeholder="Select filters"
              isSearchable={false}
              className="mb-3"
              onChange={handleFilterSelectionMobile}
              styles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: 'var(--black)',
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: 'none',
                }),
                control: (provided) => ({
                  ...provided,
                  background: 'var(--light)',
                }),
              }}
            />
          </MobileView>
          <BrowserView>
            <Stack direction="horizontal" className="d-flex">
              <Form.Label className="fw-bold ps-2 pe-2">
                QUICK FILTERS
              </Form.Label>
              {activityFilterOptions.map((filter) => (
                <ThFilterBadge
                  key={filter.value}
                  label={filter.label}
                  value={filter.value}
                  aria-label={`Filter by ${filter.label}`}
                  total={getTotalActivities(
                    filter.value,
                    activities,
                    job?.timezone || UTC_TIMEZONE
                  )}
                  selected={activitiesFilter?.includes(
                    filter.value.toLowerCase()
                  )}
                  onClick={() => handleFilterSelection(filter.value)}
                />
              ))}
              {activitiesFilter.length > 0 && (
                <Button
                  size="sm"
                  className="ms-auto text-capitalize"
                  variant="light"
                  onClick={() => setActivitiesFilter([])}
                >
                  Reset Quick filters
                </Button>
              )}
            </Stack>
          </BrowserView>
        </Col>
      </Row>
      <Row>{showActivitiesList()}</Row>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
      {showConfirmModal && modalConfirmModel && (
        <ModalConfirm
          modalConfirmModel={modalConfirmModel}
          setShowModal={setShowConfirmModal}
        />
      )}
      {showWorkerProfile && clickedJobAssignment && chatPerson === null && (
        <JobWorkerSideModal
          handleCloseWorkerProfile={handleCloseWorkerProfile}
          worker={clickedJobAssignment.worker}
          jobAssignment={clickedJobAssignment}
          isJobRelated
        />
      )}
    </>
  );
}

export default ActivityTracker;
