import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { JobApplication } from 'types/job-application.type';
import { JobAssignment } from 'types/job-assignment.type';
import DATE_FORMATS from 'src/constants/dateFormat';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Form, Row } from 'react-bootstrap';
import { IWorkerData } from 'types/worker.type';
import { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import '../style.css';

interface ApplicationInfoHeaderParams {
  worker: IWorkerData;
  jobAssignment: JobAssignment;
  jobApplication?: JobApplication;
}

export default function ApplicationInfoHeader({
  jobAssignment,
  jobApplication,
  worker,
}: ApplicationInfoHeaderParams) {
  const hasApp = worker?.hasApp ?? false;
  const applicationStatusText =
    jobAssignment?.status.toString().replaceAll('_', ' ') || '';

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const showIconHasApp = () => {
    return hasApp ? (
      <FaCheck className="mb-1" color="green" />
    ) : (
      <AiFillCloseCircle className="mb-1" color="red" />
    );
  };

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(resizeTextArea, [jobApplication?.applicationNote]);

  return (
    <>
      <p>
        <b className="fs-5">Application Info</b>
      </p>

      {jobAssignment.status && (
        <p className="text-break">
          <b>{applicationStatusText}&nbsp;&nbsp;</b>
          {jobAssignment.lastUpdated &&
            format(
              new Date(jobAssignment.lastUpdated),
              DATE_FORMATS.DATE_TIME_FORMAT
            )}
        </p>
      )}
      <p className="text-break">
        <b>CELL&nbsp;&nbsp;</b>
        {worker.cellPhone}
      </p>
      <p className="text-break">
        <b>EMAIL&nbsp;&nbsp;</b>
        {worker.email}
      </p>
      <p className="text-break">
        <b>HAS APP?&nbsp;&nbsp;</b>
        {showIconHasApp()}
      </p>

      {!jobApplication?.applicationNote && (
        <p className="text-break">
          <b>NOTE&nbsp;&nbsp;</b>
          N/a
        </p>
      )}
      {jobApplication?.applicationNote && (
        <Row>
          <p className="text-break">
            <b>NOTE</b>
          </p>
          <Form>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={1}
                ref={textAreaRef}
                style={{ resize: 'none', overflow: 'hidden' }}
                className="text-area"
                readOnly
                value={jobApplication?.applicationNote}
              />
            </Form.Group>
          </Form>
        </Row>
      )}
    </>
  );
}
