import { Col, Row } from 'react-bootstrap';
import { AiTwotoneCalendar } from '@react-icons/all-files/ai/AiTwotoneCalendar';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { MdOutlineLocationOn } from 'react-icons/md';
import { JobAssignment } from 'types/job-assignment.type';
import { ReactElement } from 'react';
import { useJobAssignmentStatus } from 'src/hooks/useJobAssignmentStatus';
import { formatLocalDate } from 'src/utils/DateUtils';

interface JobShortDescriptionParams {
  jobAssignment: JobAssignment | null;
}

export default function JobShortDescription({
  jobAssignment,
}: JobShortDescriptionParams): ReactElement {
  const { job, getLocalStartDate, getLocalEndDate } = useJobContext();

  const jobAssignmentStatus = useJobAssignmentStatus();
  const { isHired } = jobAssignmentStatus(jobAssignment?.status);

  if (!job) {
    return <div />;
  }

  const addRoleAndCompanyInfo = () => {
    if (isHired()) {
      return (
        <>
          <b>{job.position}</b> At {job.company.name} &nbsp; &nbsp;
        </>
      );
    }
    return '';
  };

  const getFormattedDate = () => {
    const formattedStartDate = getLocalStartDate();

    if (job?.localizedEnd) {
      const formattedEndDate = getLocalEndDate();
      const formattedYear = job.nationalJob
        ? formatLocalDate(job.end, 'yy')
        : formatLocalDate(job.end, 'yy', job.address.timezoneId);
      return `${formattedStartDate} - ${formattedEndDate} '${formattedYear}`;
    }

    return `${formattedStartDate} - on going`;
  };

  return (
    <Row>
      {job && (
        <Col className="font-weight-bold">
          <div>
            <p>
              {addRoleAndCompanyInfo()}
              <AiTwotoneCalendar /> {getFormattedDate()}
              &nbsp; &nbsp;
              <MdOutlineLocationOn />{' '}
              {job.nationalJob
                ? 'National Job'
                : [
                    job.address.line1,
                    job.address.city,
                    job.address.state?.ansiAbbreviation,
                  ]
                    .filter((a) => a !== null)
                    .join(', ')}
            </p>{' '}
          </div>
        </Col>
      )}
    </Row>
  );
}
