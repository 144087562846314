import packageJson from '../../../../package.json';

import './styles.css';

function VersionAndBuildTime() {
  return (
    <div className="version-and-build-data">
      <small>Version: {packageJson.version}</small>
      <br />
      <small>Build time: {BUILD_DATE}</small>
    </div>
  );
}

export default VersionAndBuildTime;
