import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useAvatarImageFallback from 'src/hooks/useAvatarImageFallback';
import {
  Badge,
  Button,
  Card,
  Form,
  Image,
  InputGroup,
  Modal,
  Stack,
} from 'react-bootstrap';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import {
  FaLinkedin,
  FaRegCopy,
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareXTwitter,
} from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { StarRating, ThLoading } from 'src/components/elements';
import { useWorkerProfileContext } from 'src/apps/worker-frontend/state/workerProfileContext';
import { updateWorkerCustomUrl } from 'src/apps/worker-frontend/services/worker/api';
import useAlert from 'src/hooks/useAlert';
import { IWorkerData } from 'src/types/worker.type';
import { IoMdClose } from 'react-icons/io';
import ProfileInfoModal from '../../../Modals/ProfileInformationModal/ProfileInfoModal';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

function LeftPanel() {
  const imageRef = useRef<HTMLImageElement>(null);
  useAvatarImageFallback(imageRef);

  const [isLoading, setIsLoading] = useState(false);
  const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false);
  const [showProfileInfoModal, setShowProfileInfoModal] = useState(false);
  const [newCustomUrl, setNewCustomUrl] = useState<string>('');
  const [newCustomUrlErrorMessage, setNewCustomUrlErrorMessage] =
    useState<string>();

  const { workerProfile, setWorkerProfile } = useWorkerProfileContext();
  const { showSuccessAlert } = useAlert();

  const openProfileInfoModal = () => setShowProfileInfoModal(true);
  const closeProfileInfoModal = () => setShowProfileInfoModal(false);

  const workerProfileUrl = `${SERVER_URL}/w/${
    workerProfile?.customUrl ?? workerProfile?.id
  }`;

  const shareWorkerProfileUrl = () => {
    navigator.clipboard.writeText(workerProfileUrl);
    setShowCopiedToClipboard(true);
  };

  const handleNewCustomUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewCustomUrl(event.target.value);
    setNewCustomUrlErrorMessage(undefined);
  };

  const onSubmitNewCustomUrl = () => {
    setIsLoading(true);
    updateWorkerCustomUrl(newCustomUrl)
      .then(() => {
        setWorkerProfile((prevState) => {
          const newState = { ...prevState, customUrl: newCustomUrl };
          return newState as IWorkerData;
        });
        showSuccessAlert('Custom url updates succesfully!');
        setNewCustomUrlErrorMessage(undefined);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setNewCustomUrlErrorMessage(error.response.data.message);
      });
  };

  useEffect(() => {
    if (workerProfile) {
      if (workerProfile.customUrl) {
        setNewCustomUrl(workerProfile.customUrl);
      } else {
        setNewCustomUrl(workerProfile.id.toString());
      }
    }
  }, [workerProfile]);

  return workerProfile ? (
    <div className="left-panel">
      {showProfileInfoModal && (
        <ProfileInfoModal onCloseModal={closeProfileInfoModal} />
      )}
      <Modal
        body
        show={showCopiedToClipboard}
        size="lg"
        onHide={() => setShowCopiedToClipboard(false)}
      >
        <Stack className="p-3 share-profile-modal">
          <Stack
            direction="horizontal"
            className="justify-content-end align-items-center"
            gap={2}
          >
            <small style={{ color: 'var(--yellow-dark-20)' }}>
              Copied to clipboard!
            </small>
            <IoMdClose
              size={20}
              className="cursor-point align-self-end"
              onClick={() => setShowCopiedToClipboard(false)}
            />
          </Stack>
          <Stack className="px-3">
            <Stack direction="horizontal" gap={2} className="fs-3 fw-bold mb-4">
              <FaRegCopy fill="var(--yellow)" />
              Share Worker Profile
            </Stack>
            <p>
              Share with companies when you apply for jobs inside, or outside
              TH.
            </p>
            <p>
              And don&apos;t worry, the link is only accessible to TH company
              members (not fellow workers!)
            </p>
            <b className="worker-url">{workerProfileUrl}</b>
          </Stack>
        </Stack>
      </Modal>
      {isLoading && <ThLoading />}

      <Card className="overflow-hidden">
        <Image
          src={workerProfile?.profileImageUrl}
          ref={imageRef}
          alt="Profile Image"
          width={isMobile ? 'unset' : 322}
          height={isMobile ? 'unset' : 322}
          className="object-fit-cover"
        />
        <Stack gap={3} className="p-2">
          {/* WORKER NAME AND LOCATION */}
          <Stack
            direction="horizontal"
            className="justify-content-between flex-wrap"
          >
            <span className="fs-5">
              <b>
                {workerProfile.firstName} {workerProfile.lastName}
              </b>
              <span className="ms-2 section-percentages">
                ({workerProfile?.profileCompletedPercentage}%)
              </span>
            </span>
            <FiEdit
              size={18}
              className="cursor-point"
              onClick={openProfileInfoModal}
            />
          </Stack>

          <Stack direction="horizontal" className="share-profile-cta">
            <Button onClick={shareWorkerProfileUrl} variant="clear">
              Share Profile
            </Button>
            <div style={{ lineHeight: '0' }}>
              <FaRegCopy size={14} fill="var(--yellow-dark-20)" />
            </div>
          </Stack>
          <Stack direction="horizontal" gap={3}>
            {workerProfile.experienceLevel && (
              <span>
                Level <b>{workerProfile.experienceLevel}</b>
              </span>
            )}
            {workerProfile.isPremium && <Badge bg="primary">IN-NETWORK</Badge>}
          </Stack>
          <div>
            <b>{workerProfile.yearsInIndustry} years</b> in the industry
          </div>
          {/* RATING */}
          <div>
            <b className="fs-5">Rating</b>
            {workerProfile.localMarketPosition! <= 20 &&
              workerProfile.localMarketPosition! > 0 && (
                <p className="m-0">
                  #{workerProfile.localMarketPosition} Local Market Rank
                </p>
              )}
            {workerProfile.rating ? (
              <Stack direction="horizontal" gap={2} className="align-items-end">
                <StarRating
                  value={workerProfile.rating}
                  color="yellow"
                  size={20}
                />
              </Stack>
            ) : (
              <p className="m-0">You are not rated yet.</p>
            )}
          </div>
          {/* SOCIAL MEDIA */}
          <Stack gap={1} className="social-media">
            <b className="fs-5">Social Media</b>
            <Stack direction="horizontal" gap={3}>
              {workerProfile.facebookUser && (
                <a
                  href={`https://facebook.com/${workerProfile.facebookUser}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSquareFacebook
                    key={1}
                    className="pe-auto cursor-point"
                    size={24}
                  />
                </a>
              )}

              {workerProfile.instagramUser && (
                <a
                  href={`https://instagram.com/${workerProfile.instagramUser}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSquareInstagram
                    key={2}
                    className="pe-auto cursor-point"
                    size={24}
                  />
                </a>
              )}
              {workerProfile.twitterUser && (
                <a
                  href={`https://x.com/${workerProfile.twitterUser}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSquareXTwitter
                    key={3}
                    className="pe-auto cursor-point"
                    size={24}
                  />
                </a>
              )}
              {workerProfile.linkedinUser && (
                <a
                  href={`https://linkedin.com/in/${workerProfile.linkedinUser}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin
                    key={4}
                    className="pe-auto cursor-point"
                    size={24}
                  />
                </a>
              )}
            </Stack>
          </Stack>
          {/* TRAVEL PREFERENCES */}
          <Stack gap={1} className="flex-grow-0">
            <b className="fs-5">Travel Preferences</b>
            <div>
              Travel nationally?
              {workerProfile.willingToTravel ? (
                <AiFillCheckCircle className="ms-1" color="green" />
              ) : (
                <AiFillCloseCircle className="ms-1" color="red" />
              )}
            </div>
            <div>
              Travel for longer periods?
              {workerProfile.travelPeriod ? (
                <AiFillCheckCircle className="ms-1" color="green" />
              ) : (
                <AiFillCloseCircle className="ms-1" color="red" />
              )}
            </div>
          </Stack>
          {workerProfile.isPremium ? (
            <Stack className="flex-grow-0" gap={2}>
              <b className="fs-5">Edit custom url</b>
              <InputGroup className="p-0">
                <InputGroup.Text id="custom-url" className="p-0">
                  {SERVER_URL}/w/
                </InputGroup.Text>
                <Form.Control
                  aria-describedby="custom-url"
                  className={`p-1 fw-bold border ${
                    newCustomUrlErrorMessage ? 'border-danger' : null
                  }`}
                  value={newCustomUrl}
                  onChange={handleNewCustomUrlChange}
                />
              </InputGroup>
              {newCustomUrlErrorMessage && (
                <small style={{ color: 'var(--red)' }}>
                  {newCustomUrlErrorMessage}
                </small>
              )}

              <Button
                onClick={onSubmitNewCustomUrl}
                disabled={
                  newCustomUrl === '' ||
                  newCustomUrl === workerProfile.customUrl
                }
              >
                Update
              </Button>
            </Stack>
          ) : null}
        </Stack>
      </Card>
    </div>
  ) : null;
}

export default LeftPanel;
