import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Alert, Nav, Tab } from 'react-bootstrap';
import NewsTab from 'company/components/CommunicationHub/Tabs/News/News';
import ReceivedReviews from 'company/components/CommunicationHub/Tabs/ReceivedReviews/ReceivedReviews';
import ReviewRequests from 'company/components/CommunicationHub/Tabs/ReviewRequests/ReviewRequests';
import ReviewsAndEndorsementsLeft from 'company/components/CommunicationHub/Tabs/ReviewsAndEndoresementsLeft/ReviewsAndEndoresementsLeft';
import ComHubWorkerSideModal from 'company/components/CommunicationHub/ComHubWorkerSideModal';
import ReceivedReviewModal from 'company/components/CommunicationHub/Modals/ReceivedReviewModal/ReceivedReviewModal';
import ReviewRequestModal from 'company/components/CommunicationHub/Modals/ReviewRequestModal/ReviewRequestModal';
import GivenReviewModal from 'company/components/CommunicationHub/Modals/GivenReviewModal/GivenReviewModal';
import GivenEndorsementModal from 'company/components/CommunicationHub/Modals/GivenEndorsementModal/GivenEndorsementModal';
import {
  getReceivedReviewsNewCounter,
  getReviewRequestsNewCounter,
  dismissItemsFromNew,
} from 'company/services/com-hub/api';
import {
  ComHubQueryParamsEnum,
  ComHubTabRoutes,
  ComHubTabRoutesType,
  ComHubTabs,
  GivenReview,
  Review,
  ReviewTypeEnum,
  ComHubDismissTypeEnum,
} from 'company/types/comm-hub.type';
import useAlert from 'hooks/useAlert';
import { SideEndModal } from 'components/elements';
import QueryKeys from 'constants/queryKeys';
import { STALE_ONE_MINUTE } from 'constants/comhub';
import { dismissItems } from 'utils/ComHubUtils';

import './styles.css';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

function CommunicationHub() {
  const [showWorkerProfile, setShowWorkerProfile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [workerProfileId, setWorkerProfileId] = useState<number | null>(null);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [selectedJobAssignmentId, setSelectedJobAssignmentId] = useState<
    number | null
  >();
  const [selectedEndorsementCaseId, setSelectedEndorsementCaseId] = useState<
    number | null
  >();
  const [reviewType, setReviewType] = useState<ReviewTypeEnum | null>();
  const [activeTabName, setActiveTabName] = useState<string>(
    ComHubTabRoutes[ComHubTabs.NEW].key
  );

  const queryClient = useQueryClient();
  const { showSuccessAlert, showErrorAlert, alertMessage } = useAlert();
  const navigate = useNavigate();
  const { activeTab } = useParams();

  useEffect(() => {
    if (activeTab) {
      setActiveTabName(activeTab);
    } else {
      navigate(ComHubTabRoutes[ComHubTabs.NEW].key);
    }
  }, [activeTab, navigate]);

  // FETCH COUNTERS
  const { data: receivedReviewsCounterData } = useQuery({
    queryKey: [QueryKeys.ComHub.RECEIVED_REVIEWS],
    queryFn: getReceivedReviewsNewCounter,
    staleTime: STALE_ONE_MINUTE,
  });
  const { data: reviewRequestsCounterData } = useQuery({
    queryKey: [QueryKeys.ComHub.REVIEW_REQUESTS],
    queryFn: getReviewRequestsNewCounter,
    staleTime: STALE_ONE_MINUTE,
  });

  // useQuery update
  const { mutateAsync: dismissMutateAsync } = useMutation({
    mutationFn: dismissItemsFromNew,
    onSuccess: () => {
      if (selectedReview?.userState.type === ReviewTypeEnum.REVIEW_REQUEST) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ComHub.REVIEW_REQUESTS],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WORKER_ASSIGNMENT_JOB],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ComHub.RECEIVED_REVIEWS],
        });
      }
      setSelectedReview(null);
    },
  });

  const handleTabChange = (tabName: string, onlyNew = false) => {
    const route = Object.values(ComHubTabRoutes).find(
      (comHubRoute: ComHubTabRoutesType) => comHubRoute.key === tabName
    );
    if (route) {
      const baseUrl = `${BASE_PATH}communication-hub/${route.key}`;
      const queryParams = new URLSearchParams();
      if (onlyNew) {
        queryParams.append(ComHubQueryParamsEnum.NEW, 'true');
      }

      const url = `${baseUrl}?${queryParams}`;
      navigate(url); // Using the mapped key for navigation
      setActiveTabName(route.key);
    }
  };

  const seeReceivedReview = (review: Review) => {
    setSelectedReview(review);
    setReviewType(ReviewTypeEnum.RECEIVED_REVIEW);
    setShowModal(true);
  };

  const handleReplyReviewSuccess = async () => {
    await dismissItems(
      [selectedReview!],
      ComHubDismissTypeEnum.COMPANY_RATING,
      dismissMutateAsync
    );
    showSuccessAlert('Reply submitted successfully');
  };

  const seeReviewRequest = (review: Review) => {
    setSelectedReview(review);
    setReviewType(ReviewTypeEnum.REVIEW_REQUEST);
    setShowModal(true);
  };

  const seeGivenReview = (review: GivenReview) => {
    setSelectedJobAssignmentId(review.jobAssignmentId);
    setReviewType(ReviewTypeEnum.GIVEN_REVIEW);
    setShowModal(true);
  };

  const seeGivenEndorsement = (endorsementCaseId: number) => {
    setSelectedEndorsementCaseId(endorsementCaseId);
    setReviewType(ReviewTypeEnum.GIVEN_ENDORSEMENT);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEndorsementCaseId(null);
    setSelectedJobAssignmentId(null);
    setSelectedReview(null);
    setReviewType(null);
  };

  const handleReviewSuccess = async () => {
    await dismissItems(
      [selectedReview!],
      ComHubDismissTypeEnum.RATING_REQUEST,
      dismissMutateAsync
    );
    showSuccessAlert('Review submitted successfully');
    closeModal();
  };

  const handleCloseWorkerProfile = () => {
    setShowWorkerProfile(false);
    setWorkerProfileId(null);
  };

  const handleShowWorkerProfile = (workerId: number) => {
    setWorkerProfileId(workerId);
    setShowWorkerProfile(true);
  };

  const showAlertMessage = async (success: boolean, message: string) => {
    if (success) {
      showSuccessAlert(message);
    } else {
      showErrorAlert(message);
    }
  };

  const getCounterNumber = (tabName: ComHubTabs): number => {
    if (!receivedReviewsCounterData || !reviewRequestsCounterData) return 0;

    switch (tabName) {
      case ComHubTabs.NEW:
        return (
          receivedReviewsCounterData.count + reviewRequestsCounterData.count
        );
      case ComHubTabs.RECEIVED_REVIEWS:
        return receivedReviewsCounterData.count;
      case ComHubTabs.RECEIVED_REQUESTS:
        return reviewRequestsCounterData.count;
      default:
        return 0;
    }
  };

  return (
    <div className="communication-hub">
      <Tab.Container
        defaultActiveKey={ComHubTabRoutes[ComHubTabs.NEW].key}
        activeKey={activeTabName}
        onSelect={(tabName) => handleTabChange(tabName!)}
        mountOnEnter
        unmountOnExit
      >
        <Nav variant="tabs" className="d-flex">
          {Object.entries(ComHubTabRoutes).map(([key, value]) => (
            <Nav.Item key={key} className="flex-grow-1 text-center">
              <Nav.Link
                eventKey={value.key}
                href={`${BASE_PATH}communication-hub/${value.key}`}
                onClick={(event) => event.preventDefault()}
              >
                {value.label}{' '}
                {getCounterNumber(value.label)
                  ? `(${getCounterNumber(value.label)})`
                  : null}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content style={{ padding: '20px' }}>
          <Tab.Pane eventKey={ComHubTabRoutes[ComHubTabs.NEW].key}>
            <NewsTab
              onSeeReceivedReview={seeReceivedReview}
              onSeeReviewRequest={seeReviewRequest}
              onShowWorkerProfile={handleShowWorkerProfile}
              onTabChange={handleTabChange}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={ComHubTabRoutes[ComHubTabs.RECEIVED_REVIEWS].key}>
            <ReceivedReviews
              onShowWorkerProfile={handleShowWorkerProfile}
              onSeeReceivedReview={seeReceivedReview}
            />
          </Tab.Pane>
          <Tab.Pane
            eventKey={ComHubTabRoutes[ComHubTabs.RECEIVED_REQUESTS].key}
          >
            <ReviewRequests
              onShowWorkerProfile={handleShowWorkerProfile}
              onSeeReviewRequest={seeReviewRequest}
            />
          </Tab.Pane>
          <Tab.Pane
            eventKey={ComHubTabRoutes[ComHubTabs.REVIEWS_AND_ENDORSEMENTS].key}
          >
            <ReviewsAndEndorsementsLeft
              onShowWorkerProfile={handleShowWorkerProfile}
              onSeeGivenReview={seeGivenReview}
              onSeeGivenEndorsement={seeGivenEndorsement}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      {showWorkerProfile && workerProfileId && (
        <ComHubWorkerSideModal
          handleCloseWorkerProfile={handleCloseWorkerProfile}
          workerId={workerProfileId}
        />
      )}
      <SideEndModal
        show={
          showModal &&
          (!!selectedReview ||
            !!selectedJobAssignmentId ||
            !!selectedEndorsementCaseId)
        }
        onHide={closeModal}
        width={
          reviewType === ReviewTypeEnum.GIVEN_ENDORSEMENT ? '40%' : undefined
        }
      >
        {reviewType === ReviewTypeEnum.RECEIVED_REVIEW && selectedReview && (
          <ReceivedReviewModal
            selectedReview={selectedReview}
            closeModal={closeModal}
            showAlertMessage={showAlertMessage}
            onReplySuccess={handleReplyReviewSuccess}
            onDismissItems={(reviews) =>
              dismissItems(
                reviews,
                ComHubDismissTypeEnum.COMPANY_RATING,
                dismissMutateAsync
              )
            }
          />
        )}
        {reviewType === ReviewTypeEnum.REVIEW_REQUEST && selectedReview && (
          <ReviewRequestModal
            selectedReview={selectedReview}
            closeModal={closeModal}
            onReviewSuccess={handleReviewSuccess}
            onDismissItems={(reviews) =>
              dismissItems(
                reviews,
                ComHubDismissTypeEnum.RATING_REQUEST,
                dismissMutateAsync
              )
            }
          />
        )}
        {reviewType === ReviewTypeEnum.GIVEN_REVIEW && (
          <GivenReviewModal
            jobAssignmentId={selectedJobAssignmentId!}
            closeModal={closeModal}
          />
        )}
        {reviewType === ReviewTypeEnum.GIVEN_ENDORSEMENT && (
          <GivenEndorsementModal
            endorsementCaseId={selectedEndorsementCaseId!}
            closeModal={closeModal}
          />
        )}
      </SideEndModal>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </div>
  );
}

export default CommunicationHub;
