import { format } from 'date-fns';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { FaCircleCheck } from 'react-icons/fa6';
import { StarRating } from 'src/components/elements';
import DATE_FORMATS from 'src/constants/dateFormat';
import { Comment, PersonType } from 'src/types/workerProfile.type';

import '../styles.scss';

interface EndorsementCommentProps {
  comment: Comment;
}

function EndorsementComment({ comment }: EndorsementCommentProps) {
  return comment ? (
    <Stack>
      <Stack direction="horizontal" className="justify-content-between">
        <b className="review-date">
          {format(new Date(comment.dateCreated), DATE_FORMATS.DATE_FORMAT)}
        </b>
        <Stack direction="horizontal" gap={3}>
          {comment.avgRating && (
            <StarRating value={comment.avgRating} color="yellow" />
          )}
          <div>
            <small>
              <b>Endorsed</b>
            </small>
            <FaCircleCheck
              className="icon-style ms-1"
              size={14}
              color="var(--yellow)"
            />
          </div>
        </Stack>
      </Stack>
      <Row className="mb-2 align-items-center">
        <Col xs={6} md={3} className="fw-bold">
          {comment.personType === PersonType.WORKER ? 'From' : 'From Company'}
        </Col>
        <Col>
          <Image
            width={30}
            height={30}
            src={comment.profileImageUrl}
            roundedCircle
          />
          <span className="ms-2">
            {comment.personType === PersonType.WORKER
              ? comment.authorName
              : comment.companyName}
          </span>
        </Col>
      </Row>
      <Row className="mb-2 align-items-center">
        <Col xs={6} md={3} className="fw-bold">
          <b>Events worked</b>
        </Col>
        <Col>
          <span>{comment.sharedEventsNumber}</span>
        </Col>
      </Row>
      <hr className="divider" />
      <p>{comment.comments}</p>
    </Stack>
  ) : null;
}

export default EndorsementComment;
