import { JobAssignmentStatus } from 'src/types/job-assignment-status.type';
import { ThJobAssignmentLog } from 'types/job-assignment.type';

export function useJobAssignmentStatus() {
  return (status: JobAssignmentStatus | undefined) => {
    const isHired = () => {
      return (
        status === JobAssignmentStatus.HIRED ||
        status === JobAssignmentStatus.TIMESHEET_COMPLETED ||
        status === JobAssignmentStatus.TIMESHEET_APPROVED
      );
    };
    const isApplicant = () => {
      return (
        status === JobAssignmentStatus.WORKER_APPLIED ||
        status === JobAssignmentStatus.WORKER_INTERESTED
      );
    };
    const isOffered = () => {
      return status === JobAssignmentStatus.OFFERED;
    };

    const isEligibleForOffer = () => {
      return (
        status !== JobAssignmentStatus.WORKER_NOT_INTERESTED &&
        status !== JobAssignmentStatus.HIRED &&
        status !== JobAssignmentStatus.DECLINED_BY_WORKER &&
        status !== JobAssignmentStatus.CANCELLED_BY_WORKER &&
        status !== JobAssignmentStatus.TIMESHEET_COMPLETED &&
        status !== JobAssignmentStatus.TIMESHEET_APPROVED
      );
    };
    const hasEngagedWithJob = () => {
      return (
        status === JobAssignmentStatus.WORKER_APPLIED ||
        status === JobAssignmentStatus.WORKER_INTERESTED ||
        status === JobAssignmentStatus.HIRED ||
        status === JobAssignmentStatus.TIMESHEET_COMPLETED ||
        status === JobAssignmentStatus.TIMESHEET_APPROVED ||
        status === JobAssignmentStatus.SHARED ||
        status === JobAssignmentStatus.OFFERED
      );
    };

    const isDeclined = () => {
      return (
        status === JobAssignmentStatus.DECLINED_BY_WORKER ||
        status === JobAssignmentStatus.DECLINED_BY_COMPANY ||
        status === JobAssignmentStatus.WORKER_NOT_INTERESTED
      );
    };

    const isCanceled = () => {
      return (
        status === JobAssignmentStatus.CANCELLED_BY_COMPANY ||
        status === JobAssignmentStatus.CANCELLED_BY_WORKER
      );
    };

    const isDeclinedOrCanceled = () => {
      return isDeclined() || isCanceled();
    };

    const isHiredOrApplicant = () => isHired() || isApplicant();
    const isHiredOrOffered = () => isHired() || isOffered();
    const isHiredOrApplicantOrOffered = () =>
      isHired() || isOffered() || isApplicant();

    const canBeReconsidered = (logs: ThJobAssignmentLog[]) => {
      const isCorrectStatus =
        status === JobAssignmentStatus.DECLINED_BY_COMPANY ||
        status === JobAssignmentStatus.CANCELLED_BY_COMPANY ||
        status === JobAssignmentStatus.DECLINED_BY_WORKER ||
        status === JobAssignmentStatus.CANCELLED_BY_WORKER;
      if (isCorrectStatus && logs.length > 0) {
        // Only reconsider if worker was applicant or hired.
        return logs.some((l) => {
          return (
            l.toStatus === JobAssignmentStatus.WORKER_INTERESTED ||
            l.toStatus === JobAssignmentStatus.WORKER_APPLIED
          );
        });
      }
      return false;
    };

    const isOfferableIfCanceled = (
      jobAssignmentLogs?: ThJobAssignmentLog[]
    ): boolean => {
      // it was no cancel, so yes
      if (
        !isCanceled() ||
        !jobAssignmentLogs ||
        jobAssignmentLogs?.length === 0
      ) {
        return true;
      }

      const maxIdLog = jobAssignmentLogs.reduce((maxLog, currentLog) => {
        return currentLog.id > maxLog.id ? currentLog : maxLog;
      });

      return (
        maxIdLog.fromStatus === JobAssignmentStatus.OFFERED &&
        maxIdLog.toStatus === JobAssignmentStatus.CANCELLED_BY_COMPANY
      );
    };

    return {
      isHired,
      isOffered,
      isApplicant,
      isHiredOrOffered,
      hasEngagedWithJob,
      isEligibleForOffer,
      isHiredOrApplicant,
      isDeclined,
      isDeclinedOrCanceled,
      isOfferableIfCanceled,
      isHiredOrApplicantOrOffered,
      canBeReconsidered,
    };
  };
}
