import { Button, Table } from 'react-bootstrap';
import { ImNewTab } from 'react-icons/im';
import { useQuery } from '@tanstack/react-query';
import DeleteRowIcon from 'components/Icons/DeleteRowIcon/DeleteRowIcon';
import { formatTimeOrDateNow } from 'utils/DateUtils';
import QueryKeys from 'constants/queryKeys';
import { STALE_ONE_MINUTE } from 'constants/comhub';
import { getReviewRequestsNewCounter } from 'company/services/com-hub/api';
import { ComHubHeadersEnum, Review } from 'company/types/comm-hub.type';

import './styles.css';
import { ReviewRequestModel } from 'company/models/ReviewRequestModel';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

interface ReviewRequestsTableProps {
  reviewRequests: Review[];
  title: string;
  titleCallback?: () => void;
  onShowWorkerProfile?: (workerId: number) => void;
  onSeeReviewRequest: (review: Review) => void;
  onDismissItems?: (item: Review[]) => void;
}

function ReviewRequestsTable({
  title,
  titleCallback,
  reviewRequests,
  onShowWorkerProfile,
  onSeeReviewRequest,
  onDismissItems,
}: ReviewRequestsTableProps) {
  const headers = [
    ComHubHeadersEnum.EVENT_NAME,
    ComHubHeadersEnum.WORKER_NAME,
    ComHubHeadersEnum.POSITION,
    ComHubHeadersEnum.DATES_WORKED,
    ComHubHeadersEnum.RESPONDED,
    ComHubHeadersEnum.ACTIONS_COLUMN,
  ];

  const newReviewRequests = reviewRequests.filter(
    (reviewRequest) => reviewRequest.userState.new
  );

  // FETCH COUNTERS
  const { data: reviewRequestsCounterData } = useQuery({
    queryKey: [QueryKeys.ComHub.REVIEW_REQUESTS],
    queryFn: getReviewRequestsNewCounter,
    staleTime: STALE_ONE_MINUTE,
  });

  const handleDismissItem = (itemsToDismiss: Review[]) => {
    if (onDismissItems) {
      onDismissItems(itemsToDismiss);
    }
  };

  return (
    <div className="th-table">
      <header>
        <button
          type="button"
          onClick={titleCallback}
          className={`${titleCallback ? 'cursor-point' : 'cursor-default'}`}
        >
          <h4>
            {title}
            {reviewRequestsCounterData && (
              <b data-testid="new-counter">
                ({reviewRequestsCounterData.count})
              </b>
            )}
          </h4>
        </button>
        {newReviewRequests.length ? (
          <Button
            type="button"
            variant="light"
            className="ms-auto fw-bold d-flex"
            onClick={() => handleDismissItem(newReviewRequests)}
          >
            <DeleteRowIcon className="me-2" />
            Dismiss {newReviewRequests.length} new
          </Button>
        ) : null}
      </header>
      <hr style={{ margin: '10px 0' }} />
      {reviewRequests.length ? (
        <Table>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* RENDER COLUMS BY ROW */}
            {reviewRequests.map((reviewRequest) => {
              const request = new ReviewRequestModel(reviewRequest);
              return (
                <tr
                  key={request.getUniqueKey()}
                  className={`${request.isNew() ? 'new' : ''}`}
                >
                  <td>
                    {reviewRequest.jobId ? (
                      <a
                        href={`${BASE_PATH}jobs/${request.jobId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-point event-name-link"
                        style={{ color: 'currentcolor' }}
                      >
                        {request.eventName}
                        <ImNewTab
                          color="var(--yellow-dark-20)"
                          className="ms-2 event-name-link-icon"
                        />
                      </a>
                    ) : (
                      <span>{request.eventName}</span>
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        if (onShowWorkerProfile && request.getWorkerId()) {
                          onShowWorkerProfile!(request.getWorkerId()!);
                        }
                      }}
                      className={`${request.isNew() ? 'new' : ''}`}
                    >
                      {request.getWorkerName()}
                    </button>
                  </td>
                  <td>{request.getPosition()}</td>
                  <td>{request.getWorkedDateRange()}</td>
                  <td>{request.completed ? 'YES' : 'NO'}</td>
                  {/* BUTTONS FOR ROW ACTIONS */}
                  <td className="actions">
                    <span className="me-3">
                      {request.companyRating &&
                        formatTimeOrDateNow(request.getDateCreated())}
                    </span>
                    <Button
                      onClick={() => onSeeReviewRequest(reviewRequest)}
                      className="action-btn me-2"
                    >
                      open
                    </Button>
                    <button
                      type="button"
                      disabled={!request.isNew()}
                      style={{
                        cursor: !request.isNew() ? 'not-allowed' : 'pointer',
                      }}
                    >
                      <DeleteRowIcon
                        onClick={() => handleDismissItem([reviewRequest])}
                        color={!request.isNew() ? 'var(--silver)' : ''}
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p>No new notifications.</p>
      )}
      {titleCallback && ( // change this for more accurate prop
        <Button variant="link" onClick={titleCallback} className="show-all-cta">
          Show All
        </Button>
      )}
    </div>
  );
}

export default ReviewRequestsTable;
