import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SideEndModal, ThError } from 'components/elements';
import QueryKeys from 'src/constants/queryKeys';
import { IWorkerJobData } from 'types/workerProfile.type';
import * as api from 'src/apps/company-frontend/services/worker/api';
import { useParams } from 'react-router-dom';
import { WorkerProfile } from 'company/components';
import { Body } from 'company/components/layout';
import { DirectMessageChat } from 'src/components/elements/DirectMessage';
import { isMobile } from 'react-device-detect';
import { useDirectMessageContext } from '../../state/directMessageContext';

function WorkerProfilePage() {
  const params = useParams();
  const { id } = params;
  const { worker, clearDirectMessageData } = useDirectMessageContext();

  const queryKey = `${QueryKeys.WORKER_PROFILE}_${id}`;

  const {
    isLoading,
    error,
    data: workerProfileData,
    isRefetching,
  } = useQuery<IWorkerJobData, AxiosError, IWorkerJobData>({
    queryKey: [queryKey],
    queryFn: () => api.fetchWorkerJobInfo(id, undefined, true),
  });

  if (error) {
    if (error.response?.status) {
      return <ThError message="No data found" />;
    }
    return <ThError message={error.message} />;
  }
  return (
    <Body>
      <WorkerProfile
        workerProfileData={workerProfileData}
        isLoadingWorker={isLoading || isRefetching}
      />
      {worker && (
        <SideEndModal
          show={!!worker}
          width={isMobile ? '90%' : '50%'}
          onHide={clearDirectMessageData}
        >
          <DirectMessageChat worker={worker} />
        </SideEndModal>
      )}
    </Body>
  );
}

export default WorkerProfilePage;
