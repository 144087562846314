import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import QueryKeys from 'src/constants/queryKeys';
import { AxiosError } from 'axios';
import { useUserContext } from 'src/state/userContext';
import { getProfileData, getWorkerSurveyHistory } from '../services/survey/api';
import {
  IProfileData,
  IWorkerSurveyHistory,
  Pagination,
  SurveyHistoryResponse,
} from '../types/surveys.type';

interface ISurveyContext {
  profileData: IProfileData | undefined;
  surveyHistory: IWorkerSurveyHistory[] | undefined;
  pagination: Pagination | undefined;
  error?: any;
  isLoading?: boolean;
  refetch?: any;
  isFetching?: boolean;
  isRefetching?: boolean;
  errorProfile?: any;
  isLoadingProfile?: boolean;
  refetchProfile?: any;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  startSurveyDisabled: boolean;
  setStartSurveyDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyContext = createContext<ISurveyContext | any>({});

interface ProviderProps {
  children: React.ReactNode;
}

export function SurveyProvider({ children }: ProviderProps) {
  const { id, loggedIn } = useUserContext();
  const [startSurveyDisabled, setStartSurveyDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const {
    isLoading: isLoadingProfile,
    error: errorProfile,
    data: profileData,
    refetch: refetchProfile,
  } = useQuery<IProfileData, AxiosError>({
    queryKey: [QueryKeys.SURVEY_PROFILING_DATA],
    queryFn: () => getProfileData(),
    enabled: id != null && loggedIn,
    retry: (failureCount, error) => {
      // Don't retry if we get a 406 status
      if (error instanceof AxiosError && error.response?.status === 406) {
        return false;
      }
      // Default retry behavior for other errors
      return failureCount < 1;
    },
  });

  useEffect(() => {
    if (errorProfile) {
      setStartSurveyDisabled(true);
    }
  }, [errorProfile, setStartSurveyDisabled]);

  const {
    isLoading,
    error,
    data: { surveys: surveyHistory, pagination } = {},
    refetch,
    isRefetching,
    isFetching,
  } = useQuery<SurveyHistoryResponse, AxiosError>({
    queryKey: [QueryKeys.SURVEY_HISTORY, id, currentPage],
    queryFn: () => getWorkerSurveyHistory(currentPage, pageSize),
    enabled: id != null && loggedIn,
    retry: 1,
  });

  const contextValue: ISurveyContext = useMemo(() => {
    return {
      error,
      isLoading,
      refetch,
      isFetching,
      isRefetching,
      errorProfile,
      isLoadingProfile,
      refetchProfile,
      surveyHistory,
      profileData,
      currentPage,
      setCurrentPage,
      totalPages: pagination?.pageCount || 1,
      pagination,
      startSurveyDisabled,
      setStartSurveyDisabled,
    };
  }, [
    error,
    isLoading,
    refetch,
    isFetching,
    isRefetching,
    errorProfile,
    isLoadingProfile,
    refetchProfile,
    surveyHistory,
    profileData,
    currentPage,
    pagination,
    startSurveyDisabled,
    setStartSurveyDisabled,
  ]);
  return (
    <SurveyContext.Provider value={contextValue}>
      {children}
    </SurveyContext.Provider>
  );
}

export function useSurveyContext() {
  const context: ISurveyContext = useContext(SurveyContext);
  if (context === undefined) {
    throw new Error(
      'useSurveyContext must be used within a Survey. Wrap a parent component in <Survey> to fix this error.'
    );
  }
  return context;
}
