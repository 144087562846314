import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import getUser from 'services/user/api';
import { privateApi } from 'src/services/api';
import { UserRole } from 'src/constants/userRoles';
import { HTTP_UNAUTHORIZED } from 'src/constants/httpStatus';
import MESSAGES from 'src/constants/messages';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';

interface IUserContext {
  loggedIn: boolean;
  id: number;
  name: string;
  email: string | null;
  profileImageUrl: string;
  fetchUser: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<any, Error>>;
  roles: UserRole[];
  hasRole(role: UserRole): boolean;
  isLoading: boolean;
}

interface ProviderProps {
  children: ReactNode;
}

// define context
const UserContext: IUserContext | any = React.createContext({});

// define provider
export function UserProvider({ children }: ProviderProps) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoggedInSet, setIsLoggedInSet] = useState(false);
  const {
    data,
    refetch: fetchUser,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
  });

  const { id, name, email, profileImageUrl = '', roles } = data || {};
  if (isSuccess && !isLoggedInSet) {
    setLoggedIn(data?.loggedIn);
    setIsLoggedInSet(true);
  }

  const hasRole = useCallback(
    (role: UserRole): boolean => {
      const userRole = UserRole[role as unknown as keyof typeof UserRole];
      return roles?.includes(userRole) || false;
    },
    [roles]
  );

  const contextValue: IUserContext = useMemo(() => {
    return {
      loggedIn,
      id,
      name,
      email,
      profileImageUrl,
      fetchUser,
      hasRole,
      roles,
      isLoading,
    };
  }, [
    id,
    email,
    loggedIn,
    name,
    profileImageUrl,
    fetchUser,
    hasRole,
    roles,
    isLoading,
  ]);

  useEffect(() => {
    /**
     * The axios interceptor is declared here to get access to
     * the loggedIn state - [8686j3quv]
     */
    privateApi.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { response } = error;
        if (
          response &&
          response.status &&
          HTTP_UNAUTHORIZED === response.status
        ) {
          setLoggedIn(false);
          // eslint-disable-next-line
          return Promise.reject({
            status: HTTP_UNAUTHORIZED,
            message: MESSAGES.expiredSession,
          });
        }
        throw error;
      }
    );
  }, []);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

export function useUserContext() {
  const context: IUserContext = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      'useUserContext must be used within a UserProvider. Wrap a parent component in <UserProvider> to fix this error.'
    );
  }
  return context;
}
