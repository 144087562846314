import { Badge, Col, Figure, Form, InputGroup, Row } from 'react-bootstrap';
import IConversationData from 'src/apps/company-frontend/types/conversation.type';
import { isMobile } from 'react-device-detect';
import { Panel } from 'components/elements';
import { Chat } from 'components/Chat';
import { BiSearch } from 'react-icons/bi';
import { debounce } from 'lodash';
import { LoadPersonCircle } from 'components/elements/ComponentLoader';
import { useJobAssignmentStatus } from 'src/hooks/useJobAssignmentStatus';
import useOnScreen from 'src/hooks/useIntersectionObserver';
import { useEffect, useRef } from 'react';

interface Params {
  selectedConversation: IConversationData | null;
  setSelectedConversation: (defaultValue: IConversationData) => void;
  conversations?: IConversationData[];
  setWorkerNameFilter: (value: string) => void;
  isLoading: boolean;
  refetchConversations: () => void;
}

function ConversationsMobile({
  selectedConversation,
  setSelectedConversation,
  conversations,
  setWorkerNameFilter,
  isLoading,
  refetchConversations,
}: Params) {
  const handleTextSearch = debounce((value: string) => {
    setWorkerNameFilter(value);
  }, 400);
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);

  const jobAssignmentStatus = useJobAssignmentStatus();
  const { isHiredOrApplicant } = jobAssignmentStatus(
    selectedConversation?.jobAssignmentStatus
  );

  function isReadOrSelected(
    conversation: IConversationData
  ): string | undefined {
    if (
      selectedConversation &&
      conversation.jobAssignmentId === selectedConversation.jobAssignmentId
    ) {
      return 'border border-primary border-3';
    }
    if (conversation.unreadCount > 0) {
      return 'border border-secondary border-4';
    }
    return undefined;
  }

  useEffect(() => {
    if (isOnScreen) {
      refetchConversations();
    }
  }, [isOnScreen, refetchConversations]);

  return (
    <Row ite>
      <Col md={12} className="mb-3">
        <InputGroup>
          <InputGroup.Text>
            <BiSearch size={22} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Filter conversation"
            name="workerName"
            onKeyDown={(e) =>
              handleTextSearch((e.target as HTMLTextAreaElement).value)
            }
          />
        </InputGroup>
      </Col>
      <Col md={12} style={{ overflowX: 'scroll' }}>
        {isLoading ? (
          <LoadPersonCircle />
        ) : (
          <div className="d-flex flex-nowrap" ref={ref}>
            {conversations?.map((conversation, index) => (
              <div key={`message_conversation_${conversation.jobAssignmentId}`}>
                <Figure.Image
                  className={`${
                    index !== 0 && 'ms-2'
                  } avatar rounded-circle ${isReadOrSelected(
                    conversation
                  )} conversation-image`}
                  src={conversation.worker.profileImageUrl}
                  width={60}
                  height={60}
                  onClick={() => setSelectedConversation(conversation)}
                />
                {conversation.unreadCount > 0 && (
                  <Badge
                    pill
                    className="text-dark"
                    style={{ height: 22, marginLeft: -15 }}
                  >
                    {conversation.unreadCount}
                  </Badge>
                )}
              </div>
            ))}
          </div>
        )}
      </Col>

      <Col md={12} className={isMobile ? 'mt-2' : ''}>
        <Panel
          style={{
            height: selectedConversation ? '78vh' : '',
            overflow: 'auto',
          }}
        >
          {selectedConversation ? (
            <Chat
              showWorkerInfo={isHiredOrApplicant()}
              worker={selectedConversation.worker}
            />
          ) : (
            'No messages/conversations selected.'
          )}
        </Panel>
      </Col>
    </Row>
  );
}

export default ConversationsMobile;
