import { privateApi } from 'services/api';
import IResultResponse from 'src/types/resultResponse.type';

export const MAIN_URL = 'messages';

export const shareJobByEmail = (
  emailAddress: string,
  emailMessage: string,
  workerUrl: string
) => {
  const result = privateApi
    .post<IResultResponse>(`${MAIN_URL}/share-worker-profile`, null, {
      params: {
        emailAddress,
        emailMessage,
        workerUrl,
      },
    })
    .catch((error) => {
      console.log(error);
    })
    .then((response) => ({
      data: {
        success: response,
      },
    }));
  return result;
};
