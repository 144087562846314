import { Button } from 'react-bootstrap';
import TooltipOverlay from 'src/components/elements/Tooltip';
import { OFFER_SHIFT } from 'src/services/shift/shiftService';

interface Props {
  openOfferShiftModal: () => void;
  totalSelectedOfferableShifts: number;
  tooltipMessage?: string;
}

export function OfferShiftsButtonWeb({
  openOfferShiftModal,
  totalSelectedOfferableShifts,
  tooltipMessage,
}: Props) {
  if (totalSelectedOfferableShifts <= 0) {
    return <div />;
  }
  return (
    <span className="offer-shifts">
      <TooltipOverlay
        delay={2}
        text={
          tooltipMessage ||
          'Offer selected shifts to a worker which are on Open status and start today or in the future.'
        }
        position="bottom"
      >
        <Button
          variant="primary"
          className="fw-bold me-2"
          onClick={openOfferShiftModal}
        >
          {OFFER_SHIFT}
          {totalSelectedOfferableShifts > 1 ? 'S' : ''} (
          {totalSelectedOfferableShifts})
        </Button>{' '}
      </TooltipOverlay>
    </span>
  );
}
