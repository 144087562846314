import Address from 'src/types/address.type';
import Picture from 'src/types/picture.type';

export interface IWorkerRankStats {
  confirmedJobsWorked: number | null;
  confirmedDaysWorked: number | null;
  confirmedJobUniqueCompanies: number | null;
  nonConfirmedJobsWorked: number | null;
  nonConfirmedDaysWorked: number | null;
  nonConfirmedJobUniqueCompanies: number | null;
}

export interface IWorkerData {
  id: number;
  address: Address;
  firstName: string;
  lastName: string;
  fullName: string;
  isPremium: boolean;
  profileImage?: Picture;
  rating: number;
  profileImageUrl: string;
  resumeUrl?: string;
  skills: Skill[];
  additionalPictures?: Pictures;
  linkedinUser?: string;
  instagramUser?: string;
  facebookUser?: string;
  cellPhone: string;
  email: string;
  twitterUser?: string;
  willingToTravel: boolean;
  travelLonger?: TravelLonger;
  travelPeriod?: TravelLonger;
  jobsInTHCount: number;
  nonTHJobsCount: number;
  yearsInIndustry: number;
  monthsInIndustry: number;
  heightFeet: number;
  heightInches: number;
  birthday: Date;
  presentationVideoURL?: string;
  features?: WorkerFeature[];
  customUrl?: string;
  gender?: string;
  localMarketPosition?: number;
  localMarketTotal?: number;
  profileCompletedPercentage?: number;
  hasApp?: boolean;
  stats?: IWorkerRankStats;
  experienceLevel?: number;
}

export interface Pictures {
  apiName: string;
  pictures: Picture[];
}

export interface Skill {
  id: number;
  name: string;
}

export interface TravelLonger {
  value: string;
  label: string;
}

export enum TravelPeriods {
  UP_TO_3_MONTHS = 'Up to 3 Months or Less',
  UP_TO_6_MONTHS = 'Up to 6 Months or Less',
  UP_TO_9_MONTHS = 'Up to 9 Months or Less',
  UP_TO_1_YEAR_OR_MORE = 'Up to 1 Year or More',
}

export interface WorkerFeature {
  feature: string;
}
