import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Button, Form, Stack } from 'react-bootstrap';
import { differenceInDays } from 'date-fns';
import { IWorkerData } from 'src/types/worker.type';
import { JobAssignment } from 'src/types/job-assignment.type';
import {
  IRatingVisibility,
  ReviewWorkerSchemaType,
  reviewWorkerValidationSchema,
} from 'src/types/rating.type';
import { rateWorker } from 'src/apps/company-frontend/services/rating/api';
import { StarRatingButton, ThLoading } from 'src/components/elements';
import WorkerActions from 'src/apps/company-frontend/components/WorkerParts/WorkerActions';
import ShowImage from 'src/components/elements/ShowImage';
import defaultAvatar from 'src/assets/default-avatar.png';
import useAlert from 'src/hooks/useAlert';

const COMMENTS_MAX_LENGTH = 300;

interface ReviewWorkerFormProps {
  worker: IWorkerData;
  jobAssignment: JobAssignment;
  onSuccess: () => void;
  isModal?: boolean;
}

function ReviewWorkerForm({
  worker,
  jobAssignment,
  onSuccess,
  isModal = false,
}: ReviewWorkerFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
  } = useForm<ReviewWorkerSchemaType>({
    resolver: zodResolver(reviewWorkerValidationSchema),
    defaultValues: {
      canceledUnexcused: false,
      jobAssignmentId: jobAssignment.id,
      jobId: jobAssignment.job.id,
      noCallNoShowed: false,
      photoIsRepresentative: undefined,
      visibility: IRatingVisibility.PRIVATE,
    },
  });
  const { showWarningAlert, alertMessage } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [internalNotesCount, setInternalNotesCount] = useState<number>(0);
  const [improvementCommentsCount, setImprovementCommentsCount] =
    useState<number>(0);

  const onSubmit: SubmitHandler<ReviewWorkerSchemaType> = async (
    data: ReviewWorkerSchemaType
  ) => {
    try {
      setIsLoading(true);
      const response = await rateWorker(data);
      if (response.status === 200) {
        onSuccess();
      }
    } catch (err: unknown) {
      if (err instanceof Error && 'response' in err) {
        const errorResponse = (err as any).response; // Type assertion to access response safely
        showWarningAlert(errorResponse.data.message);
      } else {
        console.error('Unexpected error:', err);
        showWarningAlert('An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const workerProfileData = worker
    ? {
        worker,
        isBlockedByCompany:
          jobAssignment.companyWorker.isBlockedByCompany ?? false,
        isFavoritedByCompany:
          jobAssignment.companyWorker.isFavoritedByCompany ?? false,
      }
    : null;

  const workedDays =
    differenceInDays(
      new Date(jobAssignment.datesWorked[1]),
      new Date(jobAssignment.datesWorked[0])
    ) + 1;

  if (workedDays) {
    setValue('approximatelyDaysWorked', workedDays);
  }

  return (
    <>
      {isLoading && <ThLoading />}
      <Form
        className={`review-worker ${isModal ? 'is-modal ps-4' : 'p-3'}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack direction="horizontal" gap={3}>
          <ShowImage
            className="avatar rounded cursor-point"
            src={worker.profileImageUrl || defaultAvatar}
            style={{
              width: 60,
              height: 60,
              objectFit: 'cover',
            }}
          />
          <Stack className="py-2">
            <Stack direction="horizontal" gap={2}>
              <span className="fw-bold">{worker.fullName}</span>
              {workerProfileData && (
                <WorkerActions workerProfile={workerProfileData} />
              )}
            </Stack>
          </Stack>

          <Controller
            control={control}
            name="performance"
            render={({ field }) => {
              return (
                <div className="d-inline-flex flex-column">
                  <div className={errors.performance ? 'border-error p-2' : ''}>
                    <StarRatingButton
                      value={field.value}
                      setValue={(val) => field.onChange(Number(val))}
                      hideLabels
                      size={35}
                      gap={3}
                    />
                  </div>
                  {errors.performance && (
                    <span className="rate-error-message">
                      You must rate the worker
                    </span>
                  )}
                </div>
              );
            }}
          />
        </Stack>
        <hr />
        <Stack gap={3}>
          <Controller
            control={control}
            name="photoIsRepresentative"
            render={({ field }) => {
              return (
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="mb-3 justify-content-between"
                >
                  <Form.Label className="w-50">
                    Does this picture look like them?*
                  </Form.Label>
                  <Stack>
                    <div className="w-50">
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        data-testid="check-yes"
                        checked={field.value}
                        onChange={() => field.onChange(true)}
                        isInvalid={!!errors.photoIsRepresentative}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        data-testid="check-no"
                        checked={field.value !== undefined && !field.value}
                        onChange={() => field.onChange(false)}
                        isInvalid={!!errors.photoIsRepresentative}
                      />
                    </div>

                    {errors.photoIsRepresentative && (
                      <span className="rate-error-message">
                        You must select one of these options
                      </span>
                    )}
                  </Stack>
                </Stack>
              );
            }}
          />

          <Stack direction="horizontal" gap={3}>
            {/* if jobAssignment is passed fill job title and days worked  */}
            <Stack className="w-50" gap={2}>
              <Form.Label>Job Title*</Form.Label>
              <Form.Control value={jobAssignment.jobTitle.name} readOnly />
              <Form.Control
                {...register('title.id', { valueAsNumber: true })}
                type="hidden"
                value={jobAssignment.jobTitle.id}
                readOnly
              />
            </Stack>
            <Stack className="w-50" gap={2}>
              <Form.Label>Approx. Days Worked*</Form.Label>
              <Form.Control
                {...register('approximatelyDaysWorked', {
                  valueAsNumber: true,
                })}
                placeholder="Enter # of days"
                className={errors.approximatelyDaysWorked ? 'border-error' : ''}
                readOnly={!!workedDays}
                type="number"
              />
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Form.Label>
              What does this worker do well?{' '}
              <span className="optional-badge text-uppercase small">
                (OPTIONAL)
              </span>
            </Form.Label>
            <Form.Control
              {...register('comments')}
              as="textarea"
              maxLength={COMMENTS_MAX_LENGTH}
              onChange={(e) => setCommentsCount(e.target.value.length || 0)}
              autoComplete="off"
            />
            <Form.Text className="d-flex justify-content-end">
              {commentsCount}/{COMMENTS_MAX_LENGTH}
            </Form.Text>
          </Stack>
          <Stack gap={2}>
            <Form.Label>
              Did the worker do any of the following?{' '}
              <span className="optional-badge text-uppercase small">
                (OPTIONAL)
              </span>
            </Form.Label>
            <Stack direction="horizontal" gap={3}>
              <Controller
                control={control}
                name="noCallNoShowed"
                defaultValue={false}
                render={({ field }) => {
                  const { value, ...rest } = field;

                  return (
                    <div
                      className={`${
                        field.value ? 'active' : ''
                      } worker-behavior fw-bold text-uppercase`}
                      // Let the hook manage the state of the button but change programatically the other button
                      // since it is one or the other, not both at the same time
                      onClick={() => {
                        field.onChange(!field.value);
                        setValue('canceledUnexcused', false);
                      }}
                    >
                      <input
                        type="checkbox"
                        className="invisible"
                        checked={field.value}
                        {...rest}
                      />
                      No Call/No Show
                    </div>
                  );
                }}
              />

              <Controller
                control={control}
                name="canceledUnexcused"
                defaultValue={false}
                render={({ field }) => {
                  const { value, ...rest } = field;

                  return (
                    <div
                      className={`${
                        field.value ? 'active' : ''
                      } worker-behavior fw-bold text-uppercase`}
                      // Let the hook manage the state of the button but change programatically the other button
                      // since it is one or the other, not both at the same time
                      onClick={() => {
                        field.onChange(!field.value);
                        setValue('noCallNoShowed', false);
                      }}
                    >
                      <input
                        type="checkbox"
                        className="invisible"
                        checked={value}
                        {...rest}
                      />
                      Canceled within 24 hours
                    </div>
                  );
                }}
              />
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Form.Label>Optional - What Can Worker Improve On</Form.Label>
            <p className="optional-badge small">
              Share improvements privately with the worker so they can improve,
              <b>or</b> have this appear publicly on the worker profile along
              with your name and company picture.
            </p>
          </Stack>
          <Stack gap={2}>
            <Form.Label>
              What can this worker improve?{' '}
              <span className="optional-badge text-uppercase small">
                (OPTIONAL)
              </span>
            </Form.Label>
            <Form.Control
              {...register('improvementComments')}
              as="textarea"
              maxLength={COMMENTS_MAX_LENGTH}
              onChange={(e) =>
                setImprovementCommentsCount(e.target.value.length || 0)
              }
              autoComplete="off"
            />
            <Form.Text className="d-flex justify-content-end">
              {improvementCommentsCount}/{COMMENTS_MAX_LENGTH}
            </Form.Text>
          </Stack>
          <Stack gap={2}>
            <Controller
              control={control}
              name="visibility"
              render={({ field }) => {
                return (
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input bigger"
                      checked={field.value === IRatingVisibility.PUBLIC}
                      onChange={(e) => {
                        const newValue = e.currentTarget.checked
                          ? IRatingVisibility.PUBLIC
                          : IRatingVisibility.PRIVATE;
                        field.onChange(newValue);
                      }}
                    />
                    {/* eslint-disable jsx-a11y/label-has-associated-control */}
                    <label className="form-check-label fw-bold">
                      Agree to share improvement public with company name and
                      logo
                    </label>
                  </div>
                );
              }}
            />
          </Stack>
          <Stack>
            <hr className="table-horizontal-line" />
          </Stack>
          <Stack gap={2}>
            <Form.Label>
              Internal Note on Worker Profile{' '}
              <span className="optional-badge text-uppercase small">
                (OPTIONAL, ONLY YOUR COMPANY CAN SEE THESE)
              </span>
            </Form.Label>
            <Form.Control
              {...register('internalNotes')}
              as="textarea"
              maxLength={COMMENTS_MAX_LENGTH}
              onChange={(e) =>
                setInternalNotesCount(e.target.value.length || 0)
              }
              autoComplete="off"
            />
            <Form.Text className="d-flex justify-content-end">
              {internalNotesCount}/{COMMENTS_MAX_LENGTH}
            </Form.Text>
          </Stack>
        </Stack>
        <Form.Control
          {...register('jobAssignmentId', { valueAsNumber: true })}
          type="hidden"
          readOnly
          value={jobAssignment.id}
        />
        <Form.Control
          {...register('jobId', { valueAsNumber: true })}
          type="hidden"
          readOnly
          value={jobAssignment.job.id}
        />
        <Form.Control
          {...register('workerId', { valueAsNumber: true })}
          type="hidden"
          readOnly
          value={worker.id}
        />
        <Button
          className="mt-3 me-3 mb-3 float-end"
          type="submit"
          disabled={!isValid}
        >
          Submit Review
        </Button>
      </Form>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default ReviewWorkerForm;
