import { useState } from 'react';
import { Alert, Col, Row, Stack, Button, Modal } from 'react-bootstrap';
import { BsChat } from '@react-icons/all-files/bs/BsChat';
import { FiAlertTriangle } from 'react-icons/fi';
import { isMobile } from 'react-device-detect';
import { MemberAddHoursModal } from 'company/pages/JobOverview/ActivityTracker/MemberAddHoursModal/MemberAddHoursModal';
import { containsInNetworkBadge } from 'company/services/worker/workerUtils';
import * as activityTrackerApi from 'company/services/activityTracker/api';
import BlockButton from 'company/components/WorkerParts/BlockButton';
import { useActivityContext } from 'company/state/activityContext';
import ModalConfirm from 'components/elements/ModalConfirm/ModalConfirm';
import { useChatContext } from 'company/state/chatContext';
import { ThWorkerRow } from 'company/components/th';
import * as jobApi from 'company/services/job/api';
import { CANCEL_SHIFT } from 'services/shift/shiftService';
import { ActivityDetail } from 'types/daily-activity.type';
import IModalConfirmModel from 'types/confirm-model.type';
import { IN_NETWORK_TAG, ITag } from 'types/tag.type';
import { IWorkerData } from 'types/worker.type';
import TooltipOverlay from 'components/elements/Tooltip';
import ThLoading from 'components/elements/ThLoading';
import { useJobAssignmentStatus } from 'hooks/useJobAssignmentStatus';
import useAlert from 'hooks/useAlert';
import ShiftReviewModalContent from './ShiftReviewModalContent';
import { ApproveButton } from './ApproveButton';
import '../styles.scss';

interface ShiftReviewModalProps {
  handleOpenFeedbackModal: () => void;
  openChatAction?: () => void;
  approveTimeSheet: (activityDetail: ActivityDetail) => void;
  activityDetail: ActivityDetail;
  handleClose: () => void;
}

function ShiftReviewModal({
  activityDetail,
  handleOpenFeedbackModal,
  openChatAction,
  approveTimeSheet,
  handleClose,
}: ShiftReviewModalProps) {
  const [showSubmitHoursModal, setShowSubmitHoursModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isBlockedWorker, setIsBlockedWorker] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();
  const [worker] = useState<IWorkerData | undefined>(
    activityDetail.shiftPosition.jobAssignment?.worker
  );
  const jobAssignmentStatus = useJobAssignmentStatus();
  const { setShowReviewShiftModal, refetch } = useActivityContext();
  const { openChat } = useChatContext();
  const handleToggleBlock = (state: boolean) => {
    setIsBlockedWorker(!state);
  };
  const [modalConfirmModel, setModalConfirmModel] =
    useState<IModalConfirmModel>();

  const getTags = () => {
    const tags: ITag[] = [];
    if (worker && containsInNetworkBadge(worker)) {
      tags.push(IN_NETWORK_TAG);
    }
    return tags;
  };

  const jobReviewButtons = [
    { key: 1, icon: '', label: 'BLOCK WORKER' },
    { key: 2, icon: '', label: 'MESSAGE' },
  ];

  const markPaid = async () => {
    try {
      setIsLoadingAction(true);
      let successMessage = '';
      const result = await activityTrackerApi.markShiftsAsPaid(
        activityDetail.shiftPosition.jobId,
        [activityDetail.shiftPosition.id]
      );
      successMessage = result.message;
      if (result.success) {
        await refetch();
        showSuccessAlert(successMessage);
      }
    } catch (err) {
      console.log(`Occurred an error trying to cancel shift`, err);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const cancelShiftAction = async () => {
    if (activityDetail?.shiftPosition) {
      try {
        setIsLoadingAction(true);
        const result = await jobApi.cancelJobShifts(
          activityDetail.shiftPosition.jobId,
          [activityDetail.shiftPosition.id]
        );
        if (result.status === 200) {
          const messageSuccess = result?.data.message;
          await refetch();
          showSuccessAlert(messageSuccess);
          setShowReviewShiftModal(false);
        } else {
          showWarningAlert(result.data.errorMessage);
        }
      } catch (err) {
        console.log(`Occurred an error trying to cancel shift`, err);
      } finally {
        setShowConfirmModal(false);
        setIsLoadingAction(false);
      }
    }
  };

  const openConfirmModal = async () => {
    setModalConfirmModel({
      message: (
        <p className="text-center m-2">
          This action will PERMANENTLY remove the shift data, you won&apos;t be
          able to get it back.
          <br />
          Additionally, the shift will appear open again
        </p>
      ),
      titleMessage: (
        <div>
          <FiAlertTriangle className="pb-1 me-3" size={28} />
          <span>Are you sure?</span>
        </div>
      ),
      confirmFunction: cancelShiftAction,
      show: showConfirmModal,
      styleClass: 'modal-confirm-cancel-shift-style',
    });
    setShowConfirmModal(true);
  };

  const cancelShift = () => {
    openConfirmModal();
  };

  const handleOpenChat = () => {
    if (activityDetail.shiftPosition.jobAssignment && worker) {
      const { isHiredOrApplicant } = jobAssignmentStatus(
        activityDetail.shiftPosition.jobAssignment.status
      );
      openChat(
        worker,
        activityDetail.shiftPosition.jobAssignment.id,
        isHiredOrApplicant()
      );
      if (openChatAction) {
        openChatAction();
      }
    }
  };

  const getApproveButton = () => {
    if (activityDetail.timeSheet?.isApproved) {
      return (
        <TooltipOverlay
          text="Time sheet has already been approved"
          position="bottom"
        >
          <ApproveButton
            approveTimeSheet={approveTimeSheet}
            activityDetail={activityDetail}
          />
        </TooltipOverlay>
      );
    }
    return (
      <ApproveButton
        approveTimeSheet={approveTimeSheet}
        activityDetail={activityDetail}
      />
    );
  };

  const handleSubmitHoursSuccess = (message: string) => {
    showSuccessAlert(message);
    setShowSubmitHoursModal(false);
  };

  return (
    <>
      {!activityDetail || !worker || isLoadingAction ? (
        <ThLoading />
      ) : (
        <Modal
          size="lg"
          show
          scrollable
          centered
          onHide={handleClose}
          className={`modal-shift-review-style ${
            showSubmitHoursModal ? 'faded-modal' : ''
          }`}
          aria-labelledby="shift-review-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold" id="shift-review-title-lg">
              Shift Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={5} className="d-sm-block">
                <ThWorkerRow
                  worker={activityDetail.shiftPosition.jobAssignment!.worker}
                  distance={2}
                  tags={getTags()}
                  actionButtons={isMobile ? jobReviewButtons : []}
                  isPremiumCompany
                />
              </Col>
              <Col className="mt-3 d-none d-sm-block">
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="justify-content-end"
                >
                  <BlockButton
                    workerId={worker.id}
                    isBlockedByDefault={isBlockedWorker}
                    disable={false}
                    className={`${
                      isMobile ? 'mt-2 button-web-style' : ''
                    } rounded-button`}
                    isSmallButton
                    handleToggleState={handleToggleBlock}
                  />
                  <Button
                    size="sm"
                    variant="light"
                    className="fw-bolder"
                    onClick={() => handleOpenChat()}
                  >
                    <BsChat className="mb-1" size={15} />
                    <b>&nbsp;MESSAGE</b>
                  </Button>
                </Stack>
              </Col>
            </Row>
            <ShiftReviewModalContent
              activityDetail={activityDetail}
              markPaid={() => markPaid()}
              setEditSubmittedTime={setShowSubmitHoursModal}
            />
          </Modal.Body>
          <Modal.Footer className="border-top-0 justify-content-between">
            <span>
              <Button
                size={isMobile ? 'sm' : undefined}
                variant="outline-danger fw-bold border-0"
                className="text-start"
                onClick={cancelShift}
              >
                {CANCEL_SHIFT}
              </Button>
            </span>
            <span>
              <Button
                size={isMobile ? 'sm' : undefined}
                variant="light"
                disabled
                onClick={() => {
                  handleOpenFeedbackModal();
                }}
              >
                DIDN&apos;T SHOW
              </Button>
              {getApproveButton()}
            </span>
          </Modal.Footer>
          {showConfirmModal && modalConfirmModel && (
            <ModalConfirm
              modalConfirmModel={modalConfirmModel}
              setShowModal={setShowConfirmModal}
            />
          )}
          <Alert
            show={alertMessage.show}
            variant={alertMessage.variant}
            className="alert-fixed"
            style={{ width: '20rem' }}
          >
            <Alert.Heading>{alertMessage.message}</Alert.Heading>
          </Alert>
        </Modal>
      )}{' '}
      {showSubmitHoursModal && (
        <MemberAddHoursModal
          handleSubmitHoursSuccess={handleSubmitHoursSuccess}
          setShowSubmitHoursModal={setShowSubmitHoursModal}
          activityDetail={activityDetail}
        />
      )}
    </>
  );
}

export default ShiftReviewModal;
