import { privateApi } from 'services/api';
import IResultResponse from 'src/types/resultResponse.type';
import INonTHJobCreation from 'src/apps/worker-frontend/types/non-th-job-creation.type';
import IRateCompanyCreation, {
  IPaymentRateCompanyCreation,
} from '../../types/rate-company-creation.type';
import IRatingRequestCreation from '../../types/rating-request-creation.type';
import { INonThJobAssignmentData } from '../../types/non-th-job-assignment.type';

const ENDPOINT_PATH = '/worker/jobs';

export const saveNonTHJob = async (dataRequest: INonTHJobCreation) => {
  try {
    const result = await privateApi.post<INonThJobAssignmentData>(
      `${ENDPOINT_PATH}/non-th-job?save=true`,
      dataRequest
    );
    return result?.data;
  } catch (error) {
    console.error(
      `Occurred an error when Trusted Herd tried to save non TH job assignment`
    );
    console.error(error);
    return Promise.reject(error);
  }
};

export const rateCompany = async (
  jobAssignmentId: number,
  dataRequest: IRateCompanyCreation
) => {
  const result = await privateApi
    .post<IResultResponse>(
      `${ENDPOINT_PATH}/${jobAssignmentId}/rate-company`,
      dataRequest
    )
    .catch((error) => {
      console.error(
        `Occurred an error when Trusted Herd tried to rate company`
      );
      return error?.response;
    });
  return result?.data;
};

export const updatePaymentRateCompany = async (
  jobAssignmentId: number,
  dataRequest: IPaymentRateCompanyCreation
) => {
  const result = await privateApi
    .put<IResultResponse>(
      `${ENDPOINT_PATH}/${jobAssignmentId}/rate-company-payment`,
      dataRequest
    )
    .catch((error) => {
      console.error(
        `Occurred an error when Trusted Herd tried to rate company`
      );
      console.error(error);
      return error?.response;
    });
  return result?.data;
};

export const ratingRequest = async (
  jobAssignmentId: number,
  dataRequest: IRatingRequestCreation
) => {
  const result = await privateApi
    .post<IResultResponse>(
      `${ENDPOINT_PATH}/${jobAssignmentId}/rating-request`,
      dataRequest
    )
    .catch((error) => {
      console.error(
        `Occurred an error when Trusted Herd tried to rate company`
      );
      console.error(error);
      return error?.response;
    });
  return result?.data;
};
