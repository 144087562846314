import { useMemo, useRef, useState } from 'react';
import { Alert, Badge, Button, Form, Stack } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import { useWorkerProfileContext } from 'src/apps/worker-frontend/state/workerProfileContext';
import { FaRegTrashCan } from 'react-icons/fa6';
import { ThLoading } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import * as api from '../../../../services/worker/api';
import './styles.css';

interface SkillsFormProps {
  onCancel: () => void;
}

function SkillsForm({ onCancel }: SkillsFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [selectedNewSkill, setSelectedNewSkill] =
    useState<SingleValue<{ value: number; label: string }>>();
  const selectRef = useRef(null);
  const { showErrorAlert, alertMessage } = useAlert();
  const { workerSkills, setWorkerSkills, workerProfileAllowedOptions } =
    useWorkerProfileContext();
  const { skills: allowedSkills } = workerProfileAllowedOptions!;

  const availableSkills = useMemo(() => {
    return allowedSkills?.filter((allowedSkill) => {
      return !workerSkills?.some(
        (workerSkill) => workerSkill.id === allowedSkill.id
      );
    });
  }, [allowedSkills, workerSkills]);

  const onRemoveSkill = async (id: number) => {
    setIsLoading(true);
    try {
      const result = await api.deleteSkillFromWorker(id);
      if (result && result.status === 200) {
        const newSkillsList = workerSkills?.filter((skill) => skill.id !== id);
        setWorkerSkills(newSkillsList);
      }
    } catch (error) {
      showErrorAlert('An error ocurred deleting a skill.');
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectNewSkill = async (
    newSkill: SingleValue<{ value: number; label: string }>
  ) => {
    setSelectedNewSkill(newSkill);
    setIsLoading(true);
    try {
      const result = await api.addSkillsToWorker([
        { id: newSkill!.value, name: newSkill!.label },
      ]);
      setWorkerSkills(result.skills);
    } catch (error) {
      showErrorAlert('An error ocurred adding the new skill.');
    } finally {
      setSelectedNewSkill(null);
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      {isLoading && <ThLoading />}
      <div>
        {workerSkills &&
          workerSkills.map((skill) => (
            <span
              key={skill.id}
              className="d-inline-flex align-items-center me-3 mb-2"
            >
              <Badge className="text-dark p-2 text-uppercase me-1" bg="light">
                {skill.name}
              </Badge>
              <FaRegTrashCan
                color="var(--red)"
                size={16}
                className="cursor-point"
                onClick={() => onRemoveSkill(skill.id)}
              />
            </span>
          ))}
      </div>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            <b>New Skill *</b>
          </Form.Label>

          <Select
            ref={selectRef}
            options={availableSkills.map((skill) => ({
              value: skill.id,
              label: skill.name,
            }))}
            closeMenuOnSelect={false}
            onChange={onSelectNewSkill}
            value={selectedNewSkill}
          />
        </Form.Group>
        <Stack direction="horizontal" className="justify-content-end" gap={3}>
          <Button variant="light" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Stack>
  );
}

export default SkillsForm;
