import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import Form from 'react-bootstrap/esm/Form';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { saveNewPaymentMethod } from 'company/services/payment/api';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';

export interface PaymentFormParams {
  setPaymentMethodId: (paymentMethodId: string) => void;
  setCreditCardValidData: (isValid: boolean) => void;
  showSaveButton?: boolean;
}

export interface PaymentFormRef {
  handleSubmit: (e: React.FormEvent) => Promise<number>;
}

export const PaymentForm = forwardRef<PaymentFormRef, PaymentFormParams>(
  (
    { setPaymentMethodId, setCreditCardValidData, showSaveButton = false },
    ref
  ) => {
    const [cardName, setCardName] = useState('');
    const [validCardNumber, setValidCardNumber] = useState(false);
    const [validCvc, setValidCvc] = useState(false);
    const [validExpirationDate, setValidExpirationDate] = useState(false);

    useEffect(() => {
      setCreditCardValidData(
        validCardNumber && validCvc && validExpirationDate && cardName !== ''
      );
    }, [
      cardName,
      setCreditCardValidData,
      validCardNumber,
      validCvc,
      validExpirationDate,
    ]);

    const stripe = useStripe();
    const elements = useElements();

    const options = useMemo(
      () => ({
        style: {
          base: {
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Roboto, Source Code Pro, monospace, SFUIDisplay',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
        showIcon: true,
      }),
      []
    );

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();

      // Check if Stripe and elements are still available
      if (!stripe || !elements) {
        console.error('Stripe or elements are not available');
        return -1;
      }

      let errorResponse: Error | StripeError | undefined;
      let createdPaymentMethod: PaymentMethod | undefined;
      try {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement)!,
          billing_details: { name: cardName },
        });
        createdPaymentMethod = paymentMethod;
        errorResponse = error;
      } catch (ex: any) {
        console.error(ex.message);
        errorResponse = ex;
      }

      if (errorResponse) {
        throw new Error(errorResponse.message);
      }
      if (createdPaymentMethod) {
        const response = await saveNewPaymentMethod(createdPaymentMethod.id);
        if (response.success) {
          setPaymentMethodId(createdPaymentMethod.id);
        } else {
          throw new Error(response.message);
        }
        return response.data.cardId;
      }
      return 0;
    };

    useImperativeHandle(ref, () => ({
      async handleSubmit(e: React.FormEvent) {
        return handleSubmit(e);
      },
    }));

    return (
      <Form id="offer-form" onSubmit={(e) => handleSubmit(e)}>
        <Stack gap={3}>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Name on card: </Form.Label>
              <Form.Control
                type="text"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <p>Card Number: </p>
            <CardNumberElement
              onChange={(e) => setValidCardNumber(!e.error && !e.empty)}
              options={options}
            />
          </Row>
          <Row>
            <Col>
              <p>Expiration date: </p>
              <CardExpiryElement
                onChange={(e) => setValidExpirationDate(!e.error && !e.empty)}
                options={options}
              />
            </Col>
            <Col>
              <p>CVC: </p>
              <CardCvcElement
                onChange={(e) => setValidCvc(!e.error && !e.empty)}
                options={options}
              />
            </Col>
          </Row>
          {showSaveButton && (
            <Button className="mt-3" type="submit" disabled={!stripe}>
              Save Credit Card
            </Button>
          )}
        </Stack>
      </Form>
    );
  }
);

PaymentForm.displayName = 'PaymentForm';
