import { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Alert, Button, Stack } from 'react-bootstrap';
import { FiUpload } from 'react-icons/fi';
import axios from 'axios';
import { FolderDesignationEnum } from 'src/types/uploadImage.type';
import { getS3PreSignedUrl } from 'src/services/api/uploadImagesApi';
import useAlert from 'src/hooks/useAlert';
import ThLoading from '../ThLoading';

import './styles.css';

interface DragAndDropProps {
  supportedFiles: string[];
  maxSize: number;
  entityId: string | undefined;
  onFilesSelected: (path: string, name: string, contentType: string) => void;
}

function DragAndDrop({
  supportedFiles,
  maxSize,
  entityId,
  onFilesSelected,
}: DragAndDropProps) {
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { showErrorAlert, alertMessage } = useAlert();

  const chooseFile = () => {
    inputRef.current!.click();
  };

  const isFileValidToUpload = (file: File) => {
    if (!supportedFiles.includes(file.type.split('/')[1])) {
      showErrorAlert('File type not supported.');
      return false;
    }
    if (file.size > maxSize * 1000000) {
      showErrorAlert('File is too big.');
      return false;
    }
    return true;
  };

  const uploadMediaFile = async (file: File) => {
    if (!isFileValidToUpload(file)) {
      setIsLoading(false);
      return;
    }
    try {
      const s3PreSignedResponse = await getS3PreSignedUrl(
        file.name,
        file.type,
        FolderDesignationEnum.PERSON_ADDITIONAL_PICTURES,
        entityId
      );

      axios
        .put(s3PreSignedResponse.preSignedUrl, file, {
          headers: {
            'Content-type': file.type,
            'x-amz-acl': 'public-read',
          },
        })
        .then(() => {
          setIsLoading(false);
          onFilesSelected(s3PreSignedResponse.s3Key, file.name, file.type);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
      showErrorAlert('An error ocurred uploading the image');
      setIsLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setIsLoading(true);
      const newFile = Array.from(selectedFiles)[0];
      uploadMediaFile(newFile as File);

      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 1) {
      showErrorAlert('Upload one file at a time.');
      setIsLoading(false);
      return;
    }
    if (droppedFiles.length === 1) {
      const newFile = Array.from(droppedFiles)[0];
      uploadMediaFile(newFile);
    }
  };

  const getSupportedFileExtensions = () =>
    supportedFiles.map((extension) => `.${extension}`).join(', ');

  return (
    <Stack
      gap={2}
      className="drag-n-drop-comp"
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
    >
      {isLoading && <ThLoading />}

      <FiUpload size={24} className="mb-2" />
      <b>{isMobile ? 'Choose file to upload' : 'Drag and Drop file here'}</b>
      {supportedFiles?.length && (
        <div className="fw-light">
          Supported files:{' '}
          <span className="text-uppercase">{supportedFiles.join(', ')}</span>
        </div>
      )}
      {maxSize && <div className="fw-light">Maximum size: {maxSize}MB</div>}

      <input
        type="file"
        accept={getSupportedFileExtensions()}
        hidden
        ref={inputRef}
        onChange={handleFileChange}
      />
      <Button
        className="text-uppercase mt-2"
        variant="light"
        onClick={chooseFile}
      >
        Choose File
      </Button>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Stack>
  );
}

export default DragAndDrop;
