import { useMemo, useRef } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { HiArrowLeft } from 'react-icons/hi';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ThLoading } from 'components/elements';
import ComHubReviewWorkerForm from 'src/apps/company-frontend/components/CommunicationHub/Modals/ReviewRequestModal/ComHubReviewWorkerForm';
import ComHubGigInfoPreview from 'company/components/CommunicationHub/ComHubGigInfoPreview/ComHubGigInfoPreview';
import ReceivedReview from 'company/components/ReviewSidePopup/ReceivedReview/ReceivedReview';
import { JobAssignmentRatingType } from 'company/types/job-assignment-rating.type';
import { getGivenWorkerRating } from 'company/services/rating/api';
import { useCompanyContext } from 'company/state/companyContext';
import useAvatarImageFallback from 'hooks/useAvatarImageFallback';
import { formatWorkDateRange } from 'utils/DateUtils';
import DATE_FORMATS from 'constants/dateFormat';
import QueryKeys from 'constants/queryKeys';

import '../styles.css';

interface Props {
  jobAssignmentId: number;
  closeModal: () => void;
}

function GivenReviewModal({ jobAssignmentId, closeModal }: Props) {
  const { name } = useCompanyContext();

  const avatarRef = useRef(null);
  useAvatarImageFallback(avatarRef);

  const queryKey = `${QueryKeys.COMPANY_RATING}_${jobAssignmentId}`;

  const { data: ratingAssignmentData, isLoading } = useQuery<
    JobAssignmentRatingType,
    AxiosError
  >({
    queryKey: [queryKey],
    queryFn: () => getGivenWorkerRating(jobAssignmentId),
    enabled: jobAssignmentId > 0,
  });

  const gigInfoPreviewData = useMemo(
    () =>
      ratingAssignmentData && ratingAssignmentData.worker
        ? {
            brandRepresented: ratingAssignmentData.brandRepresented,
            companyName: ratingAssignmentData.companyName || name || '',
            eventName: ratingAssignmentData.eventName,
            jobTitle: ratingAssignmentData.jobTitle.name,
            workerName: ratingAssignmentData.worker.fullName,
            workedDays:
              ratingAssignmentData?.workedDates?.length > 1
                ? formatWorkDateRange(
                    new Date(ratingAssignmentData.workedDates[0]),
                    new Date(ratingAssignmentData.workedDates[1]),
                    DATE_FORMATS.DATE_FORMAT
                  )
                : 'n/a',
          }
        : {
            brandRepresented: '',
            companyName: '',
            eventName: '',
            jobTitle: '',
            workerName: '',
            workedDays: '',
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ratingAssignmentData]
  );

  return (
    <Stack>
      <Stack
        direction="horizontal"
        gap={2}
        className="align-items-center p-3 cursor-point fw-bold"
        onClick={closeModal}
      >
        <HiArrowLeft size={20} />
        GO BACK
      </Stack>
      <span className="mb-3 fw-bold fs-5 received-review-title">
        Left Review For Worker
      </span>
      {isLoading && <ThLoading />}
      {ratingAssignmentData && (
        <Row>
          <Col md={6}>
            <Stack gap={3}>
              <ReceivedReview
                rating={ratingAssignmentData}
                workedDates={ratingAssignmentData.workedDates}
                worker={ratingAssignmentData.worker!}
                showSpanTitle={false}
              />
              <ComHubGigInfoPreview gigInfoPreview={gigInfoPreviewData} />
            </Stack>
          </Col>
          <Col md={6}>
            <ComHubReviewWorkerForm
              ratingAssignmentData={ratingAssignmentData}
              onSuccess={closeModal}
              isReadOnly
            />
          </Col>
        </Row>
      )}
    </Stack>
  );
}

export default GivenReviewModal;
