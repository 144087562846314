import trustedHerdLogo from 'src/assets/trusted-herd-logo.svg';
import { Figure, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useUserContext } from 'src/state/userContext';
import { RiArrowDownSFill } from 'react-icons/ri';

import './styles.css';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;
const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

/**
 * Use this duplicated component on crashes to avoid providing the worker context, which is needed for the survey link
 * Refactor later if needed
 * @constructor
 */
export default function THNavbarOnError() {
  const { name, profileImageUrl } = useUserContext();
  const location = useLocation();

  return (
    <Navbar
      bg="dark"
      variant="dark"
      className="header"
      collapseOnSelect
      expand="lg"
    >
      <Navbar.Brand href={SERVER_URL}>
        <img src={trustedHerdLogo} alt="Trusted Herd Logo" />
        <strong className="fs-5 ms-3">TRUSTED HERD</strong>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          <Nav.Link
            eventKey={`${SERVER_URL}/dashboard/workerDashboard`}
            href={`${SERVER_URL}/dashboard/workerDashboard`}
            className="link"
          >
            Dash
          </Nav.Link>

          <Nav.Link
            eventKey={`${SERVER_URL}/publicJobs/index`}
            href={`${SERVER_URL}/publicJobs/index`}
            className="link"
          >
            Jobs
          </Nav.Link>

          <Nav.Link
            eventKey={`${SERVER_URL}/searchCompanies/index`}
            href={`${SERVER_URL}/searchCompanies/index`}
            className="link"
          >
            Search Companies
          </Nav.Link>
          <Nav.Item className="link p-0">
            <NavDropdown
              as="div"
              title={
                <>
                  <span style={{ color: 'white', fontSize: '1.2rem' }}>
                    My Calendar
                  </span>
                  <RiArrowDownSFill className="ms-1" size={20} />
                </>
              }
            >
              <NavDropdown.Item href={`${SERVER_URL}/workerJobs/upcomingJobs`}>
                My Jobs
              </NavDropdown.Item>
              <NavDropdown.Item href={`${SERVER_URL}/workerJobs/pendingJobs`}>
                Pending Jobs
              </NavDropdown.Item>
              <NavDropdown.Item href={`${SERVER_URL}/workerJobs/jobHistory`}>
                Job History
              </NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Link
            eventKey={`${BASE_PATH}workerProfile/view`}
            href={`${BASE_PATH}workerProfile/view`}
            className={`link ${
              location.pathname === `${BASE_PATH}workerProfile/view`
                ? 'active'
                : ''
            }`}
          >
            My Profile
          </Nav.Link>
        </Nav>

        <Nav className="ms-auto">
          <Nav.Item className="link p-0">
            <NavDropdown
              title={
                <>
                  <span style={{ color: 'white', fontSize: '1.2rem' }}>
                    {name}
                  </span>
                  <Figure.Image
                    className="avatar rounded-circle"
                    src={profileImageUrl}
                  />
                  <RiArrowDownSFill className="ms-1" size={20} />
                </>
              }
            >
              <NavDropdown.Item href={`${SERVER_URL}/workerAccount`}>
                Account Settings
              </NavDropdown.Item>
              <NavDropdown.Item href={`${BASE_PATH}workerProfile/view`}>
                My Profile
              </NavDropdown.Item>
              <NavDropdown.Item href={`${SERVER_URL}/helpUsImprove/index`}>
                Help Us Improve
              </NavDropdown.Item>
              <NavDropdown.Item href={`${SERVER_URL}/api/auth/logout`}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
