export const containsDuplicates = (
  listOne: any[],
  listTwo: any[],
  fieldToCheck = null
) => {
  let listToCheckOne: any[];
  let listToCheckTwo: any[];
  if (fieldToCheck !== null) {
    listToCheckOne = listOne.map((listItem) => listItem[fieldToCheck]);
    listToCheckTwo = listTwo.map((listItem) => listItem[fieldToCheck]);
  } else {
    listToCheckOne = listOne;
    listToCheckTwo = listTwo;
  }
  return listToCheckOne.some((listItem) => listToCheckTwo.includes(listItem));
};
