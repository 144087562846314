import { useRef } from 'react';
import { Button, Image, ListGroup, Stack } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import useAvatarImageFallback from 'src/hooks/useAvatarImageFallback';
import { ImPhone } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { IWorkerData } from 'src/types/worker.type';
import { FiUser } from 'react-icons/fi';
import { FaLocationDot } from 'react-icons/fa6';
import { FaTicketAlt } from 'react-icons/fa';

import './styles.css';

interface ChatHeaderProps {
  worker: IWorkerData;
  showWorkerInfo: boolean;
  onViewProfile?: () => void;
  jobInfo?: {
    eventName: string;
    address: string;
    jobPosition: string;
  };
}

function ChatHeader({
  onViewProfile,
  worker,
  showWorkerInfo,
  jobInfo,
}: ChatHeaderProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  // hook to use default avatar image if the src doesn't load
  useAvatarImageFallback(imageRef);

  return (
    <Stack direction="horizontal" className="chat-header">
      <Image
        ref={imageRef}
        src={worker.profileImageUrl}
        roundedCircle
        alt="Profile Image"
        className={isMobile ? 'mobile-img' : ''}
      />
      <ListGroup className="worker-info align-items-start truncate-text">
        <ListGroup.Item className="worker-fullname" onClick={onViewProfile}>
          {worker?.fullName || `${worker.firstName} ${worker.lastName}`}
        </ListGroup.Item>
        {showWorkerInfo && (
          <>
            <ListGroup.Item className="worker-phone">
              <ImPhone size={12} />
              {worker?.cellPhone}
            </ListGroup.Item>
            <ListGroup.Item className="worker-email">
              <MdEmail />
              {worker?.email}
            </ListGroup.Item>
          </>
        )}
        {jobInfo && (
          <>
            <ListGroup.Item className="job-info-event-name">
              <FaLocationDot />
              {jobInfo.eventName}
            </ListGroup.Item>
            <ListGroup.Item className="job-info-address">
              <FaTicketAlt />
              {jobInfo.address}
            </ListGroup.Item>
          </>
        )}
      </ListGroup>

      <div className="header-actions ms-auto">
        <Button variant="light" onClick={onViewProfile}>
          {isMobile ? <FiUser size={20} /> : <b>VIEW PROFILE</b>}
        </Button>
        {/* VIDEO CHAT BUTTON IS IN DESIGN BUT NOT SUPPORTED YET */}
        {/* <Button variant="light">VIDEO CHAT</Button> */}
      </div>
    </Stack>
  );
}

export default ChatHeader;
