import { useState } from 'react';
import { TiThMenuOutline } from 'react-icons/ti';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BiSearch } from '@react-icons/all-files/bi/BiSearch';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Stack,
} from 'react-bootstrap';
import { ThError } from 'components/elements';
import ThLoading from 'components/elements/ThLoading';
import QueryKeys from 'src/constants/queryKeys';
import IJobsData from 'src/types/jobs.type';
import * as api from 'src/apps/company-frontend/services/job/api';
import './style.css';
import ThDateRangePicker from 'components/elements/Datepicker';
import { debounce } from 'lodash';
import { JobCompletedEnum } from 'src/constants/jobCompletedEnum';
import JobTable from '../JobTable';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

export default function Manager() {
  const [type, setType] = useState(JobCompletedEnum.ACTIVE);
  const [showGrouped, setShowGrouped] = useState(false);
  const [quickFiltersList] = useState([
    { key: 1, value: 'Company Jobs (20)' },
    { key: 2, value: 'Open Shifts (15)' },
    { key: 3, value: 'New Applicants (5)' },
    { key: 4, value: 'Approve (5)' },
    { key: 5, value: 'No Check In/Out (5)' },
    { key: 6, value: 'Missing Paperwork (3)' },
    { key: 7, value: 'Missing Hours (15)' },
    { key: 8, value: 'Missing Payment (3)' },
    { key: 9, value: 'Missing Rating (5)' },
    { key: 10, value: 'Missing Paperwork(3)' },
    { key: 11, value: 'Hidden Jobs (1)' },
    { key: 12, value: 'Jobs to Complete (3)' },
  ]);

  const { isLoading, error, data } = useQuery<IJobsData, AxiosError>({
    queryKey: [`${QueryKeys.COMPANY_JOBS}`],
    queryFn: () => api.fetchJobList(0, 100),
  });

  if (isLoading) return <ThLoading />;

  const quickFilterClick = (quickFilterName: any) => {
    // TODO: Add event
    console.log(quickFilterName);
  };

  if (error) {
    if (error.response?.status) {
      return <ThError message="No data found" />;
    }
    return <ThError message={error.message} />;
  }

  const handleActiveButton = () => {
    setType(JobCompletedEnum.ACTIVE);
  };

  const handleCompletedButton = () => {
    setType(JobCompletedEnum.COMPLETED);
  };

  const handleTextSearch = debounce(() => {
    // TODO: implement me
  }, 1000);

  return (
    <div>
      <Row className="m-3">
        <Col xs={9} md={2} className="mb-3">
          <Button
            variant={
              type === JobCompletedEnum.ACTIVE ? 'primary' : 'outline-dark'
            }
            className={isMobile ? 'btn-sm' : ''}
            onClick={() => handleActiveButton()}
          >
            &nbsp;ACTIVE
          </Button>
          <Button
            variant={
              type === JobCompletedEnum.COMPLETED ? 'primary' : 'outline-dark'
            }
            className={isMobile ? 'btn-sm' : ''}
            onClick={() => handleCompletedButton()}
          >
            COMPLETED
          </Button>
        </Col>
        <Col xs={3} md={1}>
          <Button
            variant={!showGrouped ? 'light' : 'outline-dark'}
            onClick={() => setShowGrouped(false)}
            className={isMobile ? 'btn-sm' : ''}
          >
            <TfiMenuAlt />
          </Button>
          <Button
            variant={showGrouped ? 'light' : 'outline-dark'}
            onClick={() => setShowGrouped(true)}
            className={isMobile ? 'btn-sm' : ''}
          >
            <TiThMenuOutline className="menu-icon" />
          </Button>
        </Col>

        <Col xs={12} sm={6} md={3} className="mb-3">
          <InputGroup>
            <InputGroup.Text>
              <BiSearch />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              className="search-field-style"
              name="searchField"
              onChange={() => handleTextSearch()}
            />
          </InputGroup>
        </Col>
        <Col xs={6} sm={6} md={4}>
          <Stack direction={isMobile ? 'vertical' : 'horizontal'}>
            <Form.Label className="d-md-block d-xs-block me-2">
              DATE RANGE
            </Form.Label>
            <ThDateRangePicker
              setStart={() => {}}
              setEnd={() => {}}
              placeholder="Select Date Range"
            />
          </Stack>
        </Col>
        <Col xs={2} className={isMobile ? 'd-none text-end' : 'text-end'}>
          <a href={`${SERVER_URL}/job/create`} className="link">
            <Button variant="primary">&nbsp;Create Job</Button>
          </a>
        </Col>

        <Row className="d-none d-sm-block m-4">
          <Stack direction="horizontal">
            <Col xs={2} className="quick-filters-label">
              <Form.Label>QUICK FILTERS</Form.Label>
            </Col>
            <Col xs={10} className="badges-column">
              <Container>
                {quickFiltersList.map((quickFilterName) => (
                  <Badge
                    className="me-1"
                    pill
                    bg="light"
                    text="dark"
                    key={quickFilterName.key}
                    onClick={() => quickFilterClick(quickFilterName)}
                  >
                    {quickFilterName.value}
                  </Badge>
                ))}
              </Container>
            </Col>
          </Stack>
        </Row>

        <Col xs={6} className=" d-block d-sm-none">
          <Form.Label className="d-md-block d-xs-block me-2">
            QUICK FILTERS
          </Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant="light">Select filters</Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              {quickFiltersList.map((quickFilterName) => (
                <Dropdown.Item
                  key={1}
                  onClick={() => quickFilterClick(quickFilterName)}
                >
                  {quickFilterName.value}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <div>
          <JobTable
            jobList={data!.results}
            type={type}
            showGrouped={showGrouped}
          />
        </div>
      </Row>
      <Row className="d-block d-sm-none m-3">
        <a href={`${SERVER_URL}/job/create`} className="link">
          <Button variant="primary">&nbsp;Create Job</Button>
        </a>
      </Row>
    </div>
  );
}
