import { useCallback, useEffect, useState } from 'react';
import * as api from 'src/apps/company-frontend/services/chat/api';
import { useQuery } from '@tanstack/react-query';
import QueryKeys from 'src/constants/queryKeys';
import IConversationData, {
  IConversationList,
} from 'src/apps/company-frontend/types/conversation.type';
import { AxiosError } from 'axios';
import { ThError, ThLoading } from 'components/elements';
import { isMobile } from 'react-device-detect';
import { Card, Col, Row } from 'react-bootstrap';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { MINUTE } from 'src/constants/various';
import Conversations from './Conversations';
import ConversationsMobile from './ConversationsMobile';

interface Params {
  setMessageUnreadCount: (value: number) => void;
}

export default function Messaging({ setMessageUnreadCount }: Params) {
  const { job } = useJobContext();
  const [refetchInterval, setRefetchInterval] = useState(MINUTE * 15);
  const [selectedConversation, setSelectedConversation] =
    useState<IConversationData | null>(null);
  const [workerNameFilter, setWorkerNameFilter] = useState<string | null>(null);

  const fetchConversationsFn = useCallback(async () => {
    if (!job?.id) {
      return new Promise<IConversationList>((resolve) => {
        const emptyResponse: IConversationList = { results: [], total: 0 };
        resolve(emptyResponse);
      });
    }

    return await api.fetchConversations(job?.id, workerNameFilter);
  }, [job?.id, workerNameFilter]);

  const {
    isLoading,
    isFetching,
    error,
    data,
    refetch: fetchConversations,
  } = useQuery<IConversationList, AxiosError>({
    queryKey: [`${QueryKeys.MESSAGE_CONVERSATIONS}_${job?.id}`, job?.id], // add job.id as dependency
    queryFn: fetchConversationsFn,
    enabled: job?.id !== undefined,
    refetchInterval,
    refetchIntervalInBackground: true,
    retry: (failureCount) => failureCount < 3,
    retryDelay: MINUTE / 2,
  });

  const { setChatAssignmentId } = useChatContext();

  useEffect(() => {
    if (error) {
      setRefetchInterval(0);
    }
  }, [error]);

  const handleMessageUnreadCount = useCallback(async () => {
    if (data) {
      const totalSum = data.results.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.unreadCount;
      }, 0);
      setMessageUnreadCount(totalSum);
    }
  }, [data, setMessageUnreadCount]);

  useEffect(() => {
    handleMessageUnreadCount();
  }, [data, handleMessageUnreadCount]);

  useEffect(() => {
    if (job?.id) {
      fetchConversations();
    }
  }, [workerNameFilter, fetchConversations, job?.id]);

  if (error) {
    if (error.response?.status) {
      return <>No data found</>;
    }
    return <ThError message={error.message} />;
  }

  const handleReadConversation = (conversation: IConversationData) => {
    setSelectedConversation(conversation);
    setChatAssignmentId(conversation.jobAssignmentId);

    const index = data?.results.findIndex(
      (conv) => conv.jobAssignmentId === conversation.jobAssignmentId
    );
    if (data && index) {
      data.results[index].unreadCount = 0;
    }
    handleMessageUnreadCount();
    api.readMessages(conversation.jobAssignmentId);
  };

  if (data && data.total === 0) {
    return (
      <>
        <Row>
          <Col>
            <b>Messaging</b>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <Card className="p-3 rounded-4">
              Chat with applicants, workers you&apos;ve hired, and anyone you
              shared the job with. All started conversations will appear here.
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div>
      {isFetching && <ThLoading />}
      {isMobile ? (
        <ConversationsMobile
          selectedConversation={selectedConversation}
          setSelectedConversation={handleReadConversation}
          setWorkerNameFilter={setWorkerNameFilter}
          conversations={data?.results}
          isLoading={isLoading}
          refetchConversations={fetchConversations}
        />
      ) : (
        <Conversations
          selectedConversation={selectedConversation}
          setSelectedConversation={handleReadConversation}
          setWorkerNameFilter={setWorkerNameFilter}
          conversations={data?.results}
          isLoading={isLoading}
          refetchConversations={fetchConversations}
        />
      )}
    </div>
  );
}
