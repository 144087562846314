import { privateApi } from 'services/api';
import {
  IProfileData,
  ISurveyLink,
  SurveyHistoryResponse,
} from '../../types/surveys.type';

export const WORKER_SURVEY_URL = 'worker/surveys/link';
export const WORKER_PROFILE_URL = 'worker/surveys/profile-data';
export const WORKER_SURVEY_HISTORY_URL = 'worker/surveys/history';
export const WORKER_SURVEY_REDEEM_URL = 'worker/surveys/redeem';

export const getSurveyLink = async () => {
  try {
    const result = await privateApi.get<ISurveyLink>(WORKER_SURVEY_URL);
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProfileData = async () => {
  try {
    const result = await privateApi.get<IProfileData>(WORKER_PROFILE_URL);
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWorkerSurveyHistory = async (
  page: number,
  pageSize: number
) => {
  try {
    const result = await privateApi.get(
      `${WORKER_SURVEY_HISTORY_URL}?page=${page}&pageSize=${pageSize}`
    );

    return {
      surveys: result.data.results,
      pagination: result.data.pagination,
    } as SurveyHistoryResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPrezzeeOrder = async (
  amount: number,
  name: string,
  email: string | null
) => {
  try {
    console.log('createPrezzeeOrder', amount, name, email);
    const result = await privateApi.post(WORKER_SURVEY_REDEEM_URL, {
      amount,
      name,
      email,
    });
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
