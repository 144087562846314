import {
  JobPlacementHistory,
  JobPlacementStatus,
} from 'src/apps/company-frontend/types/job-placement.type';
import { formatLocalDate } from 'src/utils/DateUtils';
import DATE_FORMATS from 'src/constants/dateFormat';
import { Panel } from 'components/elements';
import { format } from 'date-fns';
import IJobDetailData from 'src/types/job.detail.type';

interface JobWorkerShiftsHistoryProps {
  jobPlacementHistoryList: JobPlacementHistory[];
  job: IJobDetailData;
}

export default function JobWorkerShiftsHistory({
  jobPlacementHistoryList,
  job,
}: JobWorkerShiftsHistoryProps) {
  const getShiftOffered = (historyItem: JobPlacementHistory) => {
    let companyAction;
    switch (historyItem.status) {
      case JobPlacementStatus.OFFERED:
        companyAction =
          historyItem.shiftItems !== undefined
            ? 'offered shifts'
            : 'sent offer';
        break;
      case JobPlacementStatus.OFFER_UPDATED:
        companyAction = 'updated offer';
        break;
      case JobPlacementStatus.SHIFTS_UPDATED:
        companyAction = 'updated offered shifts';
        break;
      case JobPlacementStatus.ACCEPTED:
        companyAction = 'accepted offer';
        break;
      default:
        return null;
    }

    return (
      <div>
        {historyItem.updatedBy} {companyAction} at{' '}
        {format(new Date(historyItem.dateCreated), DATE_FORMATS.TIME_FORMAT)}
        {historyItem.shiftItems
          ?.sort(
            (a, b) =>
              new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
          )
          .map((shiftItem) => {
            return (
              <div className="ms-1" key={shiftItem.id}>
                <p className="mt-1">
                  Shift Date:{' '}
                  <b>
                    {format(
                      new Date(shiftItem.startTime),
                      DATE_FORMATS.DATE_FORMAT
                    )}
                    {', '}
                  </b>
                  <b>
                    {formatLocalDate(
                      new Date(shiftItem.startTime),
                      DATE_FORMATS.TIME_FORMAT,
                      job.address?.timezoneId
                    )}
                  </b>{' '}
                  -{' '}
                  <b>
                    {formatLocalDate(
                      new Date(shiftItem.endTime),
                      DATE_FORMATS.TIME_FORMAT,
                      job.address?.timezoneId
                    )}
                  </b>
                  <br />
                  Pay rate: $
                  <b>
                    {`${shiftItem.payRate}/${shiftItem.payRatePeriod
                      .toString()
                      .toLowerCase()}`}
                  </b>
                </p>
              </div>
            );
          })}
      </div>
    );
  };

  const getCanceledShiftOffer = (historyItem: JobPlacementHistory) => {
    let companyAction;
    switch (historyItem.status) {
      case JobPlacementStatus.CANCELLED:
        companyAction = `${historyItem.updatedBy} canceled all shifts`;
        break;
      case JobPlacementStatus.DECLINED:
        companyAction = `${historyItem.updatedBy} declined offer`;
        break;
      case JobPlacementStatus.DECLINED_ADDITIONAL:
        companyAction = `${historyItem.updatedBy} declined additional offer`;
        break;
      default:
        return null;
    }
    return (
      <div>
        {companyAction} at{' '}
        {format(new Date(historyItem.dateCreated), DATE_FORMATS.TIME_FORMAT)}
      </div>
    );
  };

  return (
    <Panel>
      <p className="pb-2">
        <b>Job shifts history</b>
      </p>
      {jobPlacementHistoryList.map((history) => (
        <div key={history.id + Math.random()}>
          <p>
            <b>
              {format(new Date(history.dateCreated), DATE_FORMATS.DATE_FORMAT)}
            </b>
          </p>
          {[
            JobPlacementStatus.OFFERED,
            JobPlacementStatus.ACCEPTED,
            JobPlacementStatus.OFFER_UPDATED,
            JobPlacementStatus.SHIFTS_UPDATED,
          ].includes(history.status) && getShiftOffered(history)}
          {[
            JobPlacementStatus.CANCELLED,
            JobPlacementStatus.DECLINED,
            JobPlacementStatus.DECLINED_ADDITIONAL,
          ].includes(history.status) && getCanceledShiftOffer(history)}
          <hr />
        </div>
      ))}
    </Panel>
  );
}
