import { useJobWorkerShiftContext } from 'src/apps/company-frontend/state/jobWorkerShiftContext';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown';
import { getFormattedTime } from 'src/services/shift/shiftService';
import { ShiftPosition } from 'src/types/job.detail.type';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import DATE_FORMAT from 'src/constants/dateFormat';
import { toNatural } from 'src/utils/StringUtils';
import { Fragment, useId, useMemo, useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import Table from 'react-bootstrap/Table';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import './styles.scss';
import {
  hasSameShiftAssigned,
  removeDuplicatedShifts,
} from 'src/utils/ShiftsUtils';
import { ShiftStatus } from 'types/jobs.type';
import { useJobShiftContext } from 'company/state/jobShiftContext';

interface ShiftTableMobileProps {
  date: string;
  shifts: ShiftPosition[];
  showResumed?: boolean;
  handleSelecting: (id: number) => void;
}

function JobWorkerShiftTableMobile({
  date,
  shifts,
  showResumed,
  handleSelecting,
}: ShiftTableMobileProps) {
  const { shifts: allShifts, selectedWorker } = useJobShiftContext();
  const { selectedJobWorkerShifts: selectedShifts } =
    useJobWorkerShiftContext();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [expandState, setExpandState] = useState<Record<number, boolean>>({});
  const { job } = useJobContext();

  const handleExpandRow = (shiftId: number) => {
    const isRowExpanded = expandedRows.includes(shiftId);
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter((id) => id !== shiftId)
      : expandedRows.concat(shiftId);

    const expands: { [key: number]: boolean } = {};
    if (isRowExpanded) {
      expands[shiftId] = false;
    } else {
      expands[shiftId] = true;
    }
    setExpandState(expands);
    setExpandedRows(newExpandedRows);
  };
  const divKey = useId();
  const jobKey = useId();

  const uniqueShifts = useMemo(() => {
    const uniqueFilteredShifts = removeDuplicatedShifts(shifts);
    if (
      uniqueFilteredShifts?.some(
        (shift) => shift.shiftStatus === ShiftStatus.OPEN
      ) &&
      selectedWorker &&
      allShifts
    ) {
      return uniqueFilteredShifts.filter(
        (shift) => !hasSameShiftAssigned(selectedWorker, shift, allShifts)
      );
    }
    return uniqueFilteredShifts;
  }, [allShifts, selectedWorker, shifts]);

  if (!job) {
    return null;
  }

  if (uniqueShifts.length === 0) {
    return null;
  }

  return (
    <div key={divKey}>
      <span className="fw-bold fs-6">
        {format(new Date(date), DATE_FORMAT.LIST_BY_DATE_FORMAT)}
      </span>
      <hr className="table-horizontal-line my-2" />

      <Table borderless>
        <thead className="table-header fw-bold">
          <tr>
            <th aria-label="checkbox" />
            <th className="text-start">Position</th>
            {!showResumed && <th>Status</th>}
            <th aria-label="edit" />
          </tr>
        </thead>
        <tbody className="table-body">
          {shifts?.map((shift) => (
            <Fragment key={`${jobKey}-${shift.id}-${shift.positionIndex}`}>
              <tr>
                <td className="p-0 align-middle">
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${shift.id}`}
                    checked={
                      selectedShifts &&
                      selectedShifts.some((x) => x.id === shift.id)
                    }
                    onChange={() => handleSelecting(shift.id)}
                  />
                </td>
                <td className="text-start align-middle">{shift.title.name}</td>
                {!showResumed && (
                  <td
                    className={`fw-bold status_colors status_colors__${shift.shiftStatus}`}
                  >
                    {toNatural(shift.shiftStatus)}
                  </td>
                )}
                <td className="p-0 align-middle">
                  {expandState[shift.id] ? (
                    <BsChevronUp
                      color="var(--black)"
                      onClick={() => handleExpandRow(shift.id)}
                    />
                  ) : (
                    <BsChevronDown
                      color="var(--black)"
                      onClick={() => handleExpandRow(shift.id)}
                    />
                  )}
                </td>
              </tr>
              {expandedRows.includes(shift.id) ? (
                <>
                  <tr>
                    <td />
                    <td colSpan={3}>
                      <hr className="table-horizontal-line" />
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td colSpan={3}>
                      <Stack className="text-start shift-info h-0" gap={2}>
                        <Row>
                          <Col className="shift-info__key">
                            Scheduled Shift time
                          </Col>
                          <Col className="shift-info__value">
                            {getFormattedTime(shift, job.address.timezoneId)}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="shift-info__key">FULL/BACK UP</Col>
                          <Col className="shift-info__value">
                            {capitalize(shift.type)}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="shift-info__key">WAGE</Col>
                          <Col className="shift-info__value">{`$${
                            shift.wage.payRate
                          }/${capitalize(shift.wage.payRatePeriod)}`}</Col>
                        </Row>
                        <Row>
                          <Col className="shift-info__key">PAID BREAK</Col>
                          <Col className="shift-info__value">
                            {shift.breakPaid ? 'Yes' : 'No'}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="shift-info__key">WORKER</Col>
                          <Col className="shift-info__value">
                            {shift.jobAssignment
                              ? `${shift.jobAssignment.worker.firstName} ${shift.jobAssignment.worker.lastName}`
                              : ''}
                          </Col>
                        </Row>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td colSpan={3}>
                      <hr className="table-horizontal-line" />
                    </td>
                  </tr>
                </>
              ) : null}
            </Fragment>
          ))}
        </tbody>
      </Table>
      <hr className="table-horizontal-line" />
    </div>
  );
}

export default JobWorkerShiftTableMobile;
