import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { Dispatch, SetStateAction } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { components, ControlProps } from 'react-select';
import ApplicantFilters from 'src/apps/company-frontend/components/ApplicantFilters';
import { useJobShiftContext } from 'src/apps/company-frontend/state/jobShiftContext';

export type ControlComponentProps = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
};

export default function ControlComponent(
  props: ControlProps & ControlComponentProps
) {
  const { applicantFilters, setApplicantFilters, selectedWorker } =
    useJobShiftContext();
  const { isShown, setIsShown } = props;

  const handleClick = () => {
    setIsShown((current) => !current);
  };

  const showFilter = () => {
    return !selectedWorker;
  };

  return (
    <>
      <div className="d-flex gap-2">
        <components.Control {...props} />
        {showFilter() && (
          <div className="d-flex text-end">
            <Button
              type="button"
              variant={isShown ? 'primary' : 'light'}
              className="text-end"
              onClick={handleClick}
            >
              <BiFilterAlt size={20} /> {isMobile ? '' : 'FILTERS'}
            </Button>
          </div>
        )}
      </div>
      <Collapse in={isShown}>
        <div className="mt-2">
          <ApplicantFilters
            applicantFilters={applicantFilters}
            setApplicantFilters={setApplicantFilters}
          />
        </div>
      </Collapse>
    </>
  );
}
