import { Stack } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { FaRegEdit } from 'react-icons/fa';
import { FaRegTrashCan } from 'react-icons/fa6';
import DATE_FORMATS from 'src/constants/dateFormat';
import {
  EventExperienceSchemaType,
  NonEventExperienceSchemaType,
} from 'src/types/workerProfile.type';

import './styles.css';
import { formatOpenDateRange } from 'src/utils/DateUtils';

interface ExperienceItemProps {
  experience: EventExperienceSchemaType | NonEventExperienceSchemaType;
  onEditExperience: (exp: any) => void; // CHECK THIS TYPE
  onDeleteExperience: (id: number) => void;
}

function ExperienceItem({
  experience,
  onDeleteExperience,
  onEditExperience,
}: ExperienceItemProps) {
  const getPosition = () => {
    return 'brandRepresented' in experience ? (
      <span>
        {experience.position} for {experience.brandRepresented}
      </span>
    ) : (
      <span>{experience.position}</span>
    );
  };

  return (
    <>
      <BrowserView>
        <Stack direction="horizontal" gap={3}>
          <Stack>
            <Stack
              direction="horizontal"
              gap={2}
              className="flex-shrink-0 align-items-start"
            >
              <b>{experience.hiringCompany}</b>
              <b className="ms-auto">
                {formatOpenDateRange(
                  experience.startDate,
                  experience.endDate,
                  DATE_FORMATS.DATE_FORMAT
                )}
              </b>
              <FaRegEdit
                size={20}
                className="cursor-point"
                onClick={() => onEditExperience(experience)}
              />
              <FaRegTrashCan
                size={20}
                color="var(--red)"
                className="cursor-point"
                onClick={() => onDeleteExperience(experience.id!)}
              />
            </Stack>
            {getPosition()}
            {experience.duties && (
              <span className="exp-duties">{experience.duties}</span>
            )}
          </Stack>
        </Stack>
      </BrowserView>

      <MobileView>
        <Stack direction="horizontal" gap={3}>
          <Stack>
            <Stack
              direction="horizontal"
              gap={2}
              className="flex-shrink-0 justify-content-between"
            >
              <b>{experience.hiringCompany}</b>
              <div>
                <FaRegEdit
                  size={18}
                  onClick={() => onEditExperience(experience)}
                />
                <FaRegTrashCan
                  size={18}
                  color="var(--red)"
                  onClick={() => onDeleteExperience(experience.id!)}
                  className="ms-2"
                />
              </div>
            </Stack>
            {getPosition()}
            <b>
              {formatOpenDateRange(
                experience.startDate,
                experience.endDate,
                DATE_FORMATS.DATE_FORMAT
              )}
            </b>
            {experience.duties && (
              <span className="exp-duties">{experience.duties}</span>
            )}
          </Stack>
        </Stack>
      </MobileView>
    </>
  );
}

export default ExperienceItem;
