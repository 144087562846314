import { useEffect, useState } from 'react';
import { Modal, Stack } from 'react-bootstrap';
import { CgAddR } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';
import { EventExperienceSchemaType } from 'src/types/workerProfile.type';
import { useWorkerProfileContext } from 'src/apps/worker-frontend/state/workerProfileContext';
import * as workerApi from 'src/apps/worker-frontend/services/worker/api';
import { ThLoading } from 'src/components/elements';
import EventExperienceForm from '../../Forms/EventExperienceForm/EventExperienceForm';
import ExperienceItem from '../../ExperienceItem/ExperienceItem';

interface EventExperienceModalProps {
  openAddItem?: boolean;
  onCloseModal: () => void;
}

function EventExperienceModal({
  openAddItem,
  onCloseModal,
}: EventExperienceModalProps) {
  const [experienceToEdit, setExperienceToEdit] =
    useState<EventExperienceSchemaType>();
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { promoWorkExperience, setPromoWorkExperience } =
    useWorkerProfileContext();

  const onEditEexperience = (experience: EventExperienceSchemaType) => {
    setIsAddingItem(false);
    setExperienceToEdit(experience);
  };

  const onDeleteExperience = async (expId: number) => {
    setIsLoading(true);
    const result = await workerApi.deleteEventExpFromWorker(expId);
    if (result.status === 200) {
      setPromoWorkExperience((prevState) => {
        return prevState?.filter((exp) => exp.id !== expId);
      });
    }
    setIsLoading(false);
  };

  const onAddItem = () => {
    setExperienceToEdit(undefined);
    setIsAddingItem(true);
  };

  useEffect(() => {
    if (openAddItem === true) {
      onAddItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show centered className="p-3" size="lg" onHide={onCloseModal}>
      {isLoading && <ThLoading />}
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          Event Experience
          <span className="fs-5 ms-2" style={{ color: 'var(--silver)' }}>
            ({5}%)
          </span>
        </Modal.Title>
        <Stack
          direction="horizontal"
          className="justify-content-end ms-auto"
          gap={2}
        >
          <CgAddR size={20} className="cursor-point" onClick={onAddItem} />
          <IoMdClose
            size={20}
            className="cursor-point"
            onClick={onCloseModal}
          />
        </Stack>
      </Modal.Header>
      <Modal.Body>
        {(isAddingItem || promoWorkExperience?.length === 0) && (
          <div className="mb-3">
            <EventExperienceForm
              onCancel={
                promoWorkExperience?.length === 0
                  ? onCloseModal
                  : () => setIsAddingItem(false)
              }
              onSuccess={() => setIsAddingItem(false)}
            />
          </div>
        )}
        <Stack gap={3}>
          {promoWorkExperience?.map((experience: EventExperienceSchemaType) => (
            <div key={experience.id}>
              {experienceToEdit && experienceToEdit.id === experience.id ? (
                <EventExperienceForm
                  experienceToEdit={experienceToEdit}
                  onCancel={() => setExperienceToEdit(undefined)}
                  onSuccess={() => setExperienceToEdit(undefined)}
                />
              ) : (
                <ExperienceItem
                  experience={experience}
                  onDeleteExperience={onDeleteExperience}
                  onEditExperience={onEditEexperience}
                />
              )}
            </div>
          ))}
        </Stack>
      </Modal.Body>
    </Modal>
  );
}

export default EventExperienceModal;
