import { useState } from 'react';
import * as api from 'src/apps/company-frontend/services/chat/api';
import * as jobAssignmentApi from 'src/apps/company-frontend/services/jobAssignment/api';
import { MessageObject } from 'src/types/message-request.type';
import { IWorkerData } from 'src/types/worker.type';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import useUpdateWorker from 'src/apps/company-frontend/state/useUpdateWorker/useUpdateWorker';
import MessageInput from 'src/apps/company-frontend/components/MessageInput/MessageInput';

interface Params {
  worker: IWorkerData;
  setMessageRecentlySent: (value: MessageObject) => void;
}
export default function FooterChat({ worker, setMessageRecentlySent }: Params) {
  const { job } = useJobContext();
  const { chatAssignmentId, setChatAssignmentId } = useChatContext();
  const [disableSendMessageButton, setDisableSendMessageButton] =
    useState(false);
  const { updateWorker } = useUpdateWorker();

  const onSubmit = async (message: string) => {
    if (!message) return;
    setDisableSendMessageButton(true);
    const temporaryId = Math.random();
    try {
      setMessageRecentlySent({
        message,
        sent: true,
        id: temporaryId,
      });
      let resultMessageSent;
      if (!chatAssignmentId) {
        const result = await jobAssignmentApi.shareJobToWorker(
          job?.id,
          worker.id
        );
        if (result && result.success) {
          resultMessageSent = await api.sendMessage(
            result.data.jobAssignmentId,
            { message }
          );

          if (worker && job) {
            updateWorker({
              workerId: worker.id,
              jobId: job.id,
            });
          }

          if (resultMessageSent.success) {
            setChatAssignmentId(result.data.jobAssignmentId);
          }
        }
      } else {
        resultMessageSent = await api.sendMessage(chatAssignmentId, {
          message,
        });
      }
      if (!resultMessageSent?.success) {
        setMessageRecentlySent({
          message: message || '',
          sent: false,
          id: temporaryId,
        });
      }
      setDisableSendMessageButton(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MessageInput
      onSubmitMessage={onSubmit}
      disabled={disableSendMessageButton}
    />
  );
}
