import { utcToZonedTime, format, formatInTimeZone } from 'date-fns-tz';
import DATE_FORMATS from 'src/constants/dateFormat';
import {
  isAfter,
  isEqual,
  isToday,
  subDays,
  differenceInMinutes,
} from 'date-fns';

const UTC_TIMEZONE = 'UTC';

const formatLocalDate = (
  date: Date,
  dateFormat: string = DATE_FORMATS.SHORT_DATE_FORMAT,
  timeZone?: string
): string => {
  return timeZone
    ? formatInTimeZone(date, timeZone, dateFormat)
    : format(new Date(date), dateFormat);
};

const formatOpenDateRange = (
  start: Date,
  end?: Date | null,
  dateFormat: string = DATE_FORMATS.DATE_FORMAT_SHORT,
  excludeEnd: boolean = false
): string => {
  try {
    // Ensure we have Date objects
    const startDate = start instanceof Date ? start : new Date(start);
    const endDate = end instanceof Date ? end : end && new Date(end);
    const startString = format(startDate, dateFormat);
    return excludeEnd
      ? startString
      : `${startString} - ${
          endDate ? format(new Date(endDate), dateFormat) : 'Present'
        }`;
  } catch (e) {
    console.warn(e);
    return '';
  }
};

const formatTimeOrDateNow = (dateString: string) => {
  return isToday(new Date(dateString))
    ? format(new Date(dateString), DATE_FORMATS.TIME_FORMAT)
    : format(new Date(dateString), DATE_FORMATS.DATE_FORMAT);
};

const convertToTimezone = (date: any, tzString: string) => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    })
  );
};

const parseToDate = (dateString: string, timezoneId: string) =>
  new Date(
    new Date(dateString).toLocaleString('en-US', { timeZone: timezoneId })
  );

const getDateNowByTimezone = (timezone?: string) => {
  return new Date(
    new Date().toLocaleString('en-US', { timeZone: timezone || UTC_TIMEZONE })
  );
};

const getTodayDateByTimezone = (timezone: string): Date => {
  const utcStr = new Date(new Date().toUTCString());
  const zonedDate = utcToZonedTime(utcStr, timezone);
  const offset = zonedDate.getTimezoneOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset > 0 ? '-' : '+';
  const timezoneOffset = `${sign}${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}`;

  const formattedDate = format(zonedDate, 'yyyy-MM-dd', { timeZone: timezone });
  return new Date(`${formattedDate}T00:00:00${timezoneOffset}`);
};

const getDifferenceInMinutes = (date1: Date, date2: Date): number => {
  return differenceInMinutes(date1, date2);
};

const dateDaysAgo = (days: number): Date => {
  return subDays(new Date(), days);
};

const calculateRemainingTime = (time: string) => {
  const lastSurveyDate = new Date(time).valueOf();
  const now = new Date().valueOf();

  const diffInMs = now - lastSurveyDate;

  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  if (hours >= 1) {
    return `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${
      minutes !== 1 ? 's' : ''
    }`;
  }

  return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
};

const isSameDateOrAfter = (date1: Date, date2: Date): boolean => {
  return isAfter(date1, date2) || isEqual(date1, date2);
};

const isDateAfterOrToday = (date: string, timezone: string) => {
  const today = getDateNowByTimezone(timezone);
  const dateValue = parseToDate(date, timezone);
  isToday(dateValue);
  today.setHours(0, 0, 0, 0);
  return isAfter(dateValue, today) || isEqual(dateValue, today);
};

const isDateAfterOrNow = (date: string, timezone: string) => {
  const dateNow = getDateNowByTimezone(timezone);
  const dateValue = parseToDate(date, timezone);
  return isAfter(dateValue, dateNow) || isEqual(dateValue, dateNow);
};

const createDateTimeFromDifferentDates = (date: Date, time: Date) => {
  return new Date(
    `${format(date, DATE_FORMATS.DATE_FORMAT)} ${format(
      time,
      DATE_FORMATS.TIME_FORMAT
    )}`
  );
};

const formatWorkDateRange = (
  startDate: Date,
  endDate: Date,
  dateFormat: string
) => {
  return `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`;
};

export {
  UTC_TIMEZONE,
  parseToDate,
  dateDaysAgo,
  formatLocalDate,
  isDateAfterOrNow,
  convertToTimezone,
  isSameDateOrAfter,
  isDateAfterOrToday,
  formatOpenDateRange,
  formatWorkDateRange,
  formatTimeOrDateNow,
  getDateNowByTimezone,
  getDifferenceInMinutes,
  getTodayDateByTimezone,
  calculateRemainingTime,
  createDateTimeFromDifferentDates,
};
