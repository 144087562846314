import { isMobile } from 'react-device-detect';
import { Stack } from 'react-bootstrap';
import DATE_FORMATS from 'src/constants/dateFormat';

import './styles.scss';
import { formatOpenDateRange } from 'src/utils/DateUtils';

interface ListItemProps {
  date: Date;
  description?: string;
  endDate?: Date;
  hideEndDate?: boolean;
  subTitle?: string;
  title: string;
}
function ListItem({
  date,
  description,
  endDate,
  hideEndDate,
  subTitle,
  title,
}: ListItemProps) {
  return (
    <Stack className="list-item">
      <Stack
        direction={isMobile ? 'vertical' : 'horizontal'}
        className="justify-content-between"
      >
        <b>{title}</b>
        <b>
          {formatOpenDateRange(
            date,
            endDate,
            DATE_FORMATS.DATE_FORMAT,
            hideEndDate
          )}
        </b>
      </Stack>
      {subTitle && <div>{subTitle}</div>}
      {description && (
        <div style={{ whiteSpace: 'pre-wrap' }}>{description}</div>
      )}
    </Stack>
  );
}

export default ListItem;
