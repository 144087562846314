import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { LoaderResume } from 'src/components/elements/ComponentLoader';

interface Props extends HTMLAttributes<HTMLIFrameElement | HTMLImageElement> {
  file?: string;
  useGooglePreviewerForPdf?: boolean;
}

function DocViewer({ file, useGooglePreviewerForPdf, ...props }: Props) {
  const resumeFile = file || '';
  const fileUrl = resumeFile.startsWith('https://docs.google.com/gview?url=')
    ? resumeFile.replace('https://docs.google.com/gview?url=', '')
    : resumeFile;
  const fileExt = fileUrl.substring(fileUrl.lastIndexOf('.') + 1);

  const [isLoading, setIsLoading] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);
  const iframeRef = useRef() as React.MutableRefObject<HTMLIFrameElement>;
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [googleDocs, setGoogleDocs] = useState(['doc', 'docx']);

  const reloadIframe = useCallback(() => {
    if (iframeRef?.current?.contentDocument?.body?.textContent === '') {
      setIsLoading(true);
      setIframeKey((prevKey) => prevKey + 1);
    } else {
      setIsLoading(false);
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [intervalId]);

  useEffect(() => {
    const id = window.setInterval(() => {
      reloadIframe();
    }, 2000);
    setIntervalId(id);
    if (useGooglePreviewerForPdf) {
      setGoogleDocs(['doc', 'docx', 'pdf']);
    }

    return () => window.clearInterval(id);
    // eslint-disable-next-line
  }, []);

  if (!file) {
    return <div>File not found</div>;
  }

  if (googleDocs.includes(fileExt)) {
    return (
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-center position-relative"
        >
          {isLoading && (
            <Col
              xs={12}
              className="d-flex justify-content-center position-absolute"
            >
              <LoaderResume width="81%" height="71svh" />
            </Col>
          )}
          <iframe
            key={iframeKey}
            ref={iframeRef}
            title={file}
            src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
            {...props}
          />
        </Col>
      </Row>
    );
  }
  if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExt)) {
    return <img src={fileUrl} alt={file} {...props} />;
  }

  if (['pdf'].includes(fileExt)) {
    return (
      // @ts-ignore
      <embed
        title={file}
        src={`${fileUrl}#&navpanes=0`}
        type="application/pdf"
        data-testid="embed-pdf"
        {...props}
      />
    );
  }
  return (
    <div>
      <p>Cannot display this file type. Please download to view.</p>
      <a href={fileUrl} download>
        Download
      </a>
    </div>
  );
}

export default DocViewer;
