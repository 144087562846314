import { Stack } from 'react-bootstrap';
import { IWorkerData } from 'src/types/worker.type';
import { useState } from 'react';
import { MessageObject } from 'src/types/message-request.type';
import ChatHeader from 'src/apps/company-frontend/components/ChatHeader/ChatHeader';
import MessagesChat from './MessagesChat';
import FooterChat from './FooterChat';

interface Params {
  showWorkerInfo: boolean;
  worker: IWorkerData;
}

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

export default function Chat({ showWorkerInfo, worker }: Params) {
  const [messageRecentlySent, setMessageRecentlySent] =
    useState<MessageObject | null>(null);

  const viewProfile = () => {
    window.open(
      `${BASE_PATH}workers/${worker.customUrl ?? worker.id}`,
      '_blank'
    );
  };
  return (
    <Stack style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ChatHeader
        worker={worker}
        onViewProfile={viewProfile}
        showWorkerInfo={showWorkerInfo}
      />
      <hr className="mb-0" />
      <MessagesChat
        messageRecentlySent={messageRecentlySent}
        setMessageRecentlySent={setMessageRecentlySent}
      />
      <FooterChat
        worker={worker}
        setMessageRecentlySent={setMessageRecentlySent}
      />
    </Stack>
  );
}
