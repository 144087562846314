import { Alert, Button, Col, Image, Row, Stack } from 'react-bootstrap';
import { Panel, StarRatingButton, ThLoading } from 'src/components/elements';
import defaultAvatar from 'src/assets/default-avatar.png';
import { useEffect, useState } from 'react';
import {
  rateCompany,
  updatePaymentRateCompany,
} from 'worker/services/rating/api';
import { BsExclamationTriangle } from 'react-icons/bs';
import useAlert from 'src/hooks/useAlert';
import { IRatingVisibility } from 'src/types/rating.type';
import { PaymentRating } from 'src/constants/various';
import { useWorkerContext } from '../../state/workerContext';
import IRateCompanyCreation, {
  IPaymentRateCompanyCreation,
} from '../../types/rate-company-creation.type';
import { IThJobAssignmentData } from '../../types/th-job-assignment.type';
import { INonThJobAssignmentData } from '../../types/non-th-job-assignment.type';
import './styles.scss';

interface Props {
  stepTitle?: string;
  nextStep?: () => void;
  isActive?: boolean;
  previousStep?: () => void;
  jobAssignment: IThJobAssignmentData | INonThJobAssignmentData | undefined;
  setFinished: (value: boolean) => void;
  rateCompanyCreation: IRateCompanyCreation | undefined;
}

function RateCompanyPreview({
  nextStep,
  previousStep,
  isActive,
  stepTitle,
  jobAssignment,
  setFinished,
  rateCompanyCreation,
}: Props) {
  const { name, profileImageUrl } = useWorkerContext();

  const { showErrorAlert, alertMessage } = useAlert();
  const [overall, setOverall] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { communication, atEventManagement, professionalism, payment } =
      rateCompanyCreation ?? {};

    if (
      (communication ?? 0) > 0 ||
      (atEventManagement ?? 0) > 0 ||
      (professionalism ?? 0) > 0
    ) {
      let calcOverall =
        (communication ?? 0) +
        (atEventManagement ?? 0) +
        (professionalism ?? 0);
      if (payment) {
        calcOverall +=
          PaymentRating.find((p) => p.index === payment)?.value ?? 0;
        calcOverall /= 4;
      } else {
        calcOverall /= 3;
      }
      setOverall(Number(calcOverall.toFixed(2)));
    }
  }, [rateCompanyCreation]);

  const onPrevious = () => {
    if (previousStep) {
      previousStep();
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (jobAssignment) {
      if (rateCompanyCreation) {
        setLoading(true);

        let result = null;
        if (jobAssignment?.companyRating) {
          const body: IPaymentRateCompanyCreation = {
            payment: rateCompanyCreation?.payment || null,
          };
          result = await updatePaymentRateCompany(jobAssignment.id, body);
        } else {
          result = await rateCompany(jobAssignment.id, rateCompanyCreation);
        }
        setLoading(false);

        if (result && result.success) {
          if (!jobAssignment?.canAskForRating) {
            setFinished(true);
          }
          if (nextStep) {
            nextStep();
          }
        } else {
          showErrorAlert(
            result?.message ||
              'Occurred an error when Trusted Herd tried to rate company'
          );
        }
      }
    }
  };

  return (
    <>
      {loading && <ThLoading />}
      <Row key={stepTitle} className={isActive ? 'd-block' : 'd-none'}>
        <Col md={{ span: 8, offset: 2 }}>
          <Panel>
            <Row>
              <Col xs={12}>
                <b>Preview Of Your Rating</b>
              </Col>
              <Col xs={12} className="mt-3">
                <Stack direction="horizontal" gap={1}>
                  <Image
                    className="avatar rounded"
                    src={profileImageUrl || defaultAvatar}
                    style={{
                      width: 70,
                      height: 70,
                      objectFit: 'cover',
                    }}
                  />
                  <div style={{ height: 70 }}>
                    <b className="ms-3">{name}</b>
                  </div>
                  <div className="ms-auto">
                    <b>Overall - {overall}</b>
                    <StarRatingButton
                      value={overall}
                      allowChange={false}
                      hideLabels
                      size={18}
                    />
                  </div>
                </Stack>
              </Col>
              <Col xs={12} className="mt-1">
                <hr />
              </Col>
              {rateCompanyCreation?.comments && (
                <>
                  <Col xs={12} className="mt-3">
                    <b>What did this company do really well?</b>
                  </Col>
                  <Col xs={12} className="mt-3 text-wrap text-break">
                    {rateCompanyCreation?.comments || '-'}
                  </Col>
                </>
              )}
              <Col xs={12} className="mt-3">
                <b>How long did it take for you to get paid?</b>
              </Col>
              <Col xs={12} className="mt-3">
                {PaymentRating.find(
                  (payRate) => payRate.index === rateCompanyCreation?.payment
                )?.title || '-'}
              </Col>
              <Col xs={12} className="mt-4">
                <Row>
                  {rateCompanyCreation?.communication && (
                    <Col>
                      <p>
                        <sub>
                          <b>
                            COMMUNICATION - {rateCompanyCreation?.communication}
                          </b>
                        </sub>
                      </p>
                      <StarRatingButton
                        value={rateCompanyCreation.communication}
                        allowChange={false}
                      />
                    </Col>
                  )}
                  {rateCompanyCreation?.atEventManagement && (
                    <Col>
                      <p>
                        <sub>
                          <b>
                            AT EVENT MANAGEMENT -{' '}
                            {rateCompanyCreation.atEventManagement}
                          </b>
                        </sub>
                      </p>
                      <StarRatingButton
                        value={rateCompanyCreation.atEventManagement}
                        allowChange={false}
                      />
                    </Col>
                  )}
                  {rateCompanyCreation?.professionalism && (
                    <Col>
                      <p>
                        <sub>
                          <b>
                            PROFESSIONALISM -{' '}
                            {rateCompanyCreation.professionalism}
                          </b>
                        </sub>
                      </p>
                      <StarRatingButton
                        value={rateCompanyCreation.professionalism}
                        allowChange={false}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              {rateCompanyCreation?.visibility === IRatingVisibility.PRIVATE &&
                rateCompanyCreation.improvementComments && (
                  <Col xs={12} className="mt-3">
                    <div className="custom-alert p-3 rounded">
                      <BsExclamationTriangle
                        size={20}
                        className="me-2"
                        color="var(--yellow)"
                      />
                      The information below will be shared privately for company
                      to improve internally.
                    </div>
                  </Col>
                )}
              <Row>
                {rateCompanyCreation?.improvementComments && (
                  <>
                    <Col xs={12} className="mt-3">
                      <b>What can this company improve?</b>
                    </Col>
                    <Col xs={12} className="mt-3 text-wrap text-break">
                      {rateCompanyCreation?.improvementComments || '-'}
                    </Col>
                  </>
                )}
              </Row>
            </Row>
          </Panel>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <div className="w-100 d-flex justify-content-end">
            <Button
              type="button"
              form="rateCompanyForm"
              size="sm"
              variant="light"
              className="fw-bold mt-4"
              onClick={onPrevious}
            >
              Previous
            </Button>
          </div>
        </Col>
        <Col>
          <Button
            type="submit"
            form="rateCompanyForm"
            size="sm"
            className="fw-bold mt-4"
            disabled={loading}
            onClick={onSubmit}
          >
            {loading ? 'Submitting' : 'Continue'}
          </Button>
        </Col>
      </Row>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default RateCompanyPreview;
