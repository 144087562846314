import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import FilterBar from 'company/components/CommunicationHub/FilterBar/FilterBar';
import {
  dismissItemsFromNew,
  getReceivedReviews,
  getReviewRequests,
} from 'company/services/com-hub/api';
import ReceivedReviewsTable from 'company/components/CommunicationHub/CommunicationHubTables/ReceivedReviewsTable';
import ReviewRequestsTable from 'company/components/CommunicationHub/CommunicationHubTables/ReviewRequestsTable';
import {
  ComHubDismissTypeEnum,
  ComHubQueryParamsEnum,
  ComHubTabRoutes,
  ComHubTabs,
  Review,
} from 'company/types/comm-hub.type';
import { ThLoading } from 'components/elements';
import QueryKeys from 'constants/queryKeys';
import { dismissItems } from 'utils/ComHubUtils';

interface NewsProps {
  onSeeReceivedReview: (review: Review) => void;
  onSeeReviewRequest: (review: Review) => void;
  onShowWorkerProfile?: (workerId: number) => void;
  onTabChange: (tabName: string, onlyNew?: boolean) => void;
}

function News({
  onSeeReceivedReview,
  onSeeReviewRequest,
  onShowWorkerProfile,
  onTabChange,
}: NewsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchState, setSearchState] = useState<{
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }>();

  // CONSTANTS FOR QUERY
  const queryClient = useQueryClient();
  const queryKeyReceivedReviews = [
    QueryKeys.ComHub.RECEIVED_REVIEWS,
    searchState,
  ];
  const queryKeyReviewRequest = [QueryKeys.ComHub.REVIEW_REQUESTS, searchState];

  // FETCH NEW RECEIVED REVIEWS
  const { data: newReceivedReviews, isLoading: isLoadingReceivedReviews } =
    useQuery({
      queryKey: queryKeyReceivedReviews,
      queryFn: () =>
        getReceivedReviews(
          0, // offset 0, no pagination
          10, // size of the first page (to se more it will redirect to the tab)
          searchState?.searchText,
          searchState?.dateRange,
          true
        ),
    });

  // FETCH NEW REVIEW REQUESTS
  const { data: newReviewRequests, isLoading: isLoadingReviewRequests } =
    useQuery({
      queryKey: queryKeyReviewRequest,
      queryFn: () =>
        getReviewRequests(
          0, // offset 0, no pagination
          10, // size of the first page (to se more it will redirect to the tab)
          searchState?.searchText,
          searchState?.dateRange,
          true
        ),
    });

  // useQuery update
  const { mutateAsync, isPending: isLoadingDismiss } = useMutation({
    mutationFn: dismissItemsFromNew,
    onSuccess: () => {
      [
        [QueryKeys.ComHub.RECEIVED_REVIEWS],
        [QueryKeys.ComHub.REVIEW_REQUESTS],
      ].forEach((key) => queryClient.invalidateQueries({ queryKey: key }));
    },
  });

  const handleOnSearch = (search: {
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }) => {
    setSearchState(search);
    if (search.searchText) {
      searchParams.set(ComHubQueryParamsEnum.SEARCH_TEXT, search.searchText);
    } else {
      searchParams.delete(ComHubQueryParamsEnum.SEARCH_TEXT);
    }
    if (search.dateRange.startDate) {
      searchParams.set(
        ComHubQueryParamsEnum.DATE_FROM,
        search.dateRange.startDate.toISOString()
      );
    } else {
      searchParams.delete(ComHubQueryParamsEnum.DATE_FROM);
    }
    if (search.dateRange.endDate) {
      searchParams.set(
        ComHubQueryParamsEnum.DATE_TO,
        search.dateRange.endDate.toISOString()
      );
    } else {
      searchParams.delete(ComHubQueryParamsEnum.DATE_TO);
    }
    setSearchParams(searchParams);
  };

  const handleOnClear = () => {
    setSearchState(undefined);
    searchParams.delete(ComHubQueryParamsEnum.SEARCH_TEXT);
    searchParams.delete(ComHubQueryParamsEnum.DATE_FROM);
    searchParams.delete(ComHubQueryParamsEnum.DATE_TO);
    setSearchParams(searchParams);
  };

  return (
    <>
      <FilterBar onSearch={handleOnSearch} onClear={handleOnClear} />

      {(isLoadingReceivedReviews ||
        isLoadingReviewRequests ||
        isLoadingDismiss) && <ThLoading />}

      {/* NEW RECEIVED REVIEWS BLOCK */}
      {newReceivedReviews ? (
        <ReceivedReviewsTable
          title="Received Reviews"
          titleCallback={() =>
            onTabChange(ComHubTabRoutes[ComHubTabs.RECEIVED_REVIEWS].key, true)
          }
          receivedReviews={newReceivedReviews.reviews}
          onSeeReceivedReview={onSeeReceivedReview}
          onShowWorkerProfile={onShowWorkerProfile}
          onDismissItems={(items) =>
            dismissItems(
              items,
              ComHubDismissTypeEnum.COMPANY_RATING,
              mutateAsync
            )
          }
        />
      ) : null}

      {/* NEW REVIEWS REQUESTS BLOCK */}
      {newReviewRequests ? (
        <ReviewRequestsTable
          title="Received Review Requests from Workers"
          titleCallback={() =>
            onTabChange(ComHubTabRoutes[ComHubTabs.RECEIVED_REQUESTS].key, true)
          }
          reviewRequests={newReviewRequests.ratingRequests}
          onSeeReviewRequest={onSeeReviewRequest}
          onShowWorkerProfile={onShowWorkerProfile}
          onDismissItems={(items) =>
            dismissItems(
              items,
              ComHubDismissTypeEnum.RATING_REQUEST,
              mutateAsync
            )
          }
        />
      ) : null}
    </>
  );
}

export default News;
