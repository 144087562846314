import { useState } from 'react';
import { Stack, Button, Modal, Alert, Form } from 'react-bootstrap';
import { format, isToday } from 'date-fns';
import DATE_FORMATS from 'src/constants/dateFormat';
import bankCash from 'src/assets/survey/bank-cash.svg';
import { ThLoading } from 'src/components/elements';
import { useMutation } from '@tanstack/react-query';
import { useUserContext } from 'src/state/userContext';
import { isProduction } from 'src/utils/Environment';
import { SurveyBankData } from '../Survey/types';
import { useSurveyContext } from '../../state/surveyContext';
import '../Survey/styles.scss';
import { createPrezzeeOrder } from '../../services/survey/api';

function formatDate(date: Date): string {
  const formattedDate = format(date, DATE_FORMATS.SURVEY_CURRENT_DATE_FORMAT);
  return `${isToday(date) ? 'Today, ' : ''}${formattedDate}`;
}

function SurveyBank({
  surveyAttemptLimit,
  fetchSurveyLink,
}: {
  surveyAttemptLimit: boolean;
  fetchSurveyLink: () => void;
}) {
  const {
    profileData,
    refetchProfile,
    surveyHistory,
    isLoadingProfile,
    startSurveyDisabled,
  } = useSurveyContext();
  const { email, name } = useUserContext();
  const bankData: SurveyBankData = {
    date: new Date(),
    potentialAmount: 0,
    approvedAmount: 0,
  };
  if (profileData?.rewardAmount) {
    Object.assign(bankData, {
      potentialAmount: profileData?.rewardAmount.potential,
      approvedAmount: profileData?.rewardAmount.approved,
    });
  }
  const { date, potentialAmount, approvedAmount } = bankData;
  const state =
    surveyHistory !== undefined && surveyHistory.length > 0 ? 'started' : '';

  const [redeemSuccess, setRedeemSuccess] = useState<string | null>(null);
  const [redeemError, setRedeemError] = useState<string | null>(null);
  const [redeemAmount, setRedeemAmount] = useState<number | null>(null);
  const [voucherUrl, setVoucherUrl] = useState<string>('');
  const [showInNetworkModal, setShowInNetworkModal] = useState(false);
  const [showGiftCardsModal, setShowGiftCardsModal] = useState(false);

  const handleShowInNetworkModal = () => setShowInNetworkModal(true);
  const handleCloseInNetworkModal = () => setShowInNetworkModal(false);
  const handleShowGiftCardsModal = () => setShowGiftCardsModal(true);
  const handleCloseGiftCardsModal = () => {
    setRedeemAmount(null);
    setRedeemSuccess(null);
    setRedeemError(null);
    setShowGiftCardsModal(false);
  };

  const redeemGiftCouponCall = useMutation({
    mutationFn: (amount: number) => createPrezzeeOrder(amount, name, email),
    onSuccess: (response) => {
      setVoucherUrl(response.voucher_url);
      refetchProfile();
      setRedeemSuccess(
        `Successfully redeemed $${redeemAmount!.toFixed(
          2
        )}! Remaining amount: $${(approvedAmount - redeemAmount!).toFixed(2)}.`
      );
      setRedeemAmount(null);
      setRedeemError(null);
    },
    onError: () => {
      setRedeemError('Failed to redeem the amount. Please try again later.');
      setRedeemSuccess(null);
    },
  });

  const isValidAmount =
    redeemAmount !== null &&
    redeemAmount >= 5 &&
    redeemAmount % 5 === 0 &&
    redeemAmount <= approvedAmount;
  const handleRedeem = () => {
    if (isValidAmount) {
      redeemGiftCouponCall.mutate(redeemAmount);
    } else {
      setRedeemError(
        'Amount must be greater than $5, a multiple of $5, and not exceed the approved amount.'
      );
      setRedeemSuccess(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setRedeemAmount(Number.isNaN(value) ? null : value);
  };

  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <div>
          <b className="">Survey Bank</b>
          <br />
          {formatDate(date)}
        </div>
        <b className="th-fs-27">${approvedAmount?.toFixed(2)}</b>
      </Stack>

      <Stack gap={3} className="mt-3">
        {state === 'started' ? (
          <Stack direction="horizontal" gap={2}>
            <img
              src={bankCash}
              alt="cash"
              style={{ width: '20px', position: 'relative' }}
            />
            <span style={{ width: '60%' }}>
              Potential pending survey approvals:{' '}
              <b>${potentialAmount.toFixed(2)}</b>
            </span>
          </Stack>
        ) : (
          'Complete the initial questionnaire to start.'
        )}
        {state === 'started' ? (
          <div className="d-flex gap-3">
            <Button onClick={handleShowInNetworkModal}>GET IN-NETWORK</Button>
            <Button
              className="bg-light border-0"
              onClick={handleShowGiftCardsModal}
              disabled={isProduction() && email !== 'bafox11@gmail.com'}
            >
              GET GIFT CARDS
            </Button>
          </div>
        ) : (
          <Button
            className="w-50"
            size="sm"
            onClick={fetchSurveyLink}
            disabled={
              isLoadingProfile || startSurveyDisabled || surveyAttemptLimit
            }
          >
            START NOW
          </Button>
        )}
      </Stack>
      <Modal show={showGiftCardsModal} onHide={handleCloseGiftCardsModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {redeemSuccess ? 'Redemption Successful' : 'Redeem Approved Amount'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {redeemSuccess ? (
            <div className="text-center">
              <p className="fw-bold">Your gift card is ready!</p>
              <a
                href={voucherUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Get Your Gift Card
              </a>
            </div>
          ) : (
            <>
              <p>
                Your approved amount is ${approvedAmount?.toFixed(2)}. Please
                enter the amount you wish to redeem.
              </p>
              <Alert variant="warning" className="mb-2">
                Amount must be greater than $5, a multiple of $5, and not exceed
                the approved amount.
              </Alert>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Redeem Amount</Form.Label>
                  <Form.Control
                    type="number"
                    min="5"
                    step="5"
                    placeholder="Enter amount (e.g., 10)"
                    value={redeemAmount !== null ? redeemAmount : ''}
                    onChange={handleInputChange}
                    disabled={redeemGiftCouponCall.isPending}
                  />
                </Form.Group>
              </Form>
              {redeemError && <Alert variant="danger">{redeemError}</Alert>}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseGiftCardsModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleRedeem}
            disabled={redeemGiftCouponCall.isPending || !isValidAmount}
          >
            {redeemGiftCouponCall.isPending && <ThLoading />}
            Confirm Redeem
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showInNetworkModal} onHide={handleCloseInNetworkModal}>
        <Modal.Header closeButton>
          <Modal.Title>Coming Soon!</Modal.Title>
        </Modal.Header>
        <Modal.Body>We&apos;re actively building this right now!</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseInNetworkModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SurveyBank;
