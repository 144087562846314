import { EventContentArg } from '@fullcalendar/core';
import { format } from 'date-fns';
import {
  Button,
  Figure,
  OverlayTrigger,
  Popover,
  Table,
} from 'react-bootstrap';
import DATE_FORMAT from 'src/constants/dateFormat';
import { ShiftPosition } from 'src/types/job.detail.type';

const TABLE_HEADER_WEB = ['Full name', 'Wage', 'Full Time', 'Paid break'];

export default function renderEventContent(
  eventContent: EventContentArg,
  mousePosition: { x: number; y: number },
  shifts?: ShiftPosition[]
) {
  const { backgroundColor, id, start, end, title } = eventContent.event;
  return (
    <div
      className="text-start p-2 w-100"
      style={{
        position: 'relative',
        backgroundColor: `var(${backgroundColor})`,
        borderRadius: '4px',
        padding: '3px 10px',
        gap: '10px',
        whiteSpace: 'break-spaces',
      }}
    >
      <OverlayTrigger
        rootClose
        trigger="click"
        placement={mousePosition.x > 600 ? 'left' : 'right'}
        overlay={
          <Popover id={id} style={{ maxWidth: 'fit-content' }}>
            <Popover.Header className="bg-white">
              <span className="fw-bold fs-5">{title}</span>
              <div>
                <span className="me-3">
                  {format(start as Date, DATE_FORMAT.DAY_MONTH_DATE_FORMAT)}
                </span>
                <span>
                  {`${format(
                    start as Date,
                    DATE_FORMAT.TIME_FORMAT
                  )} - ${format(end as Date, DATE_FORMAT.TIME_FORMAT)}`}
                </span>
              </div>
            </Popover.Header>
            <Popover.Body>
              <Table responsive borderless>
                <thead className="table-header fw-bold">
                  <tr>
                    {TABLE_HEADER_WEB.map((header) => (
                      <th key={`thw-${header}`}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="table-body">
                  {shifts &&
                    shifts?.map((shift) => (
                      <tr key={`${shift.id}-${shift.positionIndex}`}>
                        <td className="text-start">
                          {shift.jobAssignment && (
                            <Figure.Image
                              className="me-1 rounded-circle"
                              src={shift?.jobAssignment.worker.profileImageUrl}
                              width={15}
                              height={15}
                            />
                          )}
                          {shift.jobAssignment &&
                            `${shift.jobAssignment.worker.firstName} ${shift.jobAssignment.worker.lastName}`}
                        </td>
                        <td>{shift.wage.payRate}</td>
                        <td>{shift.openPositions === 0 ? 'Yes' : 'No'}</td>
                        <td>{shift.breakPaid ? 'Yes' : 'No'}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Popover.Body>
          </Popover>
        }
      >
        <div style={{ cursor: 'pointer' }}>
          <strong>{title}</strong>
          <br />
          <strong>{eventContent.timeText}</strong>
        </div>
      </OverlayTrigger>
      {eventContent.view.type === 'timeGridWeek' ? (
        <Button
          className="rounded-circle bi bi-plus bi-lg"
          size="sm"
          variant="light"
          type="button"
          style={{ position: 'absolute', right: 5, top: 5 }}
        />
      ) : null}
    </div>
  );
}
