import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Figure, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { RiArrowDownSFill } from 'react-icons/ri';
import trustedHerdLogo from 'src/assets/trusted-herd-logo.svg';
import { useUserContext } from 'src/state/userContext';
import { useCompanyContext } from 'src/apps/company-frontend/state/companyContext';
import { CapabilityType } from 'src/apps/company-frontend/types/company-capability.data';
import { ThBadgeIndicator } from 'src/components/elements';
import './style.css';

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;
const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

enum DropdownField {
  SEARCH_HERD,
  REVIEWS,
  USER,
}

export default function Header() {
  const { name, profileImageUrl } = useUserContext();
  const companyContext = useCompanyContext();
  const location = useLocation();
  const [navDropDownSearchIsOpen, setNavDropdownSearchIsOpen] = useState(false);
  const [navDropDownUserIsOpen, setNavDropdownUserIsOpen] = useState(false);

  const handleNavDropdown = (field: DropdownField, isOpen: boolean) => {
    switch (field) {
      case DropdownField.SEARCH_HERD:
        setNavDropdownSearchIsOpen(isOpen);
        break;
      case DropdownField.USER:
        setNavDropdownUserIsOpen(isOpen);
        break;
      default:
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="header"
    >
      <Navbar.Brand href={SERVER_URL} className="m-0">
        <img
          src={trustedHerdLogo}
          alt="Trusted Herd Logo"
          className="logo-position"
        />
        <strong className="logoTitle">TRUSTED HERD</strong>
      </Navbar.Brand>
      {companyContext.profileImageUrl && (
        <Link
          className="company-logo company-logo-border-left"
          to={`${SERVER_URL}/companyprofile/overview?name=${companyContext.profileURLName}`}
        >
          <img
            src={companyContext.profileImageUrl}
            width={31}
            height={31}
            alt="company-logo"
            className="rounded"
          />
        </Link>
      )}

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link
            eventKey={`${SERVER_URL}/companyDashboard/home`}
            href={`${SERVER_URL}/companyDashboard/home`}
            className="link"
          >
            Dashboard
          </Nav.Link>
          <NavDropdown
            onMouseEnter={() =>
              handleNavDropdown(DropdownField.SEARCH_HERD, true)
            }
            onMouseLeave={() =>
              handleNavDropdown(DropdownField.SEARCH_HERD, false)
            }
            show={navDropDownSearchIsOpen}
            title={
              <>
                <span style={{ color: 'white' }}>Search Herd</span>
                <RiArrowDownSFill />
              </>
            }
            menuVariant="light"
            className="link"
          >
            <NavDropdown.Item href={`${BASE_PATH}searchWorkers`}>
              Search Workers
            </NavDropdown.Item>
            <NavDropdown.Item href={`${SERVER_URL}/searchCompanies/index`}>
              Search Companies
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            eventKey={`${SERVER_URL}/inbox/index`}
            href={`${SERVER_URL}/inbox/index`}
            className="link"
          >
            Inbox
            <ThBadgeIndicator
              indicatorValue={companyContext.indicators?.totalInboxUnread}
              addClass="ms-2 badge-indicators"
              showSmall
            />
          </Nav.Link>
          <Nav.Link
            eventKey={`${SERVER_URL}/job/list`}
            href={`${SERVER_URL}/job/list`}
            className="link"
          >
            Jobs
          </Nav.Link>
          <Nav.Link
            eventKey={`${BASE_PATH}communication-hub`}
            href={`${BASE_PATH}communication-hub`}
            className={`link ${
              location.pathname.includes('/communication-hub') ? 'active' : ''
            }`}
          >
            CommHub
            <ThBadgeIndicator
              indicatorValue={
                companyContext.indicators
                  ? companyContext.indicators.newCommunicationsCount
                  : 0
              }
              addClass="ms-2 badge-indicators"
              showSmall
            />
          </Nav.Link>
          <Nav.Link
            eventKey={`${SERVER_URL}/requestforproposal/received`}
            href={`${SERVER_URL}/requestforproposal/received`}
            className="link"
          >
            RFPs
            <ThBadgeIndicator
              indicatorValue={companyContext.indicators?.rfpsCount}
              addClass="ms-2 badge-indicators"
              showSmall
            />
          </Nav.Link>
          <Nav.Link
            eventKey={`${BASE_PATH}payroll-services`}
            href={`${BASE_PATH}payroll-services`}
            className={`link ${
              location.pathname.includes('/payroll-services') ? 'active' : ''
            }`}
          >
            Payroll
          </Nav.Link>
          {companyContext?.hasCapability &&
            companyContext.hasCapability(CapabilityType.REPORTING) && (
              <Nav.Link
                eventKey={`${SERVER_URL}/report`}
                href={`${SERVER_URL}/report`}
                className="link"
              >
                Reports
              </Nav.Link>
            )}
        </Nav>
        <Nav>
          <NavDropdown
            onMouseEnter={() => handleNavDropdown(DropdownField.USER, true)}
            onMouseLeave={() => handleNavDropdown(DropdownField.USER, false)}
            show={navDropDownUserIsOpen}
            title={
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-white fw-bold">{name}</span>
                <div className="profileSection">
                  <Figure.Image
                    className="avatar rounded-circle"
                    src={profileImageUrl}
                  />
                </div>
                <RiArrowDownSFill />
              </div>
            }
            menuVariant="light"
          >
            <NavDropdown.Item href={`${SERVER_URL}/companyAccount/index`}>
              Account Settings
            </NavDropdown.Item>
            <NavDropdown.Item href={`${SERVER_URL}/companyProfile/myCompany`}>
              My Company Profile
            </NavDropdown.Item>
            <NavDropdown.Item href={`${SERVER_URL}/companyEdit/index`}>
              Edit Company
            </NavDropdown.Item>
            <NavDropdown.Item href={`${SERVER_URL}/helpUsImprove/index`}>
              Help Us Improve
            </NavDropdown.Item>
            <NavDropdown.Item href={`${SERVER_URL}/api/auth/logout`}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
