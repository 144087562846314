import { isMobile } from 'react-device-detect';
import { BsChat } from '@react-icons/all-files/bs/BsChat';
import { FiEye } from '@react-icons/all-files/fi/FiEye';
import { FiEyeOff } from '@react-icons/all-files/fi/FiEyeOff';
import { GrLocation } from '@react-icons/all-files/gr/GrLocation';
import { Card, Col, Image, Row, Stack } from 'react-bootstrap';
import { StarRating, ThWorkerBadge } from 'components/elements';
import * as api from 'src/apps/company-frontend/services/application/api';
import { JobAssignment } from 'src/types/job-assignment.type';
import { IWorkerData } from 'src/types/worker.type';
import { ITag } from 'src/types/tag.type';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import { useJobAssignmentStatus } from 'src/hooks/useJobAssignmentStatus';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { useUserContext } from 'src/state/userContext';
import { UserRole } from 'src/constants/userRoles';
import IActionButton from 'src/types/action-button.type';
import { useState } from 'react';
import { HiOutlineShare } from 'react-icons/hi';
import { FaHeart } from 'react-icons/fa';
import { SECOND } from 'src/constants/various';
import ModalShareApplicantLink from './ModalShareApplicantLink';
import { ActionButtonsMobile, Tags } from '../ThWorkerRow';

interface Params {
  jobAssignment: JobAssignment;
  worker: IWorkerData;
  distance: number;
  isHidden: boolean;
  // openDetails receive this callback
  // const handleShowWorkerProfile: (jobAssignment: JobAssignment, worker: IWorkerData) => void
  openDetails: (jobAssignment: JobAssignment, worker: IWorkerData) => void;
  setIsLoading: (value: boolean) => void;
  refetchApplicants: any;
}

export default function ThWorkerCard({
  jobAssignment,
  worker,
  distance,
  isHidden: hidden,
  openDetails,
  setIsLoading,
  refetchApplicants,
}: Params) {
  const { openChat } = useChatContext();
  const jobAssignmentStatus = useJobAssignmentStatus();
  const { isHiredOrOffered, isHiredOrApplicant, isApplicant } =
    jobAssignmentStatus(jobAssignment.status);

  const { fetchApplicantCounters, isInternalMember } = useJobContext();
  const { id, hasRole } = useUserContext();

  const [showShareModal, setShowShareModal] = useState(false);

  const hideApplicant = (hide: boolean) => {
    setIsLoading(true);
    api
      .hideApplicant(jobAssignment.id, hide)
      .then(() => {
        refetchApplicants();
      })
      .finally(() => {
        setTimeout(() => {
          fetchApplicantCounters();
          setIsLoading(false);
        }, SECOND);
      });
  };

  const getTags = (matchSkills: boolean, hired: boolean): ITag[] => {
    const tags: ITag[] = [];
    if (matchSkills) {
      tags.push({
        label: 'MATCH',
        bg: 'light-success',
        bgText: 'text-success',
      });
    }
    if (hired) {
      tags.push({
        label: 'HIRED',
        bg: 'primary',
        bgText: 'light',
      });
    }
    return tags;
  };

  const hiddenDropDown = () => {
    if (isApplicant()) {
      if (hidden) {
        return 'Unhide';
      }
      return 'Hide';
    }
    if (!isHiredOrOffered() && hidden) {
      return 'Unhide';
    }
    return '';
  };

  const hiddenIcon = () => {
    if (isApplicant()) {
      if (hidden) {
        return (
          <FiEye
            size={20}
            onClick={() => {
              hideApplicant(false);
            }}
            role="button"
            className="d-none d-sm-block"
          />
        );
      }
      return (
        <FiEyeOff
          size={20}
          onClick={() => {
            hideApplicant(true);
          }}
          role="button"
          className="d-none d-sm-block"
        />
      );
    }
    if (!isHiredOrOffered() && hidden) {
      return (
        <FiEye
          size={20}
          onClick={() => {
            hideApplicant(false);
          }}
          role="button"
          className="d-none d-sm-block"
        />
      );
    }
    return <div />;
  };

  const shouldRenderActionButton = () => {
    return isInternalMember(id) || hasRole(UserRole.ROLE_COMPANY_ADMIN);
  };

  function shouldRenderMessage() {
    return shouldRenderActionButton();
  }

  const getActions = () => {
    const actions: IActionButton[] = [];

    if (shouldRenderMessage()) {
      actions.push({
        label: 'Message',
        variant: 'light',
        size: 'sm',
        action: () => openChat(worker, jobAssignment.id, isHiredOrApplicant()),
      });
    }
    if (shouldRenderActionButton()) {
      actions.push({
        label: 'Share Profile',
        variant: 'light',
        size: 'sm',
        action: () => setShowShareModal(true),
      });
      const hiddenOption = hiddenDropDown();
      actions.push({
        label: hiddenOption,
        variant: 'light',
        size: 'sm',
        action: () => hideApplicant(hiddenOption === 'Hide'),
      });
    }
    return actions;
  };

  return (
    <Stack key={worker.id}>
      <Card className="p-2">
        <Row>
          <Col xs={3} md={3} lg={2} className="square-container">
            <div className="square-content position-relative">
              <Image
                rounded
                className="cursor-point"
                src={worker.profileImageUrl}
                onClick={() => openDetails(jobAssignment, worker)} // this opens the JobWorkerSideModal
              />
            </div>
          </Col>
          <Col xs={9} md={9} lg={10}>
            <Stack className="justify-content-start" gap={2}>
              <Row>
                <Col
                  className="float-left cursor-point"
                  xs={8}
                  onClick={() => openDetails(jobAssignment, worker)} // this is not working
                >
                  <b className="ms-auto">
                    {worker.firstName} {worker.lastName}
                  </b>
                  {jobAssignment.companyWorker?.isFavoritedByCompany && (
                    <FaHeart
                      className="ms-2 me-1 mb-1"
                      color="green"
                      size={12}
                    />
                  )}
                  <ThWorkerBadge
                    worker={worker}
                    badgeText="IN-NETWORK"
                    addClass="cursor-default"
                  />
                </Col>
                <Col className="float-right" xs={4}>
                  <Stack
                    direction="horizontal"
                    className="justify-content-end"
                    gap={3}
                  >
                    {shouldRenderMessage() && (
                      <BsChat
                        size={20}
                        role="button"
                        className="d-none d-sm-block"
                        onClick={() =>
                          openChat(
                            worker,
                            jobAssignment.id,
                            isHiredOrApplicant()
                          )
                        }
                      />
                    )}
                    {shouldRenderActionButton() && (
                      <>
                        <HiOutlineShare
                          size={20}
                          role="button"
                          onClick={() => setShowShareModal(true)}
                          className="d-none d-sm-block"
                        />
                        <ModalShareApplicantLink
                          showModal={showShareModal}
                          setShowModal={setShowShareModal}
                          worker={worker}
                        />
                      </>
                    )}
                    {hidden}
                    {shouldRenderActionButton() && hiddenIcon()}
                    {isMobile && (
                      <ActionButtonsMobile
                        actions={getActions()}
                        className="d-block d-sm-none"
                      />
                    )}
                  </Stack>
                </Col>
              </Row>
              <Stack
                direction={isMobile ? 'vertical' : 'horizontal'}
                className="justify-content-start"
                gap={3}
              >
                {distance >= 0 && (
                  <span>
                    <GrLocation className="location-icon" />
                    {distance?.toFixed(2)} miles
                  </span>
                )}

                <StarRating value={worker.rating} color="yellow" />
              </Stack>
              <Stack
                direction="horizontal"
                className="justify-content-start"
                gap={3}
              >
                {getTags(jobAssignment.matchSkills, jobAssignment.hired) && (
                  <div className="mt-1">
                    <Tags
                      tags={getTags(
                        jobAssignment.matchSkills,
                        jobAssignment.hired
                      )}
                    />
                  </div>
                )}
              </Stack>
            </Stack>
          </Col>
        </Row>
      </Card>
    </Stack>
  );
}
