import { IWorkerAssignment } from 'src/apps/company-frontend/types/workerAssignment';
import { JobQuestionResponseModel } from 'types/job-question-response-model.type';
import { useJobAssignmentStatus } from 'src/hooks/useJobAssignmentStatus';
import { JobApplication } from 'types/job-application.type';
import { IWorkerData } from 'types/worker.type';
import { Panel } from 'components/elements';
import ApplicationInfoHistory from './ApplicationInfoHistory';
import ApplicationInfoHeader from './ApplicationInfoHeader';
import JobQuestionComponent from '../JobQuestionComponent';

interface ApplicationInfoProps {
  worker: IWorkerData;
  jobQuestionResponseList?: JobQuestionResponseModel[];
  workerAssignment: IWorkerAssignment;
  jobApplication?: JobApplication;
}

export default function ApplicationInfo({
  worker,
  jobQuestionResponseList,
  workerAssignment,
  jobApplication,
}: ApplicationInfoProps) {
  const jobAssignmentStatus = useJobAssignmentStatus();
  const { isHired } = jobAssignmentStatus(
    workerAssignment.thJobAssignment?.status
  );

  const showWorkerInfo = () => {
    return jobApplication || isHired();
  };

  return (
    <div className="mt-3">
      {showWorkerInfo() && (
        <Panel>
          <ApplicationInfoHeader
            jobAssignment={workerAssignment.thJobAssignment}
            jobApplication={jobApplication}
            worker={worker}
          />
          <hr />
          {jobQuestionResponseList &&
            jobQuestionResponseList.map((jobQuestionResponse) => {
              return (
                <JobQuestionComponent
                  jobQuestionResponseModel={jobQuestionResponse}
                  key={`job-question-${jobQuestionResponse.questionId}`}
                />
              );
            })}
        </Panel>
      )}
      <div className="mt-3">
        <ApplicationInfoHistory
          thJobAssignmentLogList={workerAssignment.thJobAssignmentLogList}
        />
      </div>
    </div>
  );
}
