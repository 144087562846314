import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { ImNewTab } from 'react-icons/im';
import { formatTimeOrDateNow } from 'utils/DateUtils';
import { GivenReviewModel } from 'company/models/GivenReviewModel';
import {
  ComHubHeadersEnum,
  GivenReview,
  GivenReviewTypeEnum,
} from 'company/types/comm-hub.type';

import './styles.css';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

interface ReviewsAndEndorsementsTableProps {
  givenReviews: GivenReview[];
  title: string;
  onSeeGivenReview: (review: GivenReview) => void;
  onSeeGivenEndorsement: (endorsementCaseId: number) => void;
  onShowWorkerProfile?: (workerId: number) => void;
}

function ReviewsAndEndorsementsTable({
  title,
  givenReviews,
  onSeeGivenReview,
  onSeeGivenEndorsement,
  onShowWorkerProfile,
}: ReviewsAndEndorsementsTableProps) {
  const headers = [
    ComHubHeadersEnum.EVENT_NAME,
    ComHubHeadersEnum.WORKER_NAME,
    ComHubHeadersEnum.POSITION,
    ComHubHeadersEnum.DATES_WORKED,
    ComHubHeadersEnum.RATING,
    ComHubHeadersEnum.AUTHOR,
    ComHubHeadersEnum.ACTIONS_COLUMN,
  ];

  const openGivenReviewModal = (givenReview: GivenReview) => {
    if (givenReview.type === GivenReviewTypeEnum.ENDORSEMENT_CASE)
      onSeeGivenEndorsement(givenReview.endorsementCaseId);
    if (givenReview.type === GivenReviewTypeEnum.RATING)
      onSeeGivenReview(givenReview);
  };

  const getDateCreated = (givenReview: GivenReviewModel) => {
    return formatTimeOrDateNow(givenReview.getDateCreated());
  };

  return (
    <div className="th-table">
      <header>
        <h4>{title}</h4>
      </header>
      <hr style={{ margin: '10px 0' }} />
      {givenReviews.length ? (
        <Table>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* RENDER COLUMNS BY ROW */}
            {givenReviews.map((review) => {
              const givenReview = new GivenReviewModel(review);
              return (
                <tr key={givenReview.getUniqueKey()}>
                  <td>
                    {givenReview.jobId ? (
                      <a
                        href={`${BASE_PATH}jobs/${givenReview.jobId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-point event-name-link"
                      >
                        {givenReview.eventName}
                        <ImNewTab
                          color="var(--yellow-dark-20)"
                          className="ms-2 event-name-link-icon"
                        />
                      </a>
                    ) : (
                      <span>
                        {givenReview.type === GivenReviewTypeEnum.RATING
                          ? givenReview.eventName
                          : 'N/A - Endorsement'}
                      </span>
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => onShowWorkerProfile!(givenReview.workerId)}
                    >
                      {givenReview.getWorkerName()}
                    </button>
                  </td>
                  <td>{givenReview.workInfo?.position || 'N/A'}</td>
                  <td>{givenReview.getWorkedDateRange()}</td>
                  <td>{givenReview.getPerformance()}</td>
                  <td>{givenReview.getAuthorName()}</td>
                  {/* BUTTONS FOR ROW ACTIONS */}
                  <td className="actions">
                    <span className="me-3">{getDateCreated(givenReview)}</span>
                    <Button
                      onClick={() => openGivenReviewModal(givenReview)}
                      className="action-btn me-2"
                    >
                      open
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p>No new notifications.</p>
      )}
    </div>
  );
}

export default ReviewsAndEndorsementsTable;
