import { useState } from 'react';
import { Alert, Image, Modal, Stack } from 'react-bootstrap';
import { FaRegTrashCan } from 'react-icons/fa6';
import { IoMdClose } from 'react-icons/io';
import useAlert from 'src/hooks/useAlert';
import {
  deleteAdditionalPicture,
  saveAdditionalPicture,
} from 'src/apps/worker-frontend/services/worker/api';
import { useWorkerProfileContext } from 'src/apps/worker-frontend/state/workerProfileContext';
import { ThLoading } from 'src/components/elements';
import DragAndDrop from 'src/components/elements/DragAndDrop/DragAndDrop';

import './styles.css';

interface MediaModalProps {
  onCloseModal: () => void;
}

function MediaModal({ onCloseModal }: MediaModalProps) {
  const { workerMediaFiles, setWorkerMediaFiles, workerProfile } =
    useWorkerProfileContext();
  const [isLoading, setIsLoading] = useState(false);
  const { alertMessage, showErrorAlert } = useAlert();

  const onFilesSelected = async (
    path: string,
    name: string,
    contentType: string
  ) => {
    try {
      setIsLoading(true);
      const result = await saveAdditionalPicture(path, name, contentType);
      setWorkerMediaFiles((prevState) => {
        return [...prevState!, ...result.pictures];
      });
    } catch (error) {
      showErrorAlert('An error ocurred uploading the file.');
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteFile = async (id: number) => {
    try {
      setIsLoading(true);
      await deleteAdditionalPicture(id);
      setWorkerMediaFiles((prevState) => {
        return prevState?.filter((mediaFile) => mediaFile.id !== id);
      });
    } catch (error) {
      showErrorAlert('An error ocurred deleting the file.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show centered className="p-3" size="lg" onHide={onCloseModal}>
      {isLoading && <ThLoading />}
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          Media
          <span className="ms-2 section-percentages">(1% each, max 9%)</span>
        </Modal.Title>
        <Stack
          direction="horizontal"
          className="justify-content-end ms-auto"
          gap={2}
        >
          <IoMdClose
            size={20}
            className="cursor-point"
            onClick={onCloseModal}
          />
        </Stack>
      </Modal.Header>
      <Modal.Body>
        <Stack
          direction="horizontal"
          gap={4}
          className="mb-3 pictures-container"
        >
          {workerMediaFiles?.length ? (
            workerMediaFiles?.map((picture) => (
              <div
                key={picture.id}
                onClick={() => onDeleteFile(picture.id)}
                className="picture-box"
              >
                <Image
                  src={picture.url}
                  width={150}
                  height={150}
                  rounded
                  className="object-fit-cover"
                />
                <div className="delete-box">
                  <FaRegTrashCan size={36} />
                </div>
              </div>
            ))
          ) : (
            <span className="text-gray fst-italic">
              Worker does not have media files.
            </span>
          )}
        </Stack>
        {workerMediaFiles && workerMediaFiles.length >= 9 ? (
          <div className="max-imgs-reached">
            You reached the maximum amount of media files.
          </div>
        ) : (
          <DragAndDrop
            supportedFiles={['jpg', 'jpeg', 'png']}
            maxSize={6}
            entityId={workerProfile?.id?.toString()}
            onFilesSelected={onFilesSelected}
          />
        )}
      </Modal.Body>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </Modal>
  );
}

export default MediaModal;
