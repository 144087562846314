import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { BsChat } from '@react-icons/all-files/bs/BsChat';
import { BsPersonCheck } from '@react-icons/all-files/bs/BsPersonCheck';
import { BiSortAlt2 } from 'react-icons/bi';
import {
  Reducer,
  ReducerState,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap';
import { ThWorkerRow } from 'src/apps/company-frontend/components/th';
import { isMobile } from 'react-device-detect';
import ITalentsData, {
  ITalentData,
} from 'src/apps/company-frontend/types/talents.type';
import * as workerApi from 'src/apps/company-frontend/services/worker/api';
import * as jobAssignmentApi from 'src/apps/company-frontend/services/jobAssignment/api';
import IFilterData, {
  ISortOrder,
} from 'src/apps/company-frontend/types/talent-filterdata.type';
import { MilesDistance } from 'components/elements/MilesDistance/MilesDistance';
import { IN_NETWORK_TAG, ITag } from 'types/tag.type';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import QueryKeys from 'src/constants/queryKeys';
import { IWorkerData } from 'types/worker.type';
import { HiOutlineShare } from 'react-icons/hi';
import { JobHiringType } from 'src/constants/jobHiringType';
import { JobAssignmentStatus } from 'types/job-assignment-status.type';
import {
  LoaderImageGrid,
  LoaderPersonCard,
} from 'components/elements/ComponentLoader';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import useAlert from 'src/hooks/useAlert';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';
import { useJobAssignmentStatus } from 'src/hooks/useJobAssignmentStatus';
import {
  DEFAULT_MILES_RADIUS,
  InitialSearchState,
} from 'src/constants/jobInitialSearch';
import { FilterType } from 'src/constants/jobFilterType';
import { JobSearchMessages } from 'src/constants/noDataFoundMessages';
import { NoDataFound } from 'components/elements/NoDataFound';
import { useCompanyContext } from 'src/apps/company-frontend/state/companyContext';
import { JobWorkerSideModal } from 'src/apps/company-frontend/components/JobWorker';
import IActionButton from 'types/action-button.type';
import getNextPage from 'src/utils/Pagination';
import useUpdateWorker from 'src/apps/company-frontend/state/useUpdateWorker/useUpdateWorker';
import { containsInNetworkBadge } from 'src/apps/company-frontend/services/worker/workerUtils';
import { AutoCompleteAddress, ThLoading } from 'components/elements';
import { useUserContext } from 'src/state/userContext';
import { UserRole } from 'src/constants/userRoles';
import HireModal from 'src/apps/company-frontend/components/HireModal';
import { useJobShiftContext } from 'src/apps/company-frontend/state/jobShiftContext';
import SearchTalentInput from 'src/apps/company-frontend/components/SearchTalentInput/SearchTalentInput';
import { useJobWorkerShiftContext } from 'src/apps/company-frontend/state/jobWorkerShiftContext';
import AdvancedFilters from './AdvancedFilters';
import AdvancedFiltersBadges from './AdvancedFiltersBadges';
import { PaywallSidePanel } from './PaywallSidePanel';
import ModalJoinPremium from '../../../components/Payment/ModalJoinPremium';

function SearchTalent(): React.JSX.Element {
  const DEBOUNCE_RANGE_TIME = 500;
  const DEBOUNCE_TEXT_TIME = 500;
  const { chatPerson, openChat } = useChatContext();
  const [filterType, setFilterType] = useState(FilterType.ADDRESS);
  const [placeholderSearch, setPlaceholderSearch] = useState(
    `Search by ${filterType.toLowerCase()}`
  );
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false);
  const [openHireModal, setOpenHireModal] = useState<JobHiringType | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const [showWorkerProfile, setShowWorkerProfile] = useState(false);
  const [selectedWorkerProfile, setSelectedWorkerProfile] =
    useState<ITalentData>();

  const {
    shifts,
    refetch: refetchShifts,
    selectedWorker,
    setStatusFilter,
    setSelectedWorker,
    setFilteredShifts,
    setSelectedShifts,
  } = useJobShiftContext();

  const [isLoadingAction, setLoadingAction] = useState(false);
  const [remainingTalents, setRemainingTalents] = useState(0);
  const { job, initialSearch, setInitialSearch, isInternalMember } =
    useJobContext();
  const { premium: isPremiumCompany } = useCompanyContext();
  const { id, hasRole } = useUserContext();
  const nameOrEmailRef = useRef<HTMLInputElement>(null);

  const initialFilters = {
    jobId: job?.id,
    filterType: FilterType.ADDRESS,
    filterWorkers: 'ALL',
    miles: DEFAULT_MILES_RADIUS,
    address: job && !job.nationalJob ? job.address.googleAddress : '',
    addressLatitude: job && !job.nationalJob ? job.address.latitude : undefined,
    addressLongitude:
      job && !job.nationalJob ? job.address.longitude : undefined,
    name: '',
    sortOrder: ISortOrder.DESC,
  };

  type IFilterReducerData = IFilterData & Reducer<any, any>; // To handle the crybaby TS compiler
  // Allow setting partial filters state
  const filtersReducer: Reducer<IFilterData, Partial<IFilterData>> = (
    state: IFilterData,
    newState: Partial<IFilterData>
  ): IFilterData => ({
    ...state,
    ...newState,
  });
  const [filters, setFilters] = useReducer(
    filtersReducer,
    initialFilters as ReducerState<IFilterReducerData>
  );

  const [advancedFilters, setAdvancedFilters] = useState({} as IFilterData);

  const { showSuccessAlert, showWarningAlert, alertMessage } = useAlert();
  const { updateWorker, updatedWorker, fetchUpdatedWorker } = useUpdateWorker();

  const { setIsJobWorkerSideModalOpen } = useJobWorkerShiftContext();
  const jobAssignmentStatus = useJobAssignmentStatus();

  const handleShowWorkerProfile = (talent: ITalentData) => {
    if (!isPremiumCompany) {
      return;
    }
    setSelectedWorkerProfile(talent);
    setShowWorkerProfile(true);
    refetchShifts();
    setSelectedShifts([]);
    setIsJobWorkerSideModalOpen(true);
    setSelectedWorker(talent.worker);
  };

  const offerOrHire = (talent: ITalentData | undefined) => {
    const assignmentStatus = talent?.jobAssignment?.status;
    const { isApplicant, isEligibleForOffer } =
      jobAssignmentStatus(assignmentStatus);

    if (!isPremiumCompany) {
      return null;
    }
    if (assignmentStatus === undefined) {
      return JobHiringType.OFFER;
    }
    if (isApplicant()) {
      return JobHiringType.HIRE;
    }
    if (
      talent?.jobPlacement?.status !== JobAssignmentStatus.OFFERED &&
      isEligibleForOffer()
    ) {
      return JobHiringType.OFFER;
    }

    return null;
  };

  const handleShowOfferHireModal = (talent: ITalentData) => {
    if (!isPremiumCompany) {
      return;
    }
    setSelectedWorkerProfile(talent);
    setOpenHireModal(offerOrHire(talent));
    setSelectedWorker(talent.worker);
  };

  const handleSuccessHireModal = (message?: string) => {
    if (message) {
      showSuccessAlert(message);
    }
    updateWorker({
      workerId: selectedWorker?.id,
      jobId: job?.id,
    });
    setOpenHireModal(null);
    setSelectedWorker(undefined);
    setStatusFilter([]);
  };

  const handleFailureHireModal = (message?: string) => {
    if (message) {
      showWarningAlert(message);
    }
    setOpenHireModal(null);
    setSelectedWorker(undefined);
    setStatusFilter([]);
  };

  const handleCloseHireModal = () => {
    setOpenHireModal(null);
    setSelectedWorker(undefined);
    setStatusFilter([]);
  };

  const handleToggleAdvancedFilters = () => {
    if (!isPremiumCompany) {
      return;
    }
    setOpenAdvancedFilters(!openAdvancedFilters);
  };

  const handleCloseAdvancedFilters = () => {
    if (!isPremiumCompany) {
      return;
    }
    setOpenAdvancedFilters(false);
  };

  const fetchTalents = useCallback(
    async ({ pageParam = 0 }) => {
      if (!filters.name && !filters.address && !filters.email) {
        return new Promise<ITalentsData>((resolve) => {
          const emptyResponse: ITalentsData = {} as ITalentsData;
          resolve(emptyResponse);
        });
      }
      return workerApi.fetchTalents(
        { ...filters, ...advancedFilters },
        pageParam
      );
    },
    [filters, advancedFilters]
  );

  const queryKey = `${QueryKeys.COMPANY_TALENTS}_${job?.id}`;
  const queryClient = useQueryClient();

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: fetchTalents,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    // TODO: enable this when component is on focus !!!
    enabled: job !== undefined && job?.id !== undefined,
    getNextPageParam: (lastPage, allPages) => getNextPage(lastPage, allPages),
    initialPageParam: 0,
  });

  useEffect(() => {
    if (fetchUpdatedWorker) {
      const fetchUpdatedWorkerPayload = {
        updatedWorker,
        data,
        queryClient,
        queryKey,
      };
      fetchUpdatedWorker(fetchUpdatedWorkerPayload);
    }
  }, [fetchUpdatedWorker, updatedWorker, data, queryClient, queryKey]);

  const deferredFetch = useCallback(
    () => setTimeout(() => refetch(), 100),
    [refetch]
  ); // because useState is async

  const hasSomeDataToShow = !!data && data?.pages?.[0].results?.length > 0;

  const handleCloseWorkerProfile = () => {
    if (!isPremiumCompany) {
      return;
    }
    if (shifts) {
      setFilteredShifts(shifts);
    }
    setShowWorkerProfile(false);
    setIsJobWorkerSideModalOpen(false);
    setStatusFilter([]);
    setSelectedWorker(undefined);
  };

  useEffect(() => {
    if (job?.id) {
      setFilters({
        jobId: job?.id,
        address: job && !job.nationalJob ? job.address.googleAddress : '',
        addressLatitude:
          job && !job.nationalJob ? job.address.latitude : undefined,
        addressLongitude:
          job && !job.nationalJob ? job.address.longitude : undefined,
      });

      deferredFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.id]);

  useEffect(() => {
    if (data && data?.pages[0]?.results) {
      let totalResults = 0;
      data.pages.map((page) => {
        totalResults += page.results.length;
        return page;
      });
      const count = data?.pages[0]?.pagination?.count ?? 0;
      setRemainingTalents(count - totalResults);
    }
  }, [data]);

  useEffect(() => {
    if (nameOrEmailRef.current) {
      nameOrEmailRef.current.focus();
    }
  });

  useEffect(() => {
    if (filterType === FilterType.NAME || filterType === FilterType.EMAIL) {
      if (nameOrEmailRef.current) {
        nameOrEmailRef.current.value = '';
      }
    } else {
      refetch();
    }

    setFilters({
      name: filterType === FilterType.NAME ? '' : undefined,
      email: filterType === FilterType.EMAIL ? '' : undefined,
      address: filterType === FilterType.ADDRESS ? filters.address : undefined,
      sortOrder: ISortOrder.DESC,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;
    setFilters({
      name: filterType === FilterType.NAME ? value : undefined,
      email: filterType === FilterType.EMAIL ? value : undefined,
      address: filterType === FilterType.ADDRESS ? value : undefined,
      sortOrder: ISortOrder.DESC,
    });
  };

  useEffect(() => {
    if (isMobile) {
      refetch();
    } else if (
      filterType !== FilterType.ADDRESS &&
      !filters.name &&
      !filters.email
    ) {
      // refetch when the user clears the input
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.name, filters.email, filters.miles]);

  const handleInitialSearch = debounce(async () => {
    deferredFetch();
  }, DEBOUNCE_TEXT_TIME);

  useEffect(() => {
    if (initialSearch !== InitialSearchState.DONE) {
      setInitialSearch(InitialSearchState.DONE);
      handleInitialSearch();
    }
  }, [handleInitialSearch, initialSearch, setInitialSearch]);

  const handleMilesRange = debounce((newMilesRange: number | string) => {
    if (!isPremiumCompany) {
      return;
    }
    setFilters({
      miles: newMilesRange,
    });
  }, DEBOUNCE_RANGE_TIME);

  useEffect(() => {
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.sortBy, filters.sortOrder]);

  const handleSortSearchTalents = async () => {
    if (!isPremiumCompany) {
      return;
    }
    setFilters({
      sortOrder:
        filters.sortOrder !== undefined && filters.sortOrder === ISortOrder.DESC
          ? ISortOrder.ASC
          : ISortOrder.DESC,
    });
  };

  const handleSetAdvancedFilters = (newFilters: IFilterData) => {
    if (!isPremiumCompany) {
      return;
    }
    setAdvancedFilters({ ...newFilters });
  };

  const shareJobToWorker = async (talent: ITalentData) => {
    if (!isPremiumCompany) {
      return;
    }
    setLoadingAction(true);
    const result = await jobAssignmentApi.shareJobToWorker(
      job?.id,
      talent.worker.id
    );
    setLoadingAction(false);
    if (result && result.success) {
      showSuccessAlert(result.message);
      updateWorker({
        workerId: talent.worker.id,
        jobId: job?.id,
      });
    } else {
      showWarningAlert(
        'An error has occurred while was trying to share job with worker'
      );
    }
  };

  const handleOpenChat = (talent: ITalentData) => {
    if (!isPremiumCompany) {
      return;
    }
    const { isHiredOrApplicant } = jobAssignmentStatus(
      talent.jobAssignment?.status
    );
    openChat(
      talent.worker,
      talent.jobAssignment?.id || null,
      isHiredOrApplicant()
    );
  };

  const showModalCompanyPremiumMessage = (
    success: boolean,
    message: string
  ) => {
    if (success) {
      showSuccessAlert(message);
    } else {
      showWarningAlert(message);
    }
  };

  const isHired = (talent: ITalentData) => {
    const status = talent?.jobAssignment?.status;
    return jobAssignmentStatus(status).isHired();
  };

  const getActions = (talent: ITalentData) => {
    if (!job?.datePublished) {
      return [];
    }
    const actions: IActionButton[] = [];

    if (job?.isActive || talent.jobAssignment?.hired) {
      actions.push({
        label: 'Message',
        variant: 'light',
        size: 'sm',
        action: () => handleOpenChat(talent),
        icon: <BsChat size={10} />,
      });
    }

    if (job?.isActive) {
      if (!isHired(talent) && offerOrHire(talent)) {
        actions.push({
          label: offerOrHire(talent) || '',
          variant: 'light',
          size: 'sm',
          action: () => handleShowOfferHireModal(talent),
          icon: <BsPersonCheck size={10} />,
        });
      }

      if (!talent.jobAssignment && !talent.jobPlacement) {
        actions.push({
          label: 'SHARE',
          variant: 'light',
          size: 'sm',
          action: () => shareJobToWorker(talent),
          icon: <HiOutlineShare size={10} />,
        });
      }
    }
    return actions;
  };

  const getTags = (matchSkills: boolean, worker: IWorkerData): ITag[] => {
    const tags: ITag[] = [];
    if (containsInNetworkBadge(worker)) {
      tags.push(IN_NETWORK_TAG);
    }
    if (matchSkills) {
      tags.push({
        label: 'MATCH',
        bg: 'light-success',
        bgText: 'text-success',
        className: 'cursor-default',
      });
    }
    return tags;
  };

  const handleFilterType = (type: any) => {
    setFilterType(type);
    setFilters({
      filterType: type,
    });
    setPlaceholderSearch(
      `Search by ${type.toLowerCase().replace('email', 'e-mail')}`
    );
    if (
      type === FilterType.ADDRESS &&
      filters.address === undefined &&
      job?.id
    ) {
      setFilters({
        jobId: job?.id,
        address: job && !job.nationalJob ? job.address.googleAddress : '',
        addressLatitude:
          job && !job.nationalJob ? job.address.latitude : undefined,
        addressLongitude:
          job && !job.nationalJob ? job.address.longitude : undefined,
      });
    }
  };

  const renderWorkers = () => {
    if (isMobile && openAdvancedFilters) {
      return '';
    }

    if (isFetching && !isFetchingNextPage) {
      return (
        <Col md={12}>
          {isMobile ? (
            <LoaderPersonCard />
          ) : (
            <LoaderImageGrid column={openAdvancedFilters ? 4 : 6} />
          )}
        </Col>
      );
    }

    const renderNoDataFound = () => (
      <Row>
        <Col>
          <NoDataFound
            noResultsMessage={JobSearchMessages.NO_RESULTS}
            helperMessage={JobSearchMessages.NO_RESULTS_UPDATE_FILTERS}
          />
        </Col>
      </Row>
    );

    const renderDataFound = () => {
      return (
        <Row>
          {data?.pages?.map(
            (page) =>
              page?.results?.map((talent, index) => (
                <Col
                  key={talent.worker.id}
                  md={openAdvancedFilters ? 3 : 2}
                  className="mt-3"
                >
                  <div className="panel">
                    <ThWorkerRow
                      index={index + 1}
                      worker={talent.worker}
                      distance={talent.distance}
                      isFavoritedByCompany={talent.isFavoritedByCompany}
                      isBlockedByCompany={talent.isBlockedByCompany}
                      thumbStyle
                      tags={getTags(talent.matchSkills, talent.worker)}
                      actionButtons={getActions(talent)}
                      openDetails={() => handleShowWorkerProfile(talent)}
                      isHired={isHired(talent)}
                      isPremiumCompany={isPremiumCompany}
                      isInternalMember={isInternalMember(id)}
                      isCompanyAdmin={hasRole(UserRole.ROLE_COMPANY_ADMIN)}
                    />
                  </div>
                </Col>
              ))
          )}
          {selectedWorkerProfile && openHireModal && (
            <HireModal
              jobAssignment={selectedWorkerProfile.jobAssignment}
              worker={selectedWorkerProfile.worker}
              handleSuccess={handleSuccessHireModal}
              handleFailure={handleFailureHireModal}
              handleClose={handleCloseHireModal}
              hiringType={openHireModal}
            />
          )}
          {!isPremiumCompany && (
            <PaywallSidePanel setShowModal={setShowModal} />
          )}
        </Row>
      );
    };

    return (
      <>
        {isSuccess &&
          (hasSomeDataToShow ? renderDataFound() : renderNoDataFound())}
        {isFetchingNextPage && (
          <Col md={12}>
            {isMobile ? (
              <LoaderPersonCard />
            ) : (
              <LoaderImageGrid column={openAdvancedFilters ? 4 : 6} />
            )}
          </Col>
        )}
        {hasNextPage && isPremiumCompany && (
          <Row>
            <Col className="text-center mt-3">
              <Button
                variant="light"
                onClick={() => fetchNextPage()}
                disabled={isFetching}
              >
                <b>SHOW MORE ({remainingTalents})</b>
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const handleChangeAddress = (
    place: google.maps.places.PlaceResult | string
  ) => {
    if (typeof place === 'string') {
      setFilters({
        address: place,
        addressLatitude: undefined,
        addressLongitude: undefined,
      });
    } else {
      const location = place.geometry?.location;
      if (location) {
        setFilters({
          addressLatitude: location.lat(),
          addressLongitude: location.lng(),
        });
      } else {
        setFilters({
          addressLatitude: undefined,
          addressLongitude: undefined,
        });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isPremiumCompany && !isFetching) {
      refetch();
    }
  };

  return (
    <div>
      {(isLoadingAction || updatedWorker.isLoadingUpdateWorker) && (
        <ThLoading />
      )}
      <Row>
        <Col lg={2} xs={4}>
          <DropdownButton
            variant="light w-100"
            disabled={isFetching || !isPremiumCompany}
            title={
              <b style={{ fontSize: isMobile ? '14px' : '' }}>
                {(isMobile ? '' : 'FILTER BY ') + filterType}{' '}
                <FiChevronDown size={24} />
              </b>
            }
            onSelect={(e) => handleFilterType(e)}
          >
            <Dropdown.Item eventKey={FilterType.NAME}>
              {FilterType.NAME}
            </Dropdown.Item>
            <Dropdown.Item eventKey={FilterType.ADDRESS}>
              {FilterType.ADDRESS}
            </Dropdown.Item>
            <Dropdown.Item eventKey={FilterType.EMAIL}>
              {FilterType.EMAIL}
            </Dropdown.Item>
          </DropdownButton>
        </Col>

        <Col md={isMobile ? 5 : 4} xs={8}>
          {filterType === FilterType.ADDRESS ? (
            <AutoCompleteAddress
              initialValue={filters.address}
              name="address"
              placeholder={placeholderSearch}
              disabled={isFetching || !isPremiumCompany || !!filters.jobId}
              resultEvent={handleChangeAddress}
            />
          ) : (
            <SearchTalentInput
              placeholder={placeholderSearch}
              disabled={isFetching || !isPremiumCompany}
              onChange={handleInputOnChange}
              name={filterType.toLowerCase()}
              inputRef={nameOrEmailRef}
              onKeyDown={handleKeyDown}
            />
          )}
        </Col>

        <Col md={2} xs={6} className={isMobile ? 'mt-3' : ''}>
          <MilesDistance
            handleMilesRange={handleMilesRange}
            disabled={isFetching || !isPremiumCompany}
            milesRange={filters.miles ?? DEFAULT_MILES_RADIUS}
          />
        </Col>

        {!isMobile && (
          <Col md={1}>
            <Button
              disabled={!isPremiumCompany}
              type="submit"
              onClick={() => refetch()}
            >
              SEARCH
            </Button>
          </Col>
        )}

        {isPremiumCompany && (
          <Col
            md={3}
            xs={6}
            className={`${
              isMobile ? 'mt-3 d-inline-flex justify-content-end' : ''
            } text-end`}
            style={{ marginLeft: 'auto' }}
          >
            <Button
              variant="light"
              disabled={data?.pages[0]?.results?.length === 0 || isFetching}
              onClick={handleSortSearchTalents}
            >
              <BiSortAlt2 size={25} />
            </Button>
            <Button
              className="ms-3"
              variant={openAdvancedFilters ? 'primary' : 'light'}
              disabled={isFetching}
              onClick={handleToggleAdvancedFilters}
            >
              <BiFilterAlt size={25} />
              {!isMobile && <b className="">&nbsp;ADVANCED FILTERS</b>}
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={openAdvancedFilters ? 8 : 12}>
          <AdvancedFiltersBadges
            handleClose={handleCloseAdvancedFilters}
            advancedFilters={advancedFilters}
            setAdvancedFilters={handleSetAdvancedFilters}
          />
          {renderWorkers()}
        </Col>
        {openAdvancedFilters && (
          <Col md={4}>
            <AdvancedFilters
              filterByStatus
              handleClose={handleCloseAdvancedFilters}
              advancedFilters={advancedFilters}
              setAdvancedFilters={setAdvancedFilters}
              onSearch={refetch}
            />
          </Col>
        )}
      </Row>
      {showWorkerProfile &&
        selectedWorkerProfile &&
        selectedWorker &&
        chatPerson == null && (
          <JobWorkerSideModal
            handleCloseWorkerProfile={handleCloseWorkerProfile}
            worker={selectedWorker}
            isJobRelated
          />
        )}
      {showModal && (
        <ModalJoinPremium
          showMessage={showModalCompanyPremiumMessage}
          setShowModal={setShowModal}
        />
      )}
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </div>
  );
}

export default SearchTalent;
