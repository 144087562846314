import { useJobAssignmentStatus } from 'src/hooks/useJobAssignmentStatus';
import { JobAssignmentStatus } from 'types/job-assignment-status.type';
import { ThJobAssignmentLog } from 'src/types/job-assignment.type';
import DATE_FORMATS from 'src/constants/dateFormat';
import { Panel } from 'components/elements';
import { format } from 'date-fns';

interface ApplicationInfoHistoryProps {
  thJobAssignmentLogList?: ThJobAssignmentLog[];
}

export default function ApplicationInfoHistory({
  thJobAssignmentLogList,
}: ApplicationInfoHistoryProps) {
  const jobAssignmentStatus = useJobAssignmentStatus();
  return (
    <Panel>
      <p className="pb-2">
        <b>History</b>
      </p>
      {!thJobAssignmentLogList?.length ? (
        <p>
          There is no activity between the worker and this job. You can share or
          offer this job to them.
        </p>
      ) : null}
      {thJobAssignmentLogList &&
        thJobAssignmentLogList.map((history) => (
          <div key={history.id + Math.random()}>
            <p>
              <b>
                {format(
                  new Date(history.dateCreated),
                  DATE_FORMATS.DATE_FORMAT
                )}
              </b>
            </p>

            <p>
              {`${history.toStatusNatural} for `}
              <b>{history.jobEventName}</b> {' as '}
              <b>{history.jobTitle}</b> {' at '}
              <b>
                {format(
                  new Date(history.dateCreated),
                  DATE_FORMATS.TIME_FORMAT
                )}
              </b>
            </p>

            {jobAssignmentStatus(
              JobAssignmentStatus[
                history.toStatus as keyof typeof JobAssignmentStatus
              ]
            ).isHiredOrOffered() && (
              <p>
                Pay rate: $
                <b>
                  {`${history.payRate} ${history.payRatePeriod
                    .toString()
                    .toLowerCase()}`}
                </b>
              </p>
            )}
            <hr />
          </div>
        ))}
    </Panel>
  );
}
