import { toast } from 'react-toastify';
import { ToastType } from 'src/types/toasts';
import { BsFillCheckCircleFill } from 'react-icons/bs/index';
import { BiErrorCircle, BiInfoCircle } from 'react-icons/bi/index';

export const displayToast = (toastType: ToastType, response: string) => {
  switch (toastType) {
    case ToastType.INFO:
      toast.info(response, { icon: <BiInfoCircle size={18} color="yellow" /> });
      break;
    case ToastType.SUCCESS:
      toast.success(response, {
        icon: <BsFillCheckCircleFill size={18} color="green" />,
      });
      break;
    case ToastType.ERROR:
      toast.error(response, { icon: <BiErrorCircle size={18} color="red" /> });
      break;
    default:
      break;
  }
};
