import { Form, InputGroup, FormControlProps } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import './styles.css';

interface Props extends FormControlProps {
  name: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

function ThSearchInput({ inputRef, ...props }: Props) {
  return (
    <InputGroup>
      <InputGroup.Text className="th-search-input">
        <BiSearch size={22} data-testid="bi-search" />
      </InputGroup.Text>
      <Form.Control className="th-search-input" ref={inputRef} {...props} />
    </InputGroup>
  );
}

export default ThSearchInput;
