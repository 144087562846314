import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import IDirectMessage from 'src/types/direct-message.type';
import { IWorkerData } from 'src/types/worker.type';
import * as api from 'src/apps/company-frontend/services/directMessage/api';
import { ISortableDirectMessages } from 'src/components/elements/DirectMessage/DirectMessageChat';

interface IDirectMessageContext {
  worker: IWorkerData | null;
  chatId: number | null;
  directMessages: IDirectMessage[];
  unsentMessages: IDirectMessage[];
  orderedMessages: ISortableDirectMessages[];
  setDirectMessages: (messages: IDirectMessage[]) => void;
  setUnsentMessages: (messages: IDirectMessage[]) => void;
  setChatId: (chatAssignmentId: number | null) => void;
  openDirectMessageChat: (
    person: IWorkerData,
    chatId: number | null,
    isPremiumCompany: boolean
  ) => void;
  clearDirectMessageData: () => void;
}

export const DirectMessageContext = createContext<IDirectMessageContext>({
  worker: null,
  chatId: null,
  directMessages: [],
  unsentMessages: [],
  orderedMessages: [],
  setDirectMessages: () => {},
  setUnsentMessages: () => {},
  setChatId: () => {},
  openDirectMessageChat: () => {},
  clearDirectMessageData: () => {},
});

interface ProviderProps {
  children: React.ReactNode;
}

export function DirectMessageProvider({ children }: ProviderProps) {
  const [worker, setWorker] = useState<IWorkerData | null>(null);
  const [chatId, setChatId] = useState<number | null>(null);
  const [directMessages, setDirectMessages] = useState<IDirectMessage[]>([]);
  const [unsentMessages, setUnsentMessages] = useState<IDirectMessage[]>([]);
  const [orderedMessages, setOrderedMessages] = useState<
    ISortableDirectMessages[]
  >([]);

  useEffect(() => {
    let chatMsgs: ISortableDirectMessages[] = directMessages.map((m) => ({
      id: m.id,
      created: m.dateCreated,
      createdTimestamp: new Date(m.dateCreated.toLocaleString()).valueOf(),
      message: m,
      sent: m.sent === undefined || m.sent === true,
    }));

    chatMsgs = chatMsgs.concat(
      unsentMessages.map((m) => ({
        id: m.id,
        created: m.dateCreated,
        createdTimestamp: new Date(m.dateCreated.toLocaleString()).valueOf(),
        systemMessage: m,
        sent: false,
      }))
    );
    const unsentList = chatMsgs
      .filter((x) => !x.sent)
      .sort(
        (a, b) =>
          new Date(a.createdTimestamp).getTime() -
          new Date(b.createdTimestamp).getTime()
      );

    const allSent = chatMsgs
      .filter((x) => x.sent)
      .sort(
        (a, b) =>
          new Date(a.createdTimestamp).getTime() -
          new Date(b.createdTimestamp).getTime()
      );

    const all = allSent.concat(unsentList);
    setOrderedMessages(all);
  }, [directMessages, setDirectMessages, unsentMessages]);

  const clearDirectMessageData = useCallback(() => {
    setWorker(null);
    setChatId(null);
  }, []);

  const openDirectMessageChat = useCallback(
    (person: IWorkerData, id: number | null, isPremiumCompany: boolean) => {
      if (!isPremiumCompany) {
        return;
      }
      setWorker(person);
      setChatId(id);
      if (id) {
        api.readDirectMessages(id);
      }
    },
    []
  );

  const contextValue: IDirectMessageContext = useMemo(() => {
    return {
      worker,
      chatId,
      directMessages,
      unsentMessages,
      orderedMessages,
      setChatId,
      setDirectMessages,
      setUnsentMessages,
      openDirectMessageChat,
      clearDirectMessageData,
    };
  }, [
    worker,
    chatId,
    directMessages,
    unsentMessages,
    orderedMessages,
    setDirectMessages,
    setUnsentMessages,
    openDirectMessageChat,
    clearDirectMessageData,
  ]);

  return (
    <DirectMessageContext.Provider value={contextValue}>
      {children}
    </DirectMessageContext.Provider>
  );
}

export function useDirectMessageContext() {
  const context: IDirectMessageContext = useContext(DirectMessageContext);
  if (context === undefined) {
    throw new Error(
      'useDirectMessageContext must be used within a DirectMessageProvider. Wrap a parent component in <DirectMessageProvider> to fix this error.'
    );
  }
  return context;
}
