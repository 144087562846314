import { Button, Figure, Row, Stack } from 'react-bootstrap';
import { FiUser } from 'react-icons/fi';
import { isMobile } from 'react-device-detect';
import { IWorkerData } from 'src/types/worker.type';

interface Params {
  worker: IWorkerData;
}

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

export default function DirectMessageChatHeader({ worker }: Params) {
  const viewProfile = () => {
    window.open(
      `${BASE_PATH}workers/${worker.customUrl ?? worker.id}`,
      '_blank'
    );
  };

  return (
    <>
      <Row>
        <Stack direction="horizontal" gap={4}>
          <div className="cursor-point">
            <Figure.Image
              className="avatar rounded-circle"
              src={worker.profileImageUrl}
              style={{ width: 60, height: 60 }}
              onClick={viewProfile}
            />
          </div>
          <div className="float-left align-items-center truncate-text">
            <b
              className="cursor-point"
              onClick={viewProfile}
            >{`${worker.firstName} ${worker.lastName}`}</b>
            <br />
            <span>
              <small>
                <em>This is a direct message thread &nbsp;</em>
              </small>
            </span>
          </div>
          <Button className="ms-auto" variant="light" onClick={viewProfile}>
            {isMobile ? <FiUser size={20} /> : <b>VIEW PROFILE</b>}
          </Button>
        </Stack>
      </Row>
      <hr />
    </>
  );
}
