import { ReactElement } from 'react';
import { TbPointFilled } from 'react-icons/tb';
import { Col, Row } from 'react-bootstrap';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import { GENDERS, Gender } from 'src/constants/genderType';
import { capitalizeValue } from 'src/utils/StringUtils';
import './style.css';

export default function JobSkills(): ReactElement {
  const { job } = useJobContext();

  if (!job) {
    return <div />;
  }

  const getGenderSkills = (): ReactElement => {
    const genderRequirement = job.skills.filter((x) => GENDERS.includes(x));

    if (
      genderRequirement?.length === 1 &&
      genderRequirement[0] !== Gender.ANY_GENDER.valueOf()
    ) {
      return (
        <>
          <Row className="mt-1">
            <p className="job-description-text">
              <b>Gender requirement:</b>
            </p>
          </Row>
          <Col sm={12} className="d-flex ms-4">
            <TbPointFilled className="mt-1 me-1" />
            <p className="job-description-text">
              {capitalizeValue(genderRequirement[0])}
            </p>
          </Col>
        </>
      );
    }
    return <div />;
  };

  return (
    <>
      <div>
        <br />
        {job.skills?.length > 0 && (
          <Row className="mt-1">
            <p className="job-description-text">
              <b>Required skills:</b>
            </p>
          </Row>
        )}
        {job.skills
          .filter((x) => !GENDERS.includes(x))
          ?.map((skill) => (
            <Col key={skill} sm={12} className="d-flex ms-4">
              <TbPointFilled className="point-icon-custom me-1" />
              <p className="job-description-text">{skill}</p>
            </Col>
          ))}
      </div>
      {getGenderSkills()}
    </>
  );
}
