export enum StatusBadge {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  REDEEMED = 'REDEEMED',
}

export enum CallbackStatus {
  COMPLETED = 'completed',
  TERMINATED = 'terminated',
}

export enum SubmissionStatus {
  COMPLETED = 'completed',
  TERMINATED = 'terminated',
}

export interface SurveyStatusProps {
  title: string;
  description?: string;
  reward: string;
  date: string;
  status: SubmissionStatus;
  callbackReceived: boolean;
  callbackStatus: CallbackStatus;
}

export type SurveyBankData = {
  date: Date;
  potentialAmount: number;
  approvedAmount: number;
};
