import { Form, FormControlProps, InputGroup } from 'react-bootstrap';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, DateRangePicker, Range } from 'react-date-range';
import './style.css';
import { AiOutlineCalendar } from 'react-icons/ai';
import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { supportsPassiveOnEventListener } from 'src/utils/Polyfill';
import DATE_FORMATS from 'src/constants/dateFormat';

interface DatePickerProps
  extends FormControlProps,
    React.RefAttributes<HTMLElement> {
  setStart: (value: string) => void;
  setEnd: (value: string) => void;
  start?: Date;
  end?: Date;
  minDate?: Date;
  validationError?: string;
  onChange?: (...event: any[]) => void;
}

function ThDateRangePicker({
  setStart,
  setEnd,
  start,
  end,
  minDate,
  validationError,
  ...props
}: DatePickerProps) {
  const { isInvalid, onChange } = props;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRangePickerField, setDateRangePickerField] = useState('');

  const componentRef = useRef<HTMLDivElement>(null);

  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: start ? new Date(start) : undefined,
      endDate: end ? new Date(end) : undefined,
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (dateRange[0].startDate && dateRange[0].endDate) {
      const formattedStart = format(
        dateRange[0].startDate,
        DATE_FORMATS.DATE_FORMAT
      );
      const formattedEnd = format(
        dateRange[0].endDate,
        DATE_FORMATS.DATE_FORMAT
      );

      setStart(formattedStart);
      setEnd(formattedEnd);
      setDateRangePickerField(`${formattedStart} - ${formattedEnd}`);
    }
  }, [dateRange, setStart, setEnd]);

  // CLEAR SELECTION IF START AND END ARE undefined
  useEffect(() => {
    if (!start && !end) {
      setDateRange([
        {
          startDate: undefined,
          endDate: undefined,
          key: 'selection',
        },
      ]);
      setDateRangePickerField('');
    }
  }, [start, end]);

  const changeDate = (range: Range[]) => {
    if (onChange) {
      onChange(range);
    }
    setDateRange(range);
  };

  const openDatePicker = (shouldOpen: boolean) => {
    setShowDatePicker(shouldOpen);
  };

  function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of component
       */
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          openDatePicker(false);
        }
      }
      // Bind the event listener

      const supportsPassive = supportsPassiveOnEventListener();
      document.addEventListener(
        'mousedown',
        handleClickOutside,
        supportsPassive
          ? {
              passive: true,
            }
          : false
      );
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(componentRef);

  return (
    <div ref={componentRef}>
      <InputGroup>
        <InputGroup.Text>
          <AiOutlineCalendar />
        </InputGroup.Text>
        <Form.Control
          value={dateRangePickerField}
          onClick={() => openDatePicker(true)}
          readOnly
          isInvalid={isInvalid}
          style={isInvalid ? { zIndex: 0 } : {}}
          {...props}
        />

        {isInvalid && validationError && (
          <Form.Control.Feedback type="invalid">
            {validationError}
          </Form.Control.Feedback>
        )}
      </InputGroup>

      {showDatePicker &&
        (!isMobile ? (
          <DateRangePicker
            onChange={(item) => changeDate([item.selection])}
            ranges={dateRange}
            direction="horizontal"
            rangeColors={['var(--yellow)']}
            minDate={minDate ?? new Date(2016, 0, 1)}
            maxDate={new Date()}
          />
        ) : (
          <DateRange
            onChange={(item) => changeDate([item.selection])}
            ranges={dateRange}
            direction="horizontal"
            rangeColors={['var(--yellow)']}
            minDate={minDate ?? new Date(2016, 0, 1)}
            maxDate={new Date()}
          />
        ))}
    </div>
  );
}

export default ThDateRangePicker;
