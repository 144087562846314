import { Button } from 'react-bootstrap';
import { ActivityDetail } from 'src/types/daily-activity.type';
import './styles.scss';
import { isShiftConfirmed } from 'src/services/shift/shiftService';

interface Props {
  groupedBy: string;
  filteredActivities: ActivityDetail[];
  handleConfirmMarkPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
}

export default function ButtonMarkAllAsPaid({
  handleConfirmMarkPaid,
  groupedBy,
  filteredActivities,
}: Props) {
  return (
    <span className="text-end">
      <Button
        variant="light"
        size="sm"
        disabled={filteredActivities.some(
          (item) =>
            !item.shiftPosition.isPaid || isShiftConfirmed(item.shiftPosition)
        )}
        onClick={() =>
          handleConfirmMarkPaid(groupedBy, 0, filteredActivities, true)
        }
      >
        MARK ALL AS PAID
      </Button>
    </span>
  );
}
