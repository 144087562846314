import { EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { isMobile } from 'react-device-detect';
import { useJobShiftContext } from 'src/apps/company-frontend/state/jobShiftContext';
import { ShiftPosition } from 'src/types/job.detail.type';
import { convertToTimezone } from 'src/utils/DateUtils';
import { useJobContext } from 'src/apps/company-frontend/state/jobContext';
import useMousePosition from './useMousePosition';
import renderEventContentMobile from './renderEventContentMobile';
import 'bootstrap-icons/font/bootstrap-icons.css';
import renderEventContent from './renderEventContent';
import './styles.css';

export default function CalendarView() {
  const { events, filteredShifts } = useJobShiftContext();
  const { job } = useJobContext();
  const mousePosition = useMousePosition();

  if (!job) {
    return null;
  }

  const getShiftsWithTheSameDateTime = (event: EventInput): ShiftPosition[] => {
    return (
      filteredShifts?.filter(
        (shiftItem) =>
          convertToTimezone(
            shiftItem.start,
            job.address.timezoneId
          ).toLocaleString() === event.event.start.toLocaleString() &&
          convertToTimezone(
            shiftItem.end,
            job.address.timezoneId
          ).toLocaleString() === event.event.end.toLocaleString()
      ) || []
    );
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
      headerToolbar={{
        left: isMobile ? 'prev' : 'prev next today',
        center: 'title',
        right: isMobile ? 'next' : 'dayGridMonth,timeGridWeek',
      }}
      height="auto"
      initialView={isMobile ? 'dayGridDay' : 'dayGridMonth'}
      selectable
      selectMirror
      nowIndicator
      dayMaxEvents
      eventBackgroundColor="var(--light-blue)"
      eventTextColor="var(--black)"
      displayEventTime
      timeZone="local"
      themeSystem="bootstrap5"
      buttonIcons={{
        prev: 'chevron-left',
        next: 'chevron-right',
      }}
      views={{
        dayGridMonth: {
          dayMaxEvents: 2,
        },
      }}
      events={events}
      displayEventEnd
      eventContent={(event) =>
        isMobile
          ? renderEventContentMobile(event, getShiftsWithTheSameDateTime(event))
          : renderEventContent(
              event,
              mousePosition,
              getShiftsWithTheSameDateTime(event)
            )
      }
    />
  );
}
