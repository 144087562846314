import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { TiEdit } from 'react-icons/ti';
import { getFormattedShiftTimes } from 'services/shift/shiftService';
import { ActivityDetail } from 'types/daily-activity.type';
import { useJobContext } from 'company/state/jobContext';
import DATE_FORMATS from 'constants/dateFormat';
import CheckInOutLocation from './CheckInOutLocation';
import CheckInOutImage from './CheckInOutImage';
import { Marker } from '../GoogleMapComponent';
import '../styles.scss';
import './styles.css';

interface ShiftReviewModalContentProps {
  setEditSubmittedTime: (editSubmittedTime: boolean) => void;
  activityDetail: ActivityDetail;
  markPaid: () => void;
}

export default function ShiftReviewModalContent({
  setEditSubmittedTime,
  activityDetail,
  markPaid,
}: ShiftReviewModalContentProps) {
  const { shiftPosition, dailyActivity, timeSheet } = activityDetail;

  const [isShiftPaid, setIsShiftPaid] = useState(shiftPosition.isPaid);

  useEffect(() => {
    setIsShiftPaid(shiftPosition.isPaid);
  }, [shiftPosition.isPaid]);

  const { job } = useJobContext();
  if (!job) {
    return null;
  }

  const markersClassName = 'fw-bold mb-5';

  const getEventLocationMarker = () => {
    if (job.address?.latitude && job.address?.longitude) {
      return {
        lat: job.address.latitude,
        lng: job.address.longitude,
        label: {
          text: 'Event',
          className: markersClassName,
        },
      };
    }
    return null;
  };

  const getCheckInData = () => {
    if (dailyActivity?.checkInTime) {
      if (dailyActivity.checkInSelfie) {
        return <CheckInOutImage url={dailyActivity.checkInSelfie.url} />;
      }
      if (dailyActivity.checkInLat && dailyActivity.checkInLng) {
        const markers: Marker[] = [];
        const eventLocation = getEventLocationMarker();
        if (eventLocation) {
          markers.push(eventLocation);
        }
        markers.push({
          lat: dailyActivity.checkInLat,
          lng: dailyActivity.checkInLng,
          label: {
            text: 'Check-in',
            className: markersClassName,
          },
        });

        return (
          <CheckInOutLocation
            latitude={markers[0].lat}
            longitude={markers[0].lng}
            markers={markers}
          />
        );
      }
    }
    return <div />;
  };

  const getCheckOutData = () => {
    if (dailyActivity && dailyActivity.checkOutTime) {
      if (dailyActivity.checkOutSelfie) {
        return <CheckInOutImage url={dailyActivity.checkOutSelfie.url} />;
      }
      if (dailyActivity.checkOutLat && dailyActivity.checkOutLng) {
        const markers: Marker[] = [];
        const eventLocation = getEventLocationMarker();
        if (eventLocation) {
          markers.push(eventLocation);
        }
        markers.push({
          lat: dailyActivity.checkOutLat,
          lng: dailyActivity.checkOutLng,
          label: {
            text: 'Check-out',
            className: markersClassName,
          },
        });
        return (
          <CheckInOutLocation
            latitude={markers[0].lat}
            longitude={markers[0].lng}
            markers={markers}
          />
        );
      }
    }
    return <div />;
  };

  const getCheckInOutDate = (stringDate: string) => {
    const date = new Date(stringDate);
    return `${format(date, DATE_FORMATS.DATE_FORMAT)} at ${format(
      date,
      DATE_FORMATS.TIME_FORMAT
    )}`;
  };

  const getCheckInCard = () => {
    return (
      <Card className="card-style align-items-center border-line">
        {dailyActivity && dailyActivity.localizedCheckInTime ? (
          <>
            {getCheckInData()}
            <span className="fw-bold">Check in</span>
            <span className={isMobile ? 'mobile-text-check-in-out' : ''}>
              {getCheckInOutDate(dailyActivity.localizedCheckInTime)}
            </span>
          </>
        ) : (
          <span>
            <b>The worker didn&apos;t do the check-in yet</b>
          </span>
        )}
      </Card>
    );
  };

  const getCheckOutCard = () => {
    return (
      <Card className="card-style align-items-center border-line">
        {dailyActivity && dailyActivity.localizedCheckOutTime ? (
          <>
            {getCheckOutData()}
            <span className="fw-bold">Check out</span>
            <span className={isMobile ? 'mobile-text-check-in-out' : ''}>
              {getCheckInOutDate(dailyActivity.localizedCheckOutTime)}
            </span>
          </>
        ) : (
          <span>
            <b>The worker didn&apos;t do the check-out yet</b>
          </span>
        )}
      </Card>
    );
  };

  return (
    <>
      <Row className="my-3">
        <Col md={6} xs={6} className="px-2">
          {getCheckInCard()}
        </Col>
        <Col md={6} xs={6} className="px-2">
          {getCheckOutCard()}
        </Col>
      </Row>
      <Row className="mb-2">
        <span className="fw-bold modal-titles">Hours Overview</span>
        <Form.Group as={Col} md={3} xs={6} controlId="formGridScheduleTime">
          <Form.Label className="d-flex justify-content-center fw-bold modal-inputs">
            Scheduled Time
          </Form.Label>
          <Form.Control
            size="sm"
            type="label"
            className="text-center form-label-style"
            readOnly
            value={getFormattedShiftTimes(shiftPosition, job?.timezone)}
          />
        </Form.Group>
        <Form.Group as={Col} md={3} xs={6} controlId="formGridSubmittedTime">
          <Form.Label className="d-flex justify-content-center fw-bold modal-inputs">
            Submitted Time
          </Form.Label>
          <Form.Control
            size="sm"
            type="label"
            className="text-center form-label-style"
            readOnly
            value={
              timeSheet
                ? `${format(
                    new Date(timeSheet.localizedStart),
                    DATE_FORMATS.TIME_FORMAT
                  )} - ${format(
                    new Date(timeSheet.localizedEnd),
                    DATE_FORMATS.TIME_FORMAT
                  )}`
                : '-'
            }
          />
          <TiEdit
            size={14}
            color="var(--black)"
            className="edit-icon cursor-point mb-3"
            onClick={() => setEditSubmittedTime(true)}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          md={3}
          xs={6}
          controlId="formGridTotalPayableHours"
        >
          <Form.Label className="d-flex justify-content-center fw-bold modal-inputs">
            Total Payable Hours
          </Form.Label>
          <Form.Control
            size="sm"
            type="label"
            className="text-center form-label-style"
            readOnly
            value={timeSheet ? `${timeSheet.hoursWorked}h` : '-'}
          />
        </Form.Group>
        <Form.Group as={Col} md={3} xs={6} controlId="formGridBreakLength">
          <Form.Label className="d-flex justify-content-center fw-bold modal-inputs">
            Break Length
          </Form.Label>
          <Form.Control
            size="sm"
            type="label"
            className="text-center form-label-style"
            readOnly
            value={timeSheet ? `${timeSheet.minutesOfBreak} min` : '-'}
          />
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <span className="fw-bold modal-titles">Expenses</span>
        <Form.Group as={Col} md={3} xs={6} controlId="formGridWorkPay">
          <Form.Label className="d-flex justify-content-center fw-bold modal-inputs">
            Work Pay
          </Form.Label>
          <Form.Control
            size="sm"
            type="label"
            className="text-center form-label-style"
            readOnly
            value={`$ ${Number(
              shiftPosition.wage.payRate
            )}/${shiftPosition.wage.payRatePeriod.toLowerCase()}`}
          />
        </Form.Group>
        <Form.Group as={Col} md={3} xs={6} controlId="formGridTotalPay">
          <Form.Label className="d-flex justify-content-center fw-bold modal-inputs">
            Total Pay
          </Form.Label>
          <Form.Control
            size="sm"
            type="label"
            className="text-center form-label-style"
            readOnly
            value={`$ ${timeSheet?.workerEarnedMoney.toFixed(2) ?? '-'}`}
          />
        </Form.Group>
        <Form.Group as={Col} md={3} xs={6} controlId="formGridShiftPaid">
          <Form.Label className="d-flex justify-content-center fw-bold modal-inputs">
            Shift Paid?
          </Form.Label>
          {!isShiftPaid ? (
            <Button
              size="sm"
              variant={`${isShiftPaid ? 'primary' : 'light'}`}
              className="pe-auto w-100"
              onClick={() => {
                markPaid();
                setIsShiftPaid(true);
              }}
            >
              MARK PAID
            </Button>
          ) : (
            <Form.Control
              size="sm"
              type="label"
              className="text-center form-label-style"
              readOnly
              value="Paid"
            />
          )}
        </Form.Group>
      </Row>
      <Row>
        <Col className="my-2 fw-bold modal-titles">
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="mb-2">Worker Notes</Form.Label>
            <Form.Control
              as="textarea"
              type="label"
              className="form-label-style worker-notes-style"
              readOnly
              value={timeSheet ? timeSheet.additionalNotes : ''}
              rows={3}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
