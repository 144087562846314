import { Stack } from 'react-bootstrap';
import { IWorkerData } from 'src/types/worker.type';
import { useState } from 'react';
import { MessageObject } from 'src/types/message-request.type';
import DirectMessageChatHeader from './DirectMessageChatHeader';
import DirectMessagesChat from './DirectMessageChat';
import DirectMessageChatFooter from './DirectMessageChatFooter';

interface Params {
  worker: IWorkerData;
}

export default function DirectMessageChat({ worker }: Params) {
  const [messageRecentlySent, setMessageRecentlySent] =
    useState<MessageObject | null>(null);

  return (
    <Stack style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DirectMessageChatHeader worker={worker} />
      <DirectMessagesChat
        worker={worker}
        messageRecentlySent={messageRecentlySent}
        setMessageRecentlySent={setMessageRecentlySent}
      />
      <DirectMessageChatFooter
        worker={worker}
        setMessageRecentlySent={setMessageRecentlySent}
      />
    </Stack>
  );
}
