import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  dismissItemsFromNew,
  getReviewRequests,
} from 'company/services/com-hub/api';
import ThPagination from 'components/ThPagination/ThPagination';
import FilterBar from 'company/components/CommunicationHub/FilterBar/FilterBar';
import ReviewRequestsTable from 'company/components/CommunicationHub/CommunicationHubTables/ReviewRequestsTable';
import {
  ComHubDismissTypeEnum,
  ComHubQueryParamsEnum,
  ComHubTabs,
  Review,
} from 'company/types/comm-hub.type';
import { ThLoading } from 'components/elements';
import QueryKeys from 'constants/queryKeys';
import { dismissItems } from 'utils/ComHubUtils';

interface ReviewRequestsProps {
  onShowWorkerProfile?: (workerId: number) => void;
  onSeeReviewRequest: (review: Review) => void;
}

function ReviewRequests({
  onShowWorkerProfile,
  onSeeReviewRequest,
}: ReviewRequestsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentOffset, setCurrentOffset] = useState(
    Number(searchParams.get(ComHubQueryParamsEnum.OFFSET)) || 0
  );
  const [searchState, setSearchState] = useState<{
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }>();
  const [showOnlyNew, setShowOnlyNew] = useState(
    !!searchParams.get(ComHubQueryParamsEnum.NEW)
  );

  // CONSTANTS FOR QUERY
  const itemsPerPage = 20;
  const queryClient = useQueryClient();
  const queryKeyReviewRequests = [
    QueryKeys.ComHub.REVIEW_REQUESTS,
    currentOffset,
    searchState,
    showOnlyNew,
  ];

  const { data: reviewRequestsResponse, isLoading } = useQuery({
    queryKey: queryKeyReviewRequests,
    queryFn: () =>
      getReviewRequests(
        currentOffset,
        itemsPerPage,
        searchState?.searchText,
        searchState?.dateRange,
        showOnlyNew
      ),
  });

  // useQuery update
  const { mutateAsync, isPending: isLoadingDismiss } = useMutation({
    mutationFn: dismissItemsFromNew,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ComHub.REVIEW_REQUESTS],
      });
    },
  });

  const handleOnSearch = (search: {
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }) => {
    setSearchState(search);
    if (search.searchText) {
      searchParams.set(ComHubQueryParamsEnum.SEARCH_TEXT, search.searchText);
    } else {
      searchParams.delete(ComHubQueryParamsEnum.SEARCH_TEXT);
    }
    if (search.dateRange.startDate) {
      searchParams.set(
        ComHubQueryParamsEnum.DATE_FROM,
        search.dateRange.startDate.toISOString()
      );
    } else {
      searchParams.delete(ComHubQueryParamsEnum.DATE_FROM);
    }
    if (search.dateRange.endDate) {
      searchParams.set(
        ComHubQueryParamsEnum.DATE_TO,
        search.dateRange.endDate.toISOString()
      );
    } else {
      searchParams.delete(ComHubQueryParamsEnum.DATE_TO);
    }
    setSearchParams(searchParams);
    setCurrentOffset(0);
  };

  const handleOnClear = () => {
    setSearchState(undefined);
    searchParams.delete(ComHubQueryParamsEnum.SEARCH_TEXT);
    searchParams.delete(ComHubQueryParamsEnum.DATE_FROM);
    searchParams.delete(ComHubQueryParamsEnum.DATE_TO);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (currentOffset) {
      searchParams.set(ComHubQueryParamsEnum.OFFSET, currentOffset.toString());
      searchParams.set(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
    } else {
      searchParams.delete(ComHubQueryParamsEnum.OFFSET);
      searchParams.delete(ComHubQueryParamsEnum.MAX);
    }
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOffset]);

  return (
    <>
      <FilterBar
        onSearch={handleOnSearch}
        onClear={handleOnClear}
        showOnlyNew={showOnlyNew}
        onShowOnlyNew={() => setShowOnlyNew((prevState) => !prevState)}
      />
      {(isLoading || isLoadingDismiss) && <ThLoading />}
      {reviewRequestsResponse ? (
        <>
          <ReviewRequestsTable
            title={ComHubTabs.RECEIVED_REQUESTS}
            reviewRequests={reviewRequestsResponse.ratingRequests}
            onSeeReviewRequest={onSeeReviewRequest}
            onShowWorkerProfile={onShowWorkerProfile}
            onDismissItems={(items) =>
              dismissItems(
                items,
                ComHubDismissTypeEnum.RATING_REQUEST,
                mutateAsync
              )
            }
          />
          <ThPagination
            currentOffset={reviewRequestsResponse.pagination.offset}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentOffset}
            totalItems={reviewRequestsResponse.pagination.count}
          />
        </>
      ) : null}
    </>
  );
}

export default ReviewRequests;
