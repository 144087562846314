import { RefAttributes, useRef } from 'react';
import {
  Image,
  OverlayTrigger,
  Stack,
  Tooltip,
  TooltipProps,
} from 'react-bootstrap';
import useAvatarImageFallback from 'src/hooks/useAvatarImageFallback';
import IMessageData from 'src/types/message.type';
import MessageBubble from '../MessageBubble/MessageBubble';

import './styles.css';

interface ChatMessageProps {
  authorName: string;
  message: IMessageData;
  avatarImageUrl: string;
  sentByMe: boolean;
  resendMessage: (message: IMessageData | undefined) => void;
  firstInBlock: boolean;
}

function ChatMessage({
  authorName,
  avatarImageUrl,
  firstInBlock,
  message,
  sentByMe,
  resendMessage,
}: ChatMessageProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  // hook to use default avatar image if the src doesn't load
  useAvatarImageFallback(imageRef);

  const renderAuthorNameTooltip = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>
  ) => (
    <Tooltip id="button-tooltip" {...props}>
      {authorName}
    </Tooltip>
  );

  return message ? (
    <Stack>
      <Stack
        direction="horizontal"
        gap={2}
        className={`align-items-start mb-3 ${sentByMe ? 'sent-by-me' : ''}`}
      >
        <div className="avatar-container">
          {firstInBlock ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderAuthorNameTooltip}
            >
              <Image
                ref={imageRef}
                src={avatarImageUrl}
                alt="message-author-avatar"
                data-testid="message-author-avatar"
              />
            </OverlayTrigger>
          ) : null}
        </div>
        <MessageBubble
          messageContent={message.text}
          // messageSeen={message.seen}
          creationDate={message.created}
          messageSent={message.sent}
          onResendMessage={() => resendMessage(message)}
        />
      </Stack>
    </Stack>
  ) : null;
}

export default ChatMessage;
