import Button from 'react-bootstrap/esm/Button';
import { PiHandsClappingThin } from 'react-icons/pi';
import TooltipOverlay from 'components/elements/Tooltip';

interface EndorseButtonParams {
  endorseUrl: string;
  isEndorsedByUser?: boolean;
}

export default function EndorseButton({
  endorseUrl,
  isEndorsedByUser,
}: EndorseButtonParams) {
  if (isEndorsedByUser) {
    return (
      <TooltipOverlay
        text="This is disabled because you have already endorsed this worker"
        position="bottom"
      >
        <Button size="sm" variant="light" disabled={isEndorsedByUser}>
          <PiHandsClappingThin size={18} />
          <b>&nbsp; ENDORSE</b>
        </Button>
      </TooltipOverlay>
    );
  }
  return (
    <Button
      size="sm"
      variant="light"
      className="fw-bolder"
      onClick={() => {
        window.open(endorseUrl, '_blank');
      }}
    >
      <PiHandsClappingThin size={18} />
      <b>&nbsp; ENDORSE</b>
    </Button>
  );
}
