import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import { Panel, ThLoading } from 'src/components/elements';
import { useState } from 'react';
import { ISuggestion } from 'src/types/suggestions.type';
import { saveNonTHJob } from 'worker/services/rating/api';
import INonTHJobCreation from 'src/apps/worker-frontend/types/non-th-job-creation.type';
import { IJobOptionsData } from 'src/types/jobs.type';
import { PaymentPeriod } from 'src/types/paymentPeriods.enum';
import useAlert from 'src/hooks/useAlert';
import { AxiosError } from 'axios';
import { INonThJobAssignmentData } from '../../types/non-th-job-assignment.type';

interface Props {
  stepTitle?: string;
  previousStep?: () => void;
  nextStep?: () => void;
  isActive?: boolean;
  setSelectedCompany: (selectedCompany: ISuggestion) => void;
  setJobAssignment: (value: INonThJobAssignmentData) => void;
  nonTHJobCreation?: INonTHJobCreation;
  jobOptions: IJobOptionsData | null;
  companyOptions: ISuggestion[];
}

function AddNewGigPreview({
  stepTitle,
  previousStep,
  nextStep,
  isActive,
  setSelectedCompany,
  setJobAssignment,
  nonTHJobCreation,
  jobOptions,
  companyOptions,
}: Props) {
  const { showErrorAlert, alertMessage } = useAlert();

  const [loading, setLoading] = useState(false);
  const periodStart = nonTHJobCreation?.periods[0].start;
  const periodEnd = nonTHJobCreation?.periods[0].end;

  const newCompanyString = 'This is a first! - Create profile for: ';
  const cleanCompanyName = (name: string) => {
    return name?.replace(newCompanyString, '') || '';
  };

  const onSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (nonTHJobCreation) {
      const payRate = Number(
        nonTHJobCreation.payRate.toString().replace('$ ', '').replace(',', '')
      );
      const companyName = cleanCompanyName(nonTHJobCreation?.companyName);
      const body: INonTHJobCreation = {
        ...nonTHJobCreation,
        payRate,
        companyName,
      };

      try {
        setLoading(true);
        const result = await saveNonTHJob(body);
        if (result) {
          setJobAssignment(result);
          const companySelected = companyOptions.find((c) =>
            c.name.includes(body.companyName)
          );
          if (companySelected) {
            setSelectedCompany({
              ...companySelected,
              name: cleanCompanyName(companySelected.name),
            });
          }
          if (nextStep) {
            nextStep();
          }
        }
      } catch (error) {
        const dataError =
          error instanceof AxiosError ? error?.response?.data : {};
        showErrorAlert(
          dataError?.message ??
            'Occurred an error when Trusted Herd tried to save non TH job assignment'
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const onPrevious = () => {
    if (previousStep) {
      previousStep();
    }
  };

  return (
    <>
      {loading && <ThLoading />}
      <Row key={stepTitle} className={isActive ? 'd-block' : 'd-none'}>
        <Col md={{ span: 8, offset: 2 }}>
          <Panel>
            <Row>
              <Col xs={12} className="fw-bold">
                Does this info look correct?
              </Col>
              <Col xs={12} className="mt-3">
                <Table striped borderless size="lg">
                  <tbody>
                    <tr>
                      <td className="text-start fw-bold">Event Name</td>
                      <td className="text-end">
                        {nonTHJobCreation?.eventName || ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Hiring Company</td>
                      <td className="text-end">
                        {nonTHJobCreation?.companyName
                          ? nonTHJobCreation?.companyName
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Brand Represented</td>
                      <td className="text-end">
                        {nonTHJobCreation?.brandRepresented
                          ? nonTHJobCreation?.brandRepresented
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Job Title</td>
                      <td className="text-end">
                        {nonTHJobCreation?.title?.id
                          ? jobOptions?.jobTitles.find(
                              (t) => t.id === nonTHJobCreation?.title?.id
                            )?.name
                          : ''}
                      </td>
                    </tr>
                    {nonTHJobCreation?.otherJobTitleDescription && (
                      <tr>
                        <td className="text-start fw-bold">Other Job Title</td>
                        <td className="text-end">
                          {nonTHJobCreation?.otherJobTitleDescription}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="text-start fw-bold">Pay Rate</td>
                      <td className="text-end">
                        {nonTHJobCreation?.payRate
                          ? `${nonTHJobCreation?.payRate} / `
                          : ''}{' '}
                        {
                          PaymentPeriod[
                            nonTHJobCreation?.payRatePeriod as keyof typeof PaymentPeriod
                          ]
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Dates Worked</td>
                      <td className="text-end">
                        {periodStart} - {periodEnd}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-start fw-bold">Total Days Worked</td>
                      <td className="text-end">
                        {nonTHJobCreation?.approximatelyDaysWorked
                          ? `${nonTHJobCreation?.approximatelyDaysWorked} days`
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start fw-bold">Total Hours Worked</td>
                      <td className="text-end">
                        {nonTHJobCreation?.totalHoursWorked
                          ? `${nonTHJobCreation?.totalHoursWorked} hours`
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <div className="w-100 d-flex justify-content-end">
            <Button
              type="button"
              form="rateCompanyForm"
              size="sm"
              variant="light"
              className="fw-bold mt-4"
              onClick={onPrevious}
            >
              Previous
            </Button>
          </div>
        </Col>
        <Col>
          <Button
            type="submit"
            form="rateGigForm"
            size="sm"
            className="fw-bold mt-4"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? 'Submitting' : 'Continue'}
          </Button>
        </Col>
      </Row>
      <Alert
        show={alertMessage.show}
        variant={alertMessage.variant}
        className="alert-fixed"
        style={{ width: '20rem' }}
      >
        <Alert.Heading>{alertMessage.message}</Alert.Heading>
      </Alert>
    </>
  );
}

export default AddNewGigPreview;
