import { Stack } from 'react-bootstrap';
import { GigInfoPreviewData } from 'company/types/comm-hub.type';
import './styles.css';

interface ComHubGigInfoPreviewProps {
  gigInfoPreview: GigInfoPreviewData;
  isAnonymous?: boolean;
}

function ComHubGigInfoPreview({
  gigInfoPreview,
  isAnonymous,
}: ComHubGigInfoPreviewProps) {
  const {
    brandRepresented,
    companyName,
    eventName,
    jobTitle,
    workedDays,
    workerName,
  } = gigInfoPreview;

  return (
    <Stack className="gig-info-preview">
      <b className="fs-5">Gig Information Preview</b>
      <Stack direction="horizontal" className="gig-info-preview--item">
        <b>Worker</b>
        <span>{isAnonymous ? 'TH User' : workerName}</span>
      </Stack>
      <Stack direction="horizontal" className="gig-info-preview--item">
        <b>Hiring Company</b>
        <span>{companyName}</span>
      </Stack>
      <Stack direction="horizontal" className="gig-info-preview--item">
        <b>Brand Represented</b>
        <span>{brandRepresented}</span>
      </Stack>
      <Stack direction="horizontal" className="gig-info-preview--item">
        <b>Event Name</b>
        <span>{eventName}</span>
      </Stack>
      <Stack direction="horizontal" className="gig-info-preview--item">
        <b>Job Title</b>
        <span>{jobTitle}</span>
      </Stack>
      <Stack direction="horizontal" className="gig-info-preview--item">
        <b>Work Date(s)</b>
        <span>{workedDays}</span>
      </Stack>
    </Stack>
  );
}

export default ComHubGigInfoPreview;
