import { EventType, Trait } from 'company/types/workerFilters.type';
import { CompanyRating, Reviewer, WorkerRating } from 'types/rating.type';

export enum ComHubTabs {
  NEW = 'New',
  RECEIVED_REVIEWS = 'Received Reviews',
  RECEIVED_REQUESTS = 'Review Requests',
  REVIEWS_AND_ENDORSEMENTS = 'Reviews & Endorsements Left',
}

export const ComHubTabRoutes = {
  [ComHubTabs.NEW]: { label: ComHubTabs.NEW, key: 'new' },
  [ComHubTabs.RECEIVED_REVIEWS]: {
    label: ComHubTabs.RECEIVED_REVIEWS,
    key: 'received-reviews',
  },
  [ComHubTabs.RECEIVED_REQUESTS]: {
    label: ComHubTabs.RECEIVED_REQUESTS,
    key: 'received-requests',
  },
  [ComHubTabs.REVIEWS_AND_ENDORSEMENTS]: {
    label: ComHubTabs.REVIEWS_AND_ENDORSEMENTS,
    key: 'reviews-and-endorsements',
  },
} as const;

export type ComHubTabRoutesType =
  (typeof ComHubTabRoutes)[keyof typeof ComHubTabRoutes];

export interface GigInfoPreviewData {
  brandRepresented: string;
  companyName: string;
  eventName: string;
  jobTitle: string;
  workedDays: string;
  workerName: string;
}

export interface WorkInfo {
  workedDays: number;
  start: string;
  end: string;
  position: string;
  workerId: number;
  workerName: string;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  profileImageUrl: string;
  address: Record<string, any>;
  cellPhone: string;
  email: string;
}

export interface UserState {
  id: number;
  dismissed: string;
  associatedType: string;
  associatedId: number;
  dateCreated: string;
  lastUpdated: string;
  new: boolean;
  type: string;
  // user: User;
  userId: number;
}

export interface Review {
  jobAssignmentId: number;
  eventName: string;
  workerId: number | null;
  workerFullName: string | null;
  jobId: number;
  companyRating: CompanyRating;
  workInfo: WorkInfo;
  userState: UserState;
  completed?: boolean;
  reviewer?: Reviewer;
}

interface Pagination {
  max: number;
  offset: number;
  count: number;
}

export interface ReceivedReviewsResponse {
  reviews: Review[];
  pagination: Pagination;
}

export interface ReviewRequestsResponse {
  ratingRequests: Review[];
  pagination: Pagination;
}
export interface GivenReview {
  jobAssignmentId: number;
  endorsementCaseId: number;
  eventName: string;
  workerId: number;
  jobId: number;
  type: GivenReviewTypeEnum;
  workerRating: WorkerRating | null;
  endorsementCase?: ComHubEndorsementCase;
  workInfo: WorkInfo;
  userState: UserState;
}

export interface ReviewsLeftResponse {
  reviews: GivenReview[];
  pagination: Pagination;
}

interface Brand {
  id: number;
  name: string;
  premium: boolean;
  profileURLName: string;
  profileImageUrl: string;
  rating: number | null;
  reviewsCount: number;
  showSearchPosition: boolean;
  isValidatedCompany: boolean;
}

export interface Endorsement {
  id: number;
  isEndorsedManager: boolean;
  workerRating: WorkerRating | null;
  endorser: User;
  endorsedWorker: User;
  sharedEventsNumber: string;
  sharedEventsType: EventType[];
  endorsedTraits: Trait[];
  brandWorked: Brand;
  reviewDescription: string | null;
  performance: number;
  dateCreated: string;
  photoIsRepresentative: boolean;
}

export interface ComHubEndorsementCase {
  id: number;
  performance: number;
  dateCreated: string;
  reviewDescription: string;
  photoIsRepresentative: boolean;
  endorser: User;
  endorsedWorker: User;
}

export enum ComHubHeadersEnum {
  ACTIONS_COLUMN = '',
  AUTHOR = 'AUTHOR',
  DATES_WORKED = 'DATES WORKED',
  EVENT_NAME = 'EVENT NAME',
  JOB_DATES = 'JOB DATES',
  POSITION = 'POSITION',
  RATING = 'RATING',
  REPLIED = 'REPLIED',
  RESPONDED = 'RESPONDED',
  WORKER_NAME = 'WORKER NAME',
}

export enum ComHubQueryParamsEnum {
  NEW = 'searchNew',
  SEARCH_TEXT = 'searchText',
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
  MAX = 'max',
  OFFSET = 'offset',
}

export enum ComHubDismissTypeEnum {
  COMPANY_RATING = 'COMPANY_RATING',
  RATING_REQUEST = 'RATING_REQUEST',
}

export enum ReviewTypeEnum {
  RECEIVED_REVIEW = 'RECEIVED_REVIEW',
  REVIEW_REQUEST = 'REVIEW_REQUEST',
  GIVEN_REVIEW = 'GIVEN_REVIEW',
  GIVEN_ENDORSEMENT = 'GIVEN_ENDORSEMENT',
}

export enum GivenReviewTypeEnum {
  RATING = 'RATING',
  ENDORSEMENT_CASE = 'ENDORSEMENT_CASE',
}
