/* eslint-disable react/no-danger */
import { useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { useCompanyContext } from 'src/apps/company-frontend/state/companyContext';
import { ThPaywallPanel } from 'src/apps/company-frontend/components/th/ThPaywallPanel';
import { PANEL_MESSAGES } from 'src/constants/jobPaywall';

interface PaywallSidePanelParams {
  setShowModal: (show: boolean) => void;
}

export function PaywallSidePanel({
  setShowModal,
}: PaywallSidePanelParams): JSX.Element {
  const { indicators } = useCompanyContext();

  const [showMore, setShowMore] = useState(false);

  const handleLearnMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const handleJoinPremium = () => {
    setShowModal(true);
  };

  const renderPanel = (showMorePanel = false) => {
    const messagePosition = showMorePanel ? 'showMore' : 'default';
    return (
      <div className={`panel bg-white ${showMorePanel ? 'mt-3' : ''}`}>
        <Row>
          <Col>
            <h5
              className="mt-2 mb-4"
              dangerouslySetInnerHTML={{
                __html: PANEL_MESSAGES[messagePosition].title,
              }}
            >
              {}
            </h5>
            <hr />
            <div className="px-2 mt-4">
              <p
                dangerouslySetInnerHTML={{
                  __html: PANEL_MESSAGES[messagePosition].line1,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: PANEL_MESSAGES[messagePosition].line2.replace(
                    '{workersCount}',
                    indicators?.workersCount.toString() || 'many'
                  ),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: PANEL_MESSAGES[messagePosition].line3,
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Stack
              direction="horizontal"
              className="justify-content-center"
              gap={3}
            >
              {showMorePanel && (
                <Button variant="light" onClick={handleLearnMore}>
                  <b>{PANEL_MESSAGES[messagePosition].buttons[0]}</b>
                </Button>
              )}
              {!showMorePanel && (
                <>
                  <Button variant="light" onClick={handleLearnMore}>
                    <b>{PANEL_MESSAGES[messagePosition].buttons[0]}</b>
                  </Button>
                  <Button variant="primary" onClick={handleJoinPremium}>
                    <b>{PANEL_MESSAGES[messagePosition].buttons[1]}</b>
                  </Button>
                </>
              )}
            </Stack>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <ThPaywallPanel>
      {renderPanel()}
      {showMore && renderPanel(true)}
    </ThPaywallPanel>
  );
}
