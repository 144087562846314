import { useEffect, useState } from 'react';
import { Modal, Stack } from 'react-bootstrap';
import { CgAddR } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';
import { NonEventExperienceSchemaType } from 'src/types/workerProfile.type';
import { useWorkerProfileContext } from 'src/apps/worker-frontend/state/workerProfileContext';
import * as workerApi from 'src/apps/worker-frontend/services/worker/api';
import { ThLoading } from 'src/components/elements';
import NonEventExperienceForm from '../../Forms/NonEventExperienceForm/NonEventExperienceForm';
import ExperienceItem from '../../ExperienceItem/ExperienceItem';

interface NonEventExperienceModalProps {
  openAddItem?: boolean;
  onCloseModal: () => void;
}

function NonEventExperienceModal({
  openAddItem,
  onCloseModal,
}: NonEventExperienceModalProps) {
  const [experienceToEdit, setExperienceToEdit] =
    useState<NonEventExperienceSchemaType>();
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { nonPromoWorkExperience, setNonPromoWorkExperience } =
    useWorkerProfileContext();
  const onEditExperience = (experience: NonEventExperienceSchemaType) => {
    setIsAddingItem(false);
    setExperienceToEdit(experience);
  };

  const onDeleteExperience = async (expId: number) => {
    setIsLoading(true);
    const result = await workerApi.deleteNonEventExpFromWorker(expId);
    if (result.status === 200) {
      setNonPromoWorkExperience((prevState) => {
        return prevState?.filter((exp) => exp.id !== expId);
      });
    }
    setIsLoading(false);
  };

  const onAddItem = () => {
    setExperienceToEdit(undefined);
    setIsAddingItem(true);
  };

  useEffect(() => {
    if (openAddItem === true) {
      onAddItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show centered className="p-3" size="lg" onHide={onCloseModal}>
      {isLoading && <ThLoading />}
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center">
          Work Experience
          <span className="fs-5 ms-2" style={{ color: 'var(--silver)' }}>
            ({2}%)
          </span>
        </Modal.Title>
        <Stack
          direction="horizontal"
          className="justify-content-end ms-auto"
          gap={2}
        >
          <CgAddR size={20} className="cursor-point" onClick={onAddItem} />
          <IoMdClose
            size={20}
            className="cursor-point"
            onClick={onCloseModal}
          />
        </Stack>
      </Modal.Header>
      <Modal.Body>
        {(isAddingItem || nonPromoWorkExperience?.length === 0) && (
          <div className="mb-3">
            <NonEventExperienceForm
              onCancel={
                nonPromoWorkExperience?.length === 0
                  ? onCloseModal
                  : () => setIsAddingItem(false)
              }
              onSuccess={() => setIsAddingItem(false)}
            />
          </div>
        )}
        <Stack gap={3}>
          {nonPromoWorkExperience?.map(
            (experience: NonEventExperienceSchemaType) => (
              <div key={experience.id}>
                {experienceToEdit && experienceToEdit.id === experience.id ? (
                  <NonEventExperienceForm
                    experienceToEdit={experienceToEdit}
                    onCancel={() => setExperienceToEdit(undefined)}
                    onSuccess={() => setExperienceToEdit(undefined)}
                  />
                ) : (
                  <ExperienceItem
                    experience={experience}
                    onDeleteExperience={onDeleteExperience}
                    onEditExperience={onEditExperience}
                  />
                )}
              </div>
            )
          )}
        </Stack>
      </Modal.Body>
    </Modal>
  );
}

export default NonEventExperienceModal;
