import { useEffect, useState } from 'react';
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import { Form, Stack } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { zodResolver } from '@hookform/resolvers/zod';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyInput from 'react-currency-input-field';
import { PayRatePeriod } from 'src/types/payRatePeriod';
import * as jobApi from 'src/apps/company-frontend/services/job/api';
import { IJobTitle } from 'src/types/jobs.type';
import {
  shiftDefaultValues,
  getValidationSchema,
} from 'src/services/shift/shiftService';
import { ThLoading } from 'src/components/elements';
import { useJobShiftContext } from 'src/apps/company-frontend/state/jobShiftContext';
import { z } from 'zod';
import DATE_FORMATS from 'src/constants/dateFormat';
import { format } from 'date-fns';
import {
  createDateTimeFromDifferentDates,
  isSameDateOrAfter,
} from 'src/utils/DateUtils';
import { CreateShiftModalProps } from './CreateShiftModal';

function CreateShiftMobileModal({
  job,
  handleClose,
  shift,
  shiftCreatedCloseModal,
}: CreateShiftModalProps) {
  const { refetch } = useJobShiftContext();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm<ValidationShiftSchema>({
    resolver: zodResolver(getValidationSchema()),
    defaultValues: shiftDefaultValues(job, shift),
  });

  const { fields } = useFieldArray({
    control,
    name: 'shifts',
  });

  const [isLoadingAction, setLoadingAction] = useState(false);
  const [jobTitles, setJobTitles] = useState<IJobTitle[]>([]);

  useEffect(() => {
    const fetchJobTitles = async () => {
      const result = await jobApi.fetchJobPositionList();
      setJobTitles(result?.jobTitles || []);
    };
    fetchJobTitles();
  }, []);

  const validationErrors =
    (errors.shifts as Merge<
      FieldError,
      Merge<
        FieldError,
        FieldErrorsImpl<{
          date: Date;
          position: number;
          payRate: number;
          payRatePeriod: NonNullable<'hourly' | 'daily'>;
          breakPaid: NonNullable<'yes' | 'no'>;
          openPositions: number;
          openBackups: number;
          startTime: Date;
          localizedStart: string;
          endTime: Date;
          localizedEnd: string;
        }>
      >
    >[]) ?? [];

  if (!job) {
    return <div />;
  }

  const processSuccessResult = async () => {
    reset();
    await refetch();
    shiftCreatedCloseModal();
  };

  const formatShiftValues = (data: ValidationShiftSchema) => {
    const formData: ValidationShiftSchema = { shifts: [] };
    data.shifts.forEach((item) => {
      const shiftItem = item;
      shiftItem.start = format(item.startTime, DATE_FORMATS.DATE_TIME_FORMAT);
      shiftItem.end = format(item.endTime, DATE_FORMATS.DATE_TIME_FORMAT);
      shiftItem.payRatePeriod =
        shiftItem.payRatePeriod === 'daily'
          ? PayRatePeriod.DAY
          : PayRatePeriod.HOUR;
      shiftItem.payRate = shiftItem.payRate.replace('$', '').trim();
      formData.shifts.push(shiftItem);
    });
    return formData;
  };

  const validationSchema = getValidationSchema(job.timezone);
  type ValidationShiftSchema = z.infer<typeof validationSchema>;

  const onSubmit: SubmitHandler<ValidationShiftSchema> = async (
    data: ValidationShiftSchema
  ) => {
    try {
      setLoadingAction(true);
      const formData = formatShiftValues(data);
      const result = await jobApi.addJobShifts(job.id, formData.shifts);
      if (result?.results?.length > 0) {
        processSuccessResult();
      }
    } catch (err) {
      console.log(`Occurred an error trying to create shifts`, err);
    } finally {
      setLoadingAction(false);
    }
  };

  const adjustDateTimes = (date: Date) => {
    getValues().shifts[0].date = date;
    getValues().shifts[0].date.setHours(0);
    getValues().shifts[0].date.setMinutes(0);
    getValues().shifts[0].date.setSeconds(0);
    if (getValues().shifts[0].startTime) {
      getValues().shifts[0].startTime.setDate(date.getDate());
      getValues().shifts[0].startTime.setMonth(date.getMonth());
      getValues().shifts[0].startTime.setFullYear(date.getFullYear());
    }
    if (getValues().shifts[0].endTime) {
      getValues().shifts[0].endTime.setDate(date.getDate());
      getValues().shifts[0].endTime.setMonth(date.getMonth());
      getValues().shifts[0].endTime.setFullYear(date.getFullYear());
    }
  };

  return (
    <Modal
      size="xl"
      show
      centered
      onHide={() => handleClose()}
      className="create-shift-modal-mobile"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create-shift-mobile-modal">Create Shift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingAction && <ThLoading />}
        <Form id="shift-form" onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={1}>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Start date*</Form.Label>
              <InputGroup>
                <Controller
                  control={control}
                  {...register(`shifts.${0}.date`)}
                  name={`shifts.${0}.date`}
                  render={({ field }) => (
                    <DatePicker
                      id="date"
                      className={`form-control ${
                        validationErrors[0]?.date?.message
                          ? 'border-danger'
                          : ''
                      }`}
                      wrapperClassName="date-picker"
                      selected={field.value as unknown as Date}
                      placeholderText="MM/DD/YYYY"
                      formatWeekDay={(name) => name.substring(0, 1)}
                      onChange={(date) => {
                        field.onChange(date as Date);
                        if (date) {
                          adjustDateTimes(date);
                        }
                      }}
                      calendarStartDay={1}
                    />
                  )}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Position*</Form.Label>
              <Form.Select
                isInvalid={!!validationErrors[0]?.position?.message}
                {...register(`shifts.${0}.position`, {
                  valueAsNumber: true,
                })}
                className={`w-50 ${
                  validationErrors[0]?.position?.message ? 'is-invalid' : ''
                }`}
              >
                <option value={0} hidden>
                  Please select
                </option>
                {jobTitles &&
                  jobTitles.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Wage*</Form.Label>
              <InputGroup>
                <CurrencyInput
                  {...register(`shifts.${0}.payRate`)}
                  name={`shifts.${0}.payRate`}
                  allowNegativeValue={false}
                  defaultValue={Number(fields[0].payRate.substring(2))}
                  className={`form-control ${
                    validationErrors[0]?.payRate?.message ? 'is-invalid' : ''
                  }`}
                  prefix="$ "
                  step={5}
                  min={1}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Hourly/Daily*</Form.Label>
              <Form.Select
                className="w-50"
                aria-label="Please select"
                isInvalid={!!validationErrors[0]?.payRatePeriod}
                {...register(`shifts.${0}.payRatePeriod`)}
              >
                <option hidden>Select</option>
                <option value="hourly">Hourly</option>
                <option value="daily">Daily</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Openings*</Form.Label>
              <InputGroup>
                <InputGroup.Text>#</InputGroup.Text>
                <Form.Control
                  type="text"
                  isInvalid={!!validationErrors[0]?.openPositions}
                  {...register(`shifts.${0}.openPositions`, {
                    valueAsNumber: true,
                  })}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Backups*</Form.Label>
              <InputGroup>
                <InputGroup.Text>#</InputGroup.Text>
                <Form.Control
                  type="text"
                  isInvalid={!!validationErrors[0]?.openBackups}
                  {...register(`shifts.${0}.openBackups`, {
                    valueAsNumber: true,
                  })}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Start time*</Form.Label>
              <InputGroup>
                <Controller
                  control={control}
                  name={`shifts.${0}.startTime`}
                  render={({ field }) => (
                    <DatePicker
                      className="form-control"
                      selected={field.value as unknown as Date}
                      onChange={(date) => {
                        const newStartTime = createDateTimeFromDifferentDates(
                          getValues().shifts[0].date,
                          date!
                        );
                        field.onChange(newStartTime);
                        setValue(`shifts.${0}.startTime`, newStartTime);
                        setValue(
                          `shifts.${0}.isNextDay`,
                          isSameDateOrAfter(
                            getValues().shifts[0].startTime,
                            getValues().shifts[0].endTime
                          )
                        );
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm aa"
                    />
                  )}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>End time*</Form.Label>
              <InputGroup>
                <Controller
                  control={control}
                  name={`shifts.${0}.endTime`}
                  render={({ field }) => (
                    <DatePicker
                      className="form-control"
                      selected={field.value as unknown as Date}
                      onChange={(date) => {
                        const newEndTime = createDateTimeFromDifferentDates(
                          getValues().shifts[0].date,
                          date!
                        );
                        field.onChange(newEndTime);
                        setValue(`shifts.${0}.endTime`, newEndTime);
                        setValue(
                          `shifts.${0}.isNextDay`,
                          isSameDateOrAfter(
                            getValues().shifts[0].startTime,
                            getValues().shifts[0].endTime
                          )
                        );
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="hh:mm aa"
                    />
                  )}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Break Paid?*</Form.Label>
              <Form.Select
                className="w-50"
                isInvalid={!!validationErrors[0]?.breakPaid}
                {...register(`shifts.${0}.breakPaid`)}
              >
                <option hidden>Select</option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </Form.Select>
            </Form.Group>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="light" onClick={handleClose}>
          CANCEL
        </Button>
        <Button variant="primary" form="shift-form" type="submit">
          CREATE
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateShiftMobileModal;
