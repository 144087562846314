import { useState, useRef } from 'react';
import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { debounce } from 'lodash';
import { Button, Collapse, Stack, Form, Card } from 'react-bootstrap';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import { MdRefresh } from 'react-icons/md';
import { isMobile } from 'react-device-detect';
import { ApplicantsType } from 'src/constants/applicantsType';
import {
  initialApplicantFilters,
  useJobContext,
} from 'src/apps/company-frontend/state/jobContext';
import SearchTalentInput from 'src/apps/company-frontend/components/SearchTalentInput/SearchTalentInput';

interface CandidateFiltersProps {
  applicantType?: ApplicantsType;
  refreshApplicantFilters: () => void;
}

export function CandidateFilters({
  applicantType,
  refreshApplicantFilters,
}: CandidateFiltersProps): JSX.Element {
  const { applicantFilters, setApplicantFilters, fetchApplicantCounters } =
    useJobContext();
  const [showFilters, setShowFilters] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState(
    applicantFilters.nameOrEmail
  );

  const handleFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === 'radio') {
      setApplicantFilters({
        ...applicantFilters,
        [name]: value,
      });
      return;
    }

    let updatedList = [...applicantFilters[name]];
    if (checked && !updatedList.includes(value)) {
      updatedList = [...applicantFilters[name], value];
    } else {
      updatedList.splice(applicantFilters[name].indexOf(value), 1);
    }
    setApplicantFilters({ ...applicantFilters, [name]: updatedList });
  };

  const handleTextFilters = useRef(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setApplicantFilters({ ...applicantFilters, [name]: value });
    }, 1000)
  ).current;

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputSearchValue(value);
    handleTextFilters(event);
  };

  const sortApplicantsList = () => {
    const orderByDefault = applicantFilters.order === 'desc' ? 'asc' : 'desc';

    setApplicantFilters({
      ...applicantFilters,
      order: orderByDefault,
    });
  };

  const refreshListAction = () => {
    refreshApplicantFilters();
    fetchApplicantCounters();
  };

  const handleClearAllFilters = () => {
    setApplicantFilters({
      ...initialApplicantFilters,
    });
  };

  return (
    <>
      <Stack
        direction={isMobile ? 'vertical' : 'horizontal'}
        gap={2}
        className="mb-2"
      >
        <SearchTalentInput
          placeholder="Search"
          name="nameOrEmail"
          value={inputSearchValue}
          onChange={handleSearchInput}
        />
        <Stack direction={isMobile ? 'vertical' : 'horizontal'} gap={2}>
          <Button
            onClick={() => setShowFilters(!showFilters)}
            variant={`${showFilters ? 'primary' : 'light'}`}
            aria-controls="filters-collapse"
            aria-expanded={showFilters}
            className="d-flex align-items-center justify-content-center"
          >
            <BiFilterAlt />
            &nbsp;FILTERS
          </Button>
          <Button onClick={() => sortApplicantsList()} variant="light">
            {applicantFilters.order === 'desc' ? <BiSortDown /> : <BiSortUp />}
          </Button>
          <Button onClick={() => refreshListAction()} variant="light">
            <MdRefresh size={20} />
          </Button>
        </Stack>
      </Stack>
      <Collapse in={showFilters}>
        <div id="filters-collapse">
          <Card className="mt-3 p-3">
            <Stack
              direction={isMobile ? 'vertical' : 'horizontal'}
              gap={isMobile ? 4 : 5}
              className="align-items-start"
            >
              {/* GENDER */}
              <Form.Group>
                <Form.Label>
                  <b>Gender</b>
                </Form.Label>
                <Form.Check
                  label="Male"
                  name="gender"
                  type="checkbox"
                  value="MALE"
                  className="col-12"
                  checked={applicantFilters.gender?.includes('MALE')}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
                <Form.Check
                  label="Female"
                  name="gender"
                  type="checkbox"
                  value="FEMALE"
                  checked={applicantFilters.gender?.includes('FEMALE')}
                  className="col-12"
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
              </Form.Group>

              {/* COUNTRY */}
              <Form.Group>
                <Form.Label>
                  <b>Country</b>
                </Form.Label>
                <Form.Check
                  label="USA"
                  name="country"
                  type="checkbox"
                  value="UNITED_STATES"
                  checked={applicantFilters.country?.includes('UNITED_STATES')}
                  className="col-12"
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
                <Form.Check
                  label="Canada"
                  name="country"
                  type="checkbox"
                  value="CANADA"
                  checked={applicantFilters.country?.includes('CANADA')}
                  className="col-12"
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
              </Form.Group>

              {/* TRAVEL NATIONALLY */}
              {/* <Form.Group>
                <Form.Label className="d-block">
                  <b>Travel Nationally</b>
                </Form.Label>
                <Form.Check
                  inline
                  label="Yes"
                  name="willingToTravel"
                  type="radio"
                  value="true"
                  checked={applicantFilters.willingToTravel}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="willingToTravel"
                  type="radio"
                  value="false"
                  checked={applicantFilters.willingToTravel === false}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
              </Form.Group> */}

              {/* TRAVEL LONGER PERIODS */}
              {/* <Form.Group>
                <Form.Label className="d-block">
                  <b>Travel Longer Period?</b>
                </Form.Label>
                <Form.Check
                  inline
                  label="Yes"
                  name="travelLongerPeriod"
                  type="radio"
                  value="true"
                  checked={applicantFilters.travelLongerPeriod}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
                <Form.Check
                  inline
                  label="No"
                  name="travelLongerPeriod"
                  type="radio"
                  value="false"
                  checked={applicantFilters.travelLongerPeriod === false}
                  onChange={(e) => handleFilters(e)}
                  id={`inline-${applicantType}-1`}
                />
              </Form.Group> */}

              {/* SORT BY - ONLY FOR APPLIED TAB */}
              {applicantType === ApplicantsType.APPLIED && (
                <Form.Group>
                  <Form.Label>
                    <b>Sort by</b>
                  </Form.Label>
                  <Form.Check
                    disabled
                    label="Distance"
                    name="sortBy"
                    type="radio"
                    value="distance"
                    checked={applicantFilters.sortBy === 'distance'}
                    onChange={(e) => handleFilters(e)}
                  />
                  <Form.Check
                    label="Rating"
                    name="sortBy"
                    type="radio"
                    value="rating"
                    checked={applicantFilters.sortBy === 'rating'}
                    onChange={(e) => handleFilters(e)}
                  />
                  <Form.Check
                    label="Date applied"
                    name="sortBy"
                    type="radio"
                    value="dateApplied"
                    checked={applicantFilters.sortBy === 'dateApplied'}
                    onChange={(e) => handleFilters(e)}
                  />
                </Form.Group>
              )}
            </Stack>
            <Button
              variant="link"
              className="ms-auto p-0 mt-3"
              onClick={() => handleClearAllFilters()}
              style={{
                color: 'unset',
                fontSize: '0.75rem',
              }}
            >
              Clear All
            </Button>
          </Card>
        </div>
      </Collapse>
    </>
  );
}
