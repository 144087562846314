import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { ImCopy } from '@react-icons/all-files/im/ImCopy';
import * as api from 'src/apps/company-frontend/services/message/api';
import './style.css';
import ThLoading from 'components/elements/ThLoading';
import { IWorkerData } from 'src/types/worker.type';

interface ModalParams {
  worker: IWorkerData;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function ModalShareApplicantLink({
  worker,
  showModal,
  setShowModal,
}: ModalParams) {
  const [showAlert, setShowAlert] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [disableSendButton, setDisableSendButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('success');
  const [alertMessageVariant, setAlertMessageVariant] = useState('');

  useEffect(() => {
    if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress) &&
      emailMessage.length >= 5
    ) {
      setDisableSendButton(false);
    } else {
      setDisableSendButton(true);
    }
  }, [emailAddress, emailMessage.length]);

  if (isLoading) {
    return <ThLoading />;
  }

  const handleClose = () => {
    setEmailAddress('');
    setEmailMessage('');
    setDisableSendButton(true);
    setShowModal(false);
  };

  const handleVisible = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage('');
      setAlertMessageVariant('success');
    }, 3000);
  };

  // TODO We must change it after we redo the new screen in ReactJS to show worker profile
  const workerUrl = `${import.meta.env.VITE_SERVER_BASE_URL}/w/${worker.id}`;

  const handleEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value);
  };

  const handleEmailMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailMessage(event.target.value);
  };

  const emailReturn = async () => {
    setIsLoading(true);

    setDisableSendButton(true);
    const {
      data: { success },
    } = await api.shareJobByEmail(emailAddress, emailMessage, workerUrl);
    if (success?.data.success) {
      setAlertMessage('E-mail successfully sent');
      setAlertMessageVariant('success');
      handleVisible();
      handleClose();
      setTimeout(() => {
        setAlertMessageVariant('success');
      }, 3000);
    } else {
      setAlertMessage('The e-mail was not sent');
      setAlertMessageVariant('warning');
    }
    setIsLoading(false);
    handleVisible();
  };

  const onClickCopyApplicantLink = (message: string) => {
    navigator.clipboard.writeText(workerUrl);
    setAlertMessage(message);
    setAlertMessageVariant('success');
    handleVisible();
  };

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="no-border">Share Applicant Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            <b>Copy Applicant Link</b>
          </h3>
          <InputGroup className="mb-3">
            <Form.Control
              className="job-link-field"
              value={workerUrl}
              disabled
            />
            <InputGroup.Text
              className="job-click-copy"
              onClick={() => {
                onClickCopyApplicantLink('Link copied to clipboard');
              }}
            >
              <Stack direction="horizontal" gap={1}>
                <ImCopy />
              </Stack>
            </InputGroup.Text>
          </InputGroup>

          <div className="divider">
            <p className="or-style">or</p>
          </div>

          <h3>
            <b>Share via E-mail</b>
          </h3>
          <InputGroup className="mb-3">
            <Form.Control
              value={emailAddress}
              onChange={handleEmailAddress}
              placeholder="Enter e-mail address"
            />
          </InputGroup>

          <h3>
            <b>Message</b>
          </h3>
          <InputGroup className="mb-3">
            <Form.Control
              as="textarea"
              value={emailMessage}
              onChange={handleEmailMessage}
              placeholder="Add a message here..."
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={disableSendButton}
            variant="primary"
            size="sm"
            onClick={emailReturn}
          >
            Share
          </Button>
        </Modal.Footer>
      </Modal>
      <Alert
        show={showAlert}
        variant={alertMessageVariant}
        className="alert-fixed alert-width"
      >
        <Alert.Heading>{alertMessage}</Alert.Heading>
      </Alert>
    </>
  );
}
