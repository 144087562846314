import { useState } from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import {
  Badge,
  Button,
  Card,
  Image,
  Modal,
  OverlayTrigger,
  Stack,
  Tooltip,
} from 'react-bootstrap';
import { MdInfoOutline } from 'react-icons/md';
import { LuCheckCircle } from 'react-icons/lu';
import { GoPaperclip } from 'react-icons/go';
import {
  Certificate,
  CertificateType,
  Template,
} from 'src/types/certificates.type';
import { useWorkerProfileContext } from 'src/apps/worker-frontend/state/workerProfileContext';
import CertificateForm from './CertificateForm/CertificateForm';

import './styles.css';

function Badges() {
  const { workerCertificates } = useWorkerProfileContext();

  const [showModal, setShowModal] = useState(false);
  const [formTemplate, setFormTemplate] = useState<Template>();
  const [certificateTypeFilter, setCertificateFilter] = useState<
    CertificateType | undefined
  >();
  const [certificateForEdition, setCertificateForEdition] = useState<
    Certificate | undefined
  >();

  const addCertificate = (
    template: Template,
    certificateToEdit?: Certificate
  ) => {
    setFormTemplate(template);
    setCertificateForEdition(certificateToEdit);
    setShowModal(true);
  };

  const calculateCertsByType = (certificateType: CertificateType) => {
    return workerCertificates?.certificates.filter(
      (certificate: Certificate) =>
        certificate.template.type === certificateType.toString()
    ).length;
  };

  const calculateTemplatesByType = (templateType: CertificateType) => {
    return workerCertificates?.templates.filter(
      (template: Template) => template.type === templateType.toString()
    ).length;
  };

  const handleFilter = (templateType: CertificateType | undefined) => {
    if (certificateTypeFilter && certificateTypeFilter === templateType) {
      setCertificateFilter(undefined);
    } else {
      setCertificateFilter(templateType);
    }
  };

  const getCertificateByTemplateId = (templateId: number) => {
    return workerCertificates?.certificates.find(
      (cert) => cert.template.id === templateId
    );
  };

  return workerCertificates ? (
    <Card className="worker-profile-card">
      <Modal
        show={showModal}
        centered
        backdrop
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formTemplate?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CertificateForm
            certificateTemplate={formTemplate!}
            certificateForEdition={certificateForEdition}
            onComplete={() => setShowModal(false)}
          />
        </Modal.Body>
      </Modal>

      <Card.Title>Badges</Card.Title>
      <Card.Text as="div">
        <Stack
          direction="horizontal"
          gap={2}
          className={`template-filters ${isMobile ? 'mobile-view' : ''}`}
        >
          <Badge
            bg={!certificateTypeFilter ? 'primary' : 'light'}
            className="text-dark"
            onClick={() => handleFilter(undefined)}
          >
            All ({workerCertificates?.certificates.length}/
            {workerCertificates?.templates.length})
          </Badge>
          {workerCertificates?.certificateTypes.map(
            (certType: CertificateType) => (
              <Badge
                key={certType}
                bg={
                  certificateTypeFilter === certType.toString()
                    ? 'primary'
                    : 'light'
                }
                className="text-dark"
                onClick={() => handleFilter(certType)}
              >
                {/* @ts-ignore */}
                {CertificateType[certType]} ({calculateCertsByType(certType)}/
                {calculateTemplatesByType(certType)})
              </Badge>
            )
          )}
        </Stack>
        <BrowserView>
          <div className="certificates-list">
            {workerCertificates.templates
              .filter((template) =>
                certificateTypeFilter
                  ? template.type === certificateTypeFilter
                  : true
              )
              .map((template: Template) => (
                <Stack key={template.id} className="align-items-center mt-3">
                  <div style={{ position: 'relative' }} className="p-2">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>{template.description}</Tooltip>}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                        }}
                      >
                        <MdInfoOutline style={{ color: 'var(--silver)' }} />
                      </div>
                    </OverlayTrigger>
                    <Image
                      width={80}
                      height={80}
                      roundedCircle
                      src={template.badgeImage?.url}
                    />
                  </div>
                  <span>{template.name}</span>
                  {getCertificateByTemplateId(template.id) ? (
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="justify-content-center p-1"
                    >
                      <LuCheckCircle
                        color="var(--green)"
                        size={20}
                        className="cursor-point"
                        onClick={() =>
                          addCertificate(
                            template,
                            getCertificateByTemplateId(template.id)
                          )
                        }
                      />
                      {getCertificateByTemplateId(template.id)?.certificateFile
                        ?.url && (
                        <a
                          href={
                            getCertificateByTemplateId(template.id)
                              ?.certificateFile.url
                          }
                          target="_blank"
                          className="text-dark lh-1"
                          rel="noreferrer"
                        >
                          <GoPaperclip size={20} className="cursor-point" />
                        </a>
                      )}
                    </Stack>
                  ) : (
                    <Button
                      onClick={() => addCertificate(template)}
                      className="add-cert-btn"
                    >
                      Add yours
                    </Button>
                  )}
                </Stack>
              ))}
          </div>
        </BrowserView>
        <MobileView>
          <div className="certificates-list mobile-view">
            {workerCertificates.templates
              .filter((template) =>
                certificateTypeFilter
                  ? template.type === certificateTypeFilter
                  : true
              )
              .map((template: Template) => (
                <Stack
                  key={template.id}
                  className="align-items-center mt-3 cert-item"
                >
                  <div style={{ position: 'relative' }} className="p-2">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>{template.description}</Tooltip>}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                        }}
                      >
                        <MdInfoOutline style={{ color: 'var(--silver)' }} />
                      </div>
                    </OverlayTrigger>
                    <Image
                      width={60}
                      height={60}
                      roundedCircle
                      src={template.badgeImage?.url}
                    />
                  </div>
                  <span className="flex-grow-1">{template.name}</span>
                  {getCertificateByTemplateId(template.id) ? (
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="justify-content-center"
                    >
                      <LuCheckCircle
                        color="var(--green)"
                        size={14}
                        onClick={() =>
                          addCertificate(
                            template,
                            getCertificateByTemplateId(template.id)
                          )
                        }
                      />
                      {getCertificateByTemplateId(template.id)?.certificateFile
                        ?.url && (
                        <a
                          href={
                            getCertificateByTemplateId(template.id)
                              ?.certificateFile.url
                          }
                          target="_blank"
                          className="text-dark lh-1"
                          rel="noreferrer"
                        >
                          <GoPaperclip size={14} className="cursor-point" />
                        </a>
                      )}
                    </Stack>
                  ) : (
                    <Button
                      onClick={() => addCertificate(template)}
                      className="add-cert-btn"
                    >
                      Add yours
                    </Button>
                  )}
                </Stack>
              ))}
          </div>
        </MobileView>
      </Card.Text>
    </Card>
  ) : null;
}

export default Badges;
