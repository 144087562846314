import { useEffect, useState } from 'react';
import { TbMessageCircle2 } from 'react-icons/tb';
import { format } from 'date-fns';
import DATE_FORMATS from 'src/constants/dateFormat';
import { ActivityTrackerView } from 'src/types/jobs.type';
import { JobAssignment } from 'src/types/job-assignment.type';
import { ActivityDetail } from 'src/types/daily-activity.type';
import { isMobile } from 'react-device-detect';
import { Stack } from 'react-bootstrap';
import './styles.scss';
import { useChatContext } from 'src/apps/company-frontend/state/chatContext';
import ButtonMarkAllAsPaid from './ButtonMarkAllAsPaid';
import ButtonMarkAllOnboardingAsCompleted from './ButtonMarkAllOnboardingAsCompleted';

interface Props {
  viewType: string;
  groupedBy: string;
  filteredActivities: ActivityDetail[];
  openWorkerSideModal: (jobAssignment: JobAssignment) => void;
  handleConfirmMarkPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
}
export default function ActivityTrackerTableHeader({
  handleConfirmMarkPaid,
  openWorkerSideModal,
  viewType,
  groupedBy,
  filteredActivities,
}: Props) {
  const { openChat } = useChatContext();
  const [jobAssignment, setJobAssignment] = useState<JobAssignment>();

  useEffect(() => {
    if (filteredActivities.length > 0) {
      setJobAssignment(filteredActivities[0].shiftPosition.jobAssignment);
    }
  }, [filteredActivities]);

  if (!jobAssignment) {
    return null;
  }

  return (
    <div className="d-flex justify-content-between align-items-center my-3">
      {viewType === ActivityTrackerView.DATE ? (
        <div>
          <span className="fw-bold at-table-header">
            {format(new Date(groupedBy), DATE_FORMATS.LIST_BY_DATE_FORMAT)}
          </span>
        </div>
      ) : (
        <div>
          <span
            className="fw-bold pe-3 text-capitalize at-table-header cursor-point at-name-link"
            onClick={() => openWorkerSideModal(jobAssignment)}
          >
            {groupedBy}
          </span>

          <TbMessageCircle2
            color="var(--black)"
            className="cursor-point pb-1"
            onClick={() =>
              openChat(jobAssignment.worker, jobAssignment.id, false)
            }
            size={24}
          />
        </div>
      )}
      <Stack direction="horizontal" gap={3}>
        {!isMobile && (
          <ButtonMarkAllOnboardingAsCompleted
            jobAssignments={filteredActivities.map(
              (item) => item.shiftPosition.jobAssignment!
            )}
          />
        )}
        <ButtonMarkAllAsPaid
          filteredActivities={filteredActivities}
          groupedBy={groupedBy}
          handleConfirmMarkPaid={handleConfirmMarkPaid}
        />
      </Stack>
    </div>
  );
}
