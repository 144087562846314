import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { ImNewTab } from 'react-icons/im';
import { useQuery } from '@tanstack/react-query';
import DeleteRowIcon from 'components/Icons/DeleteRowIcon/DeleteRowIcon';
import { formatTimeOrDateNow } from 'utils/DateUtils';
import QueryKeys from 'constants/queryKeys';
import { STALE_ONE_MINUTE } from 'constants/comhub';
import { getReceivedReviewsNewCounter } from 'company/services/com-hub/api';
import { ComHubHeadersEnum, Review } from 'company/types/comm-hub.type';

import './styles.css';
import { ReceivedReviewModel } from 'company/models/ReceivedReviewModel';

const BASE_PATH = import.meta.env.VITE_SERVER_BASE_PATH;

interface ReceivedReviewsTableProps {
  receivedReviews: Review[];
  title: string;
  titleCallback?: () => void;
  onSeeReceivedReview: (review: Review) => void;
  onShowWorkerProfile?: (workerId: number) => void;
  onDismissItems?: (reviews: Review[]) => void;
}

function ReceivedReviewsTable({
  title,
  titleCallback,
  receivedReviews,
  onShowWorkerProfile,
  onSeeReceivedReview,
  onDismissItems,
}: ReceivedReviewsTableProps) {
  const headers = [
    ComHubHeadersEnum.EVENT_NAME,
    ComHubHeadersEnum.WORKER_NAME,
    ComHubHeadersEnum.POSITION,
    ComHubHeadersEnum.DATES_WORKED,
    ComHubHeadersEnum.RATING,
    ComHubHeadersEnum.REPLIED,
    ComHubHeadersEnum.ACTIONS_COLUMN,
  ];

  const newReceivedReviews = receivedReviews.filter(
    (receivedReview) => receivedReview.userState.new
  );

  // FETCH COUNTERS
  const { data: receivedReviewsCounterData } = useQuery({
    queryKey: [QueryKeys.ComHub.RECEIVED_REVIEWS],
    queryFn: getReceivedReviewsNewCounter,
    staleTime: STALE_ONE_MINUTE,
  });

  const handleDismissItems = (itemsToDismiss: Review[]) => {
    if (onDismissItems) {
      onDismissItems(itemsToDismiss);
    }
  };

  return (
    <div className="th-table">
      <header>
        <button
          type="button"
          onClick={titleCallback}
          className={`${titleCallback ? 'cursor-point' : 'cursor-default'}`}
        >
          <h4>
            {title}{' '}
            {receivedReviewsCounterData && (
              <b data-testid="new-counter">
                ({receivedReviewsCounterData.count})
              </b>
            )}
          </h4>
        </button>
        {newReceivedReviews.length ? (
          <Button
            type="button"
            variant="light"
            className="ms-auto fw-bold d-flex"
            onClick={() => handleDismissItems(newReceivedReviews)}
          >
            <DeleteRowIcon className="me-2" />
            Dismiss {newReceivedReviews.length} new
          </Button>
        ) : null}
      </header>
      <hr style={{ margin: '10px 0' }} />
      {receivedReviews.length ? (
        <Table>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* RENDER COLUMNS BY ROW */}
            {receivedReviews.map((receivedReview) => {
              const review = new ReceivedReviewModel(receivedReview);
              return (
                <tr
                  key={review.getUniqueKey()}
                  className={`${review.isNew() ? 'new' : ''}`}
                >
                  <td>
                    {review.jobId ? (
                      <a
                        href={`${BASE_PATH}jobs/${review.jobId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-point event-name-link"
                        style={{ color: 'currentcolor' }}
                      >
                        {review.eventName}
                        <ImNewTab
                          color="var(--yellow-dark-20)"
                          className="ms-2 event-name-link-icon"
                        />
                      </a>
                    ) : (
                      <span>{review.eventName}</span>
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        if (onShowWorkerProfile && review.getWorkerId()) {
                          onShowWorkerProfile(review.getWorkerId()!);
                        }
                      }}
                      className={`${review.isNew() ? 'new' : ''}`}
                    >
                      {review.getWorkerName()}
                    </button>
                  </td>
                  <td>{review.getPosition()}</td>
                  <td>{review.getWorkedDateRange()}</td>
                  <td>{review.getRating()}</td>
                  <td>{review.hasReplied() ? 'YES' : 'NO'}</td>
                  {/* BUTTONS FOR ROW ACTIONS */}
                  <td className="actions">
                    <span className="me-3">
                      {review.companyRating &&
                        formatTimeOrDateNow(review.getDateCreated())}
                    </span>
                    <Button
                      onClick={() => onSeeReceivedReview(receivedReview)}
                      className="action-btn me-2"
                    >
                      open
                    </Button>
                    <button
                      type="button"
                      disabled={!review.isNew()}
                      style={{
                        cursor: !review.isNew() ? 'not-allowed' : 'pointer',
                      }}
                    >
                      {review.userState.type !== 'REVIEW_LEFT' && (
                        <DeleteRowIcon
                          onClick={() => handleDismissItems([receivedReview])}
                          color={!review.isNew() ? 'var(--silver)' : ''}
                        />
                      )}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p>No new notifications.</p>
      )}
      {titleCallback && ( // change this for more accurate prop
        <Button variant="link" onClick={titleCallback} className="show-all-cta">
          Show All
        </Button>
      )}
    </div>
  );
}

export default ReceivedReviewsTable;
