import { format } from 'date-fns';
import { Badge, Col, Image, Row, Stack } from 'react-bootstrap';
import { StarRating } from 'src/components/elements';
import DATE_FORMATS from 'src/constants/dateFormat';
import { Comment } from 'src/types/workerProfile.type';

import '../styles.scss';
import { IRatingVisibility, RATINGS_CURRENT_VERSION } from 'types/rating.type';
import { ReactNode } from 'react';

interface RatingCommentProps {
  comment: Comment;
}

function renderLegacyComment(comment: Comment): ReactNode {
  return <p>{comment.comments}</p>;
}

function renderComment(comment: Comment): ReactNode {
  return (
    <>
      <Stack>
        <b>What did this worker do really well?</b>
        <p>{comment.comments || 'N/A'}</p>
      </Stack>
      <Stack>
        <span>
          <b>What can they improve on?</b>
          <small style={{ color: 'var(--silver)' }}>
            {comment.improvementComments &&
            comment.visibility === IRatingVisibility.PRIVATE
              ? ' (This is visible only for you)'
              : ''}
          </small>
        </span>
        <p>{comment.improvementComments || 'N/A'}</p>
      </Stack>
    </>
  );
}

function RatingComment({ comment }: RatingCommentProps) {
  return comment ? (
    <Stack>
      <Stack direction="horizontal" className="justify-content-between">
        <b className="review-date">
          {format(new Date(comment.dateCreated), DATE_FORMATS.DATE_FORMAT)}
        </b>
        {comment.noCallNoShowed && (
          <Badge className="no-call-no-show">No Call/No Show</Badge>
        )}
        {comment.ratingVersion === RATINGS_CURRENT_VERSION &&
          !comment.noCallNoShowed && (
            <StarRating value={comment.avgRating || 0} color="yellow" />
          )}
      </Stack>
      <Row className="mb-2 align-items-center">
        <Col xs={6} md={3} className="fw-bold">
          Company
        </Col>
        <Col>
          <Image
            width={30}
            height={30}
            src={comment.profileImageUrl}
            roundedCircle
          />
          <span className="ms-2">{comment.companyName}</span>
        </Col>
      </Row>
      {comment.ratingVersion === RATINGS_CURRENT_VERSION && (
        <Row className="mb-2 align-items-center">
          <Col xs={6} md={3} className="fw-bold">
            <b>Position</b>
          </Col>
          <Col>
            <span>{comment.position}</span>
          </Col>
        </Row>
      )}
      {comment.ratingVersion === RATINGS_CURRENT_VERSION && (
        <Row className="mb-2 align-items-center">
          <Col xs={6} md={3} className="fw-bold">
            <b>Days Worked</b>
          </Col>
          <Col>{comment.workedDays}</Col>
        </Row>
      )}
      <hr className="divider" />
      {comment.ratingVersion !== 2
        ? renderLegacyComment(comment)
        : renderComment(comment)}
    </Stack>
  ) : null;
}

export default RatingComment;
