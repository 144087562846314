import { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { JobAssignmentRatingType } from 'company/types/job-assignment-rating.type';
import { replyRating } from 'company/services/com-hub/api';
import { ThLoading } from 'components/elements';
import QueryKeys from 'constants/queryKeys';
import '../styles.css';

interface Props {
  ratingAssignmentData: JobAssignmentRatingType;
  showAlertMessage: (success: boolean, message: string) => void;
  onReplySuccess: () => void;
}

function RatingReply({
  ratingAssignmentData,
  showAlertMessage,
  onReplySuccess,
}: Props) {
  const [replyComment, setReplyComment] = useState('');
  const queryClient = useQueryClient();
  const MIN_REPLY_LENGTH = 5;

  const RATING_BY_JOB_ASSIGNMENT_QUERY_KEY = [
    QueryKeys.WORKER_ASSIGNMENT_JOB,
    ratingAssignmentData.jobAssignmentId,
  ];

  useEffect(() => {
    if (ratingAssignmentData?.companyRating?.ratingReply) {
      setReplyComment(ratingAssignmentData.companyRating.ratingReply.comments);
    } else {
      setReplyComment('');
    }
  }, [ratingAssignmentData]);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      replyRating(ratingAssignmentData.companyRating.id, replyComment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: RATING_BY_JOB_ASSIGNMENT_QUERY_KEY,
      });
      onReplySuccess();
      showAlertMessage(true, 'Reply submitted successfully');
    },
    onError: (error) => {
      console.log(`An error occurred while trying to reply`, error);
      showAlertMessage(
        false,
        'An error occurred while trying to reply, please try again later'
      );
    },
  });

  const hasRatingReply = useMemo(() => {
    return Boolean(
      (ratingAssignmentData.companyRating?.ratingReply?.comments?.length ?? 0) >
        0
    );
  }, [ratingAssignmentData]);

  if (isPending) {
    return <ThLoading />;
  }

  return (
    <div className="mt-3">
      <span>
        <b>
          {!hasRatingReply
            ? 'Reply to this review'
            : 'Reply sent to this review'}
        </b>
      </span>
      <Form.Group className="mb-3">
        {!hasRatingReply && (
          <Form.Label className="text-gray reply-text">
            THIS REPLY WILL BE SHARED PUBLICLY ON YOUR COMPANY PROFILE
          </Form.Label>
        )}
        <Form.Control
          as="textarea"
          rows={3}
          onChange={(e) => setReplyComment(e.target.value)}
          value={replyComment}
          readOnly={hasRatingReply}
        />
      </Form.Group>
      {!hasRatingReply && (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            onClick={() => mutateAsync()}
            disabled={replyComment?.length < MIN_REPLY_LENGTH}
          >
            Submit Reply
          </Button>
        </div>
      )}
    </div>
  );
}

export default RatingReply;
