import { BsStarFill } from '@react-icons/all-files/bs/BsStarFill';
import { BsStarHalf } from '@react-icons/all-files/bs/BsStarHalf';
import { BsStar } from '@react-icons/all-files/bs/BsStar';
import { Stack } from 'react-bootstrap';
import { getStarSize } from 'src/utils/ReviewUtils';

interface Props {
  value: number;
  color?: string;
  size?: number;
}

export default function StarRating({ value: rating, color, size }: Props) {
  function getStarIcon(starPosition: number) {
    if (starPosition <= rating) {
      return (
        <BsStarFill
          size={size ?? 15}
          color={color ? `var(--${color})` : 'var(--black)'}
        />
      );
    }
    const starSize = getStarSize(starPosition, rating);
    if (starSize === 0.5) {
      return (
        <BsStarHalf
          size={size ?? 15}
          color={color ? `var(--${color})` : 'var(--black)'}
        />
      );
    }
    if (starSize === 1) {
      return (
        <BsStarFill
          size={size ?? 15}
          color={color ? `var(--${color})` : 'var(--black)'}
        />
      );
    }
    return (
      <BsStar
        size={size ?? 15}
        color={color ? `var(--${color})` : 'var(--black)'}
      />
    );
  }

  return (
    <Stack
      direction="horizontal"
      className="justify-content-start star-rating lh-1"
      data-testid="star-rating"
      gap={1}
    >
      {[1, 2, 3, 4, 5].map((star) => (
        <div key={star}>{getStarIcon(star)}</div>
      ))}
    </Stack>
  );
}
