import { Stack, Tab, Tabs } from 'react-bootstrap';
import { ThLoading } from 'src/components/elements';
import ProfileDetails from './Tabs/ProfileDetails/ProfileDetails';
import Reviews from './Tabs/Reviews/Reviews';
import Resume from './Tabs/Resume/Resume';
// import Calendar from './Tabs/CalendarTab/CalendarTab';
// import History from './Tabs/History/History';
// import Documents from './Tabs/Documents/Documents';
import { useWorkerProfileContext } from '../../state/workerProfileContext';

import './styles.scss';

function WorkerProfile() {
  const { isLoadingWorkerProfile, workerReviews } = useWorkerProfileContext();
  return isLoadingWorkerProfile ? (
    <ThLoading />
  ) : (
    <Stack className="worker-profile p-3">
      <Tabs>
        <Tab eventKey="details" title="Profile Details">
          <ProfileDetails />
        </Tab>
        <Tab
          eventKey="reviews"
          title={`Reviews (${workerReviews?.length || 0})`}
        >
          <Reviews />
        </Tab>
        <Tab eventKey="resume" title="Resume">
          <Resume />
        </Tab>
        {/* <Tab eventKey="calendar" title="Calendar">
          <Calendar />
        </Tab> */}
        {/* <Tab eventKey="history" title="History"> */}
        {/*  <History /> */}
        {/* </Tab> */}
        {/* <Tab eventKey="documents" title="Documents"> */}
        {/*  <Documents /> */}
        {/* </Tab> */}
      </Tabs>
    </Stack>
  );
}

export default WorkerProfile;
