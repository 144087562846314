import { useJobShiftContext } from 'src/apps/company-frontend/state/jobShiftContext';
import { filterShiftsByDate } from 'src/services/shift/shiftService';
import { removeDuplicatedShifts } from 'src/utils/ShiftsUtils';
import { JobAssignment } from 'src/types/job-assignment.type';
import { BrowserView, MobileView } from 'react-device-detect';
import { ShiftPosition } from 'src/types/job.detail.type';
import { Fragment, useEffect, useMemo } from 'react';
import { ShiftStatus } from 'src/types/jobs.type';
import ShiftTableMobile from './ShiftTableMobile';
import ShiftTable from './ShiftTable';
import './style.scss';

type SortOrder = 'asc' | 'desc';

export interface ShiftSortAttributes {
  key: keyof ShiftPosition;
  order: SortOrder;
}

export const showJobWithNoShiftsMessage = () => {
  return (
    <p>
      You don’t have any shifts on this job. Click the yellow button{' '}
      <b className="text-uppercase">“Create Shift”</b> on this Scheduling tab.
    </p>
  );
};

interface ShiftTablesProps {
  showResumed?: boolean;
  isEditable?: boolean;
  isSelectable?: boolean;
  showOnlyOpenShifts?: boolean;
  openWorkerSideModal?: (jobAssignment: JobAssignment) => void;
}

function ShiftTables({
  showResumed = false,
  isEditable = true,
  isSelectable = true,
  showOnlyOpenShifts = false,
  openWorkerSideModal,
}: ShiftTablesProps) {
  const {
    shifts,
    setSelectedShifts,
    selectedShifts,
    filteredShifts,
    setStatusFilter,
  } = useJobShiftContext();

  useEffect(() => {
    if (showOnlyOpenShifts) {
      setStatusFilter([ShiftStatus.OPEN]);
    }
  }, [setStatusFilter, showOnlyOpenShifts]);

  const filterByDate = useMemo(() => {
    return filterShiftsByDate(
      showOnlyOpenShifts
        ? removeDuplicatedShifts(filteredShifts)
        : filteredShifts
    );
  }, [filteredShifts, showOnlyOpenShifts]);

  const handleSelecting = (id: number) => {
    const newCheckedIds = new Set(selectedShifts.map((x) => x.id));
    if (newCheckedIds.has(id)) {
      newCheckedIds.delete(id);
    } else {
      newCheckedIds.add(id);
    }
    const newSelectedShifts = filteredShifts.filter((item) =>
      newCheckedIds.has(item.id)
    );
    setSelectedShifts(newSelectedShifts || []);
  };

  return (
    <>
      {(!shifts || shifts?.length === 0) && (
        <div className="mt-5 d-flex justify-content-center">
          {showJobWithNoShiftsMessage()}
        </div>
      )}
      {filterByDate.map(({ date, data }) => {
        return (
          <Fragment key={date}>
            <MobileView>
              <ShiftTableMobile
                date={date}
                shifts={data}
                showResumed={showResumed}
                handleSelecting={handleSelecting}
              />
            </MobileView>
            <BrowserView>
              <ShiftTable
                date={date}
                shifts={data}
                handleSelecting={handleSelecting}
                openWorkerSideModal={openWorkerSideModal}
                showResumed={showResumed}
                isSelectable={isSelectable}
                isEditable={isEditable}
              />
            </BrowserView>
          </Fragment>
        );
      })}
    </>
  );
}

export default ShiftTables;
