import { Button, Stack, Table } from 'react-bootstrap';
import { FcCheckmark } from '@react-icons/all-files/fc/FcCheckmark';
import { BiEdit } from '@react-icons/all-files/bi/BiEdit';
import { TbMessageCircle2 } from 'react-icons/tb';
import { FaRegClock } from 'react-icons/fa';
import { format, isBefore } from 'date-fns';
import { isToApproveActivity } from 'services/activity-tracker/activityTrackerService';
import { getFormattedShiftTimes } from 'services/shift/shiftService';
import { convertToTimezone, getDateNowByTimezone } from 'utils/DateUtils';
import { useActivityContext } from 'company/state/activityContext';
import { useChatContext } from 'company/state/chatContext';
import { useJobContext } from 'company/state/jobContext';
import { ActivityDetail } from 'types/daily-activity.type';
import { JobAssignment } from 'types/job-assignment.type';
import { ShiftPosition } from 'src/types/job.detail.type';
import { ActivityTrackerView } from 'types/jobs.type';
import DATE_FORMATS from 'constants/dateFormat';
import './styles.scss';

interface Props {
  handleConfirmMarkPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
  handleConfirmMarkUnPaid: (
    groupedBy: string,
    id: number,
    filteredActivities: ActivityDetail[],
    all?: boolean
  ) => void;
  openWorkerSideModal: (jobAssignment: JobAssignment) => void;
  handleOpenReviewShiftModal: (activity: ActivityDetail) => void;
  handleOpenSubmitHoursModal: (activity: ActivityDetail) => void;
  activities: ActivityDetail[];
  viewType: string;
  groupedBy: string;
}

enum ActivityTrackerStatus {
  IN_PROGRESS = 'In Progress',
  TO_START = 'To Start',
  MISSING_HOURS = 'Missing Hours',
  TO_APPROVE = 'To Approve',
  APPROVED = 'Approved',
}

export default function ActivityTrackerDesktopTable({
  activities,
  groupedBy,
  viewType,
  openWorkerSideModal,
  handleConfirmMarkPaid,
  handleConfirmMarkUnPaid,
  handleOpenSubmitHoursModal,
  handleOpenReviewShiftModal,
}: Props) {
  const { job } = useJobContext();
  const {
    isShiftPayable,
    getCheckInValue,
    getCheckOutValue,
    completeOnboardingAction,
  } = useActivityContext();
  const { openChat } = useChatContext();

  const TABLE_HEADER_WEB = [
    'WORKER',
    'SHIFT STATUS',
    'ONBOARDING',
    'SCHEDULED TIME',
    'CHECK IN',
    'CHECK OUT',
    'SUBMITTED TIME',
    'TOTAL HOURS',
    'WORK PAY',
    'TOTAL PAY',
    'SHIFT PAID',
  ];
  if (viewType === ActivityTrackerView.WORKER) {
    TABLE_HEADER_WEB.shift();
    TABLE_HEADER_WEB.unshift('DATE');
  }

  if (!job) {
    return null;
  }

  const getActivityStatus = (activity: ActivityDetail) => {
    const dateNowOnTz = convertToTimezone(new Date(), job.address.timezoneId);
    const startDate = convertToTimezone(
      activity.shiftPosition.start,
      job.address.timezoneId
    );
    const endDate = convertToTimezone(
      activity.shiftPosition.end,
      job.address.timezoneId
    );

    if (isBefore(dateNowOnTz, startDate)) {
      return ActivityTrackerStatus.TO_START;
    }
    if (isBefore(startDate, dateNowOnTz) && !isBefore(endDate, dateNowOnTz)) {
      return ActivityTrackerStatus.IN_PROGRESS;
    }
    if (!activity.timeSheet) {
      return ActivityTrackerStatus.MISSING_HOURS;
    }
    if (isToApproveActivity(activity)) {
      return ActivityTrackerStatus.TO_APPROVE;
    }
    return ActivityTrackerStatus.APPROVED;
  };

  const getStatusColumn = (activity: ActivityDetail) => {
    const activityStatus = getActivityStatus(activity);
    return (
      <td
        className={`at-status-colors at-status-colors__${activityStatus
          .replace(' ', '_')
          .toLowerCase()}`}
      >
        {activityStatus}
      </td>
    );
  };

  const hasShiftStarted = (shift: ShiftPosition) => {
    return (
      convertToTimezone(new Date(shift.start), job.address.timezoneId) <
      getDateNowByTimezone(job.address.timezoneId)
    );
  };

  return (
    <Table responsive borderless>
      <thead className="table-header fw-bold">
        <tr>
          {TABLE_HEADER_WEB.map((header, index) => (
            <th className={index === 0 ? 'text-start' : ''} key={header}>
              {header}
            </th>
          ))}
          <th aria-label="edit" />
          <th aria-label="shift-review" />
        </tr>
      </thead>
      <tbody className="at-table-body">
        {activities.map((activity) => (
          <tr key={activity.shiftPosition.id}>
            <td className="text-start text-truncate" style={{ width: '180px' }}>
              {viewType === ActivityTrackerView.DATE ? (
                <span
                  className="text-capitalize cursor-point at-name-link"
                  onClick={() =>
                    openWorkerSideModal(activity.shiftPosition.jobAssignment!)
                  }
                >
                  {activity.shiftPosition.jobAssignment!.worker.fullName}
                </span>
              ) : (
                format(
                  new Date(activity.shiftPosition.date),
                  DATE_FORMATS.DATE_FORMAT_SHORT
                )
              )}
            </td>
            {getStatusColumn(activity)}
            <td>
              {!activity.shiftPosition.jobAssignment?.onBoardingCompleted ? (
                <Button
                  size="sm"
                  variant="light"
                  className="row-at-button"
                  onClick={() =>
                    completeOnboardingAction([
                      activity.shiftPosition.jobAssignment!,
                    ])
                  }
                >
                  MARK COMPLETED
                </Button>
              ) : (
                <FcCheckmark
                  color="var(--black)"
                  className="cursor-point"
                  onClick={() =>
                    completeOnboardingAction(
                      [activity.shiftPosition.jobAssignment!],
                      false
                    )
                  }
                />
              )}
            </td>
            <td>
              {getFormattedShiftTimes(activity.shiftPosition, job?.timezone)}
            </td>
            <td>{getCheckInValue(activity, handleOpenReviewShiftModal)}</td>
            <td>{getCheckOutValue(activity, handleOpenReviewShiftModal)}</td>
            <td>
              {(activity.timeSheet?.localizedStart &&
                activity.timeSheet?.localizedEnd && (
                  <div>
                    {`${format(
                      new Date(activity.timeSheet.localizedStart),
                      DATE_FORMATS.TIME_FORMAT
                    )} - ${format(
                      new Date(activity.timeSheet.localizedEnd),
                      DATE_FORMATS.TIME_FORMAT
                    )}`}
                  </div>
                )) ||
                '-'}
            </td>
            <td>{activity.timeSheet?.hoursWorked || '-'}</td>
            <td>
              {activity.shiftPosition?.wage && (
                <div>
                  {'$ '}
                  {Number(activity.shiftPosition.wage.payRate)}/
                  {activity.shiftPosition.wage.payRatePeriod.toLowerCase()}
                </div>
              )}
            </td>
            <td>
              {(activity.timeSheet?.workerEarnedMoney && (
                <div>
                  {'$ '}
                  {Number(activity.timeSheet?.workerEarnedMoney).toFixed(2)}
                </div>
              )) ||
                '-'}
            </td>
            <td>
              {activity.shiftPosition.isPaid ? (
                <FcCheckmark
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleConfirmMarkUnPaid(
                      groupedBy,
                      activity.shiftPosition.id,
                      activities,
                      true
                    );
                  }}
                />
              ) : (
                <Button
                  size="sm"
                  variant="light"
                  className="row-at-button"
                  disabled={!isShiftPayable(activity)}
                  onClick={() =>
                    handleConfirmMarkPaid(
                      groupedBy,
                      activity.shiftPosition.id,
                      activities
                    )
                  }
                >
                  MARK PAID
                </Button>
              )}
            </td>
            <td className="text-end">
              <Stack direction="horizontal" gap={2} className="text-end">
                {viewType === ActivityTrackerView.DATE && (
                  <TbMessageCircle2
                    color="var(--black)"
                    size={24}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (activity.shiftPosition.jobAssignment) {
                        openChat(
                          activity.shiftPosition.jobAssignment.worker,
                          activity.shiftPosition.jobAssignment.id,
                          false
                        );
                      }
                    }}
                  />
                )}
                {!activity.timeSheet &&
                  hasShiftStarted(activity.shiftPosition) && (
                    <FaRegClock
                      color="var(--black)"
                      size={20}
                      className="cursor-point"
                      onClick={() => handleOpenSubmitHoursModal(activity)}
                    />
                  )}
                <BiEdit
                  color="var(--black)"
                  size={24}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenReviewShiftModal(activity)}
                  aria-label={`Review ${activity.shiftPosition.jobAssignment?.worker.fullName}`}
                />
              </Stack>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
