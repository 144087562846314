import ThWorkerRow from 'src/apps/company-frontend/components/th/ThWorkerRow/ThWorkerRow';
import { AiTwotoneCalendar } from '@react-icons/all-files/ai/AiTwotoneCalendar';
import { RiSuitcaseLine } from '@react-icons/all-files/ri/RiSuitcaseLine';
import { RiHistoryFill } from '@react-icons/all-files/ri/RiHistoryFill';
import { HiArrowLeft } from '@react-icons/all-files/hi/HiArrowLeft';
import { ThTabs } from 'src/apps/company-frontend/components/th';
import { Panel, ThError, ThLoading } from 'components/elements';
import { IoMdOpen } from '@react-icons/all-files/io/IoMdOpen';
import { CgNotes } from '@react-icons/all-files/cg/CgNotes';
import { BiUser } from '@react-icons/all-files/bi/BiUser';
import {
  WorkerDetails,
  WorkerNotes,
  WorkerResume,
} from 'src/apps/company-frontend/components/WorkerParts';
import { IWorkerData } from 'types/worker.type';
import { FiChevronDown } from 'react-icons/fi';
import { isMobile } from 'react-device-detect';
import { FaRegStar } from 'react-icons/fa';
import { ITag } from 'types/tag.type';
import { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Row,
  Stack,
  Tab,
} from 'react-bootstrap';
import useUpdateWorker from 'company/state/useUpdateWorker/useUpdateWorker';
import IJobDetailData from 'types/job.detail.type';
import LoaderJobWorkerActions from '../th/ThWorkerRow/LoaderJobWorkerActions';
import { useJobAssignmentContext } from '../../state/jobAssignmentContext';
import { useJobShiftContext } from '../../state/jobShiftContext';
import { IWorkerAssignment } from '../../types/workerAssignment';
import JobWorkerShifts from './JobWorkerShifts/JobWorkerShifts';
import LoaderApplicationInfo from './LoaderApplicationInfo';
import CompanyWorkerHistory from './CompanyWorkerHistory';
import JobShortDescription from './JobShortDescription';
import JobWorkerCalendar from './JobWorkerCalendar';
import { ApplicationInfo } from './ApplicationInfo';
import JobWorkerActions from './JobWorkerActions';
import { WorkerReviews } from '../WorkerReviews';
import JobWorkerShiftsHistory from './JobWorkerShifts/JobWorkerShiftsHistory';

interface JobWorkerProps {
  worker: IWorkerData;
  closeWorkerProfile: () => void;
  openReviewModal: () => void;
  job: IJobDetailData;
  isInternalMember: (id: number) => boolean;
  fetchApplicantCounters: () => void;
}

enum JobWorkerTab {
  THIS_JOB,
  PROFILE_DETAILS,
  REVIEWS,
  CALENDAR,
  HISTORY,
  DOCUMENTS,
  RESUME,
  INTERNAL_NOTES,
}

const SERVER_URL = import.meta.env.VITE_SERVER_BASE_URL;

export default function JobWorker({
  worker,
  closeWorkerProfile,
  openReviewModal,
  job,
  isInternalMember,
  fetchApplicantCounters,
}: JobWorkerProps) {
  const [activeKey, setActiveKey] = useState<JobWorkerTab>(
    JobWorkerTab.THIS_JOB
  );
  const { manuallyUpdateWorker } = useUpdateWorker();
  const {
    workerJobData,
    isLoadingWorkerProfileData,
    refetch: refetchWorkerJob,
  } = useJobAssignmentContext();
  const [notesCount, setNotesCount] = useState(0);

  const {
    shifts,
    refetch: refetchShifts,
    setSelectedShifts,
  } = useJobShiftContext();

  if (!job) {
    return null;
  }

  const getTags = (matchSkills?: boolean, hired?: boolean): ITag[] => {
    const tags: ITag[] = [];
    if (matchSkills) {
      tags.push({
        label: 'MATCH',
        bg: 'light-success',
        bgText: 'text-success',
      });
    }
    if (hired) {
      tags.push({
        label: 'HIRED',
        bg: 'primary',
        bgText: 'light',
      });
    }
    return tags;
  };

  const getWorkerActivity = () =>
    workerJobData?.jobAssignment && workerJobData?.jobAssignmentLogs
      ? {
          thJobAssignment: workerJobData.jobAssignment,
          thJobAssignmentLogList: workerJobData.jobAssignmentLogs,
        }
      : ({} as IWorkerAssignment);

  const getThisJobTabComponent = () => {
    if (isLoadingWorkerProfileData) {
      return <LoaderApplicationInfo screen="desktop" />;
    }

    return (
      <Stack>
        <Row>
          {workerJobData?.jobAssignment && (
            <Col className="mt-3">
              {shifts?.length === 0 ? (
                <p className="panel">
                  You don’t have any open shifts for this job. You can start to
                  create shifts clicking on the <b>SCHEDULING</b> tab on the
                  job.
                </p>
              ) : (
                <>
                  <JobWorkerShifts worker={worker} />
                  {workerJobData?.jobPlacementHistoryList && (
                    <div className="mt-3">
                      <JobWorkerShiftsHistory
                        job={job}
                        jobPlacementHistoryList={
                          workerJobData.jobPlacementHistoryList
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          )}
          <Col md={workerJobData?.jobAssignment ? 4 : 12}>
            <Stack>
              <ApplicationInfo
                worker={worker}
                jobQuestionResponseList={workerJobData?.jobQuestionList}
                jobApplication={workerJobData?.jobApplication}
                workerAssignment={getWorkerActivity()}
              />
            </Stack>
          </Col>
        </Row>
      </Stack>
    );
  };

  const jobWorkerTabs = [
    {
      key: JobWorkerTab.THIS_JOB,
      icon: <RiSuitcaseLine size={20} />,
      label: 'This Job',
      component: getThisJobTabComponent(),
    },
    {
      key: JobWorkerTab.PROFILE_DETAILS,
      icon: <BiUser size={20} />,
      label: 'Profile Details',
      component: workerJobData ? (
        <WorkerDetails
          key="workerDetail"
          workerProfile={workerJobData}
          distance={workerJobData.jobAssignment?.distance}
          tags={getTags(
            workerJobData.jobAssignment?.matchSkills,
            workerJobData.jobAssignment?.hired
          )}
        />
      ) : (
        <ThError message="No data found" />
      ),
    },
    {
      key: JobWorkerTab.REVIEWS,
      icon: <FaRegStar size={20} />,
      label: `Reviews (${workerJobData?.comments?.length ?? 0})`,
      component: <WorkerReviews comments={workerJobData?.comments} />,
    },
    {
      key: JobWorkerTab.RESUME,
      icon: <CgNotes size={20} />,
      label: 'Resume',
      component: <WorkerResume worker={worker} />,
    },
    {
      key: JobWorkerTab.CALENDAR,
      icon: <AiTwotoneCalendar size={20} />,
      label: 'Calendar',
      component: <JobWorkerCalendar worker={worker} />,
    },
    {
      key: JobWorkerTab.HISTORY,
      icon: <RiHistoryFill size={20} />,
      label: 'History',
      component: (
        <CompanyWorkerHistory
          worker={worker}
          isActive={activeKey.toString() === JobWorkerTab.HISTORY.toString()}
        />
      ),
    },
    // TODO: Unhide when implemented
    // {
    //   key: JobWorkerTab.DOCUMENTS,
    //   icon: <FiFolder size={20} />,
    //   label: 'Documents',
    //   component: <JobWorkerDocuments />,
    // },
    {
      key: JobWorkerTab.INTERNAL_NOTES,
      icon: <CgNotes size={20} />,
      label: `Internal Notes (${notesCount})`,
      component: (
        <WorkerNotes
          handleNotesCount={setNotesCount}
          worker={worker}
          isActive={
            activeKey.toString() === JobWorkerTab.INTERNAL_NOTES.toString()
          }
        />
      ),
    },
  ];

  const tags = [
    {
      label: 'MATCH',
      bg: 'light-success',
      bgText: 'text-success',
    },
  ];

  const reloadJobWorker = async (
    fetchShifts: boolean = false,
    fetchCounters: boolean = false
  ) => {
    await refetchWorkerJob();
    manuallyUpdateWorker({
      workerId: worker.id,
      jobId: job.id,
      workerProfile: workerJobData,
    });
    if (fetchShifts) {
      await refetchShifts();
      if (shifts) {
        setSelectedShifts([]);
      }
    }
    if (fetchCounters) {
      await fetchApplicantCounters();
    }
  };

  return isLoadingWorkerProfileData && !workerJobData ? (
    <ThLoading />
  ) : (
    <Container className="vw-75">
      <div>
        <Stack direction="horizontal" gap={3}>
          <div onClick={closeWorkerProfile}>
            <HiArrowLeft size={20} />
            &nbsp; <b>Back to Job</b>
          </div>
          <div className="ms-auto">
            <Button
              variant="link"
              onClick={() =>
                window.open(
                  `${SERVER_URL}/w/${worker.customUrl ?? worker.id}`,
                  '_blank'
                )
              }
              style={{
                textDecoration: 'none',
                color: 'unset',
                fontSize: '16px',
              }}
            >
              <IoMdOpen size={20} />
              {!isMobile && <b>&nbsp;Open profile in new window</b>}
            </Button>
          </div>
        </Stack>
        <hr />
      </div>
      {job && (
        <JobShortDescription
          jobAssignment={workerJobData?.jobAssignment ?? null}
        />
      )}
      <Row>
        <Col md={4} className="d-none d-sm-block">
          <ThWorkerRow
            worker={worker}
            workerProfile={workerJobData}
            distance={workerJobData?.jobAssignment?.distance}
            tags={tags}
            isPremiumCompany
            shareWorkerProfile
          />
        </Col>
        <Col md={8} className="mt-2 d-none d-sm-block">
          {isLoadingWorkerProfileData && <LoaderJobWorkerActions />}
          {workerJobData && job?.datePublished && (
            <JobWorkerActions
              workerProfile={workerJobData}
              openReviewModal={openReviewModal}
              job={job}
              checkInternalMember={isInternalMember}
              reloadJobWorker={reloadJobWorker}
              isLoadingUpdateWorker={isLoadingWorkerProfileData}
            />
          )}
        </Col>
      </Row>
      <Row className="mt-2">
        {isMobile ? (
          <>
            <Dropdown>
              <Dropdown.Toggle variant="">
                <Stack direction="horizontal" gap={2}>
                  <b>
                    {jobWorkerTabs.find((tab) => tab.key === activeKey)?.icon}
                    &nbsp;
                    {jobWorkerTabs.find((tab) => tab.key === activeKey)?.label}
                  </b>
                  <FiChevronDown size={24} />
                </Stack>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {jobWorkerTabs.map((tab) => (
                  <Dropdown.Item
                    key={tab.key}
                    onClick={() => setActiveKey(tab.key)}
                  >
                    {tab.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {activeKey !== JobWorkerTab.PROFILE_DETAILS && (
              <Panel>
                <ThWorkerRow
                  worker={worker}
                  workerProfile={workerJobData}
                  distance={workerJobData?.jobAssignment?.distance}
                  tags={tags}
                  isPremiumCompany
                />
              </Panel>
            )}
            {jobWorkerTabs.find((tab) => tab.key === activeKey)?.component}
          </>
        ) : (
          <ThTabs
            fill
            activeKey={activeKey}
            onSelect={(k: any) => setActiveKey(k)}
            borderBottom
          >
            {jobWorkerTabs.map((tab) => (
              <Tab
                key={tab.key}
                eventKey={tab.key}
                title={
                  <div className="d-flex justify-content-center">
                    {tab.icon}
                    &nbsp;{tab.label}
                  </div>
                }
              >
                {tab.component}
              </Tab>
            ))}
          </ThTabs>
        )}
      </Row>
    </Container>
  );
}
