import { JobAssignment } from 'src/types/job-assignment.type';
import IResultResponse from 'src/types/resultResponse.type';
import { PayRatePeriod } from 'src/types/payRatePeriod';
import { NullableString } from 'src/types/common.type';
import { privateApi } from 'services/api';

export const MAIN_URL = 'jobs';
export const ASSIGNMENTS_URL = 'assignments';

export const shareJobToWorker = async (
  jobId: number | undefined,
  workerId: number
) => {
  if (jobId === undefined) {
    return Promise.reject(new Error('No job id was passed'));
  }
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}/${jobId}/share-job/${workerId}`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to share job with worker. \njobId: ${jobId} \nworkerId: ${workerId}`
      );
      console.log(error);
    });

  return result?.data;
};

export const offerJob = async (
  jobPositionTitleId: number,
  jobPositionDescription: NullableString,
  jobId: number,
  workerId: number,
  comments: NullableString,
  payRate?: number,
  payRatePeriod?: PayRatePeriod,
  shiftIds?: number[]
) => {
  const result = await privateApi
    .post<IResultResponse>(`${MAIN_URL}/${jobId}/offer-job/${workerId}`, {
      jobPositionTitleId,
      jobPositionDescription,
      payRate,
      payRatePeriod,
      comments,
      shiftIds,
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried offer this job to worker. Job: ${jobId} / Worker: ${workerId}`
      );
      console.log(error);
      return error.response;
    });

  return result?.data;
};

export const hireWorker = async (
  jobTitleId: number,
  jobTitleDescription: NullableString,
  hireText: NullableString,
  jobAssignment?: JobAssignment,
  payRate?: number,
  payRatePeriod?: PayRatePeriod
) => {
  const result = await privateApi
    .post<IResultResponse>(`${ASSIGNMENTS_URL}/${jobAssignment?.id}/hire`, {
      payRate: payRate || jobAssignment?.payRate,
      payRatePeriod: payRatePeriod || jobAssignment?.job?.payRatePeriod,
      jobPositionType: jobAssignment?.type,
      comments: hireText,
      jobPositionTitleId: jobTitleId,
      jobPositionDescription: jobTitleDescription,
    })
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried hire the applicant. jobAssignmentId: ${jobAssignment?.id}`
      );
      return error.response;
    });

  return result?.data;
};

export const cancelHiredWorker = async (jobAssignmentId: number) => {
  const result = await privateApi
    .post<IResultResponse>(`${ASSIGNMENTS_URL}/${jobAssignmentId}/cancel`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to cancel a hired worker.`
      );
      return error.response;
    });
  return result.data;
};

export const declineWorkerApplication = async (jobAssignmentId: number) => {
  const result = await privateApi
    .post<IResultResponse>(`${ASSIGNMENTS_URL}/${jobAssignmentId}/decline`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to decline a worker application.`
      );
      return error.response;
    });
  return result.data;
};

export const cancelJobOffer = async (jobId: number, jobPlacementId: number) => {
  const result = await privateApi
    .post<IResultResponse>(
      `${MAIN_URL}/${jobId}/offers/${jobPlacementId}/cancel`,
      {
        comments: 'Offer cancelled by a company member',
      }
    )
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried cancel a job offer. jobPlacementId: ${jobPlacementId}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};

export const reconsiderJobOffer = async (
  jobId: number,
  jobPlacementId: number
) => {
  const result = await privateApi
    .post<IResultResponse>(
      `${MAIN_URL}/${jobId}/offers/${jobPlacementId}/reconsider`,
      {
        comments: 'Offer cancelled by a company member',
      }
    )
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried cancel a job offer. jobPlacementId: ${jobPlacementId}`
      );
      console.log(error);
      return error.response;
    });

  return result.data;
};

export const reconsiderWorker = async (jobAssignmentId: number) => {
  const result = await privateApi
    .post<IResultResponse>(`${ASSIGNMENTS_URL}/${jobAssignmentId}/reconsider`)
    .catch((error) => {
      console.log(
        `Occurred an error when Trusted Herd tried to cancel a hired worker.`
      );
      return error.response;
    });
  return result.data;
};
