import Wizard from 'src/components/elements/Wizard';
import { useEffect, useMemo, useState } from 'react';
import { ISuggestion } from 'src/types/suggestions.type';
import { useLocation, useParams } from 'react-router-dom';
import { getJobAssignment } from 'worker/services/job/api';
import { ThLoading } from 'src/components/elements';
import { IJobOptionsData } from 'src/types/jobs.type';
import { fetchJobPositionList } from 'src/apps/company-frontend/services/job/api';
import { IRatingVisibility } from 'src/types/rating.type';
import RateCompany from './RateCompany';
import AddNewGig from './AddNewGig';
import { Body } from '../../components/layout';
import {
  CompanyRating,
  IJobAssignmentData,
} from '../../types/job-assignment.type';
import ReviewLastStep from './ReviewLastStep';
import IRateCompanyCreation from '../../types/rate-company-creation.type';
import RateCompanyPreview from './RateCompanyPreview';
import INonTHJobCreation from '../../types/non-th-job-creation.type';
import AddNewGigPreview from './AddNewGigPreview';

function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function LeaveReview() {
  const [selectedCompany, setSelectedCompany] = useState<
    ISuggestion | undefined
  >();
  const params = useParams();
  const { jobAssignmentId } = params;
  const queryParams = useQueryParams();
  const companyName = queryParams.get('companyName');
  const askForRating = queryParams.get('askForRating');
  const [jobAssignment, setJobAssignment] = useState<IJobAssignmentData>();
  const [isFinished, setFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rateCompanyCreation, setRateCompanyCreation] = useState<
    IRateCompanyCreation | undefined
  >();
  const [nonTHJobCreation, setNonTHJobCreation] = useState<INonTHJobCreation>();
  const [jobOptions, setJobOptions] = useState<IJobOptionsData | null>(null);
  const [companyOptions, setCompanyOptions] = useState<ISuggestion[]>([]);

  const populateRateCompanyCreation = (companyRating: CompanyRating) => {
    const body: IRateCompanyCreation = {
      communication: companyRating.communication,
      atEventManagement: companyRating.atEventManagement,
      professionalism: companyRating.professionalism,
      comments: companyRating.comments,
      improvementComments: companyRating.improvementComments,
      payment: companyRating.payment,
      visibility: companyRating.visibility
        ? IRatingVisibility.PUBLIC
        : IRatingVisibility.PRIVATE,
    };
    setRateCompanyCreation(body);
  };

  useEffect(() => {
    const fetchJobAssignment = async () => {
      if (jobAssignmentId) {
        setIsLoading(true);
        try {
          const result = await getJobAssignment(Number(jobAssignmentId));
          setJobAssignment(result);
          if (result.companyRating) {
            populateRateCompanyCreation(result.companyRating);
          }
        } catch (err) {
          console.log('Job Assignment not found');
        }
        setIsLoading(false);
      }
    };
    fetchJobAssignment();

    const fetchJobPosition = async () => {
      try {
        const response = await fetchJobPositionList();
        setJobOptions(response);
      } catch (error) {
        console.error('Error fetching Job Options:', error);
      }
    };
    fetchJobPosition();
  }, [jobAssignmentId]);

  return (
    <Body>
      {isLoading && <ThLoading />}
      <Wizard>
        {!askForRating && !jobAssignmentId && (
          <AddNewGig
            stepTitle="Gig Info"
            setSelectedCompany={setSelectedCompany}
            companyNameParam={companyName}
            setNonTHJobCreation={setNonTHJobCreation}
            jobOptions={jobOptions}
            setCompanyOptions={setCompanyOptions}
            companyOptions={companyOptions}
          />
        )}
        {!askForRating && !jobAssignmentId && (
          <AddNewGigPreview
            stepTitle="Preview Gig Info"
            setSelectedCompany={setSelectedCompany}
            setJobAssignment={setJobAssignment}
            nonTHJobCreation={nonTHJobCreation}
            jobOptions={jobOptions}
            companyOptions={companyOptions}
          />
        )}
        {!askForRating && (
          <RateCompany
            stepTitle="Company Rating"
            selectedCompany={selectedCompany}
            jobAssignment={jobAssignment}
            setFinished={setFinished}
            setRateCompanyCreation={setRateCompanyCreation}
          />
        )}
        {!askForRating && (
          <RateCompanyPreview
            stepTitle="Company Rating Preview"
            jobAssignment={jobAssignment}
            setFinished={setFinished}
            rateCompanyCreation={rateCompanyCreation}
          />
        )}
        <ReviewLastStep
          startedJobAssignmentParam={!!jobAssignmentId}
          stepTitle="Rise In Status"
          jobAssignment={jobAssignment}
          isFinished={isFinished}
          setFinished={setFinished}
        />
      </Wizard>
    </Body>
  );
}

export default LeaveReview;
