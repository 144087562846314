import { privateApi } from 'src/services/api';
import ICompanyMemberIndicators from 'src/types/company-member-indicators.type';

export const MAIN_URL = 'notifications';

export const fetchCompanyMemberNotifications = async () => {
  const url = `${MAIN_URL}/indicators`;
  const result = await privateApi.get<ICompanyMemberIndicators>(url);
  return result.data;
};
