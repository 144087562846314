import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ThLoading } from 'components/elements';
import ThPagination from 'components/ThPagination/ThPagination';
import FilterBar from 'company/components/CommunicationHub/FilterBar/FilterBar';
import ReviewsAndEndorsementsTable from 'company/components/CommunicationHub/CommunicationHubTables/ReviewsAndEndorsementsTable';
import { getReviewsAndEndorsements } from 'company/services/com-hub/api';
import {
  ComHubQueryParamsEnum,
  ComHubTabs,
  GivenReview,
} from 'company/types/comm-hub.type';
import QueryKeys from 'constants/queryKeys';

interface ReviewsAndEndoresementsLeftProps {
  onShowWorkerProfile?: (workerId: number) => void;
  onSeeGivenReview: (review: GivenReview) => void;
  onSeeGivenEndorsement: (endorsementCaseId: number) => void;
}

function ReviewsAndEndoresementsLeft({
  onShowWorkerProfile,
  onSeeGivenReview,
  onSeeGivenEndorsement,
}: ReviewsAndEndoresementsLeftProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentOffset, setCurrentOffset] = useState(
    Number(searchParams.get(ComHubQueryParamsEnum.OFFSET)) || 0
  );
  const [searchState, setSearchState] = useState<{
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }>();

  const itemsPerPage = 20;
  const queryKeyReviewAndEndorsements = [
    QueryKeys.ComHub.GIVEN_REVIEWS,
    currentOffset,
    itemsPerPage,
    searchState,
  ];

  const { data: reviewsLeftResponse, isLoading } = useQuery({
    queryKey: queryKeyReviewAndEndorsements,
    queryFn: () =>
      getReviewsAndEndorsements(
        currentOffset,
        itemsPerPage,
        searchState?.searchText,
        searchState?.dateRange
      ),
  });

  const handleOnSearch = (search: {
    searchText: string;
    dateRange: { startDate: Date | undefined; endDate: Date | undefined };
  }) => {
    setSearchState(search);
    if (search.searchText) {
      searchParams.set(ComHubQueryParamsEnum.SEARCH_TEXT, search.searchText);
    } else {
      searchParams.delete(ComHubQueryParamsEnum.SEARCH_TEXT);
    }
    if (search.dateRange.startDate) {
      searchParams.set(
        ComHubQueryParamsEnum.DATE_FROM,
        search.dateRange.startDate.toISOString()
      );
    } else {
      searchParams.delete(ComHubQueryParamsEnum.DATE_FROM);
    }
    if (search.dateRange.endDate) {
      searchParams.set(
        ComHubQueryParamsEnum.DATE_TO,
        search.dateRange.endDate.toISOString()
      );
    } else {
      searchParams.delete(ComHubQueryParamsEnum.DATE_TO);
    }
    setSearchParams(searchParams);
    setCurrentOffset(0);
  };

  const handleOnClear = () => {
    setSearchState(undefined);
    searchParams.delete(ComHubQueryParamsEnum.SEARCH_TEXT);
    searchParams.delete(ComHubQueryParamsEnum.DATE_FROM);
    searchParams.delete(ComHubQueryParamsEnum.DATE_TO);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (currentOffset) {
      searchParams.set(ComHubQueryParamsEnum.OFFSET, currentOffset.toString());
      searchParams.set(ComHubQueryParamsEnum.MAX, itemsPerPage.toString());
    } else {
      searchParams.delete(ComHubQueryParamsEnum.OFFSET);
      searchParams.delete(ComHubQueryParamsEnum.MAX);
    }
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOffset]);

  return (
    <>
      <FilterBar onSearch={handleOnSearch} onClear={handleOnClear} />
      {isLoading && <ThLoading />}

      {reviewsLeftResponse?.reviews ? (
        <>
          <ReviewsAndEndorsementsTable
            title={ComHubTabs.REVIEWS_AND_ENDORSEMENTS}
            givenReviews={reviewsLeftResponse.reviews}
            onSeeGivenReview={onSeeGivenReview}
            onSeeGivenEndorsement={onSeeGivenEndorsement}
            onShowWorkerProfile={onShowWorkerProfile}
          />
          <ThPagination
            currentOffset={currentOffset}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentOffset}
            totalItems={reviewsLeftResponse.pagination.count}
          />
        </>
      ) : null}
    </>
  );
}

export default ReviewsAndEndoresementsLeft;
