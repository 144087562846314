import { useState } from 'react';
import {
  Badge,
  Col,
  Figure,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from 'react-bootstrap';
import { chain } from 'lodash';
import { LuCheckCircle } from 'react-icons/lu';
import { GoPaperclip } from 'react-icons/go';
import THCarousel from 'components/elements/THCarousel';
import { ICertificatesData } from 'types/certificates.type';

interface Params {
  certificateList: ICertificatesData;
}

export default function WorkerCertificates({ certificateList }: Params) {
  const { certificates, templates } = certificateList;
  const [selectedType, setSelectedType] = useState('ALL');

  let templateTypes = chain(templates)
    .groupBy('type')
    .map((value, key) => ({
      templateType: key,
      values: value,
      certificates: certificates.filter(
        (cert) => !value.some((temp) => cert.templateId === temp.id)
      ),
    }))
    .value();
  templateTypes = [
    { templateType: 'ALL', values: templates, certificates },
    ...templateTypes,
  ];

  const buildWorkerCertificates = () => {
    const items: any[] = [];
    templateTypes.forEach((type) => {
      if (type.templateType === selectedType) {
        type.certificates.forEach((certificate) => {
          const template = templates.find(
            (t) => t.id === certificate.templateId
          );
          const item = (
            <Stack
              key={certificate.id}
              className="align-items-center text-center"
              style={{ width: 120 }}
            >
              <Figure.Image
                className="avatar rounded-circle"
                src={template?.imageUrl}
                width={85}
                height={85}
              />
              {template?.name}

              <Stack
                direction="horizontal"
                className="justify-content-center"
                gap={2}
              >
                <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={
                    <Popover
                      id="popover-basic"
                      style={{ width: '350px', maxWidth: 'unset' }}
                    >
                      <Popover.Body>
                        <Stack>
                          <b>{template?.name}</b>
                          <hr />
                          <b>Issuing Company/Entity</b>
                          <p>{certificate.issuingCompany}</p>
                          <b>Date Issued</b>
                          <p>{certificate.issueDate}</p>
                          <b>Date Expires</b>
                          <p>{certificate.expireDate}</p>
                          <b>Certification/License #</b>
                          <p>{certificate.certificateCode}</p>
                        </Stack>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span>
                    <LuCheckCircle size={20} color="var(--green)" />
                  </span>
                </OverlayTrigger>
                <a
                  href={certificate.certificateFile as unknown as string} // IN THE WORKER APP THE URL IS ATTR OF certificateFile
                  target="_blank"
                  className="text-dark lh-1"
                  rel="noreferrer"
                >
                  <GoPaperclip size={20} className="cursor-point" />
                </a>
              </Stack>
            </Stack>
          );
          items.push(item);
        });
      }
    });
    return items;
  };

  return (
    <>
      <Row md="auto">
        {templateTypes.map((type) => (
          <Col key={type.templateType} className="p-1">
            <Badge
              className="text-dark"
              bg={type.templateType === selectedType ? 'primary' : 'light'}
              onClick={() => setSelectedType(type.templateType)}
            >
              {type.templateType} ({type.certificates.length}/
              {type.values.length})
            </Badge>
          </Col>
        ))}
      </Row>
      {certificates && <THCarousel items={buildWorkerCertificates()} />}
    </>
  );
}
